import { Injectable, PLATFORM_ID, Inject } from "@angular/core";
import { HttpHandler } from "./httpHandler";
import { ShowsQuery } from "./showsQuery";
import { ProgramsQuery } from "./programsQuery";
import { PlaylistQuery } from "./playlistQuery";
import { environment } from "../../environments/environment";
import { isPlatformBrowser } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { ROUTES } from "../data/routes";
declare let $: any;

@Injectable()
export class NetworkService extends HttpHandler {
  public getOnBoardingQuestions() {
    const url = "onBoarding/";
    return this.get(url);
  }

  public getMenusData() {
    const url = "public/config/navigationManagement/";
    return this.get(url);
  }

  public logister(
    email: string,
    profilePhoto: string = null,
    dateOfBirth: string = null
  ) {
    const url = "logister/";
    return this.post(url, {
      email: email,
      date_of_birth: dateOfBirth,
      profile_photo: profilePhoto
    });
  }

  public getUser() {
    const url = "user/";
    return this.get(url);
  }

  public deleteUser() {
    const url = `${ROUTES.USER_MAIN_ROUTE}${ROUTES.DELETE_USER}`;
    return this.delete(url);
  }

  public saveBoardingQuestions(name, gender, questions) {
    const url = "onBoarding/user/";
    return this.put(url, {
      gender: gender == "male" ? 1 : gender == "female" ? 2 : 3,
      nickname: name,
      questions: questions
    });
  }

  public updateProfile(profile) {
    const url = "user/";
    return this.put(url, profile);
  }

  public sendContactUs(obj) {
    const url = "public/contactUs/";
    return this.post(url, obj);
  }

  public getPrograms(query: ProgramsQuery) {
    const url = "programs";
    let params = query.toString();
    return this.get(url + "?" + params);
  }

  public getProgramByID(programID) {
    const url = "programs/program/" + programID;
    return this.get(url);
  }
  
  public getAllRadioBroadcasts() {
    const url = "public/radio-single-broadcast/all"
    return this.get(url);
  }

  public searchShows(query: any) {
    const url = "shows/search";
    // let params = query.toString();
    return this.get(url + "?" + query);
  }

  public searchShowByID(showID: string) {
    const url = "shows/" + showID;
    return this.get(url);
  }

  public searchArtist(query: ShowsQuery) {
    const url = "shows/search/artist";
    let params = query.toString();
    return this.get(url + "?" + params);
  }

  public getPopularArtist() {
    const url = "artists/popular";
    return this.get(url);
  }

  public searchArtistByID(artistID: string) {
    const url = "artists/" + artistID;
    return this.get(url);
  }

  public searchVenue(query: ShowsQuery) {
    const url = "shows/search/venue";
    let params = query.toString();
    return this.get(url + "?" + params);
  }

  public searchVenueByID(venueID: string) {
    const url = "venues/" + venueID;
    return this.get(url);
  }

  public getRecommendedShows(geoRestriction: string) {
    const url = "shows/recommended";
    var query = geoRestriction ? "?geoRestriction=" + geoRestriction : "";
    if (!geoRestriction) {
      var start =
        new Date().getFullYear() +
        "/" +
        this._to2digit(new Date().getMonth() + 1) +
        "/" +
        this._to2digit(new Date().getDate());
      query = query + "?start=" + start + "&end=" + start;
    }
    return this.get(url + query);
  }
  public getTermsAndAboutTextsForCategory(category) {
    const url = "programs/details/" + category;
    return this.get(url);
  }

  public getProPackages() {
    const url = "config/paymentPlans/";
    return this.get(url);
  }

  public getNextScheduledPrograms() {
    const url = "programsBoard/";
    return this.get(url);
  }

  public setSubscriptionPlan(planType) {
    const url = "user/subscription/" + planType;
    return this.put(url, {});
  }

  public cancelSubscriptionPlan() {
    const url = "user/subscription/";
    return this.delete(url);
  }

  public inviteFamilyMember(email) {
    const url = "user/subscription/family-pro/member/";
    return this.put(url, { email });
  }

  public verifyInvitation(invite_id) {
    const url = "user/subscription/family-pro/member/verify/" + invite_id;
    return this.put(url, {});
  }

  public declineInvitation() {
    const url = "user/subscription/family-pro/member/decline-invitation/";
    return this.delete(url);
  }

  public updateSubscriptionFlag(email_active) {
    const url = "user/approvals";
    return this.put(url, { email_active });
  }

  public removeUserFromPackage(email) {
    const url = "user/subscription/family-pro/member/" + email;
    return this.delete(url);
  }

  public leaveFamilyPackage() {
    const url = "user/subscription/family-pro/member/leave";
    return this.delete(url);
  }

  public sendPaymentDetails(data) {
    const url = "user/payment";
    return this.put(url, data);
  }

  public updateExpiryNotification(expiry_popup) {
    const url = "user/expiry-notification";
    return this.put(url, { expiry_popup });
  }

  public getPlaylists(query: PlaylistQuery) {
    const url = "playlist";
    let params = query.toString();
    return this.get(url + "?" + params);
  }

  public getSimilarPlaylists(playlistID, query: PlaylistQuery) {
    const url = "playlist/" + playlistID + "/similar";
    let params = query.toString();
    return this.get(url + "?" + params);
  }

  public getPlaylistByID(playlistID: string) {
    const url = "playlist/" + playlistID;
    return this.get(url);
  }

  public getCategoryByID(categoryID: string) {
    const url = "playlist/category/" + categoryID;
    return this.get(url);
  }

  public getMoods() {
    const url = "mood-tag/tags";
    return this.get(url);
  }

  public getSubmoodByID(submoodID, query) {
    const url = `mood-tag/sub-mood/${submoodID}/content`;
    let params = query.toString();
    return this.get(url + "?" + params);
  }

  public getPlaylistForCurrent() {
    const url = "daytime/current?itemsPerPage=20";
    return this.get(url);
  }

  public getListenedContent(query, type) {
    const url = `user/listened-content/${type}`;
    let params = query.toString();
    return this.get(url + "?" + params);
  }

  public updatePlaybackReport(object_type, object_id) {
    const url = `user/listen/${object_type}/${object_id}`;
    return this.put(url, {});
  }

  public getLikedContent(query, type) {
    const url = `user/like/${type}`;
    let params = query.toString();
    return this.get(url + "?" + params);
  }

  public getplaylistsForPromotionID(query) {
    const url = `playlist/promotion/lean`;
    let params = query.toString();
    return this.get(url + "?" + params);
  }

  public updateLikedContent(object_type, object_id) {
    const url = `user/like/${object_type}/${object_id}`;
    return this.put(url, {});
  }

  public signInWithEmail(email) {
    const url = "auth/signin/email";
    return this.post(url, { email: email });
  }

  public getRecommendedAlbums(query) {
    const url = "recommendation-post";
    let params = query.toString();
    return this.get(url + "?" + params);
  }

  public createDynamicShortenLink(link, cb, isPlatformBrowser) {
    //  if (isPlatformBrowser) {
    var settings = {
      async: true,
      crossDomain: true,
      url:
        "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=" +
        environment.firebaseConfig.apiKey,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "cache-control": "no-cache"
      },
      data:
        '{"dynamicLinkInfo":{"domainUriPrefix":"' +
        environment.domainUriPrefix +
        '","link":"' +
        link +
        "?referral" +
        '","androidInfo": {\n"androidPackageName":"' +
        environment.androidPackageName +
        '"},"iosInfo":{"iosBundleId": "' +
        environment.iosBundleId +
        '"},"navigationInfo":{"enableForcedRedirect": "' +
        1 +
        '"}}}'
    };
    this.postAjax(
      "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=" +
        environment.firebaseConfig.apiKey,
      '{"dynamicLinkInfo":{"domainUriPrefix":"' +
        environment.domainUriPrefix +
        '","link":"' +
        link +
        "?referral" +
        '","androidInfo": {\n"androidPackageName":"' +
        environment.androidPackageName +
        '","androidFallbackLink":"' +
        link +
        "?referral" +
        '"},"iosInfo":{"iosBundleId": "' +
        environment.iosBundleId +
        '","iosFallbackLink": "' +
        link +
        "?referral" +
        '","iosAppStoreId": "' +
        environment.iosAppStoreId +
        '"},"navigationInfo":{"enableForcedRedirect": "' +
        1 +
        '"}}}',
      cb
    );
    // $.ajax(settings).done(cb)
    //  }else{
    //  cb({})
    //  }

    // const url = 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key='+environment.firebaseConfig.apiKey
    // return this.postWithoutBaseUrl(url,{
    // 	"dynamicLinkInfo": {
    // 	  "domainUriPrefix": environment.domainUriPrefix,
    // 	  "link": link+ '?referral',
    // 	  "androidInfo": {
    // 		"androidPackageName": environment.androidPackageName
    // 	  },
    // 	  "iosInfo": {
    // 		"iosBundleId": environment.iosBundleId
    // 	  },
    // 	  "navigationInfo": {
    // 		  enableForcedRedirect: 1
    // 	  }
    // 	}
    //   });
  }

  postAjax(url, data, success) {
    var params =
      typeof data == "string"
        ? data
        : Object.keys(data)
            .map(function(k) {
              return encodeURIComponent(k) + "=" + encodeURIComponent(data[k]);
            })
            .join("&");

    var xhr = new XMLHttpRequest();
    xhr.open("POST", url);
    xhr.onreadystatechange = function() {
      if (xhr.readyState > 3 && xhr.status == 200) {
        success(JSON.parse(xhr.responseText));
      }
    };
    xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.send(params);
    return xhr;
  }

  private _to2digit(n: number) {
    return ("00" + n).slice(-2);
  }
}
