import { Component, OnInit } from "@angular/core";
import { SharedService } from "../../services/shared.service";
import { Router, ActivatedRoute } from "@angular/router";
import { MessageService } from "../../services/messageService";
import { NetworkService } from "../../services/network.service";
import { ProgramsQuery } from "../../services/programsQuery";
declare var bodymovin: any;
import { environment } from "../../../environments/environment";
import { GoogleAnalyticsService } from "../../services/googleAnalytics.service";
import { MetaService } from "../../services/MetaService";

let baseUrl = environment.baseUrl;

@Component({
  selector: "app-hamigrash-habeiti",
  templateUrl: "./hamigrash-habeiti.component.html",
  styleUrls: ["./hamigrash-habeiti.component.scss"]
})
export class HamigrashHabeitiComponent implements OnInit {
  likeImageSrc = "like-icon.svg";
  embedLink = "";
  program: any = {};
  currentSecond = 0;
  currentSong: any;
  subscription: any;
  nextFullShows = [];
  relatedPrograms = [];
  loadingProgram = false;
  moreOptionsActive = false;
  programsQuery: any;
  loadingRelatedPrograms = false;
  shortlink = "";

  constructor(
    private messageService: MessageService,
    public sharedService: SharedService,
    public router: Router,
    private networkService: NetworkService,
    private route: ActivatedRoute,
    private analyticsService: GoogleAnalyticsService,
    private metaService: MetaService
  ) {
    sharedService.showUpperSection = false;
    this.route.params.subscribe(res => {
      if (this.sharedService.tempProgram) {
        this.program = this.sharedService.tempProgram;
        this.getProgramData();
      } else {
        this.loadingProgram = true;
        this.networkService.getProgramByID(res["id"]).subscribe(resp => {
          this.program = resp.item;
          this.getProgramData();
          this.loadingProgram = false;
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.sharedService.showUpperSection = true;
  }

  ngOnInit() {
    this.subscription = this.messageService
      .listenToPlayerActions()
      .subscribe(e => {
        if (e && e.indexOf(":") != -1) {
          this.currentSecond =
            parseInt(String(e.split(":")[0] * 60 * 60)) +
            parseInt(String(e.split(":")[1] * 60)) +
            parseInt(String(e.split(":")[2]));
        }
      });
  }

  getProgramData() {
    this.networkService.createDynamicShortenLink(
      baseUrl + "/program/המגרש_הביתי/" + this.program.original_id,
      data => {
        this.shortlink = data.shortLink;
      },
      this.sharedService.isPlatformBrowser
    );

    this.updateMetaTags(this.program);

    this.relatedPrograms = [];
    this.loadingRelatedPrograms = true;
    this.programsQuery = new ProgramsQuery(
      1,
      10,
      [30],
      this.program.insert_date,
      undefined,
      true
    );
    this.networkService.getPrograms(this.programsQuery).subscribe(
      resp => {
        let indexToRemove = -1;
        resp.programs.forEach((item, index) => {
          if (item.original_id == this.program.original_id) {
            indexToRemove = index;
          }
        });
        if (indexToRemove != -1) {
          resp.programs.splice(indexToRemove, 1);
        }
        this.relatedPrograms = resp.programs;
        this.relatedPrograms.forEach(program => {
          if (program.image_url) {
            this.checkImage(program);
          }
        });
        this.loadingRelatedPrograms = false;
      },
      err => {}
    );

    let query2 = new ProgramsQuery(1, 20, [30]);

    this.networkService.getPrograms(query2).subscribe(
      resp => {
        this.nextFullShows = resp.programs;
      },
      err => {}
    );
  }

  updateMetaTags(program) {
    let meta = {
      title: program.name + " | eco99fm",
      description: program.description,
      imageUrl: program.image_url,
      url: baseUrl + "/program/המגרש_הביתי/" + program.original_id,
      keywords: program.key_words.join()
    };
    this.metaService.updateMetaInfo(
      meta.title,
      meta.description,
      meta.imageUrl,
      meta.url,
      meta.keywords
    );
  }

  checkImage(show) {
    if (this.sharedService.isPlatformBrowser) {
      var img = new Image();
      // img.onload = good;
      img.onerror = function() {
        show.image_url = "/assets/images/show-placeholder.png";
      };
      img.src = show.image_url;
    }
  }

  playShow(program, index, event) {
    this.analyticsService.sendEvent(
      "hamigrash_habeiti",
      "full_show_program",
      "program_click",
      program.original_id
    );

    this.sharedService.tempProgram = program;
    this.program = this.sharedService.tempProgram;
    this.getProgramData();
    this.router.navigateByUrl("/program/המגרש_הביתי/" + program.original_id);
    this.sharedService.loadProgramToPlayer(
      program,
      program.description,
      this.programsQuery,
      index,
      "המגרש_הביתי"
    );
    if (this.sharedService.isPlatformBrowser) {
      window.scrollTo(0, 0);
      event.preventDefault();
    }
  }

  playPause() {
    if (
      this.sharedService.isPlaying &&
      this.sharedService.currentPlayingContentID == this.program.original_id
    ) {
      this.messageService.updatePlayerDetails({ pause: true });
    } else {
      this.sharedService.loadProgramToPlayer(
        this.program,
        this.program.description,
        this.programsQuery,
        0,
        "המגרש_הביתי"
      );
    }
  }

  getBgUrl(program) {
    if (program.image_url) {
      return program.image_url;
    } else {
      if (this.loadingProgram) {
        return "";
      }
      return "/assets/images/show-placeholder.png";
    }
  }

  copyEmbedCode() {
    this.embedLink =
      '<iframe src="' +
      baseUrl +
      "/embedded-player/program/" +
      this.program.original_id +
      '" scrolling="no" width="593" height="386" frameborder="0"></iframe>';
    setTimeout(() => {
      var copyText = document.getElementById("embedText");
      copyText["select"]();
      document.execCommand("copy");
      this.sharedService.showAlert(
        "הקוד להטמעה הועתק ללוח",
        false,
        true,
        "",
        "אחלה",
        true
      );
    });
  }

  downloadProgram() {
    // if (this.sharedService.isFreeUser()){
    // this.sharedService.showCreateUserPopup()
    // }
    // document.url = this.program.download_url
  }

  getCurrentSubtitle() {
    return this.program.short_description;
  }

  updatePlayerDetails(song) {
    let data = {
      thumbnail: song.small_image
        ? song.small_image
        : "assets/images/song-placeholder.svg",
      songName: song.song_name,
      artistName: song.artist_name
    };
    this.messageService.updatePlayerDetails(data);
  }

  toHHMMSS(full_seconds) {
    var sec_num = parseInt(full_seconds, 10); // don't forget the second param
    var hours: any = Math.floor(sec_num / 3600);
    var minutes: any = Math.floor((sec_num - hours * 3600) / 60);
    var seconds: any = sec_num - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return hours + ":" + minutes + ":" + seconds;
  }

  sendAnalytics(type) {
    switch (type) {
      case "facebookmessanger":
        this.analyticsService.sendEvent(
          "hamigrash_habeiti",
          "share_hamigrash_habeiti",
          "facebook_messanger_share",
          this.program.original_id
        );
        break;
      case "facebook":
        this.analyticsService.sendEvent(
          "hamigrash_habeiti",
          "share_hamigrash_habeiti",
          "facebook_share",
          this.program.original_id
        );
        break;
      case "whatsapp":
        this.analyticsService.sendEvent(
          "hamigrash_habeiti",
          "share_hamigrash_habeiti",
          "whatsapp_share",
          this.program.original_id
        );
        break;
      case "email":
        this.analyticsService.sendEvent(
          "hamigrash_habeiti",
          "share_hamigrash_habeiti",
          "email_share",
          this.program.original_id
        );
        break;
      case "native":
        this.analyticsService.sendEvent(
          "hamigrash_habeiti",
          "share_hamigrash_habeiti",
          "native_share",
          this.program.original_id
        );
        break;
    }
  }

  like() {
    if (this.likeImageSrc == "like-icon.svg") {
      let like = bodymovin.loadAnimation({
        container: document.getElementById("like-animation"),
        loop: false,
        autoplay: true,
        path: "assets/animations/Likeddesktop.json"
      });
      setTimeout(() => {
        this.likeImageSrc = "liked-icon.svg";
      }, 700);
      like.addEventListener("complete", () => {
        like.destroy();
      });
    } else {
      this.likeImageSrc = "like-icon.svg";
    }
  }
}
