import { BrowserModule } from "@angular/platform-browser";
import { NgModule, LOCALE_ID } from "@angular/core";
import localeHE from "@angular/common/locales/he";
import { registerLocaleData } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ErrorInterceptor } from "./services/errorInterceptor";
import { CanActivateNotLoggedUser } from "./services/CanActivateNotLoggedUser";
import { CanActivateLoggedUser } from "./services/CanActivateLoggedUser";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { MyPackageComponent } from "./components/my-package/my-package.component";
import { MyPaymentComponent } from "./components/my-payment/my-payment.component";
import { HeaderComponent } from "./common/header/header.component";
import { HomepageComponent } from "./pages/homepage/homepage.component";
import { FooterComponent } from "./common/footer/footer.component";
import { SideMenuComponent } from "./common/side-menu/side-menu.component";
import { NetworkService } from "./services/network.service";
import { SharedService } from "./services/shared.service";
import { GenericService } from "./services/generic.service";
import { AuthService } from "./services/auth.service";
import { AuthInterceptor } from "./services/AuthInterceptor";
import { DateFormat } from "./services/dateFormat.service";
import { MessageService } from "./services/messageService";
import { GoogleAnalyticsService } from "./services/googleAnalytics.service";
import { NgxMatDrpModule } from "ngx-mat-daterange-picker";
import { ReuseRouteReuseStrategy } from "./app.routes-strategy";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CookieService } from "ngx-cookie-service";
import { NgxJsonLdModule } from "@ngx-lite/json-ld";

import { DfpModule, DfpService } from "@wwei/dfp";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  MatButtonModule,
  MatCardModule,
  MatInputModule,
  MatIconModule,
  MatDividerModule,
  MatToolbarModule,
  MatListModule,
  MatSelectModule,
  MatRadioModule,
  MatDatepickerModule,
  MatNativeDateModule,
  DateAdapter,
  MatMenuModule,
  MatTabsModule,
  MatSlideToggleModule,
  MatExpansionModule,
  MatSnackBarModule,
  MatDialogRef,
  MAT_DIALOG_DATA
} from "@angular/material";
import { MatDialogModule } from '@angular/material/dialog';
import { LoginComponent } from "./pages/login/login.component";
import { AlertDialogComponent } from "./dialogs/alert-dialog/alert-dialog.component";
import { InviteFriendsDialogComponent } from "./dialogs/invite-friends-dialog/invite-friends-dialog.component";
import { SettingsComponent } from "./pages/settings/settings.component";
import { BoardingDialogComponent } from "./dialogs/boarding-dialog/boarding-dialog.component";
import { SubscriptionDialogComponent } from "./dialogs/subscription-dialog/subscription-dialog.component";
import { ProfileComponent } from "./components/profile/profile.component";
import { ProfileTabletComponent } from "./components/profile-tablet/profile-tablet.component";
import { EditProfileComponent } from "./components/edit-profile/edit-profile.component";
import { EditProfileTabletComponent } from "./components/edit-profile-tablet/edit-profile-tablet.component";
import { BirthdayPipe } from "./pipes/birthday.pipe";
import { TermsComponent } from "./pages/terms/terms.component";
import { AboutComponent } from "./pages/about/about.component";
import { LogoutDialogComponent } from "./dialogs/logout-dialog/logout-dialog.component";
import { ContactComponent } from "./pages/contact/contact.component";
import { CommonModule } from "@angular/common";
import { TransferHttpCacheModule } from "@nguniversal/common";
import { NgtUniversalModule } from "@ng-toolkit/universal";
import { TalAndAviadComponent } from "./pages/tal-and-aviad/tal-and-aviad.component";
import { ShowGalleryComponent } from "./components/show-gallery/show-gallery.component";
import { ProPackagesComponent } from "./pages/pro-packages/pro-packages.component";
import { TicketsComponent } from "./pages/tickets/tickets.component";
import { ShowComponent } from "./pages/show/show.component";
import { ShowSquareComponent } from "./components/show-square/show-square.component";
import { RemoveFriendDialogComponent } from "./dialogs/remove-friend-dialog/remove-friend-dialog.component";
import { RemovePackageDialogComponent } from "./dialogs/remove-package-dialog/remove-package-dialog.component";
import { WelcomeFriendDialogComponent } from "./dialogs/welcome-friend-dialog/welcome-friend-dialog.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { SettingsTabletComponent } from "./components/settings-tablet/settings-tablet.component";
import { SetPackageDialogComponent } from "./dialogs/set-package-dialog/set-package-dialog.component";
import { ExpandableDrawerComponent } from "./common/expandable-drawer/expandable-drawer.component";
import { SettingsInnerPageComponent } from "./pages/settings-inner-page/settings-inner-page.component";
import { NguCarouselModule } from "@ngu/carousel";

import { InvitationComponent } from "./pages/invitation/invitation.component";
import { PaymentComponent } from "./pages/payment/payment.component";
import { LiveRadioComponent } from "./pages/live-radio/live-radio.component";
import { StickyPlayerComponent } from "./components/sticky-player/sticky-player.component";
import { ExpiredSoonDialogComponent } from "./dialogs/expired-soon-dialog/expired-soon-dialog.component";
import { PrivacyPolicyComponent } from "./pages/privacy-policy/privacy-policy.component";
import { PlaylistGalleryComponent } from "./components/playlist-gallery/playlist-gallery.component";
import { PlaylistCategoriesComponent } from "./components/playlist-categories/playlist-categories.component";
import { EmbeddedPlayerComponent } from "./pages/embedded-player/embedded-player.component";
import { VisibleFilterPipe } from "./pipes/visible-filter.pipe";
import { UpgradeToProComponent } from "./dialogs/upgrade-to-pro/upgrade-to-pro.component";
import { RadioShowsComponent } from "./components/radio-shows/radio-shows.component";
import { RadioComponent } from "./pages/radio/radio.component";
import { RadioShowsScheduleComponent } from "./components/radio-shows-schedule/radio-shows-schedule.component";
import { RadioEditorsComponent } from "./components/radio-editors/radio-editors.component";
import { RadioAlbumsComponent } from "./components/radio-albums/radio-albums.component";
import { RadioShowComponent } from "./components/radio-show/radio-show.component";
import { PlaylistComponent } from "./pages/playlist/playlist.component";
import { ProgramComponent } from "./pages/program/program.component";
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { LoaderComponent } from "./components/loader/loader.component";
import { MusicComponent } from "./pages/music/music.component";
import { PlaylistGenresComponent } from "./components/playlist-genres/playlist-genres.component";
import { PlaylistSpecialsComponent } from "./components/playlist-specials/playlist-specials.component";
import { PlaylistsGalleryComponent } from "./pages/playlists-gallery/playlists-gallery.component";
import { AccessibilityComponent } from "./pages/accessibility/accessibility.component";
import { HamigrashHabeitiComponent } from "./pages/hamigrash-habeiti/hamigrash-habeiti.component";
import { MetaService } from "./services/MetaService";
import { EcoLifeComponent } from "./pages/eco-life/eco-life.component";
import { NineMonthsComponent } from "./pages/nine-months/nine-months.component";
import { MusicAppComponent } from "./pages/music-app/music-app.component";
import { environment } from "../environments/environment";
import "hammerjs";
import { RouteReuseStrategy } from "@angular/router";
import { AppRouterOutletDirective } from "./app.routes-events";
import { RedirectComponent } from "./pages/redirect/redirect.component";
import { SafeHTMLPipe } from './pipes/safe-html.pipe';
import { LegalDocumentComponent } from "./pages/legal-document/legal-document.component";
import { AdDialogComponent } from './dialogs/ad-dialog/ad-dialog.component';
import { AnnualParadeComponent } from './pages/annual-parade/annual-parade.component';
import { NewMorningShowComponent } from './pages/new-morning-show/new-morning-show.component';
import { DeleteUserModalComponent } from './components/delete-user-modal/delete-user-modal.component';
import { SpeedSelectorComponent } from "./speed-selector/speed-selector.component";

registerLocaleData(localeHE);

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomepageComponent,
    FooterComponent,
    MyPackageComponent,
    MyPaymentComponent,
    SideMenuComponent,
    ExpandableDrawerComponent,
    LoginComponent,
    AlertDialogComponent,
    SettingsComponent,
    InviteFriendsDialogComponent,
    RemoveFriendDialogComponent,
    RemovePackageDialogComponent,
    SetPackageDialogComponent,
    WelcomeFriendDialogComponent,
    SettingsTabletComponent,
    SettingsInnerPageComponent,
    BoardingDialogComponent,
    SubscriptionDialogComponent,
    ProfileComponent,
    ProfileTabletComponent,
    EditProfileComponent,
    EditProfileTabletComponent,
    BirthdayPipe,
    TermsComponent,
    PrivacyPolicyComponent,
    LegalDocumentComponent,
    AboutComponent,
    MusicAppComponent,
    LogoutDialogComponent,
    ContactComponent,
    TalAndAviadComponent,
    PaymentComponent,
    ShowGalleryComponent,
    ProPackagesComponent,
    TicketsComponent,
    ShowComponent,
    ShowSquareComponent,
    InvitationComponent,
    LiveRadioComponent,
    StickyPlayerComponent,
    ExpiredSoonDialogComponent,
    PlaylistGalleryComponent,
    PlaylistCategoriesComponent,
    EmbeddedPlayerComponent,
    VisibleFilterPipe,
    UpgradeToProComponent,
    RadioShowsComponent,
    RadioComponent,
    RadioShowsScheduleComponent,
    RadioEditorsComponent,
    RadioAlbumsComponent,
    RadioShowComponent,
    PlaylistComponent,
    ProgramComponent,
    LoaderComponent,
    MusicComponent,
    PlaylistGenresComponent,
    PlaylistSpecialsComponent,
    PlaylistsGalleryComponent,
    AccessibilityComponent,
    HamigrashHabeitiComponent,
    EcoLifeComponent,
    NineMonthsComponent,
    AppRouterOutletDirective,
    RedirectComponent,
    SafeHTMLPipe,
    AdDialogComponent,
    AnnualParadeComponent,
    NewMorningShowComponent,
    DeleteUserModalComponent,
    SpeedSelectorComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    BrowserAnimationsModule,
    NgxJsonLdModule,
    FormsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    ReactiveFormsModule,
    AppRoutingModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatIconModule,
    MatDividerModule,
    MatToolbarModule,
    MatListModule,
    MatSelectModule,
    MatRadioModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMenuModule,
    MatSelectModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatSnackBarModule,
    CommonModule,
    TransferHttpCacheModule,
    NgtUniversalModule,
    NgxMatDrpModule,
    FlexLayoutModule.withConfig({
      ssrObserveBreakpoints: ["xs", "lt-md", "lt-lg", "md", "gt-md", "lt-md"]
    }),
    NguCarouselModule,
    DfpModule
  ],
  entryComponents: [
    BoardingDialogComponent,
    AlertDialogComponent,
    LogoutDialogComponent,
    InviteFriendsDialogComponent,
    RemoveFriendDialogComponent,
    RemovePackageDialogComponent,
    SetPackageDialogComponent,
    WelcomeFriendDialogComponent,
    ExpiredSoonDialogComponent,
    UpgradeToProComponent,
    SubscriptionDialogComponent,
    DeleteUserModalComponent,
    SpeedSelectorComponent,
  ],
  providers: [
    SharedService,
    NetworkService,
    CookieService,
    HomepageComponent,
    CanActivateLoggedUser,
    CanActivateNotLoggedUser,
    ContactComponent,
    AuthService,
    GenericService,
    MessageService,
    GoogleAnalyticsService,
    MetaService,
    DfpService,
    [
      {
        provide: LOCALE_ID,
        useValue: "he-IL"
      },
      {
        provide: DateAdapter,
        useClass: DateFormat
      },

      {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: ErrorInterceptor,
        multi: true
      },
      {
        provide: RouteReuseStrategy,
        useClass: ReuseRouteReuseStrategy
      },
      { 
        provide: MAT_DIALOG_DATA,
        useValue: {} 
      },
      {
        provide: MatDialogRef,
        useValue: {}
      },
    ]
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
