import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-radio-editors",
  templateUrl: "./radio-editors.component.html",
  styleUrls: ["./radio-editors.component.scss"]
})
export class RadioEditorsComponent implements OnInit {
  editors = [
    {
      name: "שחף אייל",
      image: "assets/images/broadcasters/Shahaf_Eyal-3.png",
      content:
        "עורכת מוזיקה ראשית של תחום הדיגיטל. לפני כן הייתה כתבת אינטרנט, עורכת וידאו, שדרנית ועורכת - 106fm, תסריטאית ובימאית. בוגרת החוג לתקשורת באוניברסיטת תל אביב."
    },
    {
      name: "אידית אבשלומי",
      image: "assets/images/editors/iditavshalomi.JPG",
      content: `עורכת מוזיקה ראשית של תחום ה- fm. התחילה את דרכה ברדיו תל אביב, הפיקה וערכה את התוכניות של יואב קוטנר, דן שילון ורינה מצליח.  עורכת ברצועות המוזיקה השונות של eco99fm במהלך היום, ובנוסף, מתקלטת באירועים ,חתונות ומסיבות.`
    },
    {
      name: "אמיר צוויבן",
      image: "assets/images/editors/amirzvaiben.jpg",
      content:
        'הגיע לeco99fm לאחר שירות כעורך מוזיקלי בגלי צה"ל וגלגל"צ. בנוסף הוא מוזיקאי פעיל בפרויקטים שונים ומנהל ערבי שירה ב"שרים רנדומלית".'
    },
    {
      name: "בועז כהן",
      image: "assets/images/editors/boazcohen.jpg",
      content: `מאנשי הרדיו והמוזיקה הבולטים והמוערכים בישראל, הגיע ל- eco99fm אחרי קריירה ארוכה בקול ישראל. בעל ידע מופלג במגוון ז'אנרים מוזיקליים, משדר בתחנה רצועה קבועה וכמו כן עורך את ערוץ  eco alternative בפלטפורמה הדיגיטלית שלנו.`
    },
    {
      name: "נעמה יוגב",
      image: "assets/images/editors/naamayogev.jpeg",
      content: `נעמה התחילה דרכה כעורכת מוזיקלית בגל״צ ובגלגל״צ. משם הצטרפה לצוות ההקמה של 99fmכעורכת מוזיקלית ומגישה. בימים אלה חזרה לאקו 99fm כעורכת מוזיקלית אחרי עצירה בדרך כמגישה ועורכת ברדיו תל אביב 102fm. במקביל עובדת כדי.ג׳יית בארועים ובמסיבות.`
    },
    {
      name: "הילה אבשלומי",
      image: "assets/images/editors/hilaavshalom.jpg",
      content: `למדה עיצוב פסקול והלחנה במכללת ספיר. 
    כיום עורכת מוסיקה ברדיו Eco99 Fm, עורכת ומגישה ברדיו הקצה ומתקלטת בשאר הזמן.`
    }
  ];

  constructor() {}

  ngOnInit() {}
}
