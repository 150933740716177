import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SharedService } from "../../services/shared.service";
import { AuthService } from "../../services/auth.service";
import { MessageService } from "../../services/messageService";
import { Router, ActivatedRoute } from "@angular/router";
import { MyPackageComponent } from "../../components/my-package/my-package.component";
import { GoogleAnalyticsService } from "../../services/googleAnalytics.service";

@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"]
})
export class SettingsComponent implements OnInit, OnDestroy {
  profile = { name: "", email: "", birthday: "" };
  activeTab = "profile";
  subscription: any;

  @ViewChild("mypackage", { static: false }) mypackage: MyPackageComponent;

  constructor(
    private route: ActivatedRoute,
    public sharedService: SharedService,
    private authService: AuthService,
    private messageService: MessageService,
    private router: Router,
    private analyticsService: GoogleAnalyticsService
  ) {
    this.route.queryParams.subscribe(params => {
      if (params["invite"] != undefined) {
        this.setToTab("package");
        setTimeout(() => {
          this.mypackage.inviteFriends();
        }, 500);
      }
    });
  }

  ngOnInit() {
    setTimeout(() => {
      this.sharedService.showHeaderHam = false;
    });
    this.sharedService.showUpperSection = false;
    this.subscription = this.messageService.listenToBackClick().subscribe(e => {
      switch (this.activeTab) {
        case "profile":
          this.router.navigateByUrl("/");
          break;
        case "inner-profile":
          this.activeTab = "profile";
          break;
        case "edit-profile-tablet":
          this.activeTab = "inner-profile";
          break;
        default:
          this.activeTab = "profile";
          break;
      }
    });
  }

  ngOnDestroy(): void {
    this.sharedService.showUpperSection = true;
    this.subscription.unsubscribe();
    setTimeout(() => {
      this.sharedService.showHeaderHam = true;
    });
  }

  toggleProfileEdit() {
    if (this.activeTab == "edit-profile") {
      this.analyticsService.sendEvent(
        "settings",
        "edit_profile_cancel_button",
        "ביטול עריכת פרופיל"
      );
      this.activeTab = "profile";
    } else {
      this.activeTab = "edit-profile";
    }
  }
  setToTab(mode: string) {
    this.activeTab = mode;
  }

  updatedProfile(err) {
    if (err) {
      this.sharedService.showAlert("היתה בעיה בעדכון הפרופיל", true, false);
    }
    this.analyticsService.sendEvent(
      "settings",
      "edit_profile_save_button",
      "עדכן פרופיל"
    );
    this.sharedService.showAlert("הפרופיל עודכן בהצלחה!", false, true);
    if (this.activeTab == "edit-profile-tablet") {
      this.activeTab = "inner-profile";
    } else {
      this.activeTab = "profile";
    }
  }

  logout() {
    this.sharedService.showLogoutConfirm();
  }
}
