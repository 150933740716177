import { Component, OnInit } from "@angular/core";
import { NetworkService } from "../../services/network.service";
import { PromotionQuery } from "../../services/promotionQuery";
import { SharedService } from "../../services/shared.service";
declare var bodymovin: any;
declare var $: any;

@Component({
  selector: "app-radio-albums",
  templateUrl: "./radio-albums.component.html",
  styleUrls: ["./radio-albums.component.scss"]
})
export class RadioAlbumsComponent implements OnInit {
  constructor(
    private networkService: NetworkService,
    private sharedService: SharedService
  ) {}

  expandedIndex = -1;
  albumsQuery: any;
  showLoadMoreButton = false;
  isLoadingAlbums = false;
  loadingAnimation: any;

  albums = [];
  ngOnInit() {
    this.albumsQuery = new PromotionQuery(1, 16);
    this.isLoadingAlbums = true;
    this.networkService
      .getRecommendedAlbums(this.albumsQuery)
      .subscribe(res => {
        this.albums = res.items;
        if (res.totalPages > this.albumsQuery.page) {
          this.showLoadMoreButton = true;
        }
        this.isLoadingAlbums = false;
      });
  }

  loadMoreRecommendations() {
    this.albumsQuery.page++;
    this.isLoadingAlbums = true;
    this.startLoadingAnimation();
    this.networkService
      .getRecommendedAlbums(this.albumsQuery)
      .subscribe(res => {
        this.albums = this.albums.concat(res.items);
        if (res.totalPages == this.albumsQuery.page) {
          this.showLoadMoreButton = false;
        }
        this.isLoadingAlbums = false;
      });
  }

  selectAlbum(i) {
    this.expandedIndex = this.expandedIndex == i ? -1 : i;
    if (this.sharedService.isPlatformBrowser) {
      setTimeout(() => {
        $("html, body").animate(
          {
            scrollTop: $("#album_" + i).offset().top - 110
          },
          500
        );
      });
    }

    //TODO: change expanded image at i - 1 index ?
  }

  startLoadingAnimation() {
    if (this.loadingAnimation) {
      this.loadingAnimation.destroy();
    }
    setTimeout(() => {
      if (document.getElementById("more-albums-loader")) {
        this.loadingAnimation = bodymovin.loadAnimation({
          container: document.getElementById("more-albums-loader"),
          renderer: "svg",
          loop: true,
          autoplay: true,
          path: "assets/animations/loader.json"
        });
      }
    });
  }
}
