import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { SharedService } from "../../services/shared.service";
import { IFileUpload } from "../../services/fileUpload/IFileUpload";
import S3FileUpload from "../../services/fileUpload/S3FileUpload";
declare var bodymovin: any;
import { NetworkService } from "../../services/network.service";

@Component({
  selector: "app-edit-profile",
  templateUrl: "./edit-profile.component.html",
  styleUrls: ["./edit-profile.component.scss"]
})
export class EditProfileComponent implements OnInit {
  @Output() toggleEditMode = new EventEmitter();
  @Output() updateProfile = new EventEmitter();
  updatingProfile = false;
  showErrors = false;
  nickname = "";
  birthday: Date;
  selectedGender = -1;
  uploadingImage: boolean;
  profilePhoto: string;
  temp_user_image_url: string;
  loadingAnimation: any;
  fileUpload: IFileUpload;
  context: any;
  todayDate = new Date();
  fixOrientation = require("fix-orientation");

  constructor(
    public sharedService: SharedService,
    private networkService: NetworkService
  ) {
    this.fileUpload = new S3FileUpload(
      "eu-west-1",
      "7eab6e63-7f95-4b67-9546-f0e39c6931af",
      "modex-files"
    );
    this.loadImage(sharedService.user.personal_data.profile_photo);
    this.temp_user_image_url = sharedService.user.personal_data.profile_photo;
    this.nickname = sharedService.user.personal_data.nickname;
    if (sharedService.user.personal_data.date_of_birth) {
      this.birthday = new Date(sharedService.user.personal_data.date_of_birth);
    }
    this.selectedGender = sharedService.user.personal_data.gender;
  }

  ngOnInit() {}

  cancel() {
    this.toggleEditMode.emit();
  }

  loadImage(url) {
    var canvas = document.createElement("canvas");
    // canvas.width = 70;
    //   canvas.height = 70;
    this.context = canvas.getContext("2d");
    let base_image = new Image();
    // base_image.setAttribute('crossOrigin', 'anonymous');
    base_image.src = url;
    base_image.onload = () => {
      this.context.drawImage(base_image, 70, 70);
      this.fixOrientation(
        canvas.toDataURL(),
        { image: true },
        (fixed, image) => {
          this.profilePhoto = fixed;
        }
      );
    };
  }

  save() {
    if (this.uploadingImage) {
      return;
    }
    if (!this.nickname.length || this.nickname.length > 25) {
      this.showErrors = true;
      this.sharedService.showAlert("הזן שם תקין עד 25 תווים", true);
      return;
    }

    var newBirthday = this.birthday
      ? new Date(this.birthday.setHours(6))
      : null;

    if (this.birthday >= new Date()) {
      this.sharedService.showAlert("התאריך שבחרת אינו תקין", true);
      return;
    }
    this.updatingProfile = true;
    this.startLoadingAnimationForSave();
    var profile = {
      nickname: this.nickname,
      date_of_birth: this.birthday ? new Date(newBirthday).toISOString() : null,
      gender: this.selectedGender,
      profile_photo: this.temp_user_image_url
    };
    this.networkService.updateProfile(profile).subscribe(
      DBUser => {
        this.updatingProfile = false;
        this.sharedService.user = DBUser;
        this.updateProfile.emit();
      },
      err => {}
    );
  }

  public onImageChange(event, imageNum) {
    if (event.target.files.length > 0) {
      this.uploadImage(event.target.files[0], imageNum);
    }
  }

  private uploadImage(file: File, imageNum: any) {
    this.uploadingImage = true;
    this.startLoadingAnimation();
    this.fileUpload.UploadFileAsync(file, (res: any) => {
      if (res.success) {
        // this.loadImage(res.url)
        this.temp_user_image_url = res.url;
      } else {
        console.log(res.error);
      }
      this.uploadingImage = false;
    });
  }

  startLoadingAnimation() {
    if (this.loadingAnimation) {
      this.loadingAnimation.destroy();
    }
    this.loadingAnimation = bodymovin.loadAnimation({
      container: document.getElementById("loader"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: "assets/animations/loader.json"
    });
  }

  startLoadingAnimationForSave() {
    if (this.loadingAnimation) {
      this.loadingAnimation.destroy();
    }
    this.loadingAnimation = bodymovin.loadAnimation({
      container: document.getElementById("loader-save"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: "assets/animations/loader.json"
    });
  }
}
