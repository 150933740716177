import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { MatDialog } from "@angular/material";
import { InviteFriendsDialogComponent } from "../../dialogs/invite-friends-dialog/invite-friends-dialog.component";
import { SharedService } from "../../services/shared.service";
import { RemoveFriendDialogComponent } from "../../dialogs/remove-friend-dialog/remove-friend-dialog.component";
import { RemovePackageDialogComponent } from "../../dialogs/remove-package-dialog/remove-package-dialog.component";
import { NetworkService } from "../../services/network.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-my-payment",
  templateUrl: "./my-payment.component.html",
  styleUrls: ["./my-payment.component.scss"]
})
export class MyPaymentComponent implements OnInit {
  @Output() afterLeaveFamily = new EventEmitter();

  familyUsers = [];

  constructor(
    public dialog: MatDialog,
    public sharedService: SharedService,
    private networkService: NetworkService,
    public router: Router
  ) {}

  ngOnInit() {}
  editPaymentDetails() {
    this.router.navigateByUrl(
      "/payment/" + this.sharedService.user.payment_data.subscription + "/edit"
    );
  }
}
