import { Component, OnInit, Input } from "@angular/core";
import { SharedService } from "../../services/shared.service";
import { Router } from "@angular/router";
import { GoogleAnalyticsService } from "../../services/googleAnalytics.service";
import { ROUTES } from "src/app/data/routes";

@Component({
  selector: "app-side-menu",
  templateUrl: "./side-menu.component.html",
  styleUrls: ["./side-menu.component.scss"],
})
export class SideMenuComponent implements OnInit {
  selectedAvatar: string;
  @Input() menuItems: any;

  constructor(
    public sharedService: SharedService,
    public router: Router,
    public analyticsService: GoogleAnalyticsService
  ) {}

  ngOnInit() {}

  goToLogin() {
    this.router.navigateByUrl(ROUTES.LOGIN);
  }

  goToProfile() {
    this.router.navigateByUrl("/settings");
  }

  getLinkByName(name) {
    switch (name) {
      case "שלי":
        return "";
      case "מוזיקה":
        return "music";
      case "להיטים":
        return "trends";
      case "בוקר חדש":
        return "program/בוקר_חדש/tab/1";
      case "שידור חי":
        return "live-radio";
      case "לוח שידורים":
      case "שידורי רדיו":
        return "radio";
      case "טל ואביעד - ארכיון":
        return "program/טל_ואביעד/tab/1";
      case "כרטיסים להופעות":
        return "tickets/הופעות_בארץ";
      case "הסדר פשרה":
        return "/legal-document";
    }
  }

  isLinkSelectedByName(url, name) {
    let shortUrl = url;
    if (url.indexOf("/") != -1) {
      shortUrl = url.slice(0, url.indexOf("/"));
    }
    switch (shortUrl) {
      case "":
        return name == "שלי";
      case "playlist":
        return name == "מוזיקה";
      case "music":
        return name == "מוזיקה";
      case "trends":
        return name == "להיטים";
      case "program":
        return (
          (name == "טל ואביעד - ארכיון" &&
            url.indexOf(encodeURIComponent("טל_ואביעד")) != -1) ||
          (name == "רדיו" &&
            url.indexOf(encodeURIComponent("המגרש_הביתי")) != -1) ||
          (name == "בוקר חדש" &&
            url.indexOf(encodeURIComponent("בוקר_חדש")) != -1)
        );
      case "live-radio":
        return name == "שידור חי";
      case "radio":
        return name == "רדיו";
      case "tickets":
        return name == "כרטיסים להופעות";
      case "legal-document":
        return name == "הסדר פשרה";
    }
  }

  sendAnalyticsEvent(linkname) {
    switch (linkname) {
      case "שלי":
        this.analyticsService.sendEvent("my_music", "tab_my_music", linkname);
        break;
      case "מוזיקה":
        this.analyticsService.sendEvent("music", "tab_music", linkname);
        break;
      case "טל ואביעד - ארכיון":
        this.analyticsService.sendEvent(
          "tal_and_aviad",
          "tab_tal_and_aviad",
          linkname
        );
        break;
      case "תוכנית בוקר חדשה":
        this.analyticsService.sendEvent(
          "new_morning_show",
          "tab_new_morning_show",
          linkname
        );
        break;
      case "שידור חי":
        this.analyticsService.sendEvent(
          "live_radio",
          "tab_live_radio",
          linkname
        );
        break;
      case "רדיו":
      case "שידורי רדיו":
        this.analyticsService.sendEvent("radio", "tab_radio", linkname);
        break;
      case "כרטיסים להופעות":
        this.analyticsService.sendEvent("tickets", "tab_eco_tickets", linkname);
        break;
      case "פרופיל":
        this.analyticsService.sendEvent("tickets", "tab_profile", linkname);
        break;
      case "הסדר פשרה":
        this.analyticsService.sendEvent(
          "legal-document",
          "tab_legal_document",
          linkname
        );
        break;
    }
  }

  createNewPlaylist() {
    if (
      this.sharedService.user &&
      this.sharedService.user.payment_data.subscription == 4
    ) {
      this.sharedService.showCreateUserPopup();
    }
  }

  enableLink(link) {
    if (
      this.sharedService.user &&
      this.sharedService.user.payment_data.subscription == 4
    ) {
      return link.is_active_free;
    } else {
      return link.is_active_pro;
    }
  }
}
