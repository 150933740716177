import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { FormControl, Validators } from "@angular/forms";
declare var bodymovin: any;

@Component({
  selector: "app-remove-package-dialog",
  templateUrl: "./remove-package-dialog.component.html",
  styleUrls: ["./remove-package-dialog.component.scss"]
})
export class RemovePackageDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<any>
  ) {}
  loadingAnimation: any;
  showPopup = false;
  isLoading: boolean;

  ngOnInit() {
    setTimeout(() => {
      this.showPopup = true;
    }, 100);
  }

  onRemove() {
    this.isLoading = true;
    this.startLoadingAnimation();
    setTimeout(() => {
      this.dialogRef.close(true);
    }, 2000);
  }

  startLoadingAnimation() {
    if (this.loadingAnimation) {
      this.loadingAnimation.destroy();
    }
    this.loadingAnimation = bodymovin.loadAnimation({
      container: document.getElementById("loader"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: "assets/animations/loader.json"
    });
  }
}
