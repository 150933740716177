import { Component, OnInit, OnDestroy } from "@angular/core";
import { SharedService } from "../../services/shared.service";

@Component({
  selector: "app-music-app",
  templateUrl: "./music-app.component.html",
  styleUrls: ["./music-app.component.scss"]
})
export class MusicAppComponent implements OnInit, OnDestroy {
  constructor(private sharedService: SharedService) {}
  aboutText = `אקו 99FM  אפליקציית מוזיקה חינם - Digital eco99
  אפליקציית שירים חינמית המאפשרת לכם להאזין לפלייליסטים מעולים, בכל רגע, מכל מקום, ובחינם
  השירות הינו פלטפורמה מבוססת סטרימינג, שתעניק לכם, המשתמשים, חוויה מוזיקלית מלאה ואיכותית במחשב, בסמארטפון ובטאבלט, לרבות אפליקציות המיועדות ל- iPhone ולאנדרואיד.
  אפליקציית מוזיקה Digital eco99 כוללת ספריית ענק של סטים מוזיקליים ייעודיים הנערכים על ידי עורכי המוזיקה של התחנה, ומחולקים לפי קטגוריות ונושאים, המותאמים להזדמנות ההאזנה ולאופי המאזין. למשל: eco love- המתאימים לערבים זוגיים ורומנטיים, eco run- המתאימים לפעילות ספורטיבית, אימוני כושר וריצות, eco chill- לנסיעה רגועה, eco kids- מוזיקה לקטנטנים להאזנה לפני השינה ולפעילות שלווה או אנרגטית עימם, eco ישראלי- כל השירים מכל הזמנים ומכל הז'אנרים בעברית, ועוד הרבה סטים אחרים וטובים בבחירת העורכים שלנו: שחף איל, אידית אבשלומי, נעמה בן נפתלי, אמיר צוויבן, הילה אבשלומי, נעמה יוגב, ואפרת מירון.
  אם אתם אוהבים את שירותי הסטרימינג spotify, apple music או deezer, אז תשמחו לגלות ש אקו 99 מיוזיק שמה את הדגש על עריכת פלייליסטים, אחד אחד, ללא תוספת אלגוריתמים, כי אנחנו מכירים את הטעם שלכם הכי טוב, ויודעים להתאים את המוזיקה למה שאתם עושים ומרגישים עכשיו, ובחינם!
  
  להורדת אפליקציית מוזיקה eco99fm ב- app store: <a href="https://itunes.apple.com/il/app/radio-eco-99fm-rdyw-qw/id538406522?mt=8">לחצו כאן</a>
  
  להורדת אפליקציית מוזיקה eco99fm ב- google play: <a href="https://play.google.com/store/apps/details?id=com.cyberserve.android.reco99fm">לחצו כאן</a>
  
  eco99fm  גם ב- <a href="https://www.youtube.com/user/eco99fm">youtube</a> ב- <a href="https://www.facebook.com/eco99fm/">facebook</a> וב- <a href="https://instagram.com/eco99fm/">instagram</a>
  
  בנוסף, תוכלו לתפוס את כל התוכניות של טל ואביעד שפספסתם, תוכניות מלאות או קטעים נבחרים מתוך תוכנית הבוקר עם טל ברמן ואביעד קיסוס.
  ביקרתם כבר בערוץ ה- youtube הרשמי שלנו? אם לא, זה הזמן להתעדכן בערוץ המוסיקה שלנו המכיל טעימות מעוררות תאבון ממגוון הסטים המוזיקליים שלנו, הזמינים עבורכם בכל מקום, בכל זמן, ובחינם. וכן, בכל הביצועים מתוך פישטנק, פודקאסט אירוחי האמנים שלנו.
  להנאתכם שירים שילוו אתכם באימון בחדר כושר, בריצה, תוך כדי עבודה, נסיעה, על חוף הים, בבית, או בכל מקום שתבחרו. אנו עורכים עבורכם את מיטב המוזיקה בפלייליסטים, כמו - eco top hits, eco new hits, eco 70/80/90, eco special, eco rock, eco love, ועוד.`;

  ngOnInit() {
    this.sharedService.showUpperSection = false;
    setTimeout(() => {
      this.sharedService.showHeaderHam = false;
    });
  }

  ngOnDestroy(): void {
    this.sharedService.showUpperSection = true;
    setTimeout(() => {
      this.sharedService.showHeaderHam = true;
    });
  }
}
