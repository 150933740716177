import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { FormControl, Validators } from "@angular/forms";
import { NetworkService } from "../../services/network.service";
import { SharedService } from "../../services/shared.service";
declare var bodymovin: any;

@Component({
  selector: "app-invite-friends-dialog",
  templateUrl: "./invite-friends-dialog.component.html",
  styleUrls: ["./invite-friends-dialog.component.scss"]
})
export class InviteFriendsDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<any>,
    private networkService: NetworkService,
    private sharedService: SharedService
  ) {}
  loadingAnimation: any;
  showPopup = false;
  invitationSent = false;
  emailFocusedOut: boolean;
  isEmailValid: boolean;
  email: string = "";
  isLoading: boolean;

  ngOnInit() {
    setTimeout(() => {
      this.showPopup = true;
    }, 100);
  }

  onInvite() {
    if (this.isLoading) return;

    this.checkEmail(this.email);
    if (this.isEmailValid) {
      this.isLoading = true;
      this.startLoadingAnimation();
      this.networkService.inviteFamilyMember(this.email).subscribe(
        resp => {
          this.sharedService.user.payment_data.settings.members = resp.members;
          this.isLoading = false;
          this.invitationSent = true;
          if (
            this.sharedService.user.payment_data.settings.members.length == 5
          ) {
            this.dialogRef.close();
          }
          this.email = "";
        },
        err => {
          this.isLoading = false;
          if (err.error.error_custom_code == 14) {
            this.sharedService.showAlert(
              "המשתמש שהזמנת כבר משוייך למשפחה שלך",
              true,
              false
            );
          } else {
            if (err.error.error_custom_code == 17) {
              this.sharedService.showAlert(
                "אינך יכול להזמין את עצמך...",
                true,
                false
              );
            } else {
              this.sharedService.showAlert("היתה בעיה, אנא נסה שנית");
            }
          }
        }
      );
    }
  }

  onLater() {
    this.dialogRef.close();
  }

  checkEmail(email) {
    // this.emailFocusedOut = false
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.isEmailValid = re.test(email);
  }

  startLoadingAnimation() {
    if (this.loadingAnimation) {
      this.loadingAnimation.destroy();
    }
    this.loadingAnimation = bodymovin.loadAnimation({
      container: document.getElementById("loader"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: "assets/animations/loader.json"
    });
  }
}
