import { Injectable, Inject, PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser } from "@angular/common";

declare var gtag: any;
@Injectable()
export class GoogleAnalyticsService {
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  sendPageView(path, name) {
    if (isPlatformBrowser(this.platformId)) {
      gtag("config", "UA-38275187-10", { page_path: path });
      gtag("config", "UA-38275187-1", { page_path: path });
    }
  }
  sendEvent(eventCategory, eventAction, value, eventLabel?) {
    if (isPlatformBrowser(this.platformId)) {
      gtag("event", eventAction, {
        event_category: eventCategory,
        event_label: eventLabel ? eventLabel : value,
        value: eventLabel ? value : ""
      });
    }
  }
}
