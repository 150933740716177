import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Inject,
  HostListener,
  NgZone,
} from "@angular/core";
import { MessageService } from "../../services/messageService";
import { SharedService } from "../../services/shared.service";
import { NetworkService } from "../../services/network.service";
import { ProgramsQuery } from "../../services/programsQuery";
declare var bodymovin: any;
import { Router } from "@angular/router";
import { LOCAL_STORAGE } from "@ng-toolkit/universal";
import { PlaylistQuery } from "../../services/playlistQuery";
import { MatSnackBar } from "@angular/material";
import { GoogleAnalyticsService } from "../../services/googleAnalytics.service";

declare var NoSleep: any;
declare var $: any;

@Component({
  selector: "app-sticky-player",
  templateUrl: "./sticky-player.component.html",
  styleUrls: ["./sticky-player.component.scss"],
})
export class StickyPlayerComponent implements OnInit {
  @ViewChild("audioPlayer", { static: true }) private audioPlayer: ElementRef;
  @ViewChild("volume", { static: true }) private volume: ElementRef;
  @ViewChild("marquee", { static: false }) private marquee: ElementRef;

  noSleep: any;
  subscription: any;
  subscription2: any;
  data: any = {};
  currentTime = "";
  duration = "";
  currentPosition = 0;
  currentVoisePosition = 0;
  currentSong: {};
  currentContentType;
  isPlaying: boolean = false;
  isLoading: boolean = false;
  mutePlayer = false;
  animationInProcess: boolean = false;
  playAnimation = "";
  animation: any;
  FourMinutesInterval: any;
  secondsInterval: any;
  showSkipMessage = true;
  showPrevMessage = true;
  constructor(
    private messageService: MessageService,
    public sharedService: SharedService,
    private networkService: NetworkService,
    public router: Router,
    @Inject(LOCAL_STORAGE) private localStorage: any,
    private _snackBar: MatSnackBar,
    private analyticsService: GoogleAnalyticsService,
    private ngZone: NgZone
  ) {}

  //   bufferSound(event) {
  //     if('webkitAudioContext' in window) {
  //       var myAudioContext = new webkitAudioContext();
  //       var request = event.target;
  //       var source = myAudioContext.createBufferSource();
  //       source.buffer = myAudioContext.createBuffer(request.response, false);
  //       this.data.streamingLink = source;
  //   }

  // }
  @HostListener("document:keypress", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    // const notAllowd = ["INPUT", "TEXTAREA"];
    event.stopPropagation();
    if (
      document.activeElement.tagName === "INPUT" ||
      document.activeElement.tagName === "TEXTAREA"
    ) {
      return;
    } else if (event.keyCode === 32 || event.keyCode === 0) {
      if (this.sharedService.showStickyPlayer) {
        if (this.isPlaying) {
          this.pausePlayer();
        } else {
          this.playPlayer();
        }
        event.preventDefault();
      }
    }
  }

  ngOnInit() {
    // let request = new XMLHttpRequest();
    //   request.open('GET', 'https://a99.livecdn.biz/99fm/mediafiles/vote_150919_bitan.mp3', true);
    //   request.responseType = 'arraybuffer'
    //   request.addEventListener('load', this.bufferSound, false);
    //   request.send();
    if (this.sharedService.isPlatformBrowser) {
      this.noSleep = new NoSleep();
      // this.audioPlayer.nativeElement.play()
    }
    this.volume.nativeElement.onchange = (e) => {
      var val = e.target.value + "%";
      $(".slider").css(
        "background-image",
        "-webkit-gradient(linear, left top, right top, " +
          "color-stop(" +
          val +
          ", #57b629), " +
          "color-stop(" +
          val +
          ", #C5C5C5)" +
          ")"
      );
    };
    this.audioPlayer.nativeElement.ontimeupdate = (e) => {
      var val = this.currentPosition / 100;
      $(".position-bar").css(
        "background-image",
        "-webkit-gradient(linear, left top, right top, " +
          "color-stop(" +
          val +
          ", #57b629), " +
          "color-stop(" +
          val +
          ", #C5C5C5)" +
          ")"
        // 'linear-gradient(269deg, #00b5b4, #57b629, #57b629)'
      );
    };

    this.audioPlayer.nativeElement.addEventListener("ended", () => {
      this.playNext();
    });

    this.subscription = this.messageService
      .listenToPlayerChanges()
      .subscribe((e) => {
        if (!e.streamingLink) {
          console.log("pause");
          this.pausePlayer();
          return;
        }
        this.data = e;
        setTimeout(() => {
          if (this.marquee && this.marquee.nativeElement) {
          }
        });

        if (
          (!this.data.playlist_id ||
            this.data.playlist_id !=
              this.sharedService.currentPlayingContentID) &&
          (!this.data.program_id ||
            this.data.program_id != this.sharedService.currentPlayingContentID)
        ) {
          if (this.data.playlist_id) {
            // is playlist
            if (!this.sharedService.allowContent) {
              this._snackBar.open("התוכן אינו זמין לשמיעה במדינה זו", null, {
                duration: 2000,
              });
              return;
            }

            this.sharedService.currentPlayingContentID = this.data.playlist_id;
            this.currentContentType = "playlist";
            if (this.sharedService.user) {
              this.networkService
                .updatePlaybackReport(
                  "one_track_playlist",
                  this.data.playlist_id
                )
                .subscribe((resp) => {
                  let query = new PlaylistQuery(1, 16);
                  this.networkService
                    .getListenedContent(query, "one_track_playlist")
                    .subscribe((resp) => {
                      resp.items.forEach((playlist) => {
                        playlist.isLoaded = true;
                      });
                      this.sharedService.listenedContent = resp.items;
                    });
                });
            } else {
              let savedPlaylists;
              if (this.localStorage.getItem("listened-content")) {
                savedPlaylists = JSON.parse(
                  this.localStorage.getItem("listened-content")
                );
              } else {
                savedPlaylists = [];
              }
              this.data = this.prepareForGallery(this.data);
              this.data.isLoaded = true;
              let playlistIndex = undefined;
              savedPlaylists.forEach((playlist, i) => {
                if (playlist.playlist_id == this.data.playlist_id) {
                  playlistIndex = i;
                }
              });

              if (playlistIndex != undefined) {
                savedPlaylists.splice(playlistIndex, 1);
              }

              if (this.data.playlistList && this.data.playlistList.length) {
                let playlists_list_data = {
                  playlistList: this.data.playlistList,
                  playlistIndex: this.data.playlistIndex,
                };
                playlists_list_data.playlistList.forEach((playlist) => {
                  playlist.playlistList = null;
                });
                this.localStorage.setItem(
                  "playlists_list_data",
                  JSON.stringify(
                    playlists_list_data,
                    this.getCircularReplacer()
                  )
                );
                delete this.data.playlistList;
                delete this.data.playlistIndex;
              }

              savedPlaylists.unshift(this.data);
              this.sharedService.listenedContent = savedPlaylists;
              this.localStorage.setItem(
                "listened-content",
                JSON.stringify(savedPlaylists)
              );
            }
          }
          if (this.data.program_id) {
            // is program
            // if (!this.sharedService.allowContent){
            //   this.animation = bodymovin.loadAnimation({
            //     container: document.getElementById("paused"),
            //     renderer: "svg",
            //     loop: false,
            //     autoplay: true,
            //     path: "assets/animations/Pulse3.2/pulse3.json"
            //   });
            //   this._snackBar.open('התוכן אינו זמין לשמיעה במדינה זו', null, {
            //     duration: 2000,
            //   });
            //   return
            // }
            this.sharedService.currentPlayingContentID = this.data.program_id;
            this.currentContentType = "program";
            let playlists_list_data = {
              playlistList: this.data.playlistList,
              playlistIndex: this.data.playlistIndex,
            };
            this.localStorage.setItem(
              "playlists_list_data",
              JSON.stringify(playlists_list_data, this.getCircularReplacer())
            );

            delete this.data.playlistList;
            delete this.data.playlistIndex;
          }
          setTimeout(() => {
            if (this.audioPlayer.nativeElement) {
              this.audioPlayer.nativeElement.load();

              this.playPlayer();
            }
          });
        } else {
          setTimeout(() => {
            this.playPlayer();
          });
        }

        if (this.data.isLive) {
          this.sharedService.currentPlayingContentID = "live-radio";
          this.currentContentType = "live-radio";
          let playlists_list_data = {
            playlistList: undefined,
            playlistIndex: undefined,
          };
          this.localStorage.setItem(
            "playlists_list_data",
            JSON.stringify(playlists_list_data, this.getCircularReplacer())
          );

          delete this.data.playlistList;
          delete this.data.playlistIndex;
        }
      });
    this.subscription2 = this.messageService
      .listenToPlayerDetailsChanges()
      .subscribe((e) => {
        if (e.pause) {
          this.pausePlayer();
        } else {
          this.data.songName = e.songName;
          this.data.programName = e.artistName;
          this.data.thumbnail = e.thumbnail;
        }
      });
  }

  audioError(e) {
    if (this.data.streamingLink) {
      switch (e.target.error.code) {
        case e.target.error.MEDIA_ERR_ABORTED:
          break;
        case e.target.error.MEDIA_ERR_NETWORK:
          this._snackBar.open(
            "בעיה בהשמעת התוכן, ודא כי החיבור לאינטרנט תקין",
            null,
            {
              duration: 2000,
            }
          );
          break;
        case e.target.error.MEDIA_ERR_DECODE:
          this._snackBar.open("בעיה בהשמעת התוכן", null, {
            duration: 2000,
          });
          break;
        case e.target.error.MEDIA_ERR_SRC_NOT_SUPPORTED:
          this._snackBar.open(
            "בעיה בהשמעת התוכן",
            null,
            {
              duration: 2000,
            }
          );
          break;
        default:
          this._snackBar.open("בעיה בהשמעת התוכן", null, {
            duration: 2000,
          });
          break;
      }
    }
  }

  getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  };

  prepareForGallery(data) {
    data.image = data.image;
    data.image_mobile = data.thumbnail;
    data.name_he = data.songName;

    return data;
  }

  volumeChanged(e) {
    var val = e.currentTarget.value / 100;

    this.audioPlayer.nativeElement.volume = val;
  }

  like() {
    if (!this.sharedService.user) {
      this.sharedService.showCreateUserPopup();
      return;
    }

    this.sharedService.updateLikedContent(this.data.playlist_id);
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, null, {
      duration: 2000,
    });
  }

  playNext(playPrev?) {
    if (this.data.playlist_id) { 
      if (!playPrev) {
        this.openSnackBar("דילגת לפלייליסט הבא");
      } else {
        this.openSnackBar("דילגת לפלייליסט הקודם");
      }
    }
    if (!this.isLoading) {
      if (this.data.query) {
        // its a show
        this.isLoading = true;
        let query = this.data.query;
        this.data.index = playPrev ? this.data.index + 1 : this.data.index - 1;
        if (this.data.index < 1) {
          this.data.index = 1;
          this.isLoading = false;
          this.pausePlayer()
          return;
        }
        let originalPage = query.page;
        let originalItemsPerPage = query.itemsPerPage;
        query.page = this.data.index;
        query.itemsPerPage = 1;

        this.networkService.getPrograms(query).subscribe((resp) => {
          query.page = originalPage;
          query.itemsPerPage = originalItemsPerPage;
          if (resp.programs && resp.programs.length) {
            this.checkImage(resp.programs[0], () => {
              this.sharedService.loadProgramToPlayer(
                resp.programs[0],
                resp.programs[0].description,
                query,
                this.data.index,
                this.data.programUrl
              );
              if (
                this.router.url.indexOf("program") != -1 &&
                this.router.url.indexOf("tab") == -1
              ) {
                // player page
                this.goToPage();
              }
            });
          } else {
            this.pausePlayer();
            // this.data.index = playPrev ? this.data.index - 1 : this.data.index + 1
          }
          this.isLoading = false;
        });
      }

      if (!this.data.playlistList || !this.data.playlistList.length) {
        let playlists_list_data = JSON.parse(
          this.localStorage.getItem("playlists_list_data")
        );
        this.data.playlistList = playlists_list_data.playlistList;
        this.data.playlistIndex = playlists_list_data.playlistIndex;
      }
      if (this.data.playlistList && this.data.playlistIndex != undefined) {
        // we've got a playlist
        playPrev ? this.data.playlistIndex-- : this.data.playlistIndex++;
        let nextPlaylist;

        if (this.data.playlistList[this.data.playlistIndex]) {
          nextPlaylist = this.data.playlistList[this.data.playlistIndex];
        } else {
          // loop over playlists array
          if (playPrev) {
            this.data.playlistIndex = this.data.playlistList.length - 1;
          } else {
            this.data.playlistIndex = 0;
          }
          nextPlaylist = this.data.playlistList[this.data.playlistIndex];
        }
        nextPlaylist.playlistList = this.data.playlistList;
        nextPlaylist.playlistIndex = this.data.playlistIndex;
        this.sharedService.loadPlaylistToPlayer(nextPlaylist, true);
        if (this.router.url.indexOf("playlist/") != -1) {
          // player page
          this.goToPage();
        }
      }
    }
  }

  goToPage() {
    if (this.data.isLive) {
      // live radio
      this.router.navigateByUrl("/live-radio");
      return;
    }

    if (this.data.song_list) {
      // playlist
      this.sharedService.tempPlaylist = this.sharedService.currentPlayingPlaylist;
      this.router.navigateByUrl(
        "/playlist/" +
          (this.sharedService.tempPlaylist.original_id
            ? this.sharedService.tempPlaylist.original_id
            : this.sharedService.tempPlaylist.playlist_id)
      );
    } else {
      this.sharedService.tempProgram = this.sharedService.currentPlayingProgram;
      this.router.navigateByUrl(
        "/program/" +
          this.data.programUrl +
          "/" +
          this.sharedService.currentPlayingContentID
      );
    }
  }

  checkImage(show, cb) {
    if (this.sharedService.isPlatformBrowser) {
      var img = new Image();
      img.onload = function () {
        cb();
      };
      img.onerror = function () {
        show.image_url = "/assets/images/show-placeholder.png";
        cb();
      };
      img.src = show.image_url;
    } else {
      cb();
    }
  }

  parseTime(duration) {
    if (!duration || duration == Infinity) return "";

    return new Date(duration * 1000).toISOString().substr(11, 8);
  }
  reverseTimeParse(secondsString) {
    const splitedTime = secondsString.split(":");
    return splitedTime.length === 3
      ? parseInt(splitedTime[0]) * 3600 +
          parseInt(splitedTime[1]) * 60 +
          parseInt(splitedTime[2])
      : 0;
  }

  skipToNextSong() {
    const song_list = this.data.song_list;
    const currentPlayingSongIndex = song_list.findIndex((song) => this.data.songName === song.song_name)
    if(currentPlayingSongIndex === song_list.length-1) {
      this.playNext();
    }
    else {
      if (this.data.playlist_id && this.showSkipMessage) { 
        this.openSnackBar("דילגת לשיר הבא");
        this.showSkipMessage = false;
      }
      this.audioPlayer.nativeElement.currentTime  = song_list[currentPlayingSongIndex+1].start
      this.currentTime = this.parseTime(
        this.audioPlayer.nativeElement.currentTime
      );
      this.updatePlayerDetails();
      this.playPlayer();
    }
  }

  startSongAgain() {
    const song_list = this.data.song_list
    const currentPlayingSongIndex = song_list.findIndex((song) => this.data.songName === song.song_name)
    const currentTimeInSeconds = this.audioPlayer.nativeElement.currentTime;
    const currentSongStartTime = song_list[currentPlayingSongIndex].start;
    if(currentTimeInSeconds - currentSongStartTime > 5) {
      this.audioPlayer.nativeElement.currentTime = currentSongStartTime;
    }
    else {
      if(currentPlayingSongIndex > 0) {
        if (this.data.playlist_id && this.showPrevMessage) {
          this.showPrevMessage = false; 
          this.openSnackBar("דילגת לשיר הקודם");
        }
        this.audioPlayer.nativeElement.currentTime = song_list[currentPlayingSongIndex-1].start;
      }
      else {
        this.playNext(true);
      }
    }
    this.currentTime = this.parseTime(
      this.audioPlayer.nativeElement.currentTime
    );
    this.updatePlayerDetails();
    this.playPlayer();
  }

  skip15() {
    let timeLeft =
      this.audioPlayer.nativeElement.duration -
      this.audioPlayer.nativeElement.currentTime;
    if (timeLeft < 15) {
      this.playNext();
      return;
    }

    this.audioPlayer.nativeElement.currentTime =
      this.audioPlayer.nativeElement.currentTime + 15;
    this.currentTime = this.parseTime(
      this.audioPlayer.nativeElement.currentTime
    );
    this.playPlayer();
  }

  prev15() {
    if (this.audioPlayer.nativeElement.currentTime < 15) {
      this.playNext(true);
      return;
    }
    this.audioPlayer.nativeElement.currentTime =
      this.audioPlayer.nativeElement.currentTime - 15;
    this.currentTime = this.parseTime(
      this.audioPlayer.nativeElement.currentTime
    );
    this.playPlayer();
  }

  playPlayer() {
    const shouldPlay =
      this.data.currentSongTime === "00:00" && !this.data.dontPlay;
    if (this.data.isLive) {
      this.audioPlayer.nativeElement.load();
    }
    if (!this.audioPlayer.nativeElement) return;
    // this.audioObj.src = 'https ://99sets.livecdn.biz/99sets/best_99/eco_top_hits_black_october_israeli_2019.mp3';
    // this.audioObj.load();
    // this.audioObj.play();
    if (this.isPlaying) {
      clearInterval(this.secondsInterval);
      clearInterval(this.FourMinutesInterval);
    }
    if (this.noSleep && shouldPlay) {
      this.noSleep.enable();
      var promise = this.audioPlayer.nativeElement.play();
      if (promise !== undefined) {
        promise.then((_) => {}).catch((error) => {});
      }
    } else {
      this.audioPlayer.nativeElement.currentTime = this.reverseTimeParse(
        this.data.currentSongTime
      );
      this.data.currentSongTime = "00:00";
      this.data.dontPlay = false;
    }

    this.duration = this.parseTime(this.audioPlayer.nativeElement.duration);
    this.currentTime = this.data.isLive
      ? ""
      : this.parseTime(this.audioPlayer.nativeElement.currentTime);
    this.currentPosition = this.data.isLive
      ? 100
      : (this.audioPlayer.nativeElement.currentTime /
          this.audioPlayer.nativeElement.duration) *
        100;
    this.analyticsService.sendEvent(
      "player",
      this.data.query
        ? "program"
        : this.data.isLive
        ? "live_radio"
        : "playlist",
      this.currentTime ? this.currentTime : "",
      this.sharedService.currentPlayingContentID
    );
    this.secondsInterval = setInterval(() => {
      this.duration = this.parseTime(this.audioPlayer.nativeElement.duration);
      this.currentTime = this.data.isLive
        ? ""
        : this.parseTime(this.audioPlayer.nativeElement.currentTime);
      this.currentPosition = this.data.isLive
        ? 100
        : (this.audioPlayer.nativeElement.currentTime /
            this.audioPlayer.nativeElement.duration) *
          100;
      if (this.data.playlist_id) {
        // is playlist
        this.updatePlayerDetails();

        this.messageService.sendPlayerData({
          currentTime: this.currentTime,
          currentSong: this.currentSong,
        });
      }
      if (this.isPlaying) {
        this.savePlayerDetailsOnLocalStorage();
      }
    }, 1000);

    this.FourMinutesInterval = setInterval(() => {
      if (this.isPlaying) {
        this.analyticsService.sendEvent(
          "player",
          this.data.query
            ? "program"
            : this.data.isLive
            ? "live_radio"
            : "playlist",
          this.currentTime ? this.currentTime : "",
          this.sharedService.currentPlayingContentID
        );
      }
    }, 1000 * 240);

    if (this.isPlaying || this.animationInProcess) {
      return;
    }
    if (shouldPlay) {
      this.isPlaying = true;
      this.sharedService.isPlaying = true;
      this.animationInProcess = true;
      setTimeout(() => {
        if (this.animation) {
          this.animation.destroy();
        }
        this.animation = bodymovin.loadAnimation({
          container: document.getElementById("playing"),
          renderer: "svg",
          loop: false,
          autoplay: true,
          path: "assets/animations/Pulse1.2/pulse1.2.json",
        });
      });

      setTimeout(() => {
        if (this.animation) {
          this.animation.destroy();
        }
        this.animation = bodymovin.loadAnimation({
          container: document.getElementById("playing"),
          renderer: "svg",
          loop: true,
          autoplay: true,
          path: "assets/animations/Pulse2.2/pulse2.json",
        });
        this.animationInProcess = false;
      }, 400);
    } else {
      setTimeout(() => {
        this.animation = bodymovin.loadAnimation({
          container: document.getElementById("paused"),
          renderer: "svg",
          loop: false,
          autoplay: true,
          path: "assets/animations/Pulse3.2/pulse3.json",
        });
      });
    }
  }

  pausePlayer() {
    if (!this.isPlaying || this.animationInProcess) {
      return;
    }
    if (this.noSleep) {
      this.noSleep.disable();
    }
    this.isPlaying = false;
    this.sharedService.isPlaying = false;
    this.audioPlayer.nativeElement.pause();
    clearInterval(this.secondsInterval);
    clearInterval(this.FourMinutesInterval);

    setTimeout(() => {
      if (this.animation) {
        this.animation.destroy();
      }
      this.animation = bodymovin.loadAnimation({
        container: document.getElementById("paused"),
        renderer: "svg",
        loop: false,
        autoplay: true,
        path: "assets/animations/Pulse3.2/pulse3.json",
      });
    });
  }

  setCurrentVoisePosition(positionPercentage) {
    if (Number.isNaN(this.volume.nativeElement.duration)) return;

    this.volume.nativeElement.currentTime =
      (positionPercentage / 100) * this.volume.nativeElement.duration;
    this.currentVoisePosition =
      (this.volume.nativeElement.currentTime /
        this.volume.nativeElement.duration) *
      100;
  }

  setCurrentPosition(positionPercentage) {
    if (Number.isNaN(this.audioPlayer.nativeElement.duration)) return;

    this.audioPlayer.nativeElement.currentTime =
      (positionPercentage / 100) * this.audioPlayer.nativeElement.duration;
    this.currentPosition =
      (this.audioPlayer.nativeElement.currentTime /
        this.audioPlayer.nativeElement.duration) *
      100;
  }


  shuffle() {
    if (!this.sharedService.user || this.sharedService.isFreeUser()) {
      // free user
      this.sharedService.showCreateUserPopup();
    } else {
    }
  }

  repeat() {
    if (!this.sharedService.user || this.sharedService.isFreeUser()) {
      // free user
      this.sharedService.showCreateUserPopup();
    } else {
    }
  }

  updatePlayerDetails() {
    let currentSecond = this.audioPlayer.nativeElement.currentTime;
    // if (!this.data.playlistList)return
    let playlist = this.data;
    if (!playlist.song_list) return "";
    playlist.song_list.forEach((song, index) => {
      if (
        song.start <= currentSecond &&
        (!playlist.song_list[index + 1] ||
          playlist.song_list[index + 1].start > currentSecond)
      ) {
        this.currentSong = song;
        this.data.songName = song.song_name;
        this.data.programName = song.artist_name;
        this.data.thumbnail = song.small_image
          ? song.small_image
          : "assets/images/song-placeholder.svg";
        return;
      }
    });
  }

  savePlayerDetailsOnLocalStorage() {
    this.localStorage.setItem(
      "playing_data",
      JSON.stringify({
        time: this.currentTime,
        contentType: this.currentContentType,
        contentId: this.sharedService.currentPlayingContentID,
        index: this.data.index,
        query: this.data.query
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription = null;
    this.subscription2 = null;
  }
  durationChanged() {
    this.currentPosition = this.data.isLive
      ? 100
      : (this.audioPlayer.nativeElement.currentTime /
          this.audioPlayer.nativeElement.duration) *
        100;
  }
}
