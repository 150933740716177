import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  OnDestroy
} from "@angular/core";
import { SharedService } from "../../services/shared.service";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { NetworkService } from "../../services/network.service";
declare var bodymovin: any;
declare var $: any;
import { PlaylistGalleryComponent } from "../../components/playlist-gallery/playlist-gallery.component";
import { PlaylistQuery } from "../../services/playlistQuery";
import { AuthService } from "../../services/auth.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-homepage",
  templateUrl: "./homepage.component.html",
  styleUrls: ["./homepage.component.scss"]
})
export class HomepageComponent implements OnInit, OnDestroy {
  activeCategory: any;
  activeSubcategory: any;
  moodIndex: any;
  moodSecondaryIndex: any;
  moodID: any;
  subMoodPlaylistsLoading = false;
  @ViewChild("moodsGallery", { static: false })
  private moodsGallery: PlaylistGalleryComponent;

  constructor(
    public sharedService: SharedService,
    private router: Router,
    public dialog: MatDialog,
    public NetworkService: NetworkService,
    private networkService: NetworkService,
    private authService: AuthService
  ) {
    this.sharedService.showHeaderHam = true;
  }

  ngOnInit() {
  }

  ngOnDestroy() {}

  categorySelected(data) {
    if (this.sharedService.isPlatformBrowser) {
      setTimeout(() => {
        $("html, body").animate(
          {
            scrollTop: $("#subcategories").offset().top - 340
          },
          500
        );
      });
    }

    this.activeCategory = data.category;
    this.moodIndex = data.index;

    if (this.activeSubcategory) {
      this.activeSubcategory = undefined;
    }
  }

  subcategorySelected(data) {
    if (this.activeSubcategory == data.category) return;

    if (data.category.playlists) {
      data.category.playlists.forEach(playlist => {
        playlist.isLoaded = false;
      });
    }

    this.activeSubcategory = data.category;
    this.moodSecondaryIndex = data.index;
    this.moodID = data.category.original_id;
    this.sharedService.tempActiveSubcategory = this.activeSubcategory;
    if (this.sharedService.isPlatformBrowser) {
      setTimeout(() => {
        $("html, body").animate(
          {
            scrollTop: $("#categoryPlaylists").offset().top - 300
          },
          500
        );
      });
    }
    if (this.activeSubcategory.playlists) {
      let i = 0;
      let interval = setInterval(() => {
        if (
          !this.activeSubcategory ||
          i == this.activeSubcategory.playlists.length
        ) {
          clearInterval(interval);
        } else {
          if (this.activeSubcategory.playlists[i]) {
            this.activeSubcategory.playlists[i].isLoaded = true;
          }
          i++;
        }
      }, 50);
      return;
    }

    let playlistsQuery = new PlaylistQuery();
    playlistsQuery.itemsPerPage = 16;
    this.subMoodPlaylistsLoading = true;
    this.networkService
      .getSubmoodByID(data.category.original_id, playlistsQuery)
      .subscribe(res => {
        data.category.playlists = res.items;
        this.subMoodPlaylistsLoading = false;
        setTimeout(() => {
          this.moodsGallery.initScroll();
        });

        let i = 0;
        let interval = setInterval(() => {
          if (
            !this.activeSubcategory ||
            i == this.activeSubcategory.playlists.length
          ) {
            clearInterval(interval);
          } else {
            if (this.activeSubcategory.playlists[i]) {
              this.activeSubcategory.playlists[i].isLoaded = true;
            }
            i++;
          }
        }, 50);
      });
  }
}
