import {
  Component,
  ViewChild,
  HostListener,
  ElementRef,
} from "@angular/core";
import { DfpService } from "@wwei/dfp";
import { environment } from "src/environments/environment";
@Component({
  selector: "app-ad-dialog",
  templateUrl: "./ad-dialog.component.html",
  styleUrls: ["./ad-dialog.component.scss"],
})
export class AdDialogComponent {
  googleUnitPath = environment.googleUnitPath;
  @ViewChild("dialog", { static: false })
  dialogRef: ElementRef;
  @ViewChild("ad", { static: false }) adRef;
  showDialog = true;
  showCloseButton = false;
  @HostListener("window:load")
  onLoad() {
    const ad: HTMLCollection = this.adRef.nativeElement.children;
    this.showDialog = ad.item(0).hasChildNodes();
    this.showCloseButton = ad.item(0).hasChildNodes();
  }

  constructor(private dfp: DfpService) {
    // Use googletag to customize page-level settings
    this.dfp.cmd(() => {
      googletag.pubads().collapseEmptyDivs();
      googletag.pubads().enableSingleRequest();
    });
  }

  closeDialog() {
    this.dialogRef.nativeElement.remove();
  }
}