import { Component, OnInit, OnDestroy } from "@angular/core";
import { SharedService } from "../../services/shared.service";

@Component({
  selector: "app-privacy-policy",
  templateUrl: "./privacy-policy.component.html",
  styleUrls: ["./privacy-policy.component.scss"]
})
export class PrivacyPolicyComponent implements OnInit, OnDestroy {
  constructor(private sharedService: SharedService) {}
  policyText = `התנאים הבאים מפרטים את מדיניות הפרטיות הקשורה בשימוש באפליקציה של רדיו אקו התנאים הבאים מפרטים את מדיניות הפרטיות הקשורה בשימוש באפליקציה של רדיו אקו 99FM (להלן: "האפליקציה") ומהווה חלק בלתי נפרד מהסכם תנאי השימוש בה ונכללת בתוכו. מדיניות הפרטיות נועדה להוסיף על (ולא לגרוע מ או לפגוע ב)תנאי השימוש הנ"ל.
  עצם הורדת האפליקציה ו/או השימוש בה מהווה הסכמתך לכל התנאים המפורטים במדיניות הפרטיות
  1.	רישום לאפליקציה
  1.1	השימוש באפליקציה לא מצריך רישום, אולם רישום לאפליקציה משפר את חווית המשתמש ומעשיר אותה. 
  1.2	הרישום מתבצע באמצעות רישום ישיר (במסגרתו תתבקש למסור לנו פרטים אישיים אודותיך (כמו שמך המלא, כתובת מגורים, כתובת דוא"ל ועוד) או באמצעות חשבונך ברשתות חברתיות (דוגמת פייסבוק וגוגל+). 
  1.3	השימוש ברשתות חברתיות לשם רישום לאפליקציה הוא חיצוני ונפרד לאפליקציה וכפוף גם למדיניות הפרטיות של רשתות חברתיות אלו. כמו כן, רישום לאפליקציה באמצעות חשבונך ברשתות חברתיות מאפשר לאקו גישה למידע הבסיסי אודותייך בפרופיל הרשת החברתית שלך, כגון שמך, כתובת הדוא"ל שלך, תמונת הפרופיל שלך וכל מידע ותוכן אחר הזמין לעיון בחשבון הרשת החברתית שלך. גישתנו למידע ולתכנים כאמור מוגבלת למידע ולתכנים שמפעיל הרשת החברתית בה אתה משתמש מאפשר לנו גישה אליהם, וזאת בהתאם להגדרות הפרטיות שלך באותה רשת חברתית, אותן אתה יכול לשנות מעת לעת באמצעות ממשק הרשת החברתית.
  1.4	אנו נהיה רשאים להסתמך על פרטים ומידע אשר יימסרו לנו על ידכם, בין השאר ובמיוחד בעת הרישום, לכל דבר ועניין.
  1.5	מובהר כי אנו לא אחראים על אמיתות הפרטים אשר יימסרו לנו במסגרת הליך הרישום ו/או המידע המופיע בחשבונך ברשתות חברתיות ולא נהיה אחראים לכל נזק ישיר ו/או עקיף, לרבות הוצאות ישירות ו/או עקיפות שיגרמו לגולש באפליקציה ו/או לכל צד שלישי עקב הסתמכותנו על הפרטים ו/או המידע כאמור ושימוש שלנו בפרטים ובמידע אלו.
  
  2.	עוגיות
  2.1	מובהר כי השימוש באפליקציה, במידע, בשירותים ובתכנים הכלולים בה מהווה הסכמה מצידכם לפגיעה מוגבלת בפרטיותכם, על דרך שמירת מידע ונתונים לגביכם במאגרנו, שימוש בפרטיכם לשם אספקת השירותים הניתנים במסגרת האפליקציה, התאמת פרסומות, תפעול ופיתוח האפליקציה, בדיקות תקינות ותחזוקה הקשורים באפליקציה, גביית כספים, עריכת חשבונות, ניתוח ומסירת מידע סטטיסטי לצדדים שלישיים, לרבות מפרסמים, משלוח עדכונים או חומר פרסומי או שיווקי (במידה וניתנה הסכמתכם לכך), יצירת קשר עמכם, וביצוע מעקב ממוחשב על השימוש שאתם עושים במידע, בשירותים ובתכנים שבאתר, בכל דרך חוקית שנמצא לנכון, לרבות באמצעות "עוגיות" (“cookies”) 
  2.2	הנך יכול למחוק את העוגיה בכל שלב מציוד הקצה שברשותך. כמו כן, הנך יכול לבחור מראש שלא לאפשר לאפליקציה לאחסן עוגיות בציוד הקצה שברשותך, וזאת באמצעות הגדרות הדפדפן או מערכת האבטחה של ציוד הקצה. יחד עם זאת, נטרול אפשרות אחסון העוגיות עלול לגרום לכך כי לא תוכל להשתמש בשירותים ובתכונות המופיעים באפליקציה או בקישורים המופיעים בה, כולם או חלקם.
  
  3.	דיוור; פנייה למשתמשי האפליקציה
  3.1.	שימוש באפליקציה ואישורך לקבל דיוור מעיד על הסכמתך המפורשת לקבלת דברי פרסומת, דיוור, עדכונים, התראות וכד' מאקו ו/או מי מטעמה, כאמור בחוק התקשורת (בזק ושידורים), התשמ"ב – 1982 (להלן: "חוק התקשורת"). דברי הפרסומת ישלחו אליך, בין השאר, לפי המידע שמסרת לאקו במהלך הרישום הישיר לאפליקציה ו/או באמצעות הפרטים שאפשרת לנו גישה אליהם ככל ונרשמת לשירת באמצעות חשבונך ברשתות חברתיות.
  3.2.	בהתאם להוראות חוק התקשורת בכל עת, תוכל לחזור בך מהסכמתך ולהודיע לנו על סירובך לקבלת דברי פרסומת, דרך כלל או מסוג מסוים, על-ידי משלוח הודעת סירוב לכתובת: music@eco99.fm.
  3.3.	בנוסף, אקו עשויה לשלוח לציוד הקצה שברשותך הודעות מתפרצות (Push Notifications). במידה ותבקש לחדול מקבלת הודעות מתפרצות כאמור, יהיה עליך להסיר תכונה זו באופן עצמאי באמצעות שימוש בתפריט ההגדרות הרלבנטי במכשיר הקצה שברשותך.
  3.4.	חלק מהשירותים הניתנים באמצעות האפליקציה הינם שירותים מבוססי מיקום, אשר עושים שימוש בטכנולוגיית GPS על מנת לקבוע את מיקומך ולספק לך תכנים או פרסומים התואמים ככל הניתן למיקום זה. תוכל בכל עת לבטל את האפשרות לזהות את מיקומך על ידי ביטול תכונה זו בהגדרות מכשיר הקצה שברשותך. יחד עם זאת, ביטול אפשרות זו עשויה למנוע מאיתנו לנו לספק לך תכנים מותאמים אישית כאמור לעיל. 
  
  4.	איסוף ושימוש במידע
  4.1.	אנו רשאים להשתמש בפרטים שנמסרו על ידך ובמידע שיאסף על ידי אקו בנוגע לדפוסי השימוש באפליקציה והרכישה שלך, תכנים או פרסומות אותם קראת, השירותים וההצעות שהתעניינת בהם, מיקומך בעת השימוש, נתוני כתובת האינטרנט (IP address) שבאמצעותם ניגשת לשירות, סוג מערכת ההפעלה המשמשת אותך, סוג המכשיר הקצה שברשותך ומידע אודותיו אמצעי התשלום ששימשו אותך ועוד. זאת, בין השאר ומבלי למצות, לצורך יצירת קשר עמך, דיוור ישיר, תפעול, פיתוח ושיפור האפליקציה, גבית כספים ומסירת מידע לצדדים שלישיים (ובכלל זה למפרסמים ושותפים של אקו).
  4.2.	מבלי לגרוע מכלליות האמור לעיל, אנו רשאים לאסוף ולהסתייע בשירותיהם של צדדים שלישיים כדי לאסוף ולנתח מידע אנונימי, סטטיסטי, או מצרפי בקשר עם השימוש באפליקציה, לרבות מידע הנוגע לפעילותך באפליקציה.
  
  5.	זכויותיך כמשתמש באפליקציה.
  5.1.	על-פי חוק הגנת הפרטיות, התשמ"א-1981 (להלן: "חוק הגנת הפרטיות"), כל אדם זכאי לעיין בעצמו, או על ידי בא-כוחו שהרשהו בכתב או על ידי אפוטרופוסו, במידע שעליו המוחזק במאגר מידע. אדם שעיין במידע שעליו ומצא כי אינו נכון, שלם, ברור או מעודכן, רשאי לפנות לבעל מאגר המידע בבקשה לתקן את המידע או למוחקו. אם בעל המאגר סירב למלא בקשה זו, עליו להודיע על כך למבקש באופן ובדרך שנקבעו בתקנות שמכוח חוק הגנת הפרטיות. על סירובו של בעל מאגר מידע לאפשר עיון ועל הודעת סירוב לתקן או למחוק מידע, רשאי מבקש המידע לערער לפני בית משפט השלום באופן ובדרך שנקבעו בתקנות.
  5.2.	בנוסף, אם המידע שבמאגרנו (מאגר: "eco99music") משמש לצורך פניה אישית אליך, בהתבסס על השתייכותך לקבוצת אוכלוסין, שנקבעה על פי איפיון אחד או יותר של בני אדם ששמותיהם כלולים במאגר ("פניה בהצעה מסחרית"), כי אז אתה זכאי על-פי חוק הגנת הפרטיות לדרוש בכתב (באמצעות פנייה אלינו בכתובת: music@eco99.fm תוך ציון פרטיך לשם עדכון בדבר מחיקת פרטיך מהמאגר כמפורט להלן) שהמידע המתייחס אליך יימחק ממאגר המידע. במקרה זה אנו נמחק את המידע הדרוש לנו רק כדי לפנות אליך בהצעות מסחריות כאמור לעיל. מידע הדרוש לנו לשם ניהול עסקינו - לרבות תיעוד פעולות שביצעת באתר - יוסיף להישמר אצלנו, אך לא ישמש עוד לצורך פניות אליך. אם בתוך 30 יום לא תקבל הודעה כי המידע שביקשת למחוק אכן נמחק על-פי סעיף זה, תהיה זכאי לפנות לבית משפט השלום באופן הקבוע בתקנות שמכוח חוק הגנת הפרטיות, כדי שיורה לנו לפעול כאמור. 
  5.3.	למען הסר ספק יובהר כי מחיקת מידע כאמור לעיל עשוי למנוע ממך פעילויות שונות באפליקציה ו/או למנוע ממך מלקחת חלק בפעילויות כאלו או אחרות אשר יבוצעו על ידינו ו/ו על ידי מי מטעמנו בעתיד.
  
  6.	שונות
  6.1.	מדיניות הפרטיות חלה על כל אדם, זכר או נקבה, תאגיד או כל גוף אחר שהוא כשיר לזכויות ולחובות משפטיות, ללא יוצאים מן הכלל. השימוש בלשון זכר במדיניות הפרטיות הינו לצרכי נוחות בלבד וכולל גם פניה בלשון נקבה, יחיד ורבים ולהיפך.
  6.2.	אקו שומרת לעצמה את הזכות לשנות את מדיניות הפרטיות אלה מעת לעת, לפי שיקול דעתה הבלעדי וללא צורך במסירת הודעה כלשהי מראש. האחריות המלאה להכרת מדיניות הפרטיות, כפי שתעודכן מעת לעת חלה, בכל עת, עליך בלבד.  
  6.3.	החלוקה לסעיפים וכותרות הסעיפים הינה לשם נוחיות בלבד ולא תשמש לצורכי 
  פרשנות.
   
  עדכון אחרון 1.9.19  
  `;
  ngOnInit() {
    setTimeout(() => {
      this.sharedService.showHeaderHam = false;
    });
    this.sharedService.showUpperSection = false;
  }

  ngOnDestroy(): void {
    setTimeout(() => {
      this.sharedService.showHeaderHam = true;
    });
    this.sharedService.showUpperSection = true;
  }
}
