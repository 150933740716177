import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";
import { Subject } from "rxjs/Subject";

@Injectable()
export class MessageService {
  _listners = new Subject<any>();
  _detailsListner = new Subject<any>();
  _listners2 = new Subject<any>();
  _listners_back = new Subject<any>();

  listenToPlayerChanges(): Observable<any> {
    return this._listners.asObservable();
  }

  loadPlayerData(data) {
    this._listners.next(data);
  }

  listenToPlayerDetailsChanges(): Observable<any> {
    return this._detailsListner.asObservable();
  }

  updatePlayerDetails(data) {
    this._detailsListner.next(data);
  }

  listenToPlayerActions(): Observable<any> {
    return this._listners2.asObservable();
  }

  sendPlayerData(data) {
    this._listners2.next(data);
  }

  listenToBackClick(): Observable<any> {
    return this._listners_back.asObservable();
  }

  moveBack() {
    this._listners_back.next();
  }
}
