import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { SharedService } from "../../services/shared.service";
import { Router, ActivatedRoute } from "@angular/router";
import { NetworkService } from "../../services/network.service";
import { environment } from "../../../environments/environment";
import { AngularFirestore } from "@angular/fire/firestore";
declare var bodymovin: any;
declare var $: any;
@Component({
  selector: "app-embedded-player",
  templateUrl: "./embedded-player.component.html",
  styleUrls: ["./embedded-player.component.scss"]
})
export class EmbeddedPlayerComponent implements OnInit {
  @ViewChild("audioPlayer", { static: false }) private audioPlayer: ElementRef;
  @ViewChild("volume", { static: false }) private volume: ElementRef;
  environment = environment;
  playlist: any = {};
  currentTime: any;
  duration: any;
  currentPosition = 0;
  currentSong: {};
  isPlaying: boolean = false;
  mutePlayer = false;
  isLive = false;
  isHovered = false;
  res: any;
  animationInProcess: boolean = false;
  loading = false;
  playAnimation = "";
  animation: any;
  songName: any;
  artistName: any;
  songBG: any;
  isFirstPlay = false;

  constructor(
    public sharedService: SharedService,
    private route: ActivatedRoute,
    private networkService: NetworkService,
    private afs: AngularFirestore
  ) {
    this.route.params.subscribe(res => {
      
      this.res = res;
      this.isLive = res["datatype"] == "radio";
      switch (res["datatype"]) {
        case "playlist":
          this.loading = true;
          this.networkService.getPlaylistByID(res["id"]).subscribe(resp => {
            if (resp.item) {
              this.playlist = resp.item;
              this.songBG = this.playlist.image;
              this.songName = this.playlist.name_he
                ? this.playlist.name_he
                : this.playlist.name_en;
              this.artistName = this.playlist.short_description;
              this.loading = false;
            } else {
              // not found
            }
          });
          break;
        case "program":
          this.loading = true;
          this.networkService.getProgramByID(res["id"]).subscribe(resp => {
            if (resp.item) {
              this.playlist = resp.item;
              this.checkImage(this.playlist);
              this.songName = this.playlist.name;
              this.artistName = this.playlist.description;
              this.loading = false;
            } else {
              // not found
            }
          });
          break;
        case "radio":
          this.loading = true;
          this.playlist.url = "https://99.mediacast.co.il/99fm_aac";
          this.afs
            .collection("streamed_content")
            .doc("program")
            .valueChanges()
            .pipe()
            .subscribe(data => {
              this.loading = false;
              (this.songBG = this.sharedService.getLiveRadioImage(data)),
                (this.songName = this.sharedService.getSongName(data)),
                (this.artistName = this.sharedService.getArtistName(data));
            });
          break;
        default:
          break;
      }
    });
  }

  ngOnInit() {
    this.sharedService.showHeader = false;
    this.sharedService.showFooter = false;
    this.sharedService.showMenu = false;
    this.sharedService.showHeaderHam = false;
  }

  checkImage(show) {
    if (this.sharedService.isPlatformBrowser) {
      var img = new Image();
      // img.onload = good;
      let that = this;

      img.onerror = function() {
        show.image_url = "/assets/images/show-placeholder.png";
        that.songBG = show.image_url;
      };
      img.src = show.image_url;
    }
    this.songBG = show.image_url;
  }

  setCurrentPosition(positionPercentage) {
    if (this.audioPlayer.nativeElement.duration == NaN) return;

    this.audioPlayer.nativeElement.currentTime =
      (positionPercentage / 100) * this.audioPlayer.nativeElement.duration;
    this.currentPosition =
      (this.audioPlayer.nativeElement.currentTime /
        this.audioPlayer.nativeElement.duration) *
      100;
  }

  ngOnDestroy(): void {
    this.sharedService.showHeader = true;
    this.sharedService.showFooter = true;
    this.sharedService.showMenu = true;
  }

  parseTime(duration) {
    if (!duration || duration == Infinity) return "";

    return new Date(duration * 1000).toISOString().substr(11, 8);
  }
  playPlayer() {
    this.isFirstPlay = true;
    if (this.isLive) {
      this.audioPlayer.nativeElement.load();
    }
    if (!this.audioPlayer.nativeElement) return;

    this.audioPlayer.nativeElement.ontimeupdate = e => {
      var val = this.currentPosition / 100;
      $(".position-bar").css(
        "background-image",
        "-webkit-gradient(linear, left top, right top, " +
          "color-stop(" +
          val +
          ", #57b629), " +
          "color-stop(" +
          val +
          ", #C5C5C5)" +
          ")"
        // 'linear-gradient(269deg, #00b5b4, #57b629, #57b629)'
      );
    };

    var promise = this.audioPlayer.nativeElement.play();
    if (promise !== undefined) {
      promise.then(_ => {}).catch(error => {});
    }
    this.duration = this.parseTime(this.audioPlayer.nativeElement.duration);
    this.currentTime = this.isLive
      ? ""
      : this.parseTime(this.audioPlayer.nativeElement.currentTime);
    this.currentPosition = this.isLive
      ? 100
      : (this.audioPlayer.nativeElement.currentTime /
          this.audioPlayer.nativeElement.duration) *
        100;
    setInterval(() => {
      this.duration = this.parseTime(this.audioPlayer.nativeElement.duration);
      this.currentTime = this.isLive
        ? ""
        : this.parseTime(this.audioPlayer.nativeElement.currentTime);
      this.currentPosition = this.isLive
        ? 100
        : (this.audioPlayer.nativeElement.currentTime /
            this.audioPlayer.nativeElement.duration) *
          100;
      if (this.res["datatype"] == "playlist") {
        // is playlist
        this.updatePlayerDetails();
      }
    }, 1000);

    if (this.isPlaying || this.animationInProcess) {
      return;
    }
    this.isPlaying = true;
    this.sharedService.isPlaying = true;
    this.animationInProcess = true;
    setTimeout(() => {
      if (this.animation) {
        this.animation.destroy();
      }
      this.animation = bodymovin.loadAnimation({
        container: document.getElementById("playing"),
        renderer: "svg",
        loop: false,
        autoplay: true,
        path: "assets/animations/Pulse1.2/pulse1.2.json"
      });
    });

    setTimeout(() => {
      if (this.animation) {
        this.animation.destroy();
      }
      this.animation = bodymovin.loadAnimation({
        container: document.getElementById("playing"),
        renderer: "svg",
        loop: true,
        autoplay: true,
        path: "assets/animations/Pulse2.2/pulse2.json"
      });
      this.animationInProcess = false;
    }, 400);
  }

  pausePlayer() {
    if (!this.isPlaying || this.animationInProcess) {
      return;
    }
    this.isPlaying = false;
    this.sharedService.isPlaying = false;
    this.audioPlayer.nativeElement.pause();

    setTimeout(() => {
      if (this.animation) {
        this.animation.destroy();
      }
      this.animation = bodymovin.loadAnimation({
        container: document.getElementById("paused"),
        renderer: "svg",
        loop: false,
        autoplay: true,
        path: "assets/animations/Pulse3.2/pulse3.json"
      });
    });
  }

  updatePlayerDetails() {
    let currentSecond = this.audioPlayer.nativeElement.currentTime;
    // if (!this.data.playlistList)return
    if (!this.playlist.song_list) return "";
    this.playlist.song_list.forEach((song, index) => {
      if (
        song.start <= currentSecond &&
        (!this.playlist.song_list[index + 1] ||
          this.playlist.song_list[index + 1].start > currentSecond)
      ) {
        this.currentSong = song;
        this.songName = song.song_name;
        this.artistName = song.artist_name;
        this.songBG = song.big_image
          ? song.big_image
          : "assets/images/song-placeholder.svg";
        return;
      }
    });
  }

  volumeChanged(e) {
    var val = e.currentTarget.value / 100;

    this.audioPlayer.nativeElement.volume = val;
  }

  getProgramName() {
    switch (this.playlist.original_category_id) {
      case 12:
      case 8:
        return "טל_ואביעד";
      case 14:
      case 22:
        return "אקו_לייף";
      case 4:
        return "תשעה_חודשים";
      case 30:
        return "המגרש_הביתי";
    }
  }
}
