import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { GenericService } from "./generic.service";

@Injectable()
export class HttpHandler {
  private baseUrl = environment.baseUrl/*"http://localhost"*/ + "/api/v1/";

  public headers = new HttpHeaders();

  constructor(
    private http: HttpClient,
    private genericService: GenericService
  ) {
    let offset = -1 * (new Date().getTimezoneOffset());
    this.headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .append("eco99fm-App-Auth", environment.envHeader)
      .append("x-99-platform", genericService.getPlatform())
      .append("x-99-app-timezone", offset.toString());
  }

  public setToken(token: string) {
    this.headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization", "Bearer " + token);
  }

  public get(url: string, params: HttpParams = undefined): Observable<any> {
    return this.http.get(this.baseUrl + url, {
      params: params,
      headers: this.headers
    });
  }
  public delete(url: string, params: HttpParams = undefined): Observable<any> {
    return this.http.delete(this.baseUrl + url, {
      params: params,
      headers: this.headers
    });
  }

  public put(url: string, body: any): Observable<any> {
    return this.http.put(this.baseUrl + url, body, { headers: this.headers });
  }

  public post(url: string, body: any): Observable<any> {
    return this.http.post(this.baseUrl + url, body, { headers: this.headers });
  }

  public postWithoutBaseUrl(url: string, body: any): Observable<any> {
    var paymeHeaders = new HttpHeaders().set(
      "Content-Type",
      "application/json"
    );
    return this.http.post(url, body, { headers: paymeHeaders });
  }
}
