import { Injectable, Inject, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { LOCAL_STORAGE } from "@ng-toolkit/universal";
import { Observable } from "rxjs/Observable";
import { of, from } from "rxjs";
import { AngularFireAuth } from "@angular/fire/auth";
import { catchError, switchMap } from "rxjs/operators";
import { NetworkService } from "./network.service";
import { HttpErrorResponse } from "@angular/common/http";
import { ROUTES } from "../data/routes";

@Injectable()
export class AuthService {
  user: Observable<any>;

  constructor(
    @Inject(LOCAL_STORAGE) private localStorage: any,
    private router: Router,
    private afAuth: AngularFireAuth,
    private ngZone: NgZone,
    private networkService: NetworkService,
  ) {}

  logout() {
    this.afAuth.auth.signOut();
    this.localStorage.removeItem("eco99-token");
    this.ngZone.run(() => {
      // this.router.navigateByUrl('/login');
    });
  }

  getToken() {
    return this.localStorage.getItem("eco99-token");
  }

  setToken(token: string) {
    this.localStorage.setItem("eco99-token", token);
  }

  // Used by the http interceptor to set the auth header
  getUserIdToken(): Observable<string> {
    return this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return from(user.getIdToken());
        } else {
          return of("");
        }
      })
    );
  }

  isTokenValid(): Observable<boolean> {
    
    return this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return this.networkService.getUser().pipe(
            switchMap(validationUser => {
              if (validationUser) {
                return of(true);
              } else {
                return of(false);
              }
            }),
            catchError((error: HttpErrorResponse) => {
              if (
                error.status === 400 && 
                error.error && 
                error.error.error_custom_code === 3
                ) {
                // user has been deleted or token is invalid, trigger logout
                this.logout();
                this.router.navigateByUrl(ROUTES.LOGIN);
                return of(false);
              }

              return of(false);
            })
          );
        } else {
          // no user is authenticated, but token is invalid (anonymous user)
          return of(false);
        }
      })
    );
  }
}
