import { Component, OnInit, OnDestroy } from "@angular/core";
import { SharedService } from "../../services/shared.service";
import { AuthService } from "../../services/auth.service";
import { ActivatedRoute } from "@angular/router";

type InnerComponentViewMode = "music" | "account" | "payment";

@Component({
  selector: "app-settings-inner-page",
  templateUrl: "./settings-inner-page.component.html",
  styleUrls: ["./settings-inner-page.component.scss"]
})
export class SettingsInnerPageComponent implements OnInit, OnDestroy {
  innerComponentViewMode: InnerComponentViewMode;
  tabName: string;
  constructor(
    public sharedService: SharedService,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {
    this.sharedService.showUpperSection = false;
  }

  ngOnInit() {
    setTimeout(() => {
      this.sharedService.showHeaderHam = false;
    });
    this.innerComponentViewMode = this.route.snapshot.paramMap.get(
      "type"
    ) as InnerComponentViewMode;
    switch (this.innerComponentViewMode) {
      case "music": {
        this.tabName = "מוזיקה";
        break;
      }
      case "account": {
        this.tabName = "החשבון שלי";
        break;
      }
      case "payment": {
        this.tabName = "פרטי תשלום";
        break;
      }
      default: {
        //statements;
        break;
      }
    }
  }

  ngOnDestroy(): void {
    setTimeout(() => {
      this.sharedService.showHeaderHam = true;
    });
    this.sharedService.showUpperSection = true;
  }
}
