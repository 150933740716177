import { Component, ViewChild, OnInit } from "@angular/core";
import { SharedService } from "../../services/shared.service";
import { PromotionQuery } from "../../services/promotionQuery";
import { NetworkService } from "../../services/network.service";
import { PlaylistGenresComponent } from "../../components/playlist-genres/playlist-genres.component";
import { PlaylistSpecialsComponent } from "../../components/playlist-specials/playlist-specials.component";
import { AngularFireAuth } from "@angular/fire/auth";
import { Observable } from "rxjs-compat";
import { map } from "rxjs/operators";
import { noop } from "lodash";

@Component({
  selector: "app-music",
  templateUrl: "./music.component.html",
  styleUrls: ["./music.component.scss"]
})
export class MusicComponent implements OnInit {
  @ViewChild("specials", { static: false })
  private specials: PlaylistSpecialsComponent;
  @ViewChild("genres", { static: false })
  private genres: PlaylistGenresComponent;
  private authState: Observable<firebase.User>;

  constructor(
    public sharedService: SharedService,
    private networkService: NetworkService,
    private afAuth: AngularFireAuth
  ) {
    this.authState = this.afAuth.authState;
    this.sharedService.showUpperSection = false;
  }
  hitsContent = [];
  bestDecadeTitle: string;
  hitsTitle: string;
  genresTitle: string;
  specialsTitle: string;
  boazCohenTitle: string;
  studio99Title: string;
  itayGaloTitle: string;

  bestDecadeContent = [];
  genresContent = [];
  specialsContent = [];
  boazCohenContent = [];
  studio99Content = [];
  itayGaloContent = [];
  selectedGenre: any;
  selectedSpecial: any;

  loadingHitsContent: boolean = true;
  loadingBestDecadeContent: boolean = true;
  loadingGenreContent: boolean = true;
  loadingSpecialContent: boolean = true;
  loadingBoazCohenContent: boolean = true;
  loadingStudio99Content: boolean = true;
  loadingItayGaloContent: boolean = true;
  
  ngOnInit() {
    let pagination = new PromotionQuery(1, 16, 2);
    this.sharedService.getPromotionData(
      pagination,
      data => {
        this.loadingHitsContent = false;
        this.hitsContent.push(data);
      },
      (items, totalpages, title) => {
        this.hitsTitle = title;
      }
    );
    pagination.promotion_type_id = 6;
    this.sharedService.getPromotionData(
      pagination,
      data => {
        this.loadingBestDecadeContent = false;
        this.bestDecadeContent.push(data);
      },
      (items, totlePages, title) => {
        this.bestDecadeTitle = title;
      }
    );
    pagination.promotion_type_id = 3;
    pagination.itemsPerPage = 30;
    this.sharedService.getPromotionData(
      pagination,
      data => {
        this.loadingGenreContent = false;
        this.genresContent.push(data);
      },
      (items, totlePages, title) => {
        this.genresTitle = title;
      }
    );
    
    // for fetching the data only after we try to establish a connection with firebase
    // otherwise, if user is logged in - the token wont be sent
    // token in necessary for this specific request
    this.authState
      .pipe(
        map((_user) => {
          pagination.promotion_type_id = 4;
          pagination.itemsPerPage = 30;
          this.sharedService.getPromotionData(
            pagination,
            (data) => {
              this.loadingSpecialContent = false;
              this.specialsContent.push(data);
            },
            (_items, _totlePages, title) => {
              this.specialsTitle = title;
            }
          );
        })
      )
      .subscribe(noop, (err) => {
        console.log(err);
      });

    pagination.itemsPerPage = 16;
    pagination.promotion_type_id = 5;
    this.sharedService.getPromotionData(
      pagination,
      data => {
        this.loadingBoazCohenContent = false;
        this.boazCohenContent.push(data);
      },
      (items, totlePages, title) => {
        this.boazCohenTitle = title;
      }
    );
    pagination.itemsPerPage = 16;
    pagination.promotion_type_id = 7;
    this.sharedService.getPromotionData(
      pagination,
      data => {
        this.loadingStudio99Content = false;
        this.studio99Content.push(data);
      },
      (items, totlePages, title) => {
        this.studio99Title = title;
      }
    );
    pagination.itemsPerPage = 16;
    pagination.promotion_type_id = 8;
    this.sharedService.getPromotionData(
      pagination,
      data => {
        this.loadingItayGaloContent = false;
        this.itayGaloContent.push(data);
      },
      (items, totlePages, title) => {
        this.itayGaloTitle = title;
      }
    );
  }
  // 1 - whatsnew
  // 2 - hits
  // 3 - genre
  // 4 - special
  // 5 - boazcohen
  // 6 - bestDecade
  // 7 - Studio 99
  // 8 - Itay Galo

  genreSelected(genre) {
    if (genre.playlists) {
      genre.playlists.forEach(playlist => {
        playlist.isLoaded = false;
      });
    }

    this.specials.initSelectedSpecial();
    this.selectedGenre = genre;
    let i = 0;
    let interval = setInterval(() => {
      if (i == this.selectedGenre.playlists.length) {
        clearInterval(interval);
      } else {
        if (this.selectedGenre.playlists[i]) {
          this.selectedGenre.playlists[i].isLoaded = true;
        }
        i++;
      }
    }, 50);
  }

  specialSelected(special) {
    if (special.playlists) {
      special.playlists.forEach(playlist => {
        playlist.isLoaded = false;
      });
    }
    this.genres.initSelectedGenre();
    this.selectedSpecial = special;
    let i = 0;
    let interval = setInterval(() => {
      if (i == this.selectedSpecial.playlists.length) {
        clearInterval(interval);
      } else {
        if (this.selectedSpecial.playlists[i]) {
          this.selectedSpecial.playlists[i].isLoaded = true;
        }
        i++;
      }
    }, 50);
  }

  ngOnDestroy(): void {
    this.sharedService.showUpperSection = true;
  }
}
