import { HttpParams } from "@angular/common/http";

export class PromotionQuery {
  page: number;
  itemsPerPage: number;
  promotion_type_id: number;

  constructor(
    page: number = 1,
    itemsPerPage: number = 10,
    promotion_type_id: number = undefined
  ) {
    this.page = page;
    this.itemsPerPage = itemsPerPage;
    this.promotion_type_id = promotion_type_id;
  }

  public toString() {
    return (
      (this.page ? "page=" + this.page : "") +
      (this.itemsPerPage ? "&itemsPerPage=" + this.itemsPerPage : "") +
      (this.promotion_type_id
        ? "&promotion_type_id=" + this.promotion_type_id
        : "")
    );
  }
}
