import {
  Component,
  OnInit,
  OnDestroy,
  AfterContentInit,
  HostListener,
  Query
} from "@angular/core";
import { SharedService } from "../../services/shared.service";
import { NetworkService } from "../../services/network.service";
import { ShowsQuery } from "../../services/showsQuery";
import { Router, ActivatedRoute } from "@angular/router";
import { MetaService } from "../../services/MetaService";
import { environment } from "../../../environments/environment";
import { GoogleAnalyticsService } from "../../services/googleAnalytics.service";
import { Meta } from "@angular/platform-browser";

declare var Moment: any;
declare var $: any;
declare var bodymovin: any;

@Component({
  selector: "app-tickets",
  templateUrl: "./tickets.component.html",
  styleUrls: ["./tickets.component.scss"]
})
export class TicketsComponent implements OnInit, OnDestroy, AfterContentInit {
  @HostListener("scroll", ["$event"])
  onArtistScroll(event: any) {
    if (this.endOfArtistList || this.isLoadingArtists) return;
    if (
      event.target.offsetHeight + event.target.scrollTop >=
      event.target.scrollHeight - 150
    ) {
      this.artistQuery.page++;
      this.loadMoreArtist();
    }
  }
  @HostListener("scroll", ["$event"])
  onVenueScroll(event: any) {
    if (this.endOfVenuesList || this.isLoadingVenues) return;
    if (
      event.target.offsetHeight + event.target.scrollTop >=
      event.target.scrollHeight - 150
    ) {
      this.venueQuery.page++;
      this.loadMoreVenues();
    }
  }
  baseUrl = environment.baseUrl;
  recommendedShows = [];
  loadingRecommended = false;
  loadingUpcoming = false;
  isLoadingMoreShows = false;
  showLoadMoreButton = true;
  searchedShows = [];
  upcomingShows = [];
  loadingAnimation: any;
  activeTab: any = 0;
  query = new ShowsQuery();
  searchText = " - תוצאות חיפוש";
  searchTextEmpty = "הופעות וכרטיסים";
  

  artistQuery = new ShowsQuery();
  venueQuery = new ShowsQuery();
  artistInputSearch = "";
  locationInputSearch = "";
  selectedArea = 0;
  pageDesc = `כרטיסים להופעות מוזיקה בארץ

        הפלייליסטים שלנו עשו לכם חשק לשמוע ולראות עוד מוזיקה?
        אצלנו ב- eco tickets תוכלו למצוא את הופעות הכי חמות בארץ, של האמנים הכי חמים! הופעות בזאפה ובשוני, הופעות בתל אביב, פסטיבלי מוזיקה ועוד.

        לוח הופעות מוזיקה בארץ

        מוזיקה ישראלית שייכת לכולנו. לנו ב-eco99fm חשוב לתמוך במוזיקה הישראלית ולחשוף בפני המאזינים שלנו והגולשים שלנו את המגוון העצום של הופעות חיות של מוזיקה ישראלית. אם כל אחד ואחת מכם ילכו להופעה חיה אחת בחודש של אמן ישראלי, אז מצבנו יהיה מצוין.
        בנוסף, יש לנו כמובן את כל ההופעות של אמנים בינלאומיים שמגיעים להופעות בארץ.
        מבחר של כרטיסים להופעות מוזיקה באקוטיקטס
        אנחנו ב- eco99fm מציעים לכם מבחר ענק של כרטיסים להופעות מוזיקה בארץ: הופעות בתל אביב, הופעות בירושלים, הופעות בחיפה, הופעות בבאר שבע, ובאולמות והאצטדיונים הבולטים: הופעות בקיסריה, הופעות בלייב פארק ראשון לציון, הופעות בזאפה, שוני הופעות, הופעות היכל מנורה מבטחים, ברלה הופעות, קיסריה הופעות,  וכמובן כל האמנים הכי חמים: דודו טסה הופעות, שלומי שבת הופעות, ברי סחרוף הופעות, אמיר דדון הופעות, יהודה פוליקר הופעות,  מירי מסיקה הופעות, אהוד בנאי הופעות, יהורם גאון הופעות, משינה הופעות, הופעות שלמה ארצי, אביתר בנאי הופעות, טונה הופעות, עברי לידר הופעות, עידן רייכל הופעות, היהודים הופעות, מבית לאן, הדרן, זאפה.
        להופעות תוכלו ללכת עם מי שרק תבחרו- עם בן או בת הזוג, עם החבר או החברה הכי טובים, עם כל המשפחה, עם החברים מהעבודה, ואפילו לבד.
        הופעות אינטימיות וקטנות של אמנים ישראליים מעולים, לצד הופעות ענק של כוכבים בינלאומיים, מתי, איפה ואיך שרק תבחרו ותרצו.
        אם אתם אוהבים הופעות מוזיקה של אמנים ישראליים, שווה לכם לבדוק את עמודי הפייסבוק והאינסטגרם שלנו, שם אנחנו גם מחלקים כרטיסים להופעות הכי שוות`;
  preferredLang = "name_he";
  recommendedAnimation: any;
  datePicker: any;
  searchMode: boolean;
  showArtistAutocomplete = false;
  showLocationAutocomplete = false;
  isLoadingArtists = false;
  isLoadingVenues = false;
  artistInput = "";
  artistObj: any = {};
  locationInput = "";
  venueObj: any = {};
  freetextInput = "";
  dateInput = { from: undefined, to: undefined };

  locations = [];
  artists = [];
  popularArtist = [];
  isStartOfScroll = true;
  isEndOfScroll = false;
  scrolling: boolean = false;
  endOfArtistList = false;
  endOfVenuesList = false;

  constructor(
    public sharedService: SharedService,
    private networkService: NetworkService,
    public router: Router,
    private metaService: MetaService,
    public route: ActivatedRoute,
    private analyticsService: GoogleAnalyticsService,
    public meta: Meta
  ) {
    // this.metaService.updateMetaInfo('כרטיסים להופעות מוזיקה, הופעות בזאפה, הופעות מוזיקה בארץ, eco ticktes',
    //       'כרטיסים להופעות מוזיקה, הופעות בזאפה, הופעות מוזיקה בארץ. כרטיסים להופעות מוזיקה בזאפה. הופעות מוזיקה בתל אביב, ירושלים, חיפה והופעות מוזיקה בכל הארץ. eco99fm',
    //       this.baseUrl +'/assets/images/eco99seo.jpeg',
    //       this.baseUrl + '/tickets/הופעות_בארץ')

    this.query = new ShowsQuery();
    this.query.itemsPerPage = 9;
    this.query.page = 1;
    this.artistQuery.itemsPerPage = 20;

    this.route.params.subscribe(res => {
      this.sharedService.transparentheader = true;
      this.sharedService.showUpperSection = false;
      // if (res['activeTab']){
      let recommendedLang;
      switch (res["activeTab"]) {
        case "הופעות_בארץ":
          this.setActiveTab(0, false);
          break;
        case "הופעות_בחו״ל":
          this.query.geoRestriction = "ABROAD";
          this.setActiveTab(1, false);
          break;
        case "הופעות_היום":
          this.query.geoRestriction = undefined;
          this.setActiveTab(2, false);
          break;
        default:
          break;
      }
      // if (!this.recommendedShows.length){
      //   this.loadingRecommended = true
      //   this.networkService.getRecommendedShows(recommendedLang).subscribe(resp => {
      //     this.loadingRecommended = false
      //     this.recommendedShows = resp.items
      //   }, err => {
      //   });
      // }

      if (res["artistID"] && !res["venueID"] && !res["venueInput"]) {
        this.networkService
          .searchArtistByID(this.sharedService.escapeRegExp(res["artistID"]))
          .subscribe(resp => {
            this.chooseArtist(resp.artist, false);
          });
        return;
      }
      if (res["venueID"] && !res["artistID"]) {
        this.networkService
          .searchVenueByID(this.sharedService.escapeRegExp(res["venueID"]))
          .subscribe(resp => {
            this.chooseLocation(resp.item, false);
          });
        return;
      }
      if (res["venueInput"] && !res["artistID"]) {
        let query = new ShowsQuery();
        query.text = this.sharedService.escapeRegExp(res["venueInput"]);
        this.networkService.searchVenue(query).subscribe(resp => {
          this.chooseLocation(resp.items[0], false);
        });
        return;
      }
      if (res["venueID"] && res["artistID"]) {
        this.networkService
          .searchVenueByID(this.sharedService.escapeRegExp(res["venueID"]))
          .subscribe(resp => {
            let venue = resp.item;
            this.networkService
              .searchArtistByID(
                this.sharedService.escapeRegExp(res["artistID"])
              )
              .subscribe(resp => {
                this.chooseLocationAndArtist(venue, resp.artist);
              });
          });
        return;
      }
      if (res["venueInput"] && res["artistID"]) {
        let query = new ShowsQuery();
        query.text = this.sharedService.escapeRegExp(res["venueInput"]);
        this.networkService.searchVenue(query).subscribe(resp => {
          let venue = resp.items[0];
          this.networkService
            .searchArtistByID(this.sharedService.escapeRegExp(res["artistID"]))
            .subscribe(resp => {
              this.chooseLocationAndArtist(venue, resp.artist);
            });
        });
        return;
      }
      this.upcomingShows = [];
      this.loadingUpcoming = true;
      this.networkService.searchShows(this.query).subscribe(
        resp => {
          if (!resp.totalPages || resp.totalPages == this.query.page) {
            this.showLoadMoreButton = false;
          } else {
            this.showLoadMoreButton = true;
          }
          this.loadingUpcoming = false;
          this.upcomingShows = resp.items;
        },
        err => {}
      );
      // }
    });
  }

  ngOnInit() {
    this.networkService.getPopularArtist().subscribe(
      resp => {
        this.popularArtist = resp.items;
      },
      err => {}
    );
  }

  checkNoSpaceOnStart(e) {
    if (e.keyCode == 32 && e.currentTarget.value == "") {
      e.preventDefault();
    }
  }

  setActiveTab(activeTab, isUserInteraction = true) {
    if (isUserInteraction) {
      switch (activeTab) {
        case 0:
          this.analyticsService.sendEvent(
            "tickets",
            "tab_israel_tickets",
            "הופעות_בארץ"
          );
          break;
        case 1:
          this.analyticsService.sendEvent(
            "tickets",
            "tab_abroad_tickets",
            "הופעות_בחו״ל"
          );
          break;
        case 2:
          this.analyticsService.sendEvent(
            "tickets",
            "tab_today_tickets",
            "הופעות_היום"
          );
          break;
      }
    } else {
      if (this.sharedService.isPlatformBrowser) {
        window.scrollTo(0, 0);
      }
    }
    this.query.page = 1;
    this.activeTab = activeTab;
    let recommendedLang = "";
    switch (parseInt(this.activeTab)) {
      case 0:
        this.query.geoRestriction = "IL";
        this.query.start = undefined;
        this.query.end = undefined;
        recommendedLang = "IL";
        if (isUserInteraction) {
          this.router.navigateByUrl("/tickets/הופעות_בארץ");
        }
        this.metaService.updateMetaInfo(
          "כרטיסים להופעות מוזיקה, הופעות בזאפה, הופעות מוזיקה בארץ, eco ticktes",
          "כרטיסים להופעות מוזיקה, הופעות בזאפה, הופעות מוזיקה בארץ. כרטיסים להופעות מוזיקה בזאפה. הופעות מוזיקה בתל אביב, ירושלים, חיפה והופעות מוזיקה בכל הארץ. eco99fm",
          this.baseUrl + "/assets/images/eco99seo.jpeg",
          this.baseUrl + "/tickets/הופעות_בארץ"
        );
        this.pageDesc = `כרטיסים להופעות מוזיקה בארץ
        הפלייליסטים שלנו עשו לכם חשק לשמוע ולראות עוד מוזיקה?
        אצלנו ב- eco tickets תוכלו למצוא את הופעות הכי חמות בארץ, של האמנים הכי חמים! הופעות בזאפה ובשוני, הופעות בתל אביב, פסטיבלי מוזיקה ועוד.
        לוח הופעות מוזיקה בארץ
        מוזיקה ישראלית שייכת לכולנו. לנו ב-eco99fm חשוב לתמוך במוזיקה הישראלית ולחשוף בפני המאזינים שלנו והגולשים שלנו את המגוון העצום של הופעות חיות של מוזיקה ישראלית. אם כל אחד ואחת מכם ילכו להופעה חיה אחת בחודש של אמן ישראלי, אז מצבנו יהיה מצוין.
        בנוסף, יש לנו כמובן את כל ההופעות של אמנים בינלאומיים שמגיעים להופעות בארץ.
        מבחר של כרטיסים להופעות מוזיקה באקוטיקטס
        אנחנו ב- eco99fm מציעים לכם מבחר ענק של כרטיסים להופעות מוזיקה בארץ: הופעות בתל אביב, הופעות בירושלים, הופעות בחיפה, הופעות בבאר שבע, ובאולמות והאצטדיונים הבולטים: הופעות בקיסריה, הופעות בלייב פארק ראשון לציון, הופעות בזאפה, שוני הופעות, הופעות היכל מנורה מבטחים, ברלה הופעות, קיסריה הופעות,  וכמובן כל האמנים הכי חמים: דודו טסה הופעות, שלומי שבת הופעות, ברי סחרוף הופעות, אמיר דדון הופעות, יהודה פוליקר הופעות,  מירי מסיקה הופעות, אהוד בנאי הופעות, יהורם גאון הופעות, משינה הופעות, הופעות שלמה ארצי, אביתר בנאי הופעות, טונה הופעות, עברי לידר הופעות, עידן רייכל הופעות, היהודים הופעות, מבית לאן, הדרן, זאפה.
        להופעות תוכלו ללכת עם מי שרק תבחרו- עם בן או בת הזוג, עם החבר או החברה הכי טובים, עם כל המשפחה, עם החברים מהעבודה, ואפילו לבד.
        הופעות אינטימיות וקטנות של אמנים ישראליים מעולים, לצד הופעות ענק של כוכבים בינלאומיים, מתי, איפה ואיך שרק תבחרו ותרצו.
        אם אתם אוהבים הופעות מוזיקה של אמנים ישראליים, שווה לכם לבדוק את עמודי הפייסבוק והאינסטגרם שלנו, שם אנחנו גם מחלקים כרטיסים להופעות הכי שוות`;
        break;
      case 1:
        this.query.geoRestriction = "ABROAD";
        this.query.start = undefined;
        this.query.end = undefined;
        recommendedLang = "ABROAD";
        if (isUserInteraction) {
          this.router.navigateByUrl("/tickets/הופעות_בחו״ל");
        }
        this.metaService.updateMetaInfo(
          'הופעות בחול, כרטיסים להופעות מוזיקה בחו"ל. eco ticktes',
          'הופעות בחו"ל, כרטיסים להופעות בחול, הופעות בעולם, כרטיסים להופעות מוזיקה בחול. הופעות בלונדון, הופעות בברלין, הופעות באירופה, הופעות בארצות הברית ועוד. eco99fm',
          this.baseUrl + "/assets/images/eco99seo.jpeg",
          this.baseUrl + "/tickets/הופעות_בחו״ל"
        );

        this.pageDesc = `כרטיסים להופעות מוזיקה בחו"ל
        "זה קרה לפני הרבה זמן, אך אני עדיין זוכר איך המוסיקה גרמה לי לחייך, ידעתי אז שאם הייתה לי ההזדמנות, הייתי יכול לגרום לאנשים לרקוד ואז אולי גם הם, היו שמחים ..."
        המילים של דון מקלין שמתארות כל כך טוב מה שקורה לכולנו בהופעה טובה...
        והכי כייף זה לתפוס הופעה טובה כשאנחנו גם ככה באווירה טובה, כלומר בחופשה בחו"ל. בשנים האחרונות זו כבר ממש תופעה, ישראלים שנוסעים לחו"ל מוסיפים הופעות מוזיקה חיות לתמהיל הפעילויות שחייבים לעשות בחו"ל. ויש לא מעטים שמגדילים ראש וטסים במיוחד לאירופה או לארה"ב על מנת לתפוס הופעת מוזיקה של האמן או הלהקה שהם הכי אוהבים. ותקשיבו לזה, אנחנו לגמרי אתכם!
        לוח הופעות מוזיקה בחו"ל
        לכן אנחנו מרכזים לכם כאן את כל הופעות המוזיקה הבינלאומית הכי חמות בחודשים הקרובים. תתייחסו לזה כמו אל לוח ההופעות חו"ל שלכם, ותשתמשו בזה בשביל לתכנן את החופשה הבאה שלכם
        אין כמו לטוס לחו"ל ללוקיישן מפנק לטייל כל היום, לעשות שופינג ולאכול טוב, ואז לסגור את הערב עם הופעה של אמן בינלאומי ענק כמו טיילור סוויפט, ביונסה, U2, קולדפליי, ברונו מארס או אד שירן.
        מדובר בחוויה אדירה, המספקת תוספת נעימה ומתוקה לארסנל הזיכרונות של כולנו.
        מבחר של כרטיסים להופעות מוזיקה בעולם - livetickets, לייב טיקטס
        אנחנו ב- eco99fm מציעים לכם מבחר ענק של כרטיסים להופעות המוזיקה הכי חמות בחו"ל: הופעות באירופה, הופעות בלונדון, הופעות בברלין, הופעות באמסטרדם, הופעות בפריז, הופעות בפראג, הופעות בבודפשט, הופעות ברומא, הופעות במדריד, הופעות בברצלונה, הופעות בליסבון, ובאופן כללי יותר, הופעות באנגליה, הופעות בגרמניה, הופעות בצרפת, הופעות בספרד, הופעות באיטליה או הופעות בהולנד, פסטיבלי מוזיקה, פסטיבלי מוזיקה באירופה, טומורולנד, אליהן תוכלו ללכת עם מי שרק תבחרו- עם בן או בת הזוג, עם החבר או החברה הכי טובים, עם כל המשפחה, עם החברים מהעבודה, ואפילו לבד.
        הופעות ענק של כל הכוכבים הבינלאומיים הכי גדולים, מתי, איפה ואיך שרק תבחרו ותרצו.`;
        break;
      case 2:
        recommendedLang = null;
        this.query.start =
          new Date().getFullYear() +
          "/" +
          this._to2digit(new Date().getMonth() + 1) +
          "/" +
          this._to2digit(new Date().getDate());
        this.query.end =
          new Date().getFullYear() +
          "/" +
          this._to2digit(new Date().getMonth() + 1) +
          "/" +
          this._to2digit(new Date().getDate());
        if (isUserInteraction) {
          this.router.navigateByUrl("/tickets/הופעות_היום");
        }
        this.metaService.updateMetaInfo(
          "הופעות היום, לוח הופעות היום, מופעים היום, eco99fm",
          "הופעות היום, לוח הופעות היום, מופעים היום, כרטיסים להופעות מוזיקה היום. הופעות היום בתל אביב, הופעות היום בירושלים, הופעות היום בחיפה ועוד eco99fm",
          this.baseUrl + "/assets/images/eco99seo.jpeg",
          this.baseUrl + "/tickets/הופעות_היום"
        );
        this.pageDesc = `מרגישים ספונטניים? אז התפנה לכם הערב ומחפשים הופעה טובה לראות? כל הופעות המוזיקה הכי חמות הערב במקום אחד! אנחנו באקוטיקטס מכירים היטב את הקרייב להופעת מוזיקה טובה... אז באקוטיקטס, פלטפורמת מכירת הכרטיסים להופעות מוזיקה בארץ ובעולם, הרמנו אזור מיוחד להופעות היום, הופעות הערב, על מנת לעשות לכם את התהליך לזריז ויעיל.
        הופעה בדקה התשעים
        אז בא לכם לראות הופעת מוזיקה בדקה ה-90? יש לנו הופעות מוזיקה ישראלית הערב, וגם, אן במקרה אתם בחו"ל, הופעות מוזיקה בינלאומית היום והערב. ככה לא תפספסו את ההופעה של האמן או הלהקה האהובים, לראות אם נשארו כרטיסים, לדעת כמה עולים כרטיסים, ולרכוש כרטיסים להופעה בווייב ספונטני

        לוח הופעות היום

        כרטיסים להופעות היום מחכים לכם כאן בעמוד שמתעדכן כל הזמן עם ההופעות הכי חמות. אז אל תחשבו יותר מדי, בחרו הופעה, שתפו חבר או חברה, ויאללה נתראה מול הבמה!
        `;
        break;
      default:
        break;
    }

    this.loadingRecommended = true;
    this.recommendedShows = [];
    this.networkService.getRecommendedShows(recommendedLang).subscribe(
      resp => {
        this.loadingRecommended = false;
        this.recommendedShows = resp.items;
        //   setTimeout(() => {
        //     $('.jarallax').jarallax({
        //       speed: 0.95
        //     });
        // });
      },
      err => {}
    );

    this.loadingUpcoming = true;
    this.upcomingShows = [];
    this.networkService.searchShows(this.query).subscribe(
      resp => {
        if (!resp.totalPages || resp.totalPages == this.query.page) {
          this.showLoadMoreButton = false;
        } else {
          this.showLoadMoreButton = true;
        }
        this.upcomingShows = resp.items;
        this.loadingUpcoming = false;
      },
      err => {}
    );
  }

  searchArtist(artist) {
    this.preferredLang = this.sharedService.isEnglishUsername(artist)
      ? "name_en"
      : "name_he";
    this.artistQuery.text = artist;
    this.isLoadingArtists = true;
    this.endOfArtistList = false;
    this.artistQuery.page = 1;

    this.networkService.searchArtist(this.artistQuery).subscribe(resp => {
      this.isLoadingArtists = false;
      if (resp.totalPages == this.artistQuery.page) {
        this.endOfArtistList = true;
      }
      this.artists = resp.items;
      // this.artists.forEach(artist => {
      //   if (artist.popular){
      //     this.popularArtist.push(artist)
      //   }
      // });
    });

    // }, err => {
    // });
  }

  loadMoreArtist() {
    this.isLoadingArtists = true;
    this.networkService.searchArtist(this.artistQuery).subscribe(
      resp => {
        if (resp.totalPages == this.artistQuery.page) {
          this.endOfArtistList = true;
        }
        this.artists = this.artists.concat(resp.items);
        this.isLoadingArtists = false;
      },
      err => {}
    );
  }

  loadMoreVenues() {
    this.isLoadingVenues = true;
    this.networkService.searchVenue(this.venueQuery).subscribe(
      resp => {
        if (resp.totalPages == this.venueQuery.page) {
          this.endOfVenuesList = true;
        }
        this.locations = this.locations.concat(resp.items);
        this.isLoadingVenues = false;
      },
      err => {}
    );
  }

  loadMoreShows(withQuery) {
    this.query.page++;
    this.isLoadingMoreShows = true;
    this.startLoadingAnimation();
    this.networkService.searchShows(this.query).subscribe(
      resp => {
        if (withQuery) {
          this.searchedShows = this.searchedShows.concat(resp.items);
        } else {
          this.upcomingShows = this.upcomingShows.concat(resp.items);
        }

        if (!resp.totalPages || resp.totalPages == this.query.page) {
          this.showLoadMoreButton = false;
        } else {
          this.showLoadMoreButton = true;
        }
        this.isLoadingMoreShows = false;
      },
      err => {
        this.isLoadingMoreShows = false;
      }
    );
  }

  searchVenue(venue) {
    this.venueQuery.text = venue;
    this.isLoadingVenues = true;
    this.endOfVenuesList = false;
    this.venueQuery.page = 1;
    this.networkService.searchVenue(this.venueQuery).subscribe(
      resp => {
        this.isLoadingVenues = false;
        if (!resp.totalPages || resp.totalPages == this.venueQuery.page) {
          this.endOfVenuesList = true;
        }
        this.locations = resp.items;
      },
      err => {}
    );
  }

  private _to2digit(n: number) {
    return ("00" + n).slice(-2);
  }

  ngAfterContentInit(): void {
    if (this.sharedService.isPlatformBrowser) {
      this.datePicker = $(".date-input").daterangepicker(
        {
          autoApply: true,
          minDate: new Date(),
          autoUpdateInput: false,
          opens: "left",
          locale: {
            format: "DD/MM/YYYY",
            separator: " - ",
            applyLabel: "אישור",
            cancelLabel: "ביטול",
            fromLabel: "מתאריך",
            toLabel: "עד תאריך",
            daysOfWeek: ["א", "ב", "ג", "ד", "ה", "ו", "ש"],
            monthNames: [
              "ינואר",
              "פברואר",
              "מרץ",
              "אפריל",
              "מאי",
              "יוני",
              "יולי",
              "אוגוסט",
              "ספטמבר",
              "אוקטובר",
              "נובמבר",
              "דצמבר"
            ],
            firstDay: 0
          }
        },
        (start, end, label) => {
          this.dateInput.from = start;
          this.dateInput.to = end;
          $(".date-input").val(
            start.format("DD/MM/YY") + " - " + end.format("DD/MM/YY")
          );
        }
      );
      $(".date-input").val("");
    }
  }

  searchForShows() {
    if (this.freetextInput.length) {
      this.analyticsService.sendEvent(
        "tickets",
        "search_by_text_field",
        "search",
        this.freetextInput
      );
    }
    if (this.artistObj.id) {
      this.analyticsService.sendEvent(
        "tickets",
        "search_by_artist_field",
        "search",
        this.artistObj.id
      );
    }
    if (this.locationInput.length) {
      this.analyticsService.sendEvent(
        "tickets",
        "search_by_location_field",
        this.locationInput
      );
    }
    if (this.sharedService.isPlatformBrowser) {
      if ($(".date-input").val()) {
        var startDate = $(".date-input").data("daterangepicker").startDate._d;
        var endDate = $(".date-input").data("daterangepicker").endDate._d;
        this.query.start =
          startDate.getFullYear() +
          "/" +
          this._to2digit(startDate.getMonth() + 1) +
          "/" +
          this._to2digit(startDate.getDate());
        this.query.end =
          endDate.getFullYear() +
          "/" +
          this._to2digit(endDate.getMonth() + 1) +
          "/" +
          this._to2digit(endDate.getDate());
        this.analyticsService.sendEvent(
          "tickets",
          "search_by_date_field",
          "search",
          startDate.getFullYear() +
            "/" +
            this._to2digit(startDate.getMonth() + 1) +
            "/" +
            this._to2digit(startDate.getDate()) +
            " - " +
            endDate.getFullYear() +
            "/" +
            this._to2digit(endDate.getMonth() + 1) +
            "/" +
            this._to2digit(endDate.getDate())
        );
      }
    }

    this.searchMode = true;
    this.query.text = this.freetextInput
      ? encodeURI(this.sharedService.escapeRegExp(this.freetextInput))
      : undefined;
    this.query.artist_id = this.artistObj.id
      ? encodeURI(this.sharedService.escapeRegExp(this.artistObj.id))
      : undefined;
    this.query.location = this.locationInput
      ? encodeURI(this.sharedService.escapeRegExp(this.locationInput))
      : undefined;
    this.query.geoRestriction = null;

    this.loadingUpcoming = true;
    this.searchedShows = [];
    this.query.page = 1;
    this.networkService.searchShows(this.query).subscribe(
      resp => {
        this.searchedShows = resp.items;
        if (!resp.totalPages || resp.totalPages == this.query.page) {
          this.showLoadMoreButton = false;
        } else {
          this.showLoadMoreButton = true;
        }
        this.loadingUpcoming = false;
      },
      err => {}
    );
  }

  goToShowPage(show) {
    if (this.query.geoRestriction == "IL") {
      this.analyticsService.sendEvent(
        "tickets",
        "popular_show_israel",
        "show_click",
        show.show_id
      );
    }
    if (this.query.geoRestriction == "ABROAD") {
      this.analyticsService.sendEvent(
        "tickets",
        "popular_show_abroad",
        "show_click",
        show.show_id
      );
    }

    this.sharedService.tempShow = show;
    this.router.navigateByUrl(
      "/tickets/show/" +
        this.sharedService.escapeRegExp(
          show.event_name_he
            ? show.event_name_he.split(" ").join("_")
            : show.event_name_en.split(" ").join("_")
        ) +
        "/" +
        show.show_id
    );
  }

  clearInputs() {
    this.searchMode = false;
    this.query = new ShowsQuery();
    this.query.itemsPerPage = 9;
    this.query.page = 1;
    this.freetextInput = "";
    this.artistInput = "";
    this.locationInput = "";
    this.dateInput = { from: undefined, to: undefined };
    $(".date-input")
      .data("daterangepicker")
      .setStartDate(new Date());
    $(".date-input")
      .data("daterangepicker")
      .setEndDate(new Date());
    $(".date-input").val("");
    this.router.navigateByUrl("/tickets/הופעות_בארץ");
  }

  showCleanButton() {
    return (
      this.searchMode ||
      this.freetextInput.length ||
      this.artistInput.length ||
      this.locationInput.length ||
      this.dateInput["from"]
    );
  }

  isToday(event_date) {
    return (
      new Date(event_date).getFullYear() == new Date().getFullYear() &&
      new Date(event_date).getMonth() == new Date().getMonth() &&
      new Date(event_date).getDate() == new Date().getDate()
    );
  }

  chooseArtist(artist, isUserInteraction = true) {
    this.artistInput = artist[this.preferredLang];
    this.artistInputSearch = this.artistInput;
    this.artistObj = artist;
    this.showArtistAutocomplete = false;
    if (this.locationInput.length == 0) {
      this.locationInputSearch = "";
      this.venueObj = {};

      this.searchForShows();
      if (isUserInteraction) {
        this.router.navigateByUrl(
          "/tickets/artist/" +
            this.sharedService.escapeRegExp(
              this.artistInput.split(" ").join("_")
            ) +
            "/" +
            this.artistObj.id
        );
      }
      this.metaService.updateMetaInfo(
        this.artistInput +
          " - הופעות, " +
          this.artistInput +
          " כרטיסים להופעות, eco tickets",
        this.artistInput +
          " - הופעות, " +
          this.artistInput +
          " הופעות וכרטיסים, כרטיסים להופעות של " +
          this.artistInput +
          " - כרטיסים, eco tickets כרטיסים להופעות בארץ ובעולם.",
        this.artistObj.artist_image
          ? this.artistObj.artist_image
          : this.baseUrl + "/assets/images/eco99seo.jpeg",
        this.baseUrl +
          "/tickets/artist/" +
          this.sharedService.escapeRegExp(
            this.artistInput.split(" ").join("_")
          ) +
          "/" +
          this.artistObj.id
      );
    } else {
      if (isUserInteraction) {
        this.router.navigateByUrl(
          "/tickets/venue_artist/" +
            this.sharedService.escapeRegExp(
              this.artistInput.split(" ").join("_")
            ) +
            "/" +
            this.artistObj.id +
            "/" +
            this.sharedService.escapeRegExp(
              this.locationInput.split(" ").join("_")
            ) +
            (this.venueObj.name_he || this.venueObj.name_en
              ? "/" + this.sharedService.escapeRegExp(this.venueObj.id)
              : "")
        );
      }
    }
  }

  hideArtistAutocomplete() {
    setTimeout(() => {
      this.showArtistAutocomplete = false;
    }, 200);
  }

  hideLocationAutocomplete() {
    setTimeout(() => {
      this.showLocationAutocomplete = false;
    }, 200);
  }

  chooseLocation(venue, isUserInteraction = true) {
    this.locationInput = venue.name_he
      ? venue.name_he
      : venue.name_en
      ? venue.name_en
      : venue.city_he
      ? venue.city_he
      : venue.city_en;
    this.locationInputSearch = this.locationInput;
    this.venueObj = venue;
    this.showLocationAutocomplete = false;
    if (this.artistInput.length == 0) {
      this.artistInputSearch = "";
      this.artistObj = {};

      this.searchForShows();
      if (isUserInteraction) {
        this.router.navigateByUrl(
          "/tickets/venue/" +
            this.sharedService.escapeRegExp(
              this.locationInput.split(" ").join("_")
            ) +
            (this.venueObj.name_he || this.venueObj.name_en
              ? "/" + this.sharedService.escapeRegExp(this.venueObj.id)
              : "")
        );
      }
      this.metaService.updateMetaInfo(
        this.locationInput +
          " - הופעות, " +
          this.locationInput +
          " כרטיסים להופעות, eco tickets",
        this.locationInput +
          " - הופעות, " +
          this.locationInput +
          " הופעות וכרטיסים, כרטיסים להופעות של " +
          this.locationInput +
          " - כרטיסים, eco tickets כרטיסים להופעות בארץ ובעולם.",
        this.artistObj.artist_image
          ? this.artistObj.artist_image
          : this.baseUrl + "/assets/images/eco99seo.jpeg",
        this.baseUrl +
          "/tickets/venue/" +
          this.sharedService.escapeRegExp(
            this.locationInput.split(" ").join("_")
          ) +
          (this.venueObj.name_he || this.venueObj.name_en
            ? "/" + this.sharedService.escapeRegExp(this.venueObj.id)
            : "")
      );
    } else {
      if (isUserInteraction) {
        this.router.navigateByUrl(
          "/tickets/venue_artist/" +
            this.sharedService.escapeRegExp(
              this.artistInput.split(" ").join("_")
            ) +
            "/" +
            this.artistObj.id +
            "/" +
            this.sharedService.escapeRegExp(
              this.locationInput.split(" ").join("_")
            ) +
            (this.venueObj.name_he || this.venueObj.name_en
              ? "/" + this.sharedService.escapeRegExp(this.venueObj.id)
              : "")
        );
      }
    }
  }

  chooseLocationAndArtist(venue, artist) {
    if(artist && venue){
    this.locationInput = venue.name_he
    ? venue.name_he
    : venue.name_en
    ? venue.name_en
    : venue.city_he
    ? venue.city_he
    : venue.city_en;
  this.locationInputSearch = this.locationInput;
  this.venueObj = venue;
  this.showLocationAutocomplete = false;
      this.artistInput = artist[this.preferredLang];
      this.artistInputSearch = this.artistInput;
      this.artistObj = artist;
      this.showArtistAutocomplete = false;
  
      this.searchForShows();
      this.metaService.updateMetaInfo(
        this.artistInput +
          " ב" +
          this.locationInput +
          " - הופעות, " +
          this.artistInput +
          " ב" +
          this.locationInput +
          " כרטיסים להופעות, eco tickets",
        this.artistInput +
          " ב" +
          this.locationInput +
          " - הופעות, " +
          this.artistInput +
          " ב" +
          this.locationInput +
          " הופעות וכרטיסים, כרטיסים להופעות של " +
          this.artistInput +
          " ב" +
          this.locationInput +
          " - כרטיסים, eco tickets כרטיסים להופעות בארץ ובעולם.",
        this.artistObj.artist_image
          ? this.artistObj.artist_image
          : this.baseUrl + "/assets/images/eco99seo.jpeg",
        this.baseUrl +
          "/tickets/venue_artist/" +
          this.sharedService.escapeRegExp(this.artistInput.split(" ").join("_")) +
          "/" +
          this.artistObj.id +
          "/" +
          this.sharedService.escapeRegExp(
            this.locationInput.split(" ").join("_")
          ) +
          (this.venueObj.name_he || this.venueObj.name_en
            ? "/" + this.sharedService.escapeRegExp(this.venueObj.id)
            : "")
      );

    }
 
  }

  scroll(direction) {
    let selector = ".gallery";
    if (!this.scrolling) {
      var width = $(selector).width();
      this.scrolling = true;
      if (direction == "left") {
        this.isStartOfScroll = false;
        $(selector).animate({ scrollLeft: "-=" + width }, 800, () => {
          this.scrolling = false;
          this.sharedService.checkIfEndOrStartOfScroll(
            $(selector),
            "left",
            res => {
              this.isEndOfScroll = res;
            }
          );
        });
      } else {
        this.isEndOfScroll = false;
        $(selector).animate({ scrollLeft: "+=" + width }, 800, () => {
          this.scrolling = false;
          this.sharedService.checkIfEndOrStartOfScroll(
            $(selector),
            "right",
            res => {
              this.isStartOfScroll = res;
            }
          );
        });
      }
    }
  }

  startLoadingAnimation() {
    if (this.loadingAnimation) {
      this.loadingAnimation.destroy();
    }
    setTimeout(() => {
      if (document.getElementById("more-shows-loader")) {
        this.loadingAnimation = bodymovin.loadAnimation({
          container: document.getElementById("more-shows-loader"),
          renderer: "svg",
          loop: true,
          autoplay: true,
          path: "assets/animations/loader.json"
        });
      }
    });
  }

  getBgUrl(show) {
    if (show.show_image) {
      return show.show_image;
    } else {
      return "/assets/images/show-placeholder.png";
    }
  }

  ngOnDestroy() {
    this.sharedService.transparentheader = false;
    this.sharedService.showUpperSection = true;
  }

  getDate(event_date) {
    return new Date(event_date).getDate();
  }

  getMonth(event_date) {}
}
