import { ActivatedRoute, RouterOutlet } from "@angular/router";
import { Directive, ComponentRef } from "@angular/core";

export interface OnAttach {
  /**
   * A callback method that is invoked when the RouteReuseStrategy instructs
   * to re-attach a previously detached component / subtree
   */
  onAttach(activatedRoute: ActivatedRoute): void;
}
export interface OnDetach {
  /**
   * A callback method that is invoked when the RouteReuseStrategy instructs
   * to detach component / subtree
   */
  onDetach(): void;
}
@Directive({
  selector: "app-router-outlet"
})
export class AppRouterOutletDirective extends RouterOutlet {
  detach(): ComponentRef<any> {
    const instance: any = this.component;
    if (instance && typeof instance.onDetach === "function") {
      instance.onDetach();
    }
    return super.detach();
  }
  attach(ref: ComponentRef<any>, activatedRoute: ActivatedRoute): void {
    super.attach(ref, activatedRoute);
    if (ref.instance && typeof ref.instance.onAttach === "function") {
      ref.instance.onAttach(activatedRoute);
    }
  }
}
