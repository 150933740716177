import { Component, OnInit, NgZone, Inject } from "@angular/core";
import { NetworkService } from "./services/network.service";
import { SharedService } from "./services/shared.service";
import { Router, NavigationEnd } from "@angular/router";
import { AuthService } from "./services/auth.service";
import { BoardingDialogComponent } from "./dialogs/boarding-dialog/boarding-dialog.component";
import { ContactComponent } from "./pages/contact/contact.component";
import { WINDOW, LOCAL_STORAGE } from "@ng-toolkit/universal";
import { MessageService } from "./services/messageService";
import { AngularFireAuth } from "@angular/fire/auth";
import { MetaService } from "./services/MetaService";
import { GoogleAnalyticsService } from "./services/googleAnalytics.service";
import { ROUTES } from "./data/routes";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "eco99-music";
  menuItems = [];
  footerItems = [];
  footerPartners = [];
  aboutusText = "";
  isLoginCalledOnce = false;
  isLogoutCalledOnce = false;

  constructor(
    @Inject(WINDOW) private window: Window,
    @Inject(LOCAL_STORAGE) private localStorage: any,
    private meta: MetaService,
    private networkService: NetworkService,
    public sharedService: SharedService,
    private router: Router,
    private authService: AuthService,
    private ngZone: NgZone,
    private contactPage: ContactComponent,
    private messageService: MessageService,
    private afAuth: AngularFireAuth,
    private analyticsService: GoogleAnalyticsService
  ) {
    afAuth.authState.subscribe((user) => {
      this.sharedService.cleanAllSharedData();

      if (user) {
        this.sharedService.isAnonymous = false;
        if (!this.authService.getToken()) {
          //new user
          this.authService.setToken(" ");
          var email = user.email || user.providerData[0].email;
          var photoURL = user.photoURL || user.providerData[0].photoURL;
          if (this.window.location && this.window.location.pathname == "/invitation") {
            this.ngZone.run(() => {
              this.waitFor(
                () => this.sharedService.tookQueryParams,
                () => {
                  this.sharedService.loadLastPlayingContentIfExists();
                  this.router.navigate([""]);
                  this.logisterFlow(email, photoURL);
                }
              );
            });
          } else {
            this.ngZone.run(() => {
              if (this.window.location && this.window.location.pathname == ROUTES.LOGIN) {
                this.ngZone.run(() => {
                  this.sharedService.loadLastPlayingContentIfExists();
                  this.router.navigate([""]);
                });
              }
              this.logisterFlow(email, photoURL);
            });
          }
        } else {
          this.getUserFlow();
          if (this.window.location && this.window.location.pathname == ROUTES.LOGIN) {
            this.ngZone.run(() => {
              this.sharedService.loadLastPlayingContentIfExists();
              this.router.navigate([""]);
            });
          }
        }
      } else {
        this.sharedService.getMinePageData();
        this.sharedService.isAnonymous = true;
        this.authService.logout();
        this.sharedService.user = null;
      }
    });
  }

  ngOnInit() {
    this.meta.updateTitle();

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      this.sharedService.showMenuTablet = false;
      this.sharedService.showHeaderHam = evt.url.indexOf("/tab/") == -1;
      this.sharedService.showHeader = evt.url.indexOf("/tab/") == -1;
      this.sharedService.showUpperSection = evt.url == "/";

      this.analyticsService.sendPageView(evt.urlAfterRedirects, evt.url);
    });
    this.getAppDataContents();
  }

  getUserFlow() {
    this.networkService.getUser().subscribe((DBUser) => {
      this.setAndCheckUserFlags(DBUser);
      if (this.window.location && this.window.location.pathname == "/contact") {
        this.contactPage.setEmailField(DBUser.personal_data.email);
      }
    });
  }

  logisterFlow(email, profilePhoto) {
    this.networkService.logister(email, profilePhoto).subscribe(
      (DBUser) => {
        this.ngZone.run(() => {
          this.sharedService.user = DBUser;
          this.setAndCheckUserFlags(this.sharedService.user);
        });
      },
      (err) => {}
    );
  }

  setAndCheckUserFlags(DBUser) {
    this.ngZone.run(() => {
      this.sharedService.user = DBUser;
    });

    if (!DBUser.meta_data.is_finished_on_boarding) {
      this.sharedService.showOnboarding(BoardingDialogComponent);
    } else {
      this.sharedService.getMinePageData();
      if (DBUser.payment_method_data.expiry_popup) {
        this.sharedService.showCardExpiredSoon();
      } else {
        if (DBUser.pending_invitation && DBUser.pending_invitation.id) {
          this.sharedService.temp_invitationId = DBUser.pending_invitation.id;
          this.sharedService.temp_inviterName =
            DBUser.pending_invitation.inviting_user_name;
        }
        this.sharedService.showInvitationPopup();
      }
    }
  }

  getAppDataContents() {
    this.networkService.getMenusData().subscribe((res) => {
      this.ngZone.run(() => {
        this.menuItems = res.web_menu_items;
        this.footerItems = res.footer_sections;
        this.footerPartners = res.footer_partners;
        this.aboutusText = res.about_us_text;
        this.sharedService.allowContent = res.allow_content;
        if (this.window.location && this.window.location.pathname !== ROUTES.LOGIN && this.window.location.pathname.indexOf("/embedded-player") === -1 ){
          this.sharedService.loadLastPlayingContentIfExists();
        }
      });
    });
  }

  waitFor(condition, callback) {
    if (!condition) {
      window.setTimeout(this.waitFor.bind(null, condition, callback), 100);
    } else {
      callback();
    }
  }
}
