import { HttpParams } from "@angular/common/http";

export class PlaylistQuery {
  page: number;
  itemsPerPage: number;
  category: string;
  name: string;

  constructor(
    page: number = 1,
    itemsPerPage: number = 11,
    category: string = undefined,
    name: string = undefined
  ) {
    this.page = page;
    this.itemsPerPage = itemsPerPage;
    this.category = category;
    this.name = name;
  }

  public toString() {
    return (
      (this.page ? "page=" + this.page : "") +
      (this.itemsPerPage ? "&itemsPerPage=" + this.itemsPerPage : "") +
      (this.category ? "&category_id=" + this.category : "") +
      (this.name ? "&name=" + this.name : "")
    );
  }
}
