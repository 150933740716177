import { Injectable, Inject, NgZone, PLATFORM_ID } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { MatDialog } from "@angular/material";
import { AlertDialogComponent } from "../dialogs/alert-dialog/alert-dialog.component";
import { NetworkService } from "./network.service";
import { LogoutDialogComponent } from "../dialogs/logout-dialog/logout-dialog.component";
import { AuthService } from "./auth.service";
import { WelcomeFriendDialogComponent } from "../dialogs/welcome-friend-dialog/welcome-friend-dialog.component";
import { Router } from "@angular/router";
import { RemovePackageDialogComponent } from "../dialogs/remove-package-dialog/remove-package-dialog.component";
import { WINDOW, LOCAL_STORAGE } from "@ng-toolkit/universal";
import { ExpiredSoonDialogComponent } from "../dialogs/expired-soon-dialog/expired-soon-dialog.component";
import { MessageService } from "./messageService";
import { UpgradeToProComponent } from "../dialogs/upgrade-to-pro/upgrade-to-pro.component";
import { PlaylistQuery } from "./playlistQuery";
import { GoogleAnalyticsService } from "./googleAnalytics.service";
import { environment } from "../../environments/environment";
import { isPlatformBrowser, DOCUMENT } from "@angular/common";
import { SubscriptionDialogComponent } from "../dialogs/subscription-dialog/subscription-dialog.component";
import { GenericService } from "./generic.service";
import { AngularFirestore } from "@angular/fire/firestore";
import { ProgramsQuery } from "./programsQuery";
import { ROUTES } from "../data/routes";

declare var $: any;
declare var com_adswizz_synchro_decorateUrl: any;

@Injectable()
export class SharedService {
  vendorsToClasses = {
    unknown: "",
    amex: "assets/images/amex-icon.png",
    "3": "assets/images/amex-icon.png",
    diners: "assets/images/diners-icon.png",
    "5": "assets/images/diners-icon.png",
    "6": "assets/images/diners-icon.png",
    jcb: "assets/images/jcb-icon.png",
    "7": "assets/images/jcb-icon.png",
    visa: "assets/images/visa-icon.png",
    "8": "assets/images/visa-icon.png",
    "1": "assets/images/visa-icon.png",
    mastercard: "assets/images/mastercard-icon.png",
    "2": "assets/images/mastercard-icon.png",
    discover: "assets/images/discover-icon.ico",
    "4": "assets/images/discover-icon.ico",
    isracard: "assets/images/isracard-icon.png",
  };
  vendorsToNumber = {
    diners: 5,
    amex: 3,
    jcb: 7,
    visa: 1,
    mastercard: 2,
    discover: 4,
  };
  showHeader: boolean = true;
  showFooter: boolean = true;
  showMenu: boolean = true;
  loadingListenedContent: boolean = true;
  loadingLikedContent: boolean = true;
  loadingHitsContent: boolean = false;
  showMenuTablet: boolean = false;
  showUpperSection: boolean = true;
  showHeaderHam: boolean = true;
  showStickyPlayer: boolean = false;
  userNickname: string = "";
  gender: string = "";
  user: any;
  temp_invitationId: string;
  temp_inviterName: string;
  transparentheader = false;
  tookQueryParams = false;
  showCollapsedHeader: boolean = false;
  loadedFirstForCurrentPlaylists: boolean = false;
  isLoadingCurrentPlaylists: boolean = false;
  tempShow: any;
  tempPlaylist: any;
  tempProgram: any;
  listenedContent = [];
  likedContent = [];
  hitsContent = [];
  myPlaylists = [];
  currentPlaylistData: any;
  currentPlayingProgram: any;
  playlistCategories: any;
  promotions: any;
  isPlaying = false;
  currentPlayingContentID: string = "";
  tempActiveSubcategory: any;
  currentPlayingPlaylist: any;
  isAnonymous: boolean;
  hasNativeShare: boolean;
  allowContent: boolean;
  isPlatformBrowser: boolean;
  isIEBrowser: boolean;
  isSafariBrowser: boolean;

  constructor(
    @Inject(WINDOW) private window: Window,
    private genericService: GenericService,
    @Inject(LOCAL_STORAGE) private localStorage: any,
    private _cookieService: CookieService,
    public dialog: MatDialog,
    public networkService: NetworkService,
    private authService: AuthService,
    private router: Router,
    private ngZone: NgZone,
    private messageService: MessageService,
    private analyticsService: GoogleAnalyticsService,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private dom,
    private afs: AngularFirestore
  ) {
    this.setHasNativeShare();
    this.setIsPlatformBrowser();
    this.isIEBrowser = this.detectIEEdge();
    this.isSafariBrowser = this.detectSafari();
  }

  setIsPlatformBrowser() {
    this.isPlatformBrowser = isPlatformBrowser(this.platformId);
  }
  getCookie(key: string) {
    return this._cookieService.get(key);
  }
  setCookie(key: string, value: string) {
    return this._cookieService.set(key, value);
  }

  isEnglishUsername(name) {
    return /^[a-zA-Z0-9$@$!%*?&#^-_. +]+$/.test(name);
  }

  isFreeUser() {
    return this.user && this.user.payment_data.subscription == 4;
  }

  onPrivateContentClick() {
    this.authService.isTokenValid()
    .subscribe(
      (isValid) => {},
      (error) => {}
    );
  }

  isAdminUser() {
    return (
      this.user &&
      this.user.payment_data.settings &&
      this.user.payment_data.settings.admin._id == this.user._id
    );
  }

  getAdminUserName() {
    return (
      this.user &&
      this.user.payment_data.settings &&
      this.user.payment_data.settings.admin.personal_data.nickname
    );
  }

  loadSongToPlayer(liveRadioData, dontPlay?) {
    this.messageService.loadPlayerData(
      this.createPlayerData(liveRadioData, dontPlay)
    );
    this.showStickyPlayer = true;
  }

  loadProgramToPlayer(show, showName, query, index, url, currentSongTime?) {
    this.currentPlayingProgram = show;
    let radioUrl_AdsWizz;
    if (typeof com_adswizz_synchro_decorateUrl === "undefined") {
      radioUrl_AdsWizz = show.url;
    } else radioUrl_AdsWizz = com_adswizz_synchro_decorateUrl(show.url);

    let data = {
      streamingLink: radioUrl_AdsWizz,
      thumbnail: show.mobile_image_name
        ? show.mobile_image_name
        : show.image_url,
      image: show.image_url,
      songName: show.name,
      programName: showName,
      currentSongTime: currentSongTime || "00:00",
      songLength: "00:00",
      program_id: show.original_id,
      query: query,
      index: index,
      programUrl: url,
    };

    if (this.isSafariBrowser) {
      // fix for safari
      data.streamingLink =
        show.url +
        "/playlist.m3u8" +
        (show.file_name.split("?").length > 1
          ? "?" + show.file_name.split("?")[1]
          : "");
    }
    this.messageService.loadPlayerData(data);
    this.showStickyPlayer = true;
  }

  loadPlaylistToPlayer(playlist, isShuffled?, currentSongTime?) {
    let clonedlist;
    if (playlist.playlistList) {
      clonedlist = playlist.playlistList.slice(0);
      if (!isShuffled) {
        clonedlist = this.shuffle(clonedlist);
      }
    }
    this.currentPlayingPlaylist = playlist;

    let radioUrl_AdsWizz: string = "";

    if (typeof com_adswizz_synchro_decorateUrl === "undefined") {
      radioUrl_AdsWizz = playlist.url ? playlist.url : playlist.streamingLink;
    } else {
      radioUrl_AdsWizz = com_adswizz_synchro_decorateUrl(
        playlist.url ? playlist.url : playlist.streamingLink
      );
    }

    let data = {
      streamingLink: radioUrl_AdsWizz,
      thumbnail: playlist.image_mobile,
      image: playlist.image,
      songName: playlist.name_he ? playlist.name_he : playlist.name_en,
      programName: playlist.short_description
        ? playlist.short_description
        : playlist.programName,
      currentSongTime: currentSongTime || "00:00",
      songLength: "00:00",
      playlist_id: playlist.original_id
        ? playlist.original_id
        : playlist.playlist_id,
      song_list: playlist.song_list,
      playlistIndex: playlist.playlistIndex,
      playlistList: clonedlist ? clonedlist : [],
    };

    if (this.isSafariBrowser) {
      // fix for safari
      data.streamingLink = playlist.url
        ? playlist.url.split("?")[0] +
          "/playlist.m3u8" +
          (playlist.url.split("?").length > 1
            ? "?" + playlist.url.split("?")[1]
            : "")
        : playlist.streamingLink.split("?")[0] +
          "/playlist.m3u8" +
          (playlist.streamingLink.split("?").length > 1
            ? "?" + playlist.streamingLink.split("?")[1]
            : "");
    }
    this.messageService.loadPlayerData(data);
    this.showStickyPlayer = true;
  }

  shuffle(array) {
    var currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  hidePlayer() {
    this.messageService.loadPlayerData(this.createPlayerData({}));
    this.showStickyPlayer = false;
    this.currentPlayingContentID = "";
  }

  createPlayerData(liveRadioData, dontPlay?) {
    return {
      streamingLink: liveRadioData.streamingLink,
      thumbnail: this.getLiveRadioImage(liveRadioData),
      songName: this.getSongName(liveRadioData),
      programName: this.getArtistName(liveRadioData),
      currentSongTime: "00:00",
      songLength: "00:00",
      isLive: liveRadioData.isLive,
      dontPlay,
    };
  }

  getLiveRadioImage(liveRadioData) {
    return (
      (liveRadioData && (liveRadioData.thumbnail || liveRadioData.image_url)) ||
      "assets/images/radiobg-min.jpg"
    );
  }
  getArtistName(liveRadioData) {
    return (
      liveRadioData &&
      (liveRadioData.artist_name ||
        (liveRadioData.broadcaster_name
          ? "עם " + liveRadioData.broadcaster_name
          : ""))
    );
  }
  getSongName(liveRadioData) {
    return (
      liveRadioData && (liveRadioData.song_name || liveRadioData.program_name)
    );
  }

  updateLikedContent(id) {
    this.networkService
      .updateLikedContent("one_track_playlist", id)
      .subscribe((resp) => {
        this.getMinePageData(); // TODO : refresh data manually instead of server network
      });
  }

  cleanAllSharedData() {
    this.playlistCategories = undefined;
    this.promotions = undefined;
    this.listenedContent = undefined;
    this.likedContent = undefined;
    this.currentPlaylistData = undefined;
  }

  getMinePageData() {
    this.networkService.getMoods().subscribe((res) => {
      this.playlistCategories = res.items;
    });

    if (!this.currentPlaylistData && !this.isLoadingCurrentPlaylists) {
      this.isLoadingCurrentPlaylists = true;
      this.loadedFirstForCurrentPlaylists = false;
      //TO DO: check for daytime/current call
      this.networkService.getPlaylistForCurrent().subscribe((res) => {
        this.handlePlaylistForCurrentResponse(res);
      });
    }

    if (this.user) {
      this.loadingListenedContent = true;
      let query = new PlaylistQuery(1, 16);
      this.networkService
        .getListenedContent(query, "one_track_playlist")
        .subscribe((res) => {
          let counter = 0;
          this.listenedContent = [];
          let interval = setInterval(() => {
            if (res.items.length == counter) {
              this.loadingListenedContent = false;
              clearInterval(interval);
            } else {
              if (!this.listenedContent) {
                clearInterval(interval);
                return;
              }
              let playlistToAdd = res.items[counter];
              setTimeout(() => {
                playlistToAdd.isLoaded = true;
              }, 10);
              this.listenedContent.push(playlistToAdd);
              counter++;
            }
          }, 50);
        });

      this.loadingLikedContent = true;
      this.networkService
        .getLikedContent(query, "one_track_playlist")
        .subscribe((res) => {
          let counter = 0;
          this.likedContent = [];
          let interval = setInterval(() => {
            if (res.items.length == counter) {
              this.loadingLikedContent = false;
              clearInterval(interval);
            } else {
              let playlistToAdd = res.items[counter];
              setTimeout(() => {
                playlistToAdd.isLoaded = true;
              }, 10);
              this.likedContent.push(playlistToAdd);
              counter++;
            }
          }, 50);
        });
    } else {
      this.loadingListenedContent = true;
      let playlists;
      if (this.localStorage.getItem("listened-content")) {
        playlists = JSON.parse(this.localStorage.getItem("listened-content"));
      }

      if (!playlists) {
        this.listenedContent = [];
        this.localStorage.setItem("listened-content", "[]");
        this.loadingListenedContent = false;
        return;
      }

      let counter = 0;
      this.listenedContent = [];
      let interval = setInterval(() => {
        if (playlists.length == counter) {
          this.loadingListenedContent = false;
          clearInterval(interval);
        } else {
          if (!this.listenedContent) {
            clearInterval(interval);
            return;
          }
          let playlistToAdd = playlists[counter];
          setTimeout(() => {
            playlistToAdd.isLoaded = true;
          }, 10);
          this.listenedContent.push(playlistToAdd);
          counter++;
        }
      }, 50);
    }
  }

  getPromotionData(pagination, cb, endCB?) {
    this.networkService
      .getplaylistsForPromotionID(pagination)
      .subscribe((res) => {
        let counter = 0;

        let interval = setInterval(() => {
          if (!res || res.items.length == counter) {
            clearInterval(interval);
            if (!res) {
              res = { items: [] };
            }
            if (endCB) {
              endCB(res.items, res.totalPages, res.title);
            }
          } else {
            let playlistToAdd = res.items[counter];
            setTimeout(() => {
              playlistToAdd.isLoaded = true;
            }, 10);
            cb(playlistToAdd);
            counter++;
          }
        }, 50);
      });
  }

  handlePlaylistForCurrentResponse(res) {
    let counter = 0;
    this.currentPlaylistData = res.item;
    if (!this.currentPlaylistData) {
      this.isLoadingCurrentPlaylists = false;
      return;
    }
    let playlists = Object.assign([], res.item.playlists);
    this.currentPlaylistData.playlists = [];

    let interval = setInterval(() => {
      if (playlists.length == counter) {
        clearInterval(interval);
        this.isLoadingCurrentPlaylists = false;
      } else {
        let playlistToAdd = playlists[counter];
        setTimeout(() => {
          playlistToAdd.isLoaded = true;
          this.loadedFirstForCurrentPlaylists = true;
        }, 10);
        this.currentPlaylistData.playlists.push(playlistToAdd);
        counter++;
      }
    }, 100);
  }

  showAlert(
    text: string,
    showTitle = true,
    happyBunny = false,
    messageTitle = "אופס!",
    buttonText = "סגור",
    isBlueButton = false,
    cb = null
  ) {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data: {
        messageText: text,
        messageTitle: messageTitle,
        showTitle: showTitle,
        happyBunny: happyBunny,
        buttonText: buttonText,
        isBlueButton: isBlueButton,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (cb) {
        cb(result);
      }
    });
  }

  showLogoutConfirm() {
    const dialogRef = this.dialog.open(LogoutDialogComponent);
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.analyticsService.sendEvent("settings", "logout", "התנתק");
        this.hidePlayer();
        this.localStorage.removeItem("playing_data");
        this.authService.logout();
        this.user = null;
        this.router.navigateByUrl(ROUTES.LOGIN);
      }
    });
  }

  deleteUserConfirmed() {
    try {
      this.networkService.deleteUser().subscribe((result) => {
        if (result) {
          this.analyticsService.sendEvent("settings", "delete-user", "משתמש נמחק");
          this._cookieService.deleteAll();
          this.localStorage.removeItem("playing_data");
          this.authService.logout();
          this.user = null;
          this.router.navigateByUrl(ROUTES.LOGIN);
        }
      })
    }
    catch (err) {
      this.showAlert(
        "אירעה שגיאה בעת מחיקת המשתמש",
        true,
        true,
        "!שגיאה",
      );
    }
  }

  showUpdatePackagePopup(packageType) {
    var isFamilyAdmin = packageType == 2 && this.isAdminUser();
    this.showAlert(
      isFamilyAdmin
        ? "עכשיו אתה יכול להזמין אנשים לחבילה שלך"
        : "בוא ניתן למוזיקה לדבר",
      true,
      true,
      "קאצ'ינג! התשלום עבר בהצלחה",
      isFamilyAdmin ? "קח אותי להזמין" : "יאללה, למוזיקה שלי",
      false,
      (result) => {
        if (this.user.payment_method_data.expiry_popup) {
          this.showCardExpiredSoon();
        }
        this.router.navigateByUrl(isFamilyAdmin ? "/settings?invite" : "/");
      }
    );
  }

  showOnboarding(component, hidePrePostScreens = false) {
    const dialogRef = this.dialog.open(component, {
      disableClose: true,
      data: { hidePrePostScreens: hidePrePostScreens },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.userNickname = result.userNickname
          ? result.userNickname
          : this.user.personal_data.nickname;
        this.gender = hidePrePostScreens
          ? this.user.personal_data.gender == 1
            ? "male"
            : this.user.personal_data.gender == 2
            ? "female"
            : "unknown"
          : result.selectedAvatar;
        let questions = result.questions;
        this.setCookie("onboarding-currentindex", "0");
        this.setCookie("onboarding-answers", "");
        this.networkService
          .saveBoardingQuestions(this.userNickname, this.gender, questions)
          .subscribe(
            (DBUser) => {
              this.user = DBUser;
              this.showInvitationPopup();

              this.getMinePageData();
            },
            (err) => {}
          );
      }
    });
  }

  showInvitationPopup() {
    if (this.window.localStorage.getItem("invitationID")) {
      this.temp_invitationId = this.window.localStorage.getItem("invitationID");
      this.temp_inviterName = this.window.localStorage.getItem("inviterName");
      this.window.localStorage.removeItem("invitationID");
      this.window.localStorage.removeItem("inviterName");
    }
    if (!this.temp_invitationId) {
      if (!this.user.meta_data.has_seen_email_approval) {
        this.showSubscriptionPopup();
      }
      return;
    }
    if (this.user.payment_data.subscription != 4) {
      this.networkService.declineInvitation().subscribe();
      this.showAlert(
        this.temp_inviterName +
          " הזמין אותך לחבילה המשפחתית אך חשבון זה כבר מחובר לחבילה, ניתן להשתייך לחבילה אחת בלבד",
        true,
        false
      );
    } else {
      const dialogRef = this.dialog.open(WelcomeFriendDialogComponent, {
        data: {
          invitationId: this.temp_invitationId,
          inviterName: this.temp_inviterName,
        },
      });
      dialogRef.afterClosed().subscribe(
        (result) => {
          this.temp_invitationId = "";
          this.temp_inviterName = "";
          if (!result.success) {
            this.showAlert(result.resp, true, false);
          } else {
            if (!result.isDecline) {
              this.user.payment_data.settings = result.resp;
              this.user.payment_data.subscription = 2;
            }
          }
          if (!this.user.meta_data.has_seen_email_approval) {
            this.showSubscriptionPopup();
          }
        },
        (err) => {}
      );
    }
  }

  showCardExpiredSoon() {
    const dialogRef = this.dialog.open(ExpiredSoonDialogComponent, {
      data: {
        subscription: this.user.payment_data.subscription,
      },
    });

    dialogRef.afterClosed().subscribe(
      (result) => {
        if (result && result.success) {
          this.user = result.resp;
        }
      },
      (err) => {
        this.showAlert("היתה בעיה, אנא נסה שנית", true, false);
      }
    );
  }

  showSubscriptionPopup() {
    const dialogRef = this.dialog.open(SubscriptionDialogComponent, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(
      (result) => {
        if (result && result.success) {
          this.user = result.resp;
        }
      },
      (err) => {
        // this.showAlert('היתה בעיה, אנא נסה שנית', true, false)
      }
    );
  }

  showLeaveFamily(cb) {
    const dialogRef = this.dialog.open(RemovePackageDialogComponent, {
      data: {
        title: "לעזוב את" + this.getAdminUserName() + " והמשפחה? באמת?",
        approveButton: "עוף גוזל",
      },
    });

    dialogRef.afterClosed().subscribe(
      (result) => {
        if (result) {
          this.networkService.leaveFamilyPackage().subscribe(
            (resp) => {
              this.user = resp;
              cb();
            },
            (err) => {
              this.showAlert("היתה בעיה, אנא נסה שנית", true, false);
            }
          );
        }
      },
      (err) => {}
    );
  }

  showCreateUserPopup() {
    const dialogRef = this.dialog.open(UpgradeToProComponent, {});
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.success) {
        this.router.navigateByUrl(ROUTES.LOGIN);
      }
    });
  }

  checkIfEndOrStartOfScroll($elem, dir, cb) {
    var newScrollLeft = $elem.scrollLeft(),
      width = $elem.width(),
      scrollWidth = $elem.get(0).scrollWidth;
    var offset = 40;
    if (dir == "left") {
      if (newScrollLeft === 0) {
        //isend
        cb(true);
        $elem.blur();
      } else {
        cb(false);
      }
      return;
    } else {
      if (scrollWidth - newScrollLeft - width < offset) {
        //is start
        cb(true);
        $elem.blur();
      } else {
        cb(false);
      }
      return;
    }
  }

  getMonthString(selectedMonth) {
    switch (selectedMonth) {
      case "ינואר":
        return 1;
      case "פברואר":
        return 2;
      case "מרץ":
        return 3;
      case "אפריל":
        return 4;
      case "מאי":
        return 5;
      case "יוני":
        return 6;
      case "יולי":
        return 7;
      case "אוגוסט":
        return 8;
      case "ספטמבר":
        return 9;
      case "אוקטובר":
        return 10;
      case "נובמבר":
        return 11;
      case "דצמבר":
        return 12;
    }
  }

  isEmptyText(text) {
    if (this.isPlatformBrowser) {
      return $(text)[0].innerText.trim().length == 0;
    } else {
      return false;
    }
  }

  escapeRegExp(str) {
    if (str && !this.isPlatformBrowser) {
      str = encodeURIComponent(str);
    }
    return str;
  }

  setHasNativeShare() {
    if (this.isMobileDevice() && "share" in navigator) {
      this.hasNativeShare = true;
    }
  }

  shareWithFacebookMessanger(dynamicLink) {
    var windowReference = window.open();
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      windowReference.location.href =
        "fb-messenger://share?link=" +
        encodeURIComponent(
          dynamicLink + "&app_id=" + encodeURIComponent("413355102015257")
        );
    } else {
      (windowReference.location.href =
        "http://www.facebook.com/dialog/send? app_id=413355102015257&link=" +
        dynamicLink +
        "&redirect_uri=" +
        environment.baseUrl),
        "targetWindow";
    }
  }

  goToStore() {
    let osType = this.genericService.getMobileOperatingSystem();
    if (osType == "Android") {
      window.open("https://eco99fm.page.link/app");
      return;
    }

    if (osType == "iOS") {
      window.open("https://eco99fm.page.link/app");
      return;
    }

    this.router.navigateByUrl(ROUTES.LOGIN);
  }

  shareWithFacebook(dynamicLink) {
    /**
     * The commented part was the body of the function once.
     * Back then, it worked with Meta Developer application, which since was disabled.
     * It gave a little bit more analytics about sharing inside this app.
     * An analytics is sent without it anyway via a function in the code, so it is not relevant for now.
     * If you want to return it to the old way, you need to search the reason the app (app_id=413355102015257)
     * was disabled and fix it (and maybe open a new application if it can't be enabled).
     */
    // var windowReference = window.open();
    // (windowReference.location.href =
    //   "https://www.facebook.com/dialog/share?app_id=413355102015257&display=popup&href=" +
    //   dynamicLink),
    //   "targetWindow";
    //   "toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=700,height=400";
    const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${dynamicLink}`;
    window.open(shareUrl, "_blank", "width=700,height=400");
  }

  shareWithEmail(text) {
    var windowReference = window.open();
    (windowReference.location.href = "mailto:?body=" + text),
      "targetWindow",
      "toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=700,height=400";
  }

  shareWithWhatsApp(text) {
    var windowReference = window.open();
    let message = text.split(" ").join("%20");
    (windowReference.location.href =
      "https://api.whatsapp.com/send?&text=%20" + message),
      "targetWindow",
      "toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=700,height=700";
  }

  nativeShare(text, shortlink) {
    var share = {
      text: text,
      url: shortlink,
    };
    navigator["share"](share)
      .then(function () {})
      .catch(function (error) {
        console.log("Error sharing", error);
      });
  }

  isMobileDevice() {
    let os = this.genericService.getMobileOperatingSystem();
    return os == "Android" || os == "iOS" || window.innerWidth <= 768;
  }

  extractContent(s) {
    var span = this.dom.createElement("span");
    span.innerHTML = s;
    return span.textContent || span.innerText;
  }

  detectIEEdge() {
    var ua = window.navigator.userAgent;

    var msie = ua.indexOf("MSIE ");
    if (msie > 0) {
      // IE 10 or older => return version number
      return true; //parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    var trident = ua.indexOf("Trident/");
    if (trident > 0) {
      // IE 11 => return version number
      var rv = ua.indexOf("rv:");
      return true; //parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    var edge = ua.indexOf("Edge/");
    if (edge > 0) {
      // Edge => return version number
      return false; //parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return false;
  }
  detectSafari() {
    return (
      /^((?!chrome|android).)*safari/i.test(navigator.userAgent) ||
      (this.isPlatformBrowser &&
        this.isFacebookApp() &&
        this.genericService.getMobileOperatingSystem() === "iOS")
    );
  }

  isFacebookApp() {
    var ua = navigator.userAgent || navigator.vendor || window["opera"];
    return ua.indexOf("FBAN") > -1 || ua.indexOf("FBAV") > -1;
  }

  loadLastPlayingContentIfExists() {
    if (this.localStorage.getItem("playing_data") && this.isPlatformBrowser) {
      const playingData = JSON.parse(this.localStorage.getItem("playing_data"));
      switch (playingData.contentType) {
        case "playlist":
          this.networkService
            .getPlaylistByID(playingData.contentId)
            .subscribe((resp) => {
              if (resp.item) {
                const playlist = resp.item;
                this.loadPlaylistToPlayer(playlist, false, playingData.time);
              }
            });
          break;
        case "program":
          this.networkService
            .getProgramByID(playingData.contentId)
            .subscribe((resp) => {
              const program = resp.item;
              this.loadProgramToPlayer(
                program,
                program.description,
                new ProgramsQuery(
                  playingData.query.page,
                  playingData.query.itemsPerPage,
                  playingData.query.category_id,
                  playingData.query.date_filter,
                  playingData.query.name_filter,
                  playingData.query.byDay
                ),
                playingData.index,
                this.getProgramName(program.original_category_id),
                playingData.time
              );
            });
          break;
        case "live-radio":
          const liveRadioData: any = {};
          let radioUrl_mp3: string = "";
          let radioUrl_m4a: string = "";
          const mp3BaseURL = "https://99.mediacast.co.il/99fm?.mp3";
          const m4aBaseURL = "https://99.mediacast.co.il/99fm_aac?.m4a";

          if (typeof com_adswizz_synchro_decorateUrl === "undefined") {
            radioUrl_mp3 = mp3BaseURL;
            radioUrl_m4a = m4aBaseURL;
          } else {
            radioUrl_mp3 = com_adswizz_synchro_decorateUrl(mp3BaseURL);
            radioUrl_m4a = com_adswizz_synchro_decorateUrl(m4aBaseURL);
          }

          if (this.isIEBrowser) {
            liveRadioData.streamingLink = radioUrl_mp3;
          } else {
            liveRadioData.streamingLink = radioUrl_m4a;
          }

          liveRadioData.isLive = true;
          this.loadSongToPlayer(liveRadioData, true);
          if (this.isPlatformBrowser) {
            this.afs
              .collection("streamed_content")
              .doc("program")
              .valueChanges()
              .pipe()
              .subscribe((data) => {
                let playerData = {
                  thumbnail: this.getLiveRadioImage(data),
                  songName: this.getSongName(data),
                  artistName: this.getArtistName(data),
                };
                this.messageService.updatePlayerDetails(playerData);
              });
          }
          break;
      }
    }
  }
  getProgramName(categoryID) {
    switch (categoryID) {
      case 12:
      case 8:
        return "טל_ואביעד";
      case 14:
      case 22:
        return "אקו_לייף";
      case 4:
        return "תשעה_חודשים";
      case 30:
        return "המגרש_הביתי";
    }
  }
}
