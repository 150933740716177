import { Injectable, Inject } from "@angular/core";
import { Title, Meta } from "@angular/platform-browser";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { filter, map, mergeMap } from "rxjs/operators";
import { DOCUMENT } from "@angular/common";

@Injectable()
export class MetaService {
  link: HTMLLinkElement;

  constructor(
    private titleService: Title,
    private meta: Meta,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private dom
  ) {
    this.link = this.dom.createElement("link");
    this.link.setAttribute("rel", "canonical");
    this.dom.head.appendChild(this.link);
  }

  updateMetaInfo(
    title: string,
    description: string,
    imageUrl: string,
    url?: string,
    keywords?: string,
    deeplinkUrl?: string
  ) {
    this.dom.title = title;
    this.meta.updateTag({ name: "description", content: description });

    this.meta.removeTag("itemprop='name'");
    this.meta.removeTag("itemprop='description'");
    this.meta.removeTag("itemprop='image'");

    this.meta.updateTag({ itemprop: "name", content: title });
    this.meta.updateTag({ itemprop: "description", content: description });
    this.meta.updateTag({ itemprop: "image", content: imageUrl });

    this.meta.updateTag({ property: "og:site_name", content: "eco99music" });
    this.meta.updateTag({ property: "og:type", content: "website" });
    this.meta.updateTag({ property: "og:title", content: title });
    this.meta.updateTag({ property: "og:description", content: description });
    this.meta.updateTag({ property: "og:image", content: imageUrl });
    this.meta.updateTag({ property: "og:image:url", content: imageUrl });
    this.meta.updateTag({ property: "og:image:alt", content: title });
    this.meta.updateTag({ property: "og:image:secure_url", content: imageUrl });
    this.meta.updateTag({ property: "og:image:width", content: "675" });
    this.meta.updateTag({ property: "og:image:height", content: "337" });
    this.meta.updateTag({ name: "twitter:site", content: "eco99music" });
    this.meta.updateTag({ name: "twitter:card", content: imageUrl });
    this.meta.updateTag({ name: "twitter:title", content: title });
    this.meta.updateTag({ name: "twitter:description", content: description });
    this.meta.updateTag({ name: "twitter:image", content: imageUrl });
    if (url) {
      this.meta.updateTag({ property: "og:url", content: url });
    } else {
      this.meta.updateTag({ property: "og:url", content: this.dom.URL });
    }
    // if (deeplinkUrl){
    //     this.meta.updateTag({ property: 'al:android:url', content: deeplinkUrl });
    //     this.meta.updateTag({ property: 'al:ios:url', content: deeplinkUrl });
    //     this.meta.updateTag({ property: 'al:web:url', content: deeplinkUrl });
    // }
    if (keywords) {
      this.meta.updateTag({ name: "keywords", content: keywords });
    } else {
      this.meta.removeTag("name='keywords'");
    }
    this.createCanonicalURL(url);
  }

  updateOGUrl(url: string) {
    this.meta.updateTag({ property: "og:url", content: url });
  }

  updateTitle(title?: string) {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === "primary"),
        mergeMap(route => route.data)
      )
      .subscribe(event => {
        if (event["title"]) {
          this.updateMetaInfo(
            event["title"],
            event["description"],
            event["imageUrl"],
            event["url"],
            event["keywords"]
          );
        }
      });
  }

  createCanonicalURL(url) {
    this.link.setAttribute("href", url);
  }
}
