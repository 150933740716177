import { Component, OnInit, OnDestroy } from "@angular/core";
import { SharedService } from "../../services/shared.service";

@Component({
  selector: "app-legal-document",
  templateUrl: "./legal-document.component.html",
  styleUrls: ["./legal-document.component.scss"]
})
export class LegalDocumentComponent implements OnInit {
  constructor(private sharedService: SharedService) {}
  intro = `בהתאם לסעיף 25(א)(4) לחוק תובענות ייצוגיות, התשס"ו-2006 ("<strong>חוק תובענות ייצוגיות</strong>"), מובא בזה לידיעתך כי ביום 2.6.2022 ניתן פסק דין המאשר הסדר פשרה שהושג בת"צ (שלום ת"א) 16620-09-19 <strong>בן פורת ואח' נ' רדיו ללא הפסקה (ניהול 2008) בע"מ</strong> בין הגב' נוי בן פורת וד"ר דוד ביטון ("<strong>התובעים המייצגים</strong>") לבין רדיו ללא הפסקה (ניהול 2008) בע"מ ("<strong>פסק הדין</strong>", "<strong>התובענה הייצוגית</strong>", "<strong>הנתבעת</strong>"). `
  textArray = [
    `ביום 20.12.2020 בית המשפט אישר לתובעים המייצגים לנהל כנגד הנתבעת תובענה ייצוגית שעילותיה הן: שליחת דברי פרסומת למשתמשים שנרשמו באמצעות חשבון הפייסבוק שלהם לשירותים שהנתבעת מספקת באמצעות אתר אינטרנט ויישומון שהיא מפעילה ("<strong>שירותי הדיגיטל</strong>"), מבלי לקבל את הסכמתם המפורשת לקבלת דברי פרסומת כנדרש בסעיף 30א(ב) לחוק התקשורת (בזק ושידורים), התשמ"ב-1982 ("<strong>חוק התקשורת</strong>"); ושליחת דברי פרסומת שאינם עומדים בדרישה הצורנית הקבועה בסעיף 30א(ה)(1)(א) לחוק התקשורת.`,
    `הנתבעת הגישה על ההחלטה האמורה בקשת רשות ערעור (אשר לא הוכרעה).`,
    `בהמלצת בית המשפט, הצדדים ניהלו משא ומתן והגיעו להסכמות על סיום ההליך בהסדר פשרה, וזאת מבלי לגרוע מעמדותיהם ומבלי שצד כלשהו הודה בטענה מטענות משנהו ו/או בחבות כלשהי בכל הקשור, במישרין או בעקיפין לתובענה הייצוגית.`,
    `ביום 7.11.2021 הצדדים הגישו בקשה לאישור הסדר פשרה. להסדר הפשרה לא הוגשו התנגדויות כלשהן, וביום 2.6.2022 ניתן פסק דין המאשר את הסדר הפשרה. עיקרי ההסכמות המגולמות בהסדר הפשרה ופסק הדין שאישר את הסדר הפשרה, הם כמפורט בתמצית להלן.`,
    `<strong><u>הגדרת הקבוצה שהסדר הפשרה חל עליה</u></strong>: מי שנרשם לשירותי הדיגיטל שהנתבעת מפעילה באמצעות חשבון הפייסבוק שלו במהלך שבע השנים שקדמו להגשת התובענה הייצוגית, קיבל ממנה דברי פרסומת בדואר אלקטרוני תוך הפרה של סעיפים 30א(ב) ו-30א(ה)(1) לחוק התקשורת, וספג כתוצאה מכך נזק המזכה בפיצוי, למעט מי שהודיע על יציאה מהקבוצה כדין ("<strong>הקבוצה הייצוגית</strong>"). `,
    `<strong><u>התובעים המייצגים</u></strong>: נוי בן פורת, ד"ר דוד ביטון; <strong><u>ב"כ התובעים המייצגים</u></strong>: עו"ד לירון פרמינגר.`,
    `<strong><u>עילות התובענה שלגביהן יקום מעשה בי-דין</u></strong>: זכאות חברי הקבוצה לפיצוי מהנתבעת בגין הודעות דוא"ל שנשלחו אליה תוך הפרה של סעיפים 30א(ב) ו-30א(ה)(1) לחוק התקשורת.`,
    `<strong><u>התחייבויות הנתבעת לפי הסדר הפשרה</u></strong>: תוך 30 ימים מהמועד שבו פסק הדין יהפוך לחלוט, הנתבעת תעניק לכל מי שנרשם לשירותי הדיגיטל באמצעות חשבון הפייסבוק שלו עד ליום הגשת בקשת האישור (למעט מי שפרש מהקבוצה הייצוגית), הטבה בדמות 60 רשימות השמעה (פלייליסטים) ללא פרסומות כלשהן, שיוענקו באופן מדורג ("<strong>ההטבה</strong>"). ההטבה תוענק באמצעות מתן גישה לאזור פרימיום באתר האינטרנט וביישומון של הנתבעת, שהגישה אליו תתאפשר רק למי שזכאי להטבה על פי הסדר הפשרה, וזאת למשך 24 חודשים לפחות ממועד העלאת אזור הפרימיום לאתר וליישומון ("<strong>תקופת מתן ההטבה</strong>"). במועד העלאת אזור הפרימיום הוא יכלול 30 רשימות השמעה אשר יהיו זמינות למשך כל תקופת מתן ההטבה. לאחר 6 חודשים, הנתבעת תוסיף 30 רשימות השמעה נוספות אשר יהיו זמינות עד לתום תקופת מתן ההטבה.`,
    `<strong><u>מעשה בית דין, ויתור וסילוק</u></strong>: בכפוף למילוי התחייבויות הנתבעת, פסק הדין מהווה מעשה בית דין לגבי כל אחד מחברי הקבוצה הייצוגית, וזאת למעט חברי קבוצה שביקשו לפרוש מהקבוצה ("<strong>חברי הקבוצה המאושרת</strong>"). פסק הדין כאמור לעיל יהווה מיצוי סופי ומוחלט של כלל טענות התובעים המייצגים וחברי הקבוצה המאושרת, ועם נתינתו יתגבש אוטומטית ביחס לכל אחד ואחד מחברי הקבוצה המאושרת ויתור וסילוק ביחס לכל העניינים נושא ההליך (כהגדרתם בהסדר הפשרה).`,
    `<strong><u>תמצית פסק הדין</u></strong>: בית המשפט מצא כי הסדר הפשרה ראוי, הוגן ומעניק הטבה סבירה לחברי הקבוצה, בשים לב לכך שהנזק הממשי לחברי הקבוצה הוא קטן למדי וברף הנמוך (והוערך על ידי בית המשפט בטווח שבין 30,000 ש"ח ל- 150,000 ש"ח לכל חברי הקבוצה). בית המשפט לא מצא מקום למנות בודק מן הטעמים המפורטים בפסק הדין.`,
    `<strong><u>גמול ושכר טרחה לתובעים המייצגים ובא-כוחם</u></strong>: בית המשפט פסק לתובעים המייצגים גמול בסך 6,000 ₪ ולבא-כוחם שכר טרחה בסך 28,080 ₪ (כולל מע"מ). נקבע כי מחצית מהסכום תשולם תוך 30 ימים ממועד פסק הדין, והמחצית השנייה לאחר שהתובעים המייצגים ובא-כוחם יוודאו שהנתבעת קיימה את הסדר הפשרה ויגישו לבית המשפט בקשה המעידה על כך.`,
    `<strong><u>עיון בהסדר הפשרה ובפסק הדין</u></strong>: הנוסח המלא של הסדר הפשרה ופסק הדין עומד לעיון חברי הקבוצה הייצוגית במזכירות בית המשפט השלום בתל אביב, ברחוב וייצמן 1, תל אביב, בימים א'-ה', בין השעות 08:30-13:30. הסדר הפשרה מפורסם גם בפנקס התובענות הייצוגיות. ניתן לעיין בהסדר הפשרה ובפסק הדין גם במשרדי ב"כ התובעים בתיאום מראש.`,
    `יובהר כי הנוסח המלא של הסדר הפשרה ופסק הדין הם המחייבים. בכל מקרה של סתירה בין הוראות הסדר הפשרה ופסק הדין לבין האמור בהודעה זו, יקבעו ההוראות שבהסדר הפשרה ובפסק הדין.`,
    `נוסח הודעה זו אושר על ידי בית המשפט השלום בתל אביב. ההודעה מתפרסמת בהתאם להחלטתו.`,
  ]
  ngOnInit() {
  }

}
