import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { MatDialog } from "@angular/material";
import { InviteFriendsDialogComponent } from "../../dialogs/invite-friends-dialog/invite-friends-dialog.component";
import { SharedService } from "../../services/shared.service";
import { RemoveFriendDialogComponent } from "../../dialogs/remove-friend-dialog/remove-friend-dialog.component";
import { RemovePackageDialogComponent } from "../../dialogs/remove-package-dialog/remove-package-dialog.component";
import { NetworkService } from "../../services/network.service";

@Component({
  selector: "app-my-package",
  templateUrl: "./my-package.component.html",
  styleUrls: ["./my-package.component.scss"]
})
export class MyPackageComponent implements OnInit {
  @Output() afterLeaveFamily = new EventEmitter();

  familyUsers = [];

  constructor(
    public dialog: MatDialog,
    public sharedService: SharedService,
    private networkService: NetworkService
  ) {}

  ngOnInit() {}

  removeUser(index) {
    const dialogRef = this.dialog.open(RemoveFriendDialogComponent, {
      data: {
        userName: this.sharedService.user.payment_data.settings.members[index]
          .user
          ? this.sharedService.user.payment_data.settings.members[index].user
              .personal_data.nickname
          : this.sharedService.user.payment_data.settings.members[index].email,
        email: this.sharedService.user.payment_data.settings.members[index]
          .email,
        approveButton: "כן, הגיע הזמן",
        cancelButton: "מה פתאום"
      }
    });

    dialogRef.afterClosed().subscribe(
      result => {},
      err => {}
    );
  }

  leaveFamily() {
    this.sharedService.showLeaveFamily(() => {
      this.afterLeaveFamily.emit();
    });
  }

  inviteFriends() {
    if (this.sharedService.user.payment_data.settings.members.length == 4) {
      this.sharedService.showAlert(
        "חבילת Pro Family מאפשרת הוספה של עד כ-4 חברים",
        true
      );
      return;
    }
    const dialogRef = this.dialog.open(InviteFriendsDialogComponent, {
      data: {
        numberOFSeats:
          4 - this.sharedService.user.payment_data.settings.members.length
      }
    });

    dialogRef.afterClosed().subscribe(
      result => {},
      err => {}
    );
  }

  getPackageName(packageType) {
    switch (packageType) {
      case 1:
        return "";
      case 2:
        return "Family";
      case 3:
        return "Lite";
    }
  }
}
