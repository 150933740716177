import {
  Component,
  OnInit,
  Input,
  HostListener,
  OnDestroy,
} from "@angular/core";
import { NetworkService } from "../../services/network.service";
import { SharedService } from "../../services/shared.service";
import { ProgramsQuery } from "../../services/programsQuery";
import { Router } from "@angular/router";
import { GoogleAnalyticsService } from "../../services/googleAnalytics.service";
declare var bodymovin: any;
declare var $: any;

@Component({
  selector: "app-show-gallery",
  templateUrl: "./show-gallery.component.html",
  styleUrls: ["./show-gallery.component.scss"],
})
export class ShowGalleryComponent implements OnInit, OnDestroy {
  @Input() recentShows: any;
  @Input() shows: any;
  @Input() query: ProgramsQuery;
  @Input() show: boolean;
  @Input() hide: boolean;
  @Input() noResultsText: String;
  @Input() hideLoader: boolean;
  @Input() programUrl: string;
  @Input() program_en_name: string;
  @Input() selectedTab: number;
  @Input() withoutFacebookWidget: string;
  @Input() instagramLink: string;

  showLoadMoreButton = false;
  isLoading: boolean;
  isLoadingMoreShows: boolean;
  programsQuery: ProgramsQuery;
  loadingAnimation: any;
  fullShows = [];
  loadMoreWithNoResults: boolean;

  constructor(
    private networkService: NetworkService,
    private sharedService: SharedService,
    private router: Router,
    private analyticsService: GoogleAnalyticsService
  ) {}

  ngOnInit() {
    this.loadShows(this.query);
  }

  loadShows(query: ProgramsQuery, isSearch: boolean = false) {
    if (isSearch && this.sharedService.isPlatformBrowser) {
      window.scrollTo(0, 0);
    }
    this.loadMoreWithNoResults = false;
    this.recentShows = [];
    this.shows = [];

    this.isLoading = true;
    this.programsQuery = query;
    this.networkService.getPrograms(this.programsQuery).subscribe(
      (resp) => {
        if (resp.programs.length) {
          this.recentShows = [resp.programs[0]];
          this.shows = resp.programs.slice(2);
          if (resp.programs.length > 1) {
            this.recentShows.push(resp.programs[1]);
          }
          if (this.sharedService.isPlatformBrowser) {
          }
        }

        resp.programs.forEach((program) => {
          if (program.image_url) {
            this.checkImage(program);
          }
        });
        if (resp.programs.length == 11) {
          this.showLoadMoreButton = true;
        } else {
          this.showLoadMoreButton = false;
        }
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
        // this.sharedService.showAlert('היתה בעיה בטעינת התוכניות.', true)
      }
    );
  }

  enterShowPage(program, index, event) {
    this.sendAnalyticsEvent(program.original_id);

    this.sharedService.tempProgram = program;
    this.router.navigateByUrl(
      "/program/" + this.programUrl + "/" + program.original_id
    );
    event.preventDefault();
  }

  playShowWithoutEnterPage(program, index, event) {
    this.sendAnalyticsEvent(program.original_id);

    this.sharedService.tempProgram = program;
    this.sharedService.loadProgramToPlayer(
      program,
      program.description,
      this.programsQuery,
      index,
      this.programUrl
    );
    event.preventDefault();
    event.stopPropagation();
  }

  sendAnalyticsEvent(programID) {
    if (this.selectedTab == 1) {
      this.analyticsService.sendEvent(
        this.program_en_name,
        "selected_sections_program",
        "program_click",
        programID
      );
    }
    if (this.selectedTab == 2) {
      this.analyticsService.sendEvent(
        this.program_en_name,
        "full_show_program",
        "program_click",
        programID
      );
    }
  }

  downloadClicked(programID, e) {
    let downloadName = this.program_en_name;
    if (this.program_en_name === "tal_and_aviad") {
      downloadName = "tal_aviad";
    }
    this.analyticsService.sendEvent(
      this.program_en_name,
      `download_${downloadName}`,
      "download_program_click",
      programID
    );
    e.stopPropagation();
  }

  instagramClicked(instagramLink) {
    window.open(instagramLink, "_self");
  }

  ngOnDestroy() {
    window.removeEventListener("scroll", this.scroll, true);
  }

  scroll = (e): void => {
    if (
      e.currentTarget.pageYOffset + e.currentTarget.innerHeight >=
      e.target.scrollingElement.scrollHeight - 700
    ) {
      if (!this.isLoading && !this.loadMoreWithNoResults) this.loadMoreShows();
    }
  };

  loadMoreShows() {
    this.isLoadingMoreShows = true;
    this.startLoadingAnimation();
    this.programsQuery.page++;
    this.networkService.getPrograms(this.programsQuery).subscribe(
      (resp) => {
        if (resp.programs.length) {
          resp.programs.forEach((program) => {
            if (program.image_url) {
              this.checkImage(program);
            }
          });
          this.shows = this.shows.concat(resp.programs);
        } else {
          this.programsQuery.page--;
          this.loadMoreWithNoResults = true;
          this.showLoadMoreButton = false;
        }
        this.isLoadingMoreShows = false;
      },
      (err) => {
        this.isLoadingMoreShows = false;
      }
    );
  }

  getBgUrl(show) {
    if (show.image_url) {
      return show.image_url;
    } else {
      return "/assets/images/show-placeholder.png";
    }
  }

  checkImage(show) {
    if (this.sharedService.isPlatformBrowser) {
      var img = new Image();
      img.onerror = function () {
        show.image_url = "/assets/images/show-placeholder.png";
      };
      img.src = show.image_url;
    }
  }

  morethan2Lines(el) {
    var divHeight = el.offsetParent ? el.offsetParent.clientHeight : 0;
    return divHeight > 103;
  }

  morethan2SmallLines(el) {
    var divHeight = el.offsetParent ? el.offsetParent.clientHeight : 0;
    return divHeight > 73;
  }

  startLoadingAnimation() {
    if (this.loadingAnimation) {
      this.loadingAnimation.destroy();
    }
    setTimeout(() => {
      this.loadingAnimation = bodymovin.loadAnimation({
        container: document.getElementById("more-shows-loader"),
        renderer: "svg",
        loop: true,
        autoplay: true,
        path: "assets/animations/loader.json",
      });
    });
  }
}
