import {
  Component,
  OnInit,
  Injectable,
  NgZone,
  OnDestroy
} from "@angular/core";
import { SharedService } from "../../services/shared.service";
import { NetworkService } from "../../services/network.service";
import { GoogleAnalyticsService } from "../../services/googleAnalytics.service";
declare var bodymovin: any;

@Injectable()
@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.scss"]
})
export class ContactComponent implements OnInit, OnDestroy {
  email: string;
  phone = "";
  message = "";
  showEmailError;
  showPhoneError;
  showMessageError;
  isSending = false;
  loadingAnimation: any;
  constructor(
    public sharedService: SharedService,
    private networkService: NetworkService,
    private ngZone: NgZone,
    private analyticsService: GoogleAnalyticsService
  ) {}

  ngOnInit() {
    setTimeout(() => {
      this.sharedService.showHeaderHam = false;
    });
    setTimeout(() => {
      this.startLoadingAnimationForSave();
    }, 1000);
    this.sharedService.showUpperSection = false;
    if (this.sharedService.user) {
      this.setEmailField(this.sharedService.user.personal_data.email);
    } else {
      setTimeout(() => {
        if (this.sharedService.user) {
          this.setEmailField(this.sharedService.user.personal_data.email);
        }
      }, 2000);
    }
  }

  startLoadingAnimationForSave() {
    if (this.loadingAnimation) {
      this.loadingAnimation.destroy();
    }
    this.loadingAnimation = bodymovin.loadAnimation({
      container: document.getElementById("loader"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: "assets/animations/loader.json"
    });
  }

  public setEmailField(email) {
    this.ngZone.run(() => {
      this.email = email;
    });
  }

  ngOnDestroy(): void {
    setTimeout(() => {
      this.sharedService.showHeaderHam = true;
    });
    this.sharedService.showUpperSection = true;
  }

  send() {
    if (!this.checkEmail()) {
      this.showEmailError = true;
    }
    if (!this.checkphone(this.phone, true)) {
      this.showPhoneError = true;
    }
    if (!this.message.length) {
      this.showMessageError = true;
    }
    if (
      !this.showEmailError &&
      !this.showPhoneError &&
      !this.showMessageError &&
      !this.isSending
    ) {
      this.isSending = true;
      this.analyticsService.sendEvent(
        "contact_us",
        "contac_us_send_button",
        "שלח"
      );
      this.networkService
        .sendContactUs({
          name:
            this.sharedService.user &&
            this.sharedService.user.personal_data.nickname
              ? this.sharedService.user.personal_data.nickname
              : "אנונימי",
          description: this.message,
          phone: this.phone,
          email: this.email
        })
        .subscribe(
          DBUser => {
            this.isSending = false;
            this.sharedService.showAlert("ההודעה נשלחה!", false, true);
            this.message = "";
            this.phone = "";
            if (this.sharedService.user) {
              this.setEmailField(this.sharedService.user.personal_data.email);
            }
          },
          err => {
            this.isSending = false;
            this.sharedService.showAlert(
              "ההודעה לא נשלחה. רוצה לנסות שוב?",
              true
            );
          }
        );
    }
  }

  checkEmail() {
    // this.emailFocusedOut = false
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(this.email);
  }

  checkphone(phone: string, isOptional: boolean): boolean {
    if (isOptional && String(phone).length == 0) {
      return true;
    }
    var regex = /^(?:(?:(\+?972|\(\+?972\)|\+?\(972\))(?:\s|\.|-)?([1-9]\d?))|(0[23489]{1})|(0[57]{1}[0-9]))(?:\s|\.|-)?([^0\D]{1}\d{2}(?:\s|\.|-)?\d{4})$/gm;
    if (!phone.search(regex)) {
      return true;
    }
    return false;
  }
}
