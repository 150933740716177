import { Component, OnInit, Input } from "@angular/core";
import { SharedService } from "../../services/shared.service";
import { Router } from "@angular/router";
import { GoogleAnalyticsService } from "../../services/googleAnalytics.service";

@Component({
  selector: "app-expandable-drawer",
  templateUrl: "./expandable-drawer.component.html",
  styleUrls: ["./expandable-drawer.component.scss"],
})
export class ExpandableDrawerComponent implements OnInit {
  @Input() menuItems: any;
  selectedAvatar: string;

  constructor(
    public sharedService: SharedService,
    public router: Router,
    private analyticsService: GoogleAnalyticsService
  ) {}

  ngOnInit() {}

  toggleDrawer() {
    this.sharedService.showMenuTablet = !this.sharedService.showMenuTablet;
  }

  getLinkByName(name) {
    switch (name) {
      case "שלי":
        return "/";
      case "מוזיקה":
        return "/music";
      // case 'להיטים':
      //   return '/trends';
      case "טל ואביעד - ארכיון":
        return "/program/טל_ואביעד/tab/1";
      case "בוקר חדש":
        return "/program/בוקר_חדש/tab/1";
      case "שידור חי":
        return "/live-radio";
      case "כרטיסים להופעות":
        return "/tickets/הופעות_בארץ";
      default:
        break;
    }
  }

  getIconByName(name) {
    switch (name) {
      case "שלי":
        return "user-icon";
      case "מוזיקה":
        return "headphones-icon";
      case "להיטים":
        return "star-icon";
      case "טל ואביעד - ארכיון":
        return "archive-icon";
      case "בוקר חדש":
        return "star-icon";
      case "שידור חי":
        return "radio-menu";
      case "כרטיסים להופעות":
        return "ticket-icon";
      default:
        break;
    }
  }

  enableLink(link) {
    if (
      this.sharedService.user &&
      this.sharedService.user.payment_data.subscription != 4
    ) {
      return link.is_active_pro;
    } else {
      return link.is_active_free;
    }
  }

  goToSettings() {
    this.analyticsService.sendEvent("settings", "settings_button", "פרופיל");
    this.router.navigateByUrl("/settings");
  }
}
