import { Component, OnInit, Renderer2 } from '@angular/core';
import { SharedService } from '../services/shared.service';

@Component({
  selector: 'app-speed-selector',
  templateUrl: './speed-selector.component.html',
  styleUrls: ['./speed-selector.component.scss']
})
export class SpeedSelectorComponent implements OnInit {
  speeds = [1, 1.2, 1.5, 1.8, 2];
  selectedSpeed: number = 1;
  selectedIndex: number = 0;
  showDropdown: boolean = false;
  isDragging: boolean = false;


  constructor(
    private renderer: Renderer2,
    public sharedService: SharedService,
  ) {}

  ngOnInit() {
    const savedSpeed = parseFloat(localStorage.getItem('playbackSpeed')) || 1;
    const savedIndex = this.speeds.indexOf(savedSpeed);
    if (savedIndex !== -1) {
      this.selectedIndex = savedIndex;
      this.selectedSpeed = savedSpeed;
    }

    setTimeout(() => this.applyPlaybackSpeed(), 500);
  }

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
  }

  onSliderChange() {
    this.selectedSpeed = this.speeds[this.selectedIndex];
    this.applyPlaybackSpeed();
    localStorage.setItem('playbackSpeed', this.selectedSpeed.toString());

    this.showDropdown = false;
  }

  applyPlaybackSpeed() {
    const audio = document.querySelector('audio');
    if (audio) {
      audio.playbackRate = this.selectedSpeed;
    }
  }

  selectSpeed(speed: number) {
    this.selectedSpeed = speed;
    this.selectedIndex = this.speeds.indexOf(speed);
    this.applyPlaybackSpeed();
    localStorage.setItem('playbackSpeed', speed.toString());

    this.showDropdown = false;
  }

  onSliderTouchStart(event: TouchEvent) {
    event.stopPropagation();
    this.isDragging = true;
  }

  onSliderTouchMove(event: TouchEvent) {
    if (!this.isDragging) {
      event.preventDefault();
    }
  }

  onSliderTouchEnd() {
    this.renderer.setStyle(document.body, 'overflow', '');
    this.isDragging = false;
  }
}
