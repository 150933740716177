import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { SharedService } from "../../services/shared.service";
import { NetworkService } from "../../services/network.service";
import { Router } from "@angular/router";
import { RemovePackageDialogComponent } from "../../dialogs/remove-package-dialog/remove-package-dialog.component";
import { MatDialog } from "@angular/material";
import { SetPackageDialogComponent } from "../../dialogs/set-package-dialog/set-package-dialog.component";
import { NguCarousel, NguCarouselConfig } from "@ngu/carousel";
declare var bodymovin: any;

@Component({
  selector: "app-pro-packages",
  templateUrl: "./pro-packages.component.html",
  styleUrls: ["./pro-packages.component.scss"]
})
export class ProPackagesComponent implements OnInit, OnDestroy {
  packages = [];
  isLoading = false;
  loadingAnimation: any;
  newPackageType: any;

  constructor(
    private sharedService: SharedService,
    private networkService: NetworkService,
    private router: Router,
    public dialog: MatDialog
  ) {
    sharedService.showUpperSection = false;
    sharedService.transparentheader = true;
    sharedService.showHeader = true;
  }
  @ViewChild("myCarousel", { static: false }) myCarousel: NguCarousel<any>;

  public carouselConfig: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
    RTL: true,
    slide: 1,
    speed: 250,
    point: {
      visible: true
    },
    load: 2,
    loop: true,
    velocity: 0,
    touch: true,
    easing: "cubic-bezier(0, 0, 0.2, 1)"
  };

  ngOnInit() {
    this.networkService.getProPackages().subscribe(
      resp => {
        resp.items.forEach(pkg => {
          if (pkg.is_visible) {
            this.packages.push(pkg);
          }
        });
      },
      err => {}
    );
  }

  ngOnDestroy(): void {
    this.sharedService.showUpperSection = true;
    this.sharedService.transparentheader = false;
    this.sharedService.showHeader = true;
  }

  startLoadingAnimation(index) {
    if (this.loadingAnimation) {
      this.loadingAnimation.destroy();
    }
    this.loadingAnimation = bodymovin.loadAnimation({
      container: document.getElementById("loader_" + index),
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: "assets/animations/loader.json"
    });
  }

  getIcon(type) {
    switch (type) {
      case 1:
        return "proicon.png";
      case 2:
        return "pro-family.png";
      case 3:
        return "pro-lite.png";
      default:
        return "proicon.png";
    }
  }

  getClassNameForPackage(type) {
    switch (type) {
      case 1:
        return "first";
      case 2:
        return "second";
      case 3:
        return "third";
    }
  }

  canChangePackage(packageType) {
    return (
      this.sharedService.user &&
      this.sharedService.user.payment_data.subscription != 4 &&
      (this.sharedService.user.payment_data.subscription != 2 ||
        this.sharedService.isAdminUser()) &&
      this.sharedService.user.payment_data.subscription != packageType
    );
  }

  getIconForPackage(type) {
    switch (type) {
      case 1:
        return "pro-icon.svg";
      case 2:
        return "family-pkg-icon.svg";
      case 3:
        return "lite-pkg-icon.svg";
    }
  }

  calcPriceForSix(price: number) {
    return (price / 5).toFixed(2);
  }

  priceToPresent(price) {
    if (price % 1 == 0) {
      return price;
    } else {
      return price.toFixed(2);
    }
  }

  selectPackage(packageType, isChange = false) {
    let data;
    if (isChange) {
      data = {
        title: "רוצה להחליף את החבילה הנוכחית שלך<br>בחבילה הזאת?",
        subtitle: "",
        approveButton: "כן, להחליף!",
        packageType: packageType
      };
    } else {
      this.router.navigateByUrl("/payment/" + packageType + "/subscription");
      return;
    }

    const dialogRef = this.dialog.open(SetPackageDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe(
      result => {
        if (result) {
        }
      },
      err => {}
    );
  }

  cancelPackage() {
    const dialogRef = this.dialog.open(RemovePackageDialogComponent, {
      data: {
        title: "להפסיק את החבילה הנוכחית?",
        approveButton: "להפסיק",
        change: true
      }
    });

    dialogRef.afterClosed().subscribe(
      result => {
        if (result) {
          this.networkService.cancelSubscriptionPlan().subscribe(
            resp => {
              this.sharedService.user = resp;
            },
            err => {
              this.isLoading = false;
              this.sharedService.showAlert(
                "היתה בעיה, אנא נסה שנית",
                true,
                false
              );
            }
          );
        }
      },
      err => {}
    );
  }
}
