import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NetworkService } from "../../services/network.service";
import { PlaylistQuery } from "../../services/playlistQuery";
import { SharedService } from "../../services/shared.service";
import { Router } from "@angular/router";
import { MessageService } from "../../services/messageService";
import { GoogleAnalyticsService } from "../../services/googleAnalytics.service";
declare var $: any;
declare var jarallax: any;
@Component({
  selector: "app-playlist-gallery",
  templateUrl: "./playlist-gallery.component.html",
  styleUrls: ["./playlist-gallery.component.scss"]
})
export class PlaylistGalleryComponent implements OnInit {
  @Input() playlists;
  @Input() stringTitle;
  @Input() mainTitle;
  @Input() welcomeTitle;
  @Input() showFilters;
  @Input() noContentText;
  @Input() galleryID;
  @Input() hideNavigation;
  @Input() hideUnderline;
  @Input() hideTitle;
  @Input() playlistPlaceholder;
  @Input() disable = false;
  @Input() isLoading: boolean;
  @Input() grid: boolean;
  @Input() category_id: boolean = undefined;
  @Input() itemSecondaryIndex: boolean = undefined;
  @Input() showAllButton: boolean = undefined;
  @Input() takeClickEvent;
  @Input() moodID;
  @Output() handleClickEvent = new EventEmitter();

  activeFilter = "playlists";
  isStartOfScroll = true;
  isEndOfScroll = false;
  scrolling: boolean = false;

  constructor(
    public sharedService: SharedService,
    public router: Router,
    private messageService: MessageService,
    private analyticsService: GoogleAnalyticsService
  ) {}

  ngOnInit() {
    // console.log("hideNavigation " + this.hideNavigation);
    // console.log("welcomeTitle " + this.welcomeTitle);
    // console.log("hideNavigation " + this.hideNavigation);
    //   setTimeout(() => {
    //     $('.jarallax').jarallax({
    //       speed: 0.9
    //     });
    // }, 10000);
  }

  scroll(direction) {
    //   setTimeout(() => {
    //     $('.jarallax').jarallax('destroy');
    //     $('.jarallax').jarallax({
    //       speed: 0.9
    //     });
    // },1000);

    let selector = ".gallery-container." + this.galleryID;
    if (!this.scrolling) {
      var width = $(selector).width() + 40;
      this.scrolling = true;
      if (direction == "left") {
        this.isStartOfScroll = false;
        $(selector).animate({ scrollLeft: "-=" + width }, 800, () => {
          this.scrolling = false;
          this.sharedService.checkIfEndOrStartOfScroll(
            $(selector),
            "left",
            res => {
              this.isEndOfScroll = res;
            }
          );
        });
      } else {
        this.isEndOfScroll = false;
        $(selector).animate({ scrollLeft: "+=" + width }, 800, () => {
          this.scrolling = false;
          this.sharedService.checkIfEndOrStartOfScroll(
            $(selector),
            "right",
            res => {
              this.isStartOfScroll = res;
            }
          );
        });
      }
    }
  }

  initScroll() {
    var left = $(".gallery-container." + this.galleryID).width() * 10;
    this.isStartOfScroll = true;
    this.isEndOfScroll = false;
    $(".gallery-container." + this.galleryID).scrollLeft(left);
  }

  selectFilter(filter) {
    if (!this.sharedService.user || this.sharedService.isFreeUser()) {
      // free user
      this.sharedService.showCreateUserPopup();
    } else {
      this.activeFilter = filter;
    }
  }

  sendAnalyticsEvent(playlist) {
    switch (this.galleryID) {
      case "recommended":
        this.analyticsService.sendEvent(
          "my_music",
          "carrousel_hourly",
          "playlist_click",
          playlist.original_id
        );
        break;
      case "mood":
        this.analyticsService.sendEvent(
          "my_music",
          "carrousel_sub_moods_music",
          "playlist_click",
          playlist.original_id
        );
        break;
      case "recently":
        this.analyticsService.sendEvent(
          "my_music",
          "carrousel_last_heard",
          "playlist_click",
          playlist.original_id
        );
        break;
      case "likes":
        this.analyticsService.sendEvent(
          "my_music",
          "carrousel_favorites",
          "playlist_click",
          playlist.original_id
        );
        break;
      case "hits":
        this.analyticsService.sendEvent(
          "music",
          "carrousel_hits",
          "playlist_click",
          playlist.original_id
        );
        break;
      case "bestDecade":
        this.analyticsService.sendEvent(
          "music",
          "carrousel_bestDecade",
          "playlist_click",
          playlist.original_id
        );
        break;
      case "genres":
        this.analyticsService.sendEvent(
          "music",
          "carrousel_genre_playlist",
          "playlist_click",
          playlist.original_id
        );
        break;
      case "specials":
        this.analyticsService.sendEvent(
          "music",
          "carrousel_specials",
          "playlist_click",
          playlist.original_id
        );
        break;
      case "boazcohen":
        this.analyticsService.sendEvent(
          "music",
          "carrousel_boaz_cohen",
          "playlist_click",
          playlist.original_id
        );
        break;
      case "related":
        this.analyticsService.sendEvent(
          "playlists",
          "similar_playlists",
          "playlist_click",
          playlist.original_id
        );
        break;
    }
  }

  sendShowAllAnalyticsEvent() {
    switch (this.galleryID) {
      case "mood":
        this.analyticsService.sendEvent(
          "my_music",
          "show_all_sub_moods_music",
          "הצג_הכל"
        );
        break;
      case "recently":
        this.analyticsService.sendEvent(
          "my_music",
          "show_all_last_heard",
          "הצג_הכל"
        );
        break;
      case "likes":
        this.analyticsService.sendEvent(
          "my_music",
          "show_all_favorites",
          "הצג_הכל"
        );
        break;
      case "hits":
        this.analyticsService.sendEvent("music", "show_all_hits", "הצג_הכל");
        break;
      case "bestDecade":
        this.analyticsService.sendEvent(
          "music",
          "show_all_bestDecade",
          "הצג_הכל"
        );
        break;
      case "genres":
        this.analyticsService.sendEvent(
          "music",
          "carrousel_genre_show_all",
          "הצג_הכל"
        );
        break;
      case "specials":
        this.analyticsService.sendEvent(
          "music",
          "show_all_specials",
          "הצג_הכל"
        );
        break;
      case "boazcohen":
        this.analyticsService.sendEvent(
          "music",
          "show_all_boaz_cohen",
          "הצג_הכל"
        );
        break;
      case "related":
        this.analyticsService.sendEvent(
          "playlists",
          "show_all_similar_playlists",
          "הצג_הכל"
        );
        break;
    }
  }

  playlistClicked(playlist, playlistIndex, goToInnerPage, e = undefined) {


    let isShuffled = this.galleryID == "recommended" ? true : false;

    this.sendAnalyticsEvent(playlist);

    this.sharedService.tempPlaylist = playlist;

    playlist.song_list.sort((a, b) => (a.start > b.start ? 1 : -1)); //Remove when server fixes this
    if (this.takeClickEvent) {
      this.handleClickEvent.emit();
      playlist.playlistList = this.playlists;
      playlist.playlistIndex = playlistIndex;
      if (goToInnerPage) {
        // debugger
        // if(playlist.type == "program" && isShuffled){
        //   this.router.navigateByUrl(
        //     "/program/" + playlist.programName + "/" + playlist.original_id
        //   );
       // }else{ //type == playlist
          this.router.navigateByUrl(
            "/playlist/" +
              (playlist.playlist_id ? playlist.playlist_id : playlist.original_id)
          );
       // }

      } else {
        e.stopPropagation();
        if (
          (playlist.playlist_id
            ? playlist.playlist_id
            : playlist.original_id) ==
            this.sharedService.currentPlayingContentID &&
          this.sharedService.isPlaying
        ) {
          this.messageService.updatePlayerDetails({ pause: true });
        } else {
          this.sharedService.loadPlaylistToPlayer(playlist, isShuffled);
        }
      }

      return;
    }
    if (goToInnerPage) {
      // debugger
      // if(playlist.type == "program" && isShuffled){
      //   this.router.navigateByUrl(
      //     "/program/" + playlist.programName + "/" + playlist.original_id
      //   );
     // }else{ //type == playlist

        this.router.navigateByUrl(

          "/playlist/" +
            (playlist.playlist_id ? playlist.playlist_id : playlist.original_id)

        );

     // }
    } else {
      e.stopPropagation();
      playlist.playlistList = this.playlists;
      playlist.playlistIndex = playlistIndex;
      if (
        (playlist.playlist_id ? playlist.playlist_id : playlist.original_id) ==
          this.sharedService.currentPlayingContentID &&
        this.sharedService.isPlaying
      ) {
        this.messageService.updatePlayerDetails({ pause: true });
      } else {
        this.sharedService.loadPlaylistToPlayer(playlist, isShuffled);
      }
    }
  }

  goToShowAllPage() {
    this.sendShowAllAnalyticsEvent();
    const encodedTitle = this.stringTitle ? encodeURIComponent(this.stringTitle.split(" ").join("_")) : '';
    if (this.moodID) {
      //TO DO: send deffrint value to show for type and index
      this.router.navigateByUrl(
        "/playlists/" +
          this.galleryID +
          "/" +
          encodedTitle +
          "/" +
          this.moodID
      );
      return;
    }
    if (this.category_id != undefined) {
      if (this.stringTitle) {
        this.router.navigateByUrl(
          "/playlists/" +
            this.galleryID +
            "/" +
            encodedTitle +
            "/" +
            this.category_id
        );
      } else {
        this.router.navigateByUrl(
          "/playlists/" + this.galleryID + "/" + this.category_id
        );
      }
    } else {
      this.router.navigateByUrl(
        "/playlists/" +
          this.galleryID +
          "/" +
          encodedTitle
      );
    }
  }

  checkIfNew(date) {
    let itemDate = new Date(date);
    let nowDate = new Date();
    itemDate.setDate(itemDate.getDate() + 30);
    if (itemDate > nowDate) {
      return true;
    } else {
      return false;
    }
  }
}
