import { Component, OnInit } from "@angular/core";
import { SharedService } from "../../services/shared.service";
import { Router, ActivatedRoute } from "@angular/router";
import { MessageService } from "../../services/messageService";
import { NetworkService } from "../../services/network.service";
import { ProgramsQuery } from "../../services/programsQuery";
import { MetaService } from "../../services/MetaService";
import { environment } from "../../../environments/environment";
import { GoogleAnalyticsService } from "../../services/googleAnalytics.service";

let baseUrl = environment.baseUrl;
declare var bodymovin: any;

@Component({
  selector: "app-program",
  templateUrl: "./program.component.html",
  styleUrls: ["./program.component.scss"]
})
export class ProgramComponent implements OnInit {
  likeImageSrc = "like-icon.svg";
  embedLink = "";
  program_en_name = "";
  program: any = {};
  currentSecond = 0;
  currentSong: any;
  subscription: any;
  nextFullShows = [];
  relatedPrograms = [];
  loadingProgram = false;
  moreOptionsActive = false;
  programsQuery: any;
  fullProgramsQuery: any;
  loadingRelatedPrograms = true;
  programName = "";
  showRelatedPrograms = false;
  shortlink = "";

  constructor(
    private messageService: MessageService,
    public sharedService: SharedService,
    public router: Router,
    private networkService: NetworkService,
    private route: ActivatedRoute,
    private metaService: MetaService,
    private analyticsService: GoogleAnalyticsService
  ) {
    this.programName = this.router.url.split("/")[2];
    this.showRelatedProgramsByProgramName(this.programName);
    this.route.params.subscribe(res => {
      this.route.queryParams.subscribe(params => {
        if (params["referral"] == "") {
          this.analyticsService.sendEvent(
            this.program_en_name,
            "clicking_link_share_" + this.program_en_name,
            "referral",
            res["id"]
          );
        }
      });
      if (
        this.sharedService.tempProgram &&
        this.sharedService.tempProgram.original_id === res["id"]
      ) {
        this.program = this.sharedService.tempProgram;
        this.getProgramData();
      } else {
        if (this.sharedService.isPlatformBrowser) {
          window.scrollTo(0, 0);
        }
        this.loadingProgram = true;
        this.networkService.getProgramByID(res["id"]).subscribe(resp => {
          this.program = resp.item;
          this.getProgramData();
          this.loadingProgram = false;
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.sharedService.showUpperSection = true;
  }

  ngOnInit() {
    this.subscription = this.messageService
      .listenToPlayerActions()
      .subscribe(e => {
        if (e && e.indexOf(":") != -1) {
          this.currentSecond =
            parseInt(String(e.split(":")[0] * 60 * 60)) +
            parseInt(String(e.split(":")[1] * 60)) +
            parseInt(String(e.split(":")[2]));
        }
      });
    if (this.program) {
    }
  }

  updateMetaTags(program) {
    let meta = {
      title: program.name,
      description: program.description ? program.description : "&nbsp;",
      imageUrl: program.image_url,
      url: baseUrl + "/program/" + this.programName + "/" + program.original_id,
      keywords: program.key_words.join(),
      deeplinkUrl: this.shortlink
    };
    this.metaService.updateMetaInfo(
      meta.title,
      meta.description,
      meta.imageUrl,
      meta.url,
      meta.keywords,
      meta.deeplinkUrl
    );
  }

  getProgramData() {
    this.networkService.createDynamicShortenLink(
      baseUrl + "/program/" + this.programName + "/" + this.program.original_id,
      data => {
        this.shortlink = data.shortLink;
        this.updateMetaTags(this.program);
      },
      this.sharedService.isPlatformBrowser
    );

    this.relatedPrograms = [];
    this.loadingRelatedPrograms = true;
    let categories = this.getCategoriesForProgramName(this.programName, false);
    this.programsQuery = new ProgramsQuery(
      1,
      10,
      categories,
      this.program.insert_date,
      undefined,
      true
    );
    this.networkService.getPrograms(this.programsQuery).subscribe(
      resp => {
        let indexToRemove = -1;
        resp.programs.forEach((item, index) => {
          if (item.original_id == this.program.original_id) {
            indexToRemove = index;
          }
        });
        if (indexToRemove != -1) {
          resp.programs.splice(indexToRemove, 1);
        }
        this.relatedPrograms = resp.programs;
        this.relatedPrograms.forEach(program => {
          if (program.image_url) {
            this.checkImage(program);
          }
        });
        this.loadingRelatedPrograms = false;
      },
      err => {}
    );
    categories = this.getCategoriesForProgramName(this.programName, true);
    this.fullProgramsQuery = new ProgramsQuery(
      1,
      10,
      categories,
      undefined,
      undefined,
      false
    );

    this.networkService.getPrograms(this.fullProgramsQuery).subscribe(
      resp => {
        this.nextFullShows = resp.programs;
      },
      err => {}
    );
  }

  getCategoriesForProgramName(programName, isFullShow) {
    if (isFullShow) {
      switch (decodeURI(programName)) {
        case "אקו_לייף":
          return [14];
        case "טל_ואביעד":
          return [8];
          break;
        case "בוקר_חדש":
          return [32];
        case "תשעה_חודשים":
          return [4];
          break;
        default:
          break;
      }
    } else {
      switch (decodeURI(programName)) {
        case "אקו_לייף":
          return [22];
        case "טל_ואביעד":
          return [12];
          break;
        default:
          break;
      }
    }
  }

  showRelatedProgramsByProgramName(programName) {
    switch (decodeURI(programName)) {
      case "אקו_לייף":
        this.showRelatedPrograms = true;
        this.program_en_name = "eco_life";
        break;
      case "טל_ואביעד":
        this.showRelatedPrograms = true;
        this.program_en_name = "tal_and_aviad";
        break;
      case "תשעה_חודשים":
        this.showRelatedPrograms = false;
        this.program_en_name = "nine_months";
        break;
      case "המגרש_הביתי":
        this.showRelatedPrograms = false;
        this.program_en_name = "hamigrash_habeiti";
        break;
      case "בוקר_חדש":
        this.showRelatedPrograms = true;
        this.program_en_name = "new_morning";
        break;
      default:
        break;
    }
  }

  checkImage(show) {
    if (this.sharedService.isPlatformBrowser) {
      var img = new Image();
      // img.onload = good;
      img.onerror = function() {
        show.image_url = "/assets/images/show-placeholder.png";
      };
      img.src = show.image_url;
    }
  }

  playShow(program, index, event, isFullShow?) {
    this.analyticsService.sendEvent(
      this.program_en_name,
      isFullShow ? "full_show_program" : "selected_sections_program",
      "program_click",
      program.original_id
    );

    this.sharedService.tempProgram = program;
    this.program = this.sharedService.tempProgram;
    this.getProgramData();
    this.router.navigateByUrl(
      "/program/" + this.programName + "/" + program.original_id
    );
    this.sharedService.loadProgramToPlayer(
      program,
      program.description,
      isFullShow ? this.fullProgramsQuery : this.programsQuery,
      index,
      this.programName
    );
    if (this.sharedService.isPlatformBrowser) {
      window.scrollTo(0, 0);
      event.preventDefault();
    }
  }

  playPause() {
    if (
      this.sharedService.isPlaying &&
      this.sharedService.currentPlayingContentID == this.program.original_id
    ) {
      this.messageService.updatePlayerDetails({ pause: true });
    } else {
      this.sharedService.loadProgramToPlayer(
        this.program,
        this.program.description,
        this.programsQuery,
        0,
        this.programName
      );
    }
  }

  getBgUrl(program) {
    if (program.image_url) {
      return program.image_url;
    } else {
      if (this.loadingProgram) {
        return "";
      }
      return "/assets/images/show-placeholder.png";
    }
  }

  getMobileBgUrl(program) {
    if (program.mobile_image_name) {
      return program.mobile_image_name;
    }
    if (program.image_url) {
      return program.image_url;
    } else {
      if (this.loadingProgram) {
        return "";
      }
      return "/assets/images/show-placeholder.png";
    }
  }

  copyEmbedCode() {
    this.embedLink =
      '<iframe src="' +
      baseUrl +
      "/embedded-player/program/" +
      this.program.original_id +
      '" scrolling="no" width="593" height="386" frameborder="0"></iframe>';
    setTimeout(() => {
      var copyText = document.getElementById("embedText");
      copyText["select"]();
      document.execCommand("copy");
      this.sharedService.showAlert(
        "הקוד להטמעה הועתק ללוח",
        false,
        true,
        "",
        "אחלה",
        true
      );
    });
  }

  downloadProgram() {
    // if (this.sharedService.isFreeUser()){
    // this.sharedService.showCreateUserPopup()
    // }
    // document.url = this.program.download_url
  }

  getCurrentSubtitle() {
    return this.program.short_description;
  }

  updatePlayerDetails(song) {
    let data = {
      thumbnail: song.small_image
        ? song.small_image
        : "assets/images/song-placeholder.svg",
      songName: song.song_name,
      artistName: song.artist_name
    };
    this.messageService.updatePlayerDetails(data);
  }

  toHHMMSS(full_seconds) {
    var sec_num = parseInt(full_seconds, 10); // don't forget the second param
    var hours: any = Math.floor(sec_num / 3600);
    var minutes: any = Math.floor((sec_num - hours * 3600) / 60);
    var seconds: any = sec_num - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return hours + ":" + minutes + ":" + seconds;
  }

  like() {
    if (this.sharedService.isPlatformBrowser) {
      if (this.likeImageSrc == "like-icon.svg") {
        let like = bodymovin.loadAnimation({
          container: document.getElementById("like-animation"),
          loop: false,
          autoplay: true,
          path: "assets/animations/Likeddesktop.json"
        });
        setTimeout(() => {
          this.likeImageSrc = "liked-icon.svg";
        }, 700);
        like.addEventListener("complete", () => {
          like.destroy();
        });
      } else {
        this.likeImageSrc = "like-icon.svg";
      }
    }
  }

  sendAnalytics(type) {
    switch (type) {
      case "facebookmessanger":
        this.analyticsService.sendEvent(
          this.program_en_name,
          "share_" + this.program_en_name,
          "facebook_messanger_share",
          this.program.original_id
        );
        break;
      case "facebook":
        this.analyticsService.sendEvent(
          this.program_en_name,
          "share_" + this.program_en_name,
          "facebook_share",
          this.program.original_id
        );
        break;
      case "whatsapp":
        this.analyticsService.sendEvent(
          this.program_en_name,
          "share_" + this.program_en_name,
          "whatsapp_share",
          this.program.original_id
        );
        break;
      case "email":
        this.analyticsService.sendEvent(
          this.program_en_name,
          "share_" + this.program_en_name,
          "email_share",
          this.program.original_id
        );
        break;
      case "native":
        this.analyticsService.sendEvent(
          this.program_en_name,
          "share_" + this.program_en_name,
          "native_share",
          this.program.original_id
        );
        break;
    }
  }
}
