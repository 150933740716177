import {
  Component,
  OnInit,
  OnDestroy,
  NgZone,
  HostListener,
  ViewChild,
  ElementRef,
  Inject,
  PLATFORM_ID
} from "@angular/core";
import { SharedService } from "../../services/shared.service";
import { Router, ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material";
import { NetworkService } from "../../services/network.service";
import { WINDOW } from "@ng-toolkit/universal";
import { WelcomeFriendDialogComponent } from "../../dialogs/welcome-friend-dialog/welcome-friend-dialog.component";
import { AngularFireAuth } from "@angular/fire/auth";
import { auth } from "firebase/app";
import { GoogleAnalyticsService } from "../../services/googleAnalytics.service";
import { environment } from "../../../environments/environment";
import { ROUTES } from "src/app/data/routes";

declare var bodymovin: any;
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit, OnDestroy {
  @HostListener("scroll", ["$event"])
  onScroll(event: any) {
    // visible height + pixel scrolled >= total height
    if (
      event.target.offsetHeight + event.target.scrollTop >=
      event.target.scrollHeight
    ) {
      this.finishReadingTerms = true;
    }
  }

  @ViewChild("emailInput", { static: false }) emailInput: ElementRef;
  baseUrl = environment.baseUrl;
  finishReadingTerms = false;
  email = "";
  photoUrl = "";
  isLoading = false;
  isEmailValid = false;
  emailFocusedOut = false;
  mailSentAgain = false;
  acceptedTerms = false;
  scaleAcceptSection = false;
  loginstate = "";
  loadingAnimation: any;
  policy = `התנאים הבאים מפרטים את מדיניות הפרטיות הקשורה בשימוש באפליקציה של רדיו אקו התנאים הבאים מפרטים את מדיניות הפרטיות הקשורה בשימוש באפליקציה של רדיו אקו 99FM (להלן: "האפליקציה") ומהווה חלק בלתי נפרד מהסכם תנאי השימוש בה ונכללת בתוכו. מדיניות הפרטיות נועדה להוסיף על (ולא לגרוע מ או לפגוע ב)תנאי השימוש הנ"ל.
  עצם הורדת האפליקציה ו/או השימוש בה מהווה הסכמתך לכל התנאים המפורטים במדיניות הפרטיות
  1.	רישום לאפליקציה
  1.1	השימוש באפליקציה לא מצריך רישום, אולם רישום לאפליקציה משפר את חווית המשתמש ומעשיר אותה.
  1.2	הרישום מתבצע באמצעות רישום ישיר (במסגרתו תתבקש למסור לנו פרטים אישיים אודותיך (כמו שמך המלא, כתובת מגורים, כתובת דוא"ל ועוד) או באמצעות חשבונך ברשתות חברתיות (דוגמת פייסבוק וגוגל+).
  1.3	השימוש ברשתות חברתיות לשם רישום לאפליקציה הוא חיצוני ונפרד לאפליקציה וכפוף גם למדיניות הפרטיות של רשתות חברתיות אלו. כמו כן, רישום לאפליקציה באמצעות חשבונך ברשתות חברתיות מאפשר לאקו גישה למידע הבסיסי אודותייך בפרופיל הרשת החברתית שלך, כגון שמך, כתובת הדוא"ל שלך, תמונת הפרופיל שלך וכל מידע ותוכן אחר הזמין לעיון בחשבון הרשת החברתית שלך. גישתנו למידע ולתכנים כאמור מוגבלת למידע ולתכנים שמפעיל הרשת החברתית בה אתה משתמש מאפשר לנו גישה אליהם, וזאת בהתאם להגדרות הפרטיות שלך באותה רשת חברתית, אותן אתה יכול לשנות מעת לעת באמצעות ממשק הרשת החברתית.
  1.4	אנו נהיה רשאים להסתמך על פרטים ומידע אשר יימסרו לנו על ידכם, בין השאר ובמיוחד בעת הרישום, לכל דבר ועניין.
  1.5	מובהר כי אנו לא אחראים על אמיתות הפרטים אשר יימסרו לנו במסגרת הליך הרישום ו/או המידע המופיע בחשבונך ברשתות חברתיות ולא נהיה אחראים לכל נזק ישיר ו/או עקיף, לרבות הוצאות ישירות ו/או עקיפות שיגרמו לגולש באפליקציה ו/או לכל צד שלישי עקב הסתמכותנו על הפרטים ו/או המידע כאמור ושימוש שלנו בפרטים ובמידע אלו.

  2.	עוגיות
  2.1	מובהר כי השימוש באפליקציה, במידע, בשירותים ובתכנים הכלולים בה מהווה הסכמה מצידכם לפגיעה מוגבלת בפרטיותכם, על דרך שמירת מידע ונתונים לגביכם במאגרנו, שימוש בפרטיכם לשם אספקת השירותים הניתנים במסגרת האפליקציה, התאמת פרסומות, תפעול ופיתוח האפליקציה, בדיקות תקינות ותחזוקה הקשורים באפליקציה, גביית כספים, עריכת חשבונות, ניתוח ומסירת מידע סטטיסטי לצדדים שלישיים, לרבות מפרסמים, משלוח עדכונים או חומר פרסומי או שיווקי (במידה וניתנה הסכמתכם לכך), יצירת קשר עמכם, וביצוע מעקב ממוחשב על השימוש שאתם עושים במידע, בשירותים ובתכנים שבאתר, בכל דרך חוקית שנמצא לנכון, לרבות באמצעות "עוגיות" (“cookies”)
  2.2	הנך יכול למחוק את העוגיה בכל שלב מציוד הקצה שברשותך. כמו כן, הנך יכול לבחור מראש שלא לאפשר לאפליקציה לאחסן עוגיות בציוד הקצה שברשותך, וזאת באמצעות הגדרות הדפדפן או מערכת האבטחה של ציוד הקצה. יחד עם זאת, נטרול אפשרות אחסון העוגיות עלול לגרום לכך כי לא תוכל להשתמש בשירותים ובתכונות המופיעים באפליקציה או בקישורים המופיעים בה, כולם או חלקם.

  3.	דיוור; פנייה למשתמשי האפליקציה
  3.1.	שימוש באפליקציה ואישורך לקבל דיוור מעיד על הסכמתך המפורשת לקבלת דברי פרסומת, דיוור, עדכונים, התראות וכד' מאקו ו/או מי מטעמה, כאמור בחוק התקשורת (בזק ושידורים), התשמ"ב – 1982 (להלן: "חוק התקשורת"). דברי הפרסומת ישלחו אליך, בין השאר, לפי המידע שמסרת לאקו במהלך הרישום הישיר לאפליקציה ו/או באמצעות הפרטים שאפשרת לנו גישה אליהם ככל ונרשמת לשירת באמצעות חשבונך ברשתות חברתיות.
  3.2.	בהתאם להוראות חוק התקשורת בכל עת, תוכל לחזור בך מהסכמתך ולהודיע לנו על סירובך לקבלת דברי פרסומת, דרך כלל או מסוג מסוים, על-ידי משלוח הודעת סירוב לכתובת: music@eco99.fm.
  3.3.	בנוסף, אקו עשויה לשלוח לציוד הקצה שברשותך הודעות מתפרצות (Push Notifications). במידה ותבקש לחדול מקבלת הודעות מתפרצות כאמור, יהיה עליך להסיר תכונה זו באופן עצמאי באמצעות שימוש בתפריט ההגדרות הרלבנטי במכשיר הקצה שברשותך.
  3.4.	חלק מהשירותים הניתנים באמצעות האפליקציה הינם שירותים מבוססי מיקום, אשר עושים שימוש בטכנולוגיית GPS על מנת לקבוע את מיקומך ולספק לך תכנים או פרסומים התואמים ככל הניתן למיקום זה. תוכל בכל עת לבטל את האפשרות לזהות את מיקומך על ידי ביטול תכונה זו בהגדרות מכשיר הקצה שברשותך. יחד עם זאת, ביטול אפשרות זו עשויה למנוע מאיתנו לנו לספק לך תכנים מותאמים אישית כאמור לעיל.

  4.	איסוף ושימוש במידע
  4.1.	אנו רשאים להשתמש בפרטים שנמסרו על ידך ובמידע שיאסף על ידי אקו בנוגע לדפוסי השימוש באפליקציה והרכישה שלך, תכנים או פרסומות אותם קראת, השירותים וההצעות שהתעניינת בהם, מיקומך בעת השימוש, נתוני כתובת האינטרנט (IP address) שבאמצעותם ניגשת לשירות, סוג מערכת ההפעלה המשמשת אותך, סוג המכשיר הקצה שברשותך ומידע אודותיו אמצעי התשלום ששימשו אותך ועוד. זאת, בין השאר ומבלי למצות, לצורך יצירת קשר עמך, דיוור ישיר, תפעול, פיתוח ושיפור האפליקציה, גבית כספים ומסירת מידע לצדדים שלישיים (ובכלל זה למפרסמים ושותפים של אקו).
  4.2.	מבלי לגרוע מכלליות האמור לעיל, אנו רשאים לאסוף ולהסתייע בשירותיהם של צדדים שלישיים כדי לאסוף ולנתח מידע אנונימי, סטטיסטי, או מצרפי בקשר עם השימוש באפליקציה, לרבות מידע הנוגע לפעילותך באפליקציה.

  5.	זכויותיך כמשתמש באפליקציה.
  5.1.	על-פי חוק הגנת הפרטיות, התשמ"א-1981 (להלן: "חוק הגנת הפרטיות"), כל אדם זכאי לעיין בעצמו, או על ידי בא-כוחו שהרשהו בכתב או על ידי אפוטרופוסו, במידע שעליו המוחזק במאגר מידע. אדם שעיין במידע שעליו ומצא כי אינו נכון, שלם, ברור או מעודכן, רשאי לפנות לבעל מאגר המידע בבקשה לתקן את המידע או למוחקו. אם בעל המאגר סירב למלא בקשה זו, עליו להודיע על כך למבקש באופן ובדרך שנקבעו בתקנות שמכוח חוק הגנת הפרטיות. על סירובו של בעל מאגר מידע לאפשר עיון ועל הודעת סירוב לתקן או למחוק מידע, רשאי מבקש המידע לערער לפני בית משפט השלום באופן ובדרך שנקבעו בתקנות.
  5.2.	בנוסף, אם המידע שבמאגרנו (מאגר: "eco99music") משמש לצורך פניה אישית אליך, בהתבסס על השתייכותך לקבוצת אוכלוסין, שנקבעה על פי איפיון אחד או יותר של בני אדם ששמותיהם כלולים במאגר ("פניה בהצעה מסחרית"), כי אז אתה זכאי על-פי חוק הגנת הפרטיות לדרוש בכתב (באמצעות פנייה אלינו בכתובת: music@eco99.fm תוך ציון פרטיך לשם עדכון בדבר מחיקת פרטיך מהמאגר כמפורט להלן) שהמידע המתייחס אליך יימחק ממאגר המידע. במקרה זה אנו נמחק את המידע הדרוש לנו רק כדי לפנות אליך בהצעות מסחריות כאמור לעיל. מידע הדרוש לנו לשם ניהול עסקינו - לרבות תיעוד פעולות שביצעת באתר - יוסיף להישמר אצלנו, אך לא ישמש עוד לצורך פניות אליך. אם בתוך 30 יום לא תקבל הודעה כי המידע שביקשת למחוק אכן נמחק על-פי סעיף זה, תהיה זכאי לפנות לבית משפט השלום באופן הקבוע בתקנות שמכוח חוק הגנת הפרטיות, כדי שיורה לנו לפעול כאמור.
  5.3.	למען הסר ספק יובהר כי מחיקת מידע כאמור לעיל עשוי למנוע ממך פעילויות שונות באפליקציה ו/או למנוע ממך מלקחת חלק בפעילויות כאלו או אחרות אשר יבוצעו על ידינו ו/ו על ידי מי מטעמנו בעתיד.

  6.	שונות
  6.1.	מדיניות הפרטיות חלה על כל אדם, זכר או נקבה, תאגיד או כל גוף אחר שהוא כשיר לזכויות ולחובות משפטיות, ללא יוצאים מן הכלל. השימוש בלשון זכר במדיניות הפרטיות הינו לצרכי נוחות בלבד וכולל גם פניה בלשון נקבה, יחיד ורבים ולהיפך.
  6.2.	אקו שומרת לעצמה את הזכות לשנות את מדיניות הפרטיות אלה מעת לעת, לפי שיקול דעתה הבלעדי וללא צורך במסירת הודעה כלשהי מראש. האחריות המלאה להכרת מדיניות הפרטיות, כפי שתעודכן מעת לעת חלה, בכל עת, עליך בלבד.
  6.3.	החלוקה לסעיפים וכותרות הסעיפים הינה לשם נוחיות בלבד ולא תשמש לצורכי
  פרשנות.

  עדכון אחרון 1.9.19
  `;

  terms =
    `ברוכים הבאים לאפליקציה של רדיו אקו 99FM.
  אנו מאחלים לכם חוויה מהנה, אך בטרם תיפנו לשימוש באפליקציה, נבקש להביא לתשומת ליבכם כללי יסוד שהם בעלי משמעות משפטית. לפיכך, נבקשכם לקרוא תנאי שימוש אלו במלואם ובעיון. עצם השימוש שלך או של מי מטעמך באפליקציה, יהווה אישור להסכמתך לכל תנאי השימוש המפורטים במסמך זה.
  1.	כללי
  1.1	מסמך זה מהווה הסכם בנוגע לשימוש, הפעילות והשירותים המוצעים לך באפליקציה של רדיו ללא הפסקה (ניהול 2008) בע"מ ("אקו"). השירותים הניתנים לך במסגרת האפליקציה מוצעים לשימושך בתנאי שתקבל ללא שינוי את כל התנאים הכלולים במסמך זה. עצם השימוש שלך או של מי מטעמך באפליקציה, יהווה אישור להסכמתך לכל תנאי השימוש המפורטים במסמך זה. אם אינך מסכים לתנאי השימוש באפליקציה, אינך רשאי לעשות שימוש בו, או בשירותיו.
  1.2	תנאי השימוש באפליקציה הם ההסכם המחייב היחיד והבלעדי בכל הקשור לשימוש באפליקציה ולפיכך בכל מקרה של סתירה או אי התאמה בין תנאי שימוש אלו לבין כל פרסום, מידע, תקנון או מסמך  אחר, מכל סוג ומין, יגברו הוראות תנאי השימוש המפורטים להלן (למעט לגבי שירותים נוספים כמפורט בסעיף 1.3 להלן).
  1.3	השימוש בחלק מהשירותים המוצעים באפליקציה (למשל, שירותים בתשלום, או קבלת הטבות שונות) עשוי להיות כפוף לתנאים נוספים. התנאים הנוספים מצטרפים לאמור במסמך תנאי שימוש זה, אולם במקרה של סתירה או אי התאמה בין תנאי השימוש לבין התנאים הנוספים, יגבר האמור בתנאים הנוספים.
  1.4	אקו שומרת לעצמה את הזכות לשנות תנאי שימוש אלה מעת לעת, לפי שיקול דעתה הבלעדי וללא צורך במסירת הודעה כלשהי מראש. האחריות המלאה להכרת תנאי שימוש אלה, כפי שיעודכנו מעת לעת חלה, בכל עת, עליך בלבד.
  1.5	השימוש באפליקציה ובכל המידע, התכנים והשירותים הנכללים בה נועד לשימוש אישי בלבד. אין לעשות שימוש באפליקציה לצרכים מסחריים או למטרות רווח.
  1.6	אקו, עובדיה, מנהליה, נציגיה ו/או מי מטעמה אינם אחראים בכל חבות ו/או אחריות לשירותים אשר אינם מסופקים ישירות עבור אקו (לרבות ומבלי למצות רישום ו/או גבייה ו/או סליקה ו/או תשלום המבוצעים באמצעות צדדים שלישיים).

  2.	הורדת האפליקציה, רישום ושימוש בה
  2.1.	השימוש באפליקציה עשוי להיות כפוף להוראות חוזיות ולתנאי שימוש נוספים של צדדים שלישיים (לרבות כאלו המסדירות את השימוש בחנות המקוונת שממנה ניתן להוריד את האפליקציה, כגון Google Play ו-App Store).
  2.2.	השימוש באפליקציה לא מצריך רישום, אולם רישום לאפליקציה משפר את חווית המשתמש ומעשיר אותה.
  2.3.	הרישום מתבצע באמצעות רישום ישיר (במסגרתו תתבקש למסור לנו פרטים אישיים מזהים, פרטי יצירת קשר ומגדר) או באמצעות חשבונך ברשתות חברתיות (דוגמת פייסבוק וגוגל+).
  2.4.	השימוש ברשתות חברתיות לשם רישום לאפליקציה הוא חיצוני ונפרד לאפליקציה וכפוף גם למדיניות הפרטיות של רשתות חברתיות אלו. כמו כן רישום לאפליקציה באמצעות חשבונך ברשתות חברתיות מאפשר לאקו גישה למידע הבסיסי אודותייך בפרופיל הרשת החברתית שלך, כגון שמך, כתובת הדוא"ל שלך, תמונת הפרופיל שלך וכל מידע ותוכן אחר הזמין לעיון בחשבון הרשת החברתית שלך. גישתנו למידע ולתכנים כאמור מוגבלת למידע ולתכנים שמפעיל הרשת החברתית בה אתה משתמש מאפשר לנו גישה אליהם, וזאת בהתאם להגדרות הפרטיות שלך באותה רשת חברתית, אותן אתה יכול לשנות מעת לעת באמצעות ממשק הרשת החברתית.
  2.5.	אנו נהיה רשאים להסתמך על פרטים ומידע אשר יימסרו לנו על ידכם, בין השאר ובמיוחד בעת הרישום לאפליקציה, לכל דבר ועניין.
  2.6.	מובהר כי אנו לא אחראים על אמיתות הפרטים אשר יימסרו לנו במסגרת הליך הרישום ו/או התשלום (ככל ששירות מסוים כרוך בתשלום) ו/או המידע המופיע בחשבונך ברשתות חברתיות ולא נהיה אחראים לכל נזק ישיר ו/או עקיף, לרבות הוצאות ישירות ו/או עקיפות שיגרמו לגולש באפליקציה ו/או לכל צד שלישי עקב הסתמכותנו על הפרטים אשר יימסרו לנו על ידכם ו/או המידע המופיע ברשתות חברתיות כאמור ושימוש שלנו בפרטים ו/או במידע אלו.
  2.7.	הנכם אחראים לשמור על פרטי המייל שבחרתם לעשות בו שימוש לצורך השימוש באפליקציה (בין אם באמצעות אשרור המייל האישי ובין אם באמצעות רשת חברתית) בסודיות ולדווח לנו באופן מידי (באמצעות פנייה לכתובת music@eco99.fm) בכל מקרה בו תגלו שמישהו עשה בהם שימוש בלי הסכמתכם. אי דיווח כאמור משמעו כי הנכם עלולים להיות אחראים לכל שימוש אשר יעשה צד שלישי בפרטים שנמסרו על ידכם (כולל לתשלום עבור שירותים), אפילו אם נעשה בלי הסכמתך. כמו כן הנכם מתחייבים שלא לעשות שימוש בשם המשתמש או בסיסמה של אחר.
  2.8.	אנו רשאים גם בכל עת להפסיק, לשנות או להגביל את פעילות האפליקציה, את אפשרויות השימוש בה, את הגישה או השימוש במידע, בשירותים ובתכנים הכלולים בה, כולם או חלקם או כל היבט אחר הקשור באפליקציה וזאת בכל עת לפי שיקול דעתנו הבלעדי, וללא צורך בהודעה מוקדמת.
  2.9.	מבלי לגרוע מהאמור לעיל, מעת לעת עשויה אקו לבצע שינויים, שיפורים ותיקונים באפליקציה, במידע, בשירותים ובתכנים הכלולים בה, וזאת לפי שיקול דעתנו הבלעדי וללא צורך בהודעה מוקדמת. שינויים, שיפורים, תיקונים וכיו"ב עלולים להיות כרוכים בתקלות, איבוד מידע, אי זמינות או הפסקתם של שירותים מסוימים וכיו"ב ולא תהיה לך טענה, תביעה ו/או דרישה כלפי אקו האמור.
  2.10.	אנו לא מציגים מצג כלשהו לגבי התאמה, איכות, אמינות, זמינות, תקינות, העדר טעויות ודייקנות האפליקציה, על כל חלקיה ומרכיביה, לכל מטרה שהיא, וכן לגבי היעדר וירוסים, רכיבים מזיקים אחרים בה, או העדרן של תקלות טכניות באפליקציה ו/או במערכות המחשוב ו/או התקשורת של אקו ו/או ספקיה. אקו לא תהא אחראית לכל נזק, ישיר או עקיף שייגרמו לך או לרכושך כתוצאה מכך. באחריותך לנקוט באמצעי אבטחה הולמים על מנת להגן על עצמך ועל הציוד שבבעלותך (נגד וירוסים, פריצה לציוד הקצה שברשותך וכו').
  2.11.	התכנים והשירותים באפליקציה ניתנים לשימוש "כפי שהם" (as is) ו"ככל שיהיו זמינים" (as available). לא ניתן להתאימם למטרה כלשהי או לצרכיו של כל משתמש ומשתמש.
  2.12.	הנכם מסכימים לכל פעולה שבה ננקוט כדי להגן על האפליקציה, השירותים ומערכות המחשוב של אקו, גם אם פעולות אלו עלולות, עקב קשיים טכנולוגיים, לחסום או לעכב גם שימוש לגיטימי באפליקציה ו/או בשירותים אלו.
  2.13.	אקו אינה מתחייבת כי התוכן אשר יהא זמין באפליקציה יהא זהה או דומה לתוכן המשודר בתחנת הרדיו 99FM או כי התוכן המשודר ברדיו יהא זמין להאזנה באפליקציה במועד בו שודר ברדיו או בסמוך לו או כי שימוש באפליקציה יהיה זמין מחוץ לגבולות מדינת ישראל. אף אם שימוש באפליקציה יהא זמין מחוץ לגבולות מדינת ישראל (באופן חלקי או מלא) שימוש זה כפוף, בנוסף לאמור בתנאי שימוש אלו, לתנאי השימוש של צדדים שלישיים (לרבות ארגוני זכויות יוצרים).
  2.14.	יובהר, כי על אף שהשימוש באפליקציה פתוח בעיקרון לכלל המשתמשים, אנו שומרים על זכותנו למנוע או להגביל או לחסום שימוש של משתמשים באפליקציה, במידע, או בשירותים הכלולים בה (בין היתר באמצעות מגבלות על גיל המשתמש, חובת תשלום, היקף שימוש, אופן השימוש, מועד השימוש, מספר החשבונות או שמות המשתמשים המותרים לכל אדם או תאגיד יחיד מניעת כניסה לאפליקציה לתקופה מוגבלת או לצמיתות) וכו' מכל סיבה שהיא ולפי שיקול דעתנו הבלעדי (לרבות, ומבלי למצות, במקרה של הפרת הוראת כל דין, הפרה של תנאי השימוש, הפרעה לפעילות התקינה של האפליקציה). זאת, מבלי לגרוע ו/או לפגוע בכל סעד אחר העומד לרשותה של אקו על פי כל דין.

  3.	שירותים נוספים בתשלום
  3.1.	אקו עשויה להציע למשתמשי האפליקציה שירותים נוספים עשויים להיות כרוכים בתשלום על פי החלטתה של אקו. על שירותים אלו יחולו כל תנאי השימוש שבמסמך זה וכן תנאים נוספים הקשורים לשירותים בתשלום אשר יפורסמו בצד שירותים אלו.
  3.2.	אקו תהיה רשאית לשנות בכל עת את השירותים שניתנים על ידה, או את העלות שהיא גובה עבורם, להוסיף ולהפסיק שירותים בהתאם לשיקול דעתה הבלעדי וזאת ללא כל הודעה מראש.
  3.3.	ככל שהרישום ו/או התשלום עבור שירותים שונים מתבצע באמצעות צדדים שלישיים, הנך מתחייב לפעול על פי תנאי הרישיונות של אותם גורמים ואקו לא תישא באחריות ליחסיך מולם.

  4.	תכנים מסחריים
  4.1.	האפליקציה כוללת תכנים ומידע מסחרי, כגון פרסומות ומודעות, הנמסרים לפרסום מטעמם של מפרסמים שונים, המבקשים להציע למכירה שירותים או מוצרים. פרסום התכנים והמידע המסחרי באפליקציה אינו מהווה עידוד או המלצה מצד אקו לרכוש ו/או להשתמש בשירותים או המוצרים המוצעים בה.
  4.2.	אקו אינה אחראית לתכנים ולמידע המסחרי המפורסמים באפליקציה מטעם ו/או על ידי צדדים שלישיים ואלו מצויים אך ורק באחריותם. מבלי לגרוע מכלליות האמור, איננו מקבלים על עצמנו אחריות לבצע פיקוח, סינון, בדיקת נכונות, בדיקת יעילות או שמירה על המידע, השירותים והתכנים המפורסמים על ידי מפרסמים באפליקציה. לפיכך, הנך מתבקש לנהוג משנה זהירות בהסתמכות או בשימוש במושא החומר הפרסומי, וכל שימוש או הסתמכות על חומר פרסומי הוא על אחריותך בלבד.
  4.3.	מבלי לגרוע מהאמור לעיל, כל תוצאה שתיגרם משימוש במוצרים או בשירותים המפורסמים באפליקציה או מהסתמכות על תכנים ומידע מסחרי של צדדים שלישיים - חלה על המפרסמים בלבד.
  4.4.	כל עסקה שתיעשה בעקבות תכנים ומידע מסחרי המתפרסם באפליקציה על ידי צדדים שלישיים תתבצע ישירות בינך לבין המפרסם הנוגע בדבר. אקו אינה צד לעסקה כזו, והיא לא תישא באחריות לעסקה שכזו ו/או תוצאותיה. האמור בסעיף זה נוגע גם לכל עסקה, השתתפות במבצע, רכישה של מוצרים ו/או שירותים וכיו"ב אשר יהיו מוצעים לך במסגרת קישורים חיצוניים לאפליקציה כהגדרתם בסעיף 5 להלן.

  5.	קישורים באפליקציה ואל האפליקציה
  5.1.	האפליקציה עשויה להכיל בתוכה קישורים (hyperlinks) אל וממקורות מידע ותכנים אחרים המצויים ברשת האינטרנט  ואשר הינם חיצוניים לאפליקציה (לרבות, ומבלי למצות, קישורים לאתרי רכישת כרטיסים להופעות ולמופעים באמצעות היישום "אקו טיקטס").
  5.2.	העובדה שהאפליקציה מכילה קישורים למקורות חיצוניים כאמור אינה מעידה על הסכמת אקו, לא במפורש ולא במשתמע, לתוכנם ואינה מהווה הבעת תמיכה או חסות מצידנו באותו מקור חיצוני או ערובה לאמינותם, תקינותם, שלמותם, לעדכניותם, לעמידתם בתנאי כל דין, לאופן בו ינהגו בתחום פרטיות הגולשים אליהם ולכל היבט אחר. הביקור בקישורים חיצוניים אלו הוא על אחריותך בלבד (וזאת אף אם ההפניה אליהם נעשתה באמצעות האפליקציה, לרבות ומבלי למצות, באמצעות "אקו טיקטס"). בהתאם, אקו אינה ולא תהיה אחראית לכל תוצאה אשר תגרם כתוצאה מגלישתך לאתרים אלו ו/או להסתמכותך על המידע המפורסם בהם. כדי לברר את התנאים והמחירים החלים על תכנים ושירותים של צדדים שלישיים, עליכם לפנות לספקים הרלוונטיים.
  5.3.	בכל מקרה של שימוש בתוכנות ו/או בתכנים של צדדים שלישיים הנך מתחייב לפעול על פי תנאי הרישיונות של התוכנות ו/או התכנים האמורים. במקום בו השימוש בתוכנות ו/או בתכנים כאמור מותנה בתשלום, הנך מתחייב לשלם כנדרש. שימוש בלא תשלום מהווה הפרה של תנאי רישיון התוכנות ו/או התכנים, אשר עלול לגרום, בין השאר, להפרה של דיני זכויות יוצרים ואסור על פי דין.
  5.4.	אין להציג תכנים מהאפליקציה באמצעות טכנולוגית מסגור (framing), או כל טכנולוגיה אחרת באמצעותה מוצגים למשתמש תכנים מהאפליקציה.
  5.5.	אקו מעמידה לרשות משתמשי האפליקציה כלים שונים שמטרתם מתן אפשרות לשיתוף וקישור תכנים מהאפליקציה באמצעות רשתות חברתיות ושירותים מקוונים אחרים של צדדים שלישיים. תשומת ליבך כי השימוש ברשתות חברתיות ושירותי צדדים שלישיים כאמור נעשה על אחריותך בלבד. שימוש כאמור אינו קשור לאפליקציה או לאקו והוא כפוף לתנאי השימוש בשירותים מקוונים אלו ומדיניות הפרטיות שלהם. באחריותך לקרוא ולהסכים לתנאי השימוש האמורים לפני שתעשה כל שיתוף ו/או קישור לתכנים כלשהם מהאפליקציה.

  6.	תנאים ומגבלות על השימוש באפליקציה
  6.1.	אנו מעודדים כמובן שימוש באפליקציה, על כל חלקיה. יחד עם זאת, שימוש באפליקציה חייב לעשות על פי הוראות כל דין ומבלי שתהיה בשימוש זה פגיעה באחרים, בנו או הפרה של תנאי השימוש. לעניין זה מובהר  כי כל שימוש באפליקציה שלא הותר באופן מפורש בתנאי השימוש – אסור.
  6.2.	מבלי לגרוע מהאמור לעיל, להלן יפורטו תנאים ומגבלות שהשימוש באפליקציה בכל דרך שהיא חייב להיעשות תוך שמירתם ובכפוף להם:
  6.3.	אין לעשות שימוש באפליקציה, או בכל חלק ממנה לכל מטרה לא חוקית ו/או לכל מטרה האסורה על פי תנאי השימוש.
  6.4.	השימוש באפליקציה נועד אך ורק לשימוש ביתי, פרטי שאינו מסחרי בלבד על ידך, המשתמש. כל שימוש אחר באפליקציה ו/או בתכניה אסורים בהחלט. בפרט, אינך רשאי: (1) לבצע אילו מתכני האפליקציה בביצוע פומבי ו/או (2) להעביר אילו מהתכנים הנכללים באפליקציה בהעברת המשך (re-transmission), לשדרם, לאפשר את ביצועם הפומבי ו/או להעמידם לרשות הציבור ו/או (3) להעמיד אילו מהתכנים המצויים באפליקציה, בצורה או אופן כלשהם, לרשות גורמים מסחריים ו/או ציבוריים.
  6.5.	אין להירשם לאפליקציה באמצעות פרטי הזדהות שאינם נכונים, מלאים ומדויקים. מבלי לגרוע מהאמור לעיל, אין להתחזות או לזייף פרטי משתמשים.
  6.6.	אינך רשאי להשתמש באפליקציה באופן העלול להזיק, לשתק, לגרום עומס יתר או לפגוע באפליקציה, ברשתות תקשורת ובציוד תקשורת כלשהו, או להפריע לשימוש של גורם אחר כלשהו ולהנאה שלו מהאפליקציה
  6.7.	אין להשתמש באפליקציה באופן כלשהו שיש בו כדי להוות פגיעה בפרטיות, פגיעה בשם הטוב, הפרת זכויות יוצרים, זכויות מבצעים, סימנים ושמות מסחריים, מדגמים, פטנטים וכו', הפרת זכויות אחרות כלשהן של צדדים שלישיים, עוולות אזרחיות או מסחריות, עבירות פליליות, הפרה של צווי ואיסורי פרסום, פרסום מידע על קטינים, חסויים או פסולי דין,  הטעיה או הונאה של משמשים.
  6.8.	אין לנסות לקבל גישה ללא הרשאה לאפליקציה, לשירותים, לתכנים, לחשבונות או פרטים של אחרים או למערכות מחשבים או רשתות המחוברות לאפליקציה ו/או קשורות אליה באמצעות "פריצה" (Hacking), "כריית סיסמאות" (Password Mining) או בכל אמצעי אחר.
  6.9.	חל איסור להפעיל ו/או לנסות להפעיל כל אמצעי (תוכנה, חומרה או אחר) אשר מטרתו לעקוף (circumvention) את תנאי השימוש ו/או המגבלות השונות של האפליקציה.
  6.10.	במידה ונהיה סבורים, על פי שיקול דעתנו הבלעדי, כי הופר על ידך תנאי כלשהו מתנאי השימוש ו/או כי עבירת על הוראת דין כלשהו, תהא אקו רשאית למנוע ממך גישה לאפליקציה (כולה או חלקה, באופן זמני או קבוע) ו/או לבטל את הרישום שלך לאפליקציה (כולו או חלקו) ו/או לפעול בכל דרך אחרת, על פי שיקול דעתה הבלעדי. בכל אחד מהמקרים המנויים לעיל לא תהיה לך כל טענה, תביעה או דרישה כנגד אקו.
  6.11.	מבלי לגרוע מהאמור לעיל, הנך מתחייב לשפות את אקו, עובדיה, מנהליה ומי מטעמה בגין כל טענה, תביעה, נזק, הפסד, אבדן רווח, תשלום או הוצאה שיגרמו לה (לרבות שכר טרחת עו"ד והוצאות משפט) עקב הפרה שלך את תנאי השימוש.

  7.	פיקוח, העברת מידע ואבטחת מידע
  7.1.	אקו אינה מחויבות לפקח על האפליקציה על כל רכיביה ומרכיביה ו/או על קהל המשתמשים שלה והיא אינה עושה זאת. יחד עם זאת, אקו שומרת לעצמה את הזכות לעשות כן, על פי שיקול דעתה הבלעדי.
  7.2.	אף אם נפעיל פיקוח או אמצעי זהירות או שליטה כלשהם על המידע, השירותים והתכנים שבאפליקציה, ואף אם ייוודע לנו על האפשרות לפגיעה או לנזק שעלול להיגרם למשתמשים או לצדדים שלישיים כלשהם - כל אמצעי זהירות, פיקוח או שימוש כאמור, ואפילו ניסיון לפתור או לסייע בפתרון בעיות, או מניעת או צמצום או הפחתת נזקים, נעשים ללא מחויבות בדין ומתוך רצון טוב, ומבלי שהדבר יתפרש כקבלת אחריות כלשהי מצדנו מקום בו צוין מפורשות כי אין אנו אחראים.
  7.3.	אנו שומרים לעצמנו את הזכות לחשוף כל מידע אישי אודותיך או אודות השימוש שלך באפליקציה ללא קבלת רשות ממך, אם פעולה זו חיונית כדי: (1) לציית לדרישות משפטיות או למלא אחר צווים שניתנו במסגרת הליך משפטי או על-ידי רשות מוסמכת על פי כל דין; (2) לאכוף את תנאי השימוש; (3) על מנת למנוע נזק לגוף או לרכוש שלך או של כל צד שלישי שהוא; (4) בכל מקרה אחר בו אנו סבורים כי הדבר דרוש לשם שמירה על הוראות כל דין או לשם הגנה על זכויותיה של אקו.
  7.4.	מאחר שימוש באפליקציה נעשה גם באמצעות רשת האינטרנט, יתכן שמידע שתפרסם או תעלה לאפליקציה (ככל שתהיה לך אפשרות לעשות כן) יגיע לידי צדדים שלישיים.

  8.	מדיניות פרטיות
  8.1.	השימוש באפליקציה כפוף גם להסכמתך לקבל ללא שינוי את מדיניות הפרטיות של אקו בקשר עם האפליקציה ותכניה והיא מהווה חלק בלתי נפרד מתנאי השימוש באפליקציה.
  8.2.	את מדיניות הפרטיות שלנו ניתן לקרוא ב<a href="` +
    environment.baseUrl +
    `/privacy-policy">קישור</a> אם אינך מסכים למדיניות פרטיות זו, אינך רשאי לעשות שימוש באפליקציה.

  9.	זכויות קניין רוחני והשימוש בהן
  9.1.	המידע והתכנים הכלולים באפליקציה ובשירותים המוצעים בה, לרבות טקסט, איורים, וידאו, גרפיקה, צליל, קטעי גרפיקה, קוד מחשב, יישומי תוכנה, תוכניות, גרפים ותמונות מוגנים בזכויות יוצרים ובזכויות קניין רוחני אחרות של אקו או של צדדים שלישיים, בהתאם לחוקי מדינת ישראל, אמנות בינלאומיות וחוקי זכויות יוצרים של מדינות אחרות.
  9.2.	מבלי לגרוע מהאמור בכל מקום אחר בתנאי שימוש אלה, התכנים הנכללים באפליקציה מיועדים לשימוש אישי בלבד ואין לעשות בהם כל שימוש, ובכלל כך, להעתיק את השירותים או תוכנם, לשכפל, לשנות, להפיץ, לשדר, להציג בפומבי, לבצע בפומבי, להעביר לציבור, לפרסם, לעבד, ליצור יצירות נגזרות, להסב, לתת רישיון או הרשאה, למכור או להשכיר ולאחסן את תוכנה של האפליקציה וכל תוכן אחר שהתקבל באמצעותו, באופן חלקי או מלא, זמני או קבוע, בלא הסכמה מפורשת של אקו מראש ובכתב. למען הבהירות נציין כי האמור לעיל חל גם על שמות וסימנים מסחריים המופיעים באפליקציה, פטנטים ומדגמים, סודות מסחריים וכיו"ב
  9.3.	יצירת עותקים של ההקלטות (downloading) הנכללות באפליקציה הינו אסור ומהווה הפרה של זכויות יוצרים.
  9.4.	האמור בתנאי השימוש נועד כדי להעניק הרשאה להשתמש באפליקציה בהתאם לתנאי השימוש בלבד ומותנה בהתחייבותך כי לא תבצע או תהיה מעורב בביצוע פעולות המפרות תנאי שימוש אלה, ובפרט בפעולות הגורמות להפרת זכויות קניין רוחני.  אין לעשות שום שימוש באפליקציה, במידע או בשירותים השייכים לנו, כהגדרתם לעיל, לרבות שימוש כלשהו במאגרי הנתונים שלנו, סימני המסחר ושמות המסחר וכיו"ב, לשום מטרה אחרת מלבד שימוש אישי שאינו מסחרי באפליקציה ולפי תנאי השימוש, ללא אישורנו בכתב ומראש.

  10.	אזהרה לגבי סיכונים; אחריות מוגבלת
  10.1.	כל שימוש באפליקציה ובתכנים הנכללים בה הינו על אחריותך המלאה והבלעדית. בשל כך,  ולאור העובדה, כי ככלל אקו אינה מפקחת על האפליקציה או על קהל המשתמשים שלה, מצאנו לנכון להביא בפניכם במסגרת תנאי השימוש אזהרות מסוימות ביחס לסיכונים הכרוכים בשימוש במידע ובשירותים שבאפליקציה ולהתריע בפניך בדבר ההשלכות הנובעות מכך.
  10.2.	המידע והשירותים באפליקציה עשויים לכלול מידע מקצועי בתחומים שונים. מידע כאמור אינו מהווה המלצה או חוות דעת מוסמכת ואינו תחליף לייעוץ מקצועי של איש מקצוע מוסמך. כל הסתמכות על מידע כאמור המופיע באפליקציה הינה באחריותכם הבלעדית ואנו לא נישא בשום אחריות לנזק כתוצאה מהשימוש במידע זה או מההסתמכות עליו.
  10.3.	איננו מתחייבים שהאפליקציה תפעל בצורה רצופה, תהא זמינה כל העת, תפעל ללא הפרעות, הפסקות, טעויות, באגים או ליקויים אחרים, או שהשימוש באפליקציה יהיה בטוח וחסין מפני גישה בלתי מורשית לשרתי האפליקציה או מחשבי המשתמשים, או לתקלות או כשלים בפעילות באפליקציה, חומרה, בתוכנה, קווים ובמערכות התקשורת הקשורות באפליקציה.
  10.4.	 איננו אחראים למעשים או מחדלים של צדדים שלישיים, משתמשים אחרים או כל גורם אחר שאין לנו שליטה מלאה עליו, או לתוצאות של מעשים או מחדלים כאמור, לרבות לפגיעה או להפרה של זכויות יוצרים, מבצעים או קניין רוחני כלשהו על ידי משתמשים באפליקציה
  10.5.	איננו אחראים לקשר בין המשתמשים לבין משתמשים אחרים או צדדים שלישיים, או לתוצאותיו ואיננו צד להתקשרות כזו ולא נישא בשום אחריות לנזקים או פגיעות שתהיינה כתוצאה מהתקשרות כזו.
  10.6.	איננו אחראים לכל נזק אשר ייגרם בעקבות נסיבות של כח עליון וכל נסיבה אחרת שאיננה בשליטתה של אקו.
  10.7.	מבלי לגרוע מהאמור לעיל בשום מקרה, ובמידה המקסימאלית המותרת בחוק, לא תהיה אקו אחראית לכל נזק ישיר או עקיף, כספי או אחר, שייגרם לך כתוצאה משימוש באפליקציה, מהסתמכות על המידע שמופיע בה או בקישורים חיצוניים לה, שאליהם תגיע באמצעות האפליקציה.
  10.8.	כמו כן, אקו אינה אחראית ולא תחוב בכל חובה בקשר עם כל טענה, תביעה או דרישה שעיקרה הפרת זכויות של צדדים שלישיים  (לרבות משתמשי האפליקציה) על ידי מי מהמשתמשים באפליקציה.
  10.9.	הפעלת או אי הפעלת אילו מסמכויותיה או זכויותיה של אקו כמפורט בתנאי השימוש לא תקים לך או לצד שלישי כל טענה, תביעה ו/או דרישה כלפי אקו, עובדיה, מנהליה או נציגיה, לא תתפרש כנטילת אחריות מצד אקו או כהטלת חובה עלינו להפעלת סמכויות אלו או כויתור על אילו מתנאי השימוש באפליקציה, ולא תסיר את האחריות המוטלת עליכם או על צד שלישי כלשהו על פי תנאי השימוש או הוראות הדין.



  11.	פניות
  11.1.	אם הנך סבור כי תוכן כלשהו המופיע באפליקציה פוגע בך מטעם כלשהו, או שהתוכן מפר את תנאי השימוש או את זכויותיך, הנך מוזמן אלינו בכתובת music@eco99.fm ואנו נשתדל לטפל בפנייתך בהקדם ובהתחשב בשעות ובימי העבודה המקובלים. על מנת שנוכל לטפל בפנייה ביעילות, במסגרת הפנייה עליך לפרט מהי הפגיעה או ההפרה הנטענת, מהו החומר הפוגע או המפר (תוך צירוף של צילום מסך או של קישור לחומר זה), וכן את פרטייך האישיים המלאים (שם, כתובת, טלפון וכתובת דוא"ל) לשם יצירת קשר.

  12.	שונות
  12.1.	החלוקה לסעיפים וכותרות הסעיפים הינה לשם נוחיות בלבד ולא תשמש לצורכי
  פרשנות.
  12.2.	כל הקשור והנובע מהשימוש באפליקציה, במידע, בשירותים ובתכנים שבה, וכן כל הקשור ונובע מתנאי שימוש אלו יהיה כפוף לדין הישראלי בלבד, לרבות לצורך פרשנות. כל סכסוך או מחלוקת בקשר אליהם יהיו בסמכות בתי המשפט המוסמכים בישראל, באזור השיפוט של תל אביב יפו ויובאו בפניהם בלבד כמקום שיפוט ייחודי.
  12.3.	האמור בתנאי השימוש לעיל מבטא באופן מלא וממצה את כל הנושאים והעניינים הנדונים בו או הקשורים לשימוש באפליקציה, וגובר על כל מצג, פרסום, נוהג, מכתבי כוונות או התחייבות, וכל מסמך אחר, בנושאים ובעניינים האמורים.
  12.4.	התנהגותנו או הסכמתנו לסטות, מתנאי כלשהו של תנאי השימוש, במקרה מסוים ובסדרת מקרים, לא תהווה תקדים ואין ללמוד ממנה גזירה שווה לכל מקרה בעתיד ולא תחשב כויתור על איזה מתנאי השימוש ו/או כויתור ו/או כהסכמה מצידנו לאיזו הפרה או אי קיום תנאי כלשהו,  אלא אם-כן נעשו במפורש ובכתב.
  12.5.	אם ייקבע שחלק כלשהו מתנאי השימוש אינו חוקי, אינו תקף או אינו בר אכיפה, הרי שאותו חלק יחשב כמוחלף בסעיפים תקפים אחרים הניתנים לאכיפה שתוכנם תואם במידת הקירוב הגדולה ביותר את כוונת הסעיפים המקוריים, ואילו יתר תנאי השימוש יישארו בתוקפם.
  12.6.	אין בתנאי השימוש כדי להקנות למשתמשים או לצדדים שלישיים זכויות שלא הוקנו להם במפורש על פי תנאי השימוש או על פי הדין.
  12.7.	אנו נהיה רשאים להעביר ו/או להסב בכל דרך (במישרין או בעקיפין, בתמורה או שלא בתמורה) את תנאי השימוש ו/או הזכויות ו/או החובות הכלולות ו/או הנובעות מהם, באופן מלא או חלקי, לכל אדם, תאגיד או כל יישות משפטית אחרת , ללא צורך בהודעה מוקדמת או בהסכמת המשתמש.
  12.8.	להסרת ספק נבהיר, כי תנאי השימוש חלים על כל אדם, זכר או נקבה, תאגיד או כל גוף אחר שהוא כשיר לזכויות ולחובות משפטיות, ללא יוצאים מן הכלל. השימוש בלשון זכר בתנאי שימוש אלה הינו לצרכי נוחות בלבד וכולל גם פניה בלשון נקבה, יחיד ורבים ולהיפך.

  עדכון אחרון: 1.9.19
`;
  constructor(
    @Inject(WINDOW) private window: Window,
    public sharedService: SharedService,
    private router: Router,
    private ngZone: NgZone,
    private networkService: NetworkService,
    public dialog: MatDialog,
    private afAuth: AngularFireAuth,
    private analyticsService: GoogleAnalyticsService
  ) {}

  ngOnInit() {
    this.sharedService.showHeader = false;
    this.sharedService.showMenu = false;
    this.sharedService.showFooter = false;
    if (this.sharedService.isPlatformBrowser) {
      this.checkIfSigninLink();
      this.checkIfRedirectResult();
    }

    this.sharedService.hidePlayer();
    this.playBGVideo();
  }

  @ViewChild('continueButton', { static: false }) continueButton: ElementRef<HTMLButtonElement>;

  playBGVideo() {
    var video = document.querySelector("video");
    var promise = video.play();

    if (promise !== undefined) {
      promise
        .then(_ => {
          // Autoplay started!
        })
        .catch(error => {
          // Show something in the UI that the video is muted
          video.muted = true;
          video.play();
        });
    }
  }

  ngOnDestroy(): void {
    this.sharedService.showHeader = true;
    this.sharedService.showMenu = true;
    this.sharedService.showFooter = true;
  }

  checkIfSigninLink() {
    if (!this.window.location) return;

    if (this.afAuth.auth.isSignInWithEmailLink(this.window.location.href)) {
      this.email = this.window.localStorage.getItem("99music-emailForSignIn");
      if (!this.email) {
        // User opened the link on a different device
        this.email = window.prompt(
          "היי, על מנת להשלים את הרישום, יש להזין את כתובת המייל עמה נרשמת."
        );
      }
      this.analyticsService.sendEvent("login_page", "sign_in_email", "דוא״ל");
      this.afAuth.auth
        .signInWithEmailLink(this.email, this.window.location.href)
        .then(result => {
          this.window.localStorage.removeItem("99music-emailForSignIn");
        })
        .catch(error => {
          // link is not valid
          this.loginstate = "login";
          this.sharedService.showAlert("תוקף הלינק פג, אנא הזן את המייל שנית");
          console.log(error);
        });
    } else {
      this.loginstate = "login";
    }
  }

  checkIfRedirectResult() {
    this.afAuth.auth
      .getRedirectResult()
      .then(result => {
        this.ngZone.run(() => {
          if (!result.user) return;

          this.email =
            result.additionalUserInfo.profile["email"] || result.user.email;
          this.photoUrl = result.additionalUserInfo.profile["picture"].data.url;
          if (!this.email) {
            this.email = "";
            this.sharedService.showAlert(
              "לא מצאנו דוא״ל בחשבון הפייסבוק שלך. אנא בחר דרך הרשמה אחרת."
            );
          }
        });
      })
      .catch(error => {
        // if (error.code == 'auth/popup-closed-by-user') {
        //   return
        // }
        // this.sharedService.showAlert('היתה בעיה, אנא נסה שנית')
        // console.log(error)
        // var errorCode = error.code;
        // var errorMessage = error.message;
        // var email = error.email;
        // var credential = error.credential;
      });
  }

  scaleAcceptTerms() {
    this.scaleAcceptSection = true;
    setTimeout(() => {
      this.scaleAcceptSection = false;
    }, 100);
  }

  onLoginEnterPressed() {
    this.continueButton.nativeElement.click();
  }

  login(changeLoginState = true) {
    this.checkEmail(this.email);
    if (!this.acceptedTerms) {
      return;
    }
    if (this.isEmailValid) {
      var actionCodeSettings = {
        url: this.baseUrl + ROUTES.LOGIN,
        // This must be true.
        handleCodeInApp: true,
        iOS: {
          bundleId: environment.iosBundleId
        },
        android: {
          packageName: environment.androidPackageName,
          installApp: true
        }
      };
      this.isLoading = true;
      this.startLoadingAnimation();
      this.networkService.signInWithEmail(this.email).subscribe(resp => {
        this.ngZone.run(() => {
          this.isLoading = false;
          if (changeLoginState) {
            this.loginstate = "mail-sent";
          }
        });
        this.window.localStorage.setItem("99music-emailForSignIn", this.email);
      });

      // this.afAuth.auth.sendSignInLinkToEmail(this.email, actionCodeSettings)
      //   .then(() => {
      //     this.ngZone.run(() => {
      //       this.isLoading = false
      //       if (changeLoginState) {
      //         this.loginstate = 'mail-sent'
      //       }
      //     })
      //     this.window.localStorage.setItem('99music-emailForSignIn', this.email);
      //   })
      //   .catch((error) => {
      //     console.log(error)
      //     this.isLoading = false
      //   });
    }
  }

  startLoadingAnimation() {
    if (this.loadingAnimation) {
      this.loadingAnimation.destroy();
    }
    this.loadingAnimation = bodymovin.loadAnimation({
      container: document.getElementById("loader"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: "assets/animations/loader.json"
    });
  }

  sendEvent(type) {
    if (type == "privacy") {
      this.analyticsService.sendEvent(
        "login_page",
        "privacy_policy",
        "מדיניות פרטיות"
      );
    } else {
      if (type == "terms") {
        this.analyticsService.sendEvent(
          "login_page",
          "terms_of_use",
          "תנאי שימוש"
        );
      }
    }
  }

  checkIfAcceptTerms() {
    if (!this.acceptedTerms) {
      this.scaleAcceptTerms();
      return;
    }
  }

  signInWithFacebook() {
    if (!this.acceptedTerms) {
      return;
    }
    var provider = new auth.FacebookAuthProvider();
    // provider.addScope('user_birthday');
    provider.addScope("email");
    this.afAuth.auth.useDeviceLanguage();

    this.analyticsService.sendEvent(
      "login_page",
      "sign_in_facebook",
      "להמשיך עם Facebook"
    );

    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      this.afAuth.auth
        .signInWithRedirect(provider)
        .then(() => {
          this.ngZone.run(() => {
            // console.log(result)
            // this.email = result.additionalUserInfo.profile['email'] || result.user.email
            // this.photoUrl = result.additionalUserInfo.profile['picture'] || result.user.photoURL
          });
        })
        .catch(error => {
          if (error.code == "auth/popup-closed-by-user") {
            return;
          }
          this.sharedService.showAlert("היתה בעיה, אנא נסה שנית");
          console.log(error);
          var errorCode = error.code;
          var errorMessage = error.message;
          var email = error.email;
          var credential = error.credential;
        });
    } else {
      this.afAuth.auth
        .signInWithPopup(provider)
        .then(result => {
          this.ngZone.run(() => {
            this.email =
              result.additionalUserInfo.profile["email"] || result.user.email;
            this.photoUrl =
              result.additionalUserInfo.profile["picture"] ||
              result.user.photoURL;
          });
        })
        .catch(error => {
          if (error.code == "auth/popup-closed-by-user") {
            return;
          }
          this.sharedService.showAlert("היתה בעיה, אנא נסה שנית");
          console.log(error);
          var errorCode = error.code;
          var errorMessage = error.message;
          var email = error.email;
          var credential = error.credential;
        });
    }
  }

  signInWithGoogle() {
    if (!this.acceptedTerms) {
      return;
    }
    var provider = new auth.GoogleAuthProvider();
    provider.addScope("https://www.googleapis.com/auth/userinfo.email");
    this.afAuth.auth.useDeviceLanguage();
    this.analyticsService.sendEvent(
      "login_page",
      "sign_in_google",
      "להמשיך עם Google"
    );

    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      this.afAuth.auth
        .signInWithRedirect(provider)
        .then(() => {
          this.ngZone.run(() => {
            // console.log(result)
            // this.email = result.additionalUserInfo.profile['email'] || result.user.email
            // this.photoUrl = result.additionalUserInfo.profile['picture'] || result.user.photoURL
          });
        })
        .catch(error => {
          if (error.code == "auth/popup-closed-by-user") {
            return;
          }
          this.sharedService.showAlert("היתה בעיה, אנא נסה שנית");
          console.log(error);
          var errorCode = error.code;
          var errorMessage = error.message;
          var email = error.email;
          var credential = error.credential;
        });
    } else {
      this.afAuth.auth
        .signInWithPopup(provider)
        .then(result => {
          this.ngZone.run(() => {
            this.email =
              result.additionalUserInfo.profile["email"] || result.user.email;
            this.photoUrl =
              result.additionalUserInfo.profile["picture"] ||
              result.user.photoURL;
          });
        })
        .catch(error => {
          if (error.code == "auth/popup-closed-by-user") {
            return;
          }
          this.sharedService.showAlert("היתה בעיה, אנא נסה שנית");
          console.log(error);
          var errorCode = error.code;
          var errorMessage = error.message;
          var email = error.email;
          var credential = error.credential;
        });
    }
  }

  sendEmailAgain() {
    if (!this.mailSentAgain) {
      this.login(false);
      this.mailSentAgain = true;
    }
  }

  backToLogin() {
    this.loginstate = "login";
    this.mailSentAgain = false;
  }

  checkEmail(email) {
    // this.emailFocusedOut = false
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.isEmailValid = re.test(email);
  }

  goAsAnnonymus() {
    this.analyticsService.sendEvent(
      "login_page",
      "anonymous_user",
      "Anonymous"
    );
    this.sharedService.loadLastPlayingContentIfExists();
    this.router.navigateByUrl("/");
  }
}
