import { Component, OnInit, OnDestroy } from "@angular/core";
import { SharedService } from "../../services/shared.service";
import { NetworkService } from "../../services/network.service";
import { ShowsQuery } from "../../services/showsQuery";
import {
  AngularFirestore,
  AngularFirestoreCollection
} from "@angular/fire/firestore";
import { MessageService } from "../../services/messageService";
import { environment } from "../../../environments/environment";
import { take } from "rxjs/internal/operators/take";
import { GoogleAnalyticsService } from "../../services/googleAnalytics.service";

let baseUrl = environment.baseUrl;
declare const com_adswizz_synchro_decorateUrl: any;

@Component({
  selector: "app-live-radio",
  templateUrl: "./live-radio.component.html",
  styleUrls: ["./live-radio.component.scss"]
})
export class LiveRadioComponent implements OnInit, OnDestroy {
  parseInt = parseInt;
  radioShows = [];
  shows = [];
  shortlink = "";
  embedLink =
    '<iframe src="' +
    baseUrl +
    "/embedded-player/radio/0" +
    '" scrolling="no" width="593" height="386" frameborder="0"></iframe>';
  liveRadioData;
  radioDesc = `<h1>רדיו און ליין eco99fm</h1>eco99fm מוזיקה מעולה כל היום!
<h2>רדיו אונליין בשידור חי, איתכם 7 ימים בשבוע, 24 שעות ביממה, וזמין בכל מקום בעולם!</h2>
הפסקול האולטימטיבי שלכם ממתין לכם מאחורי כפתור הפליי. מיטב עורכי המוזיקה שלנו בוחרים את השירים הכי טובים שילוו אתכם בכל מקום ובכל שעה, רדיו בשידור חי, תמיד אתכם ותמיד בשבילכם.
ברדיו eco99fm אנחנו אוהבים להתאים את המוזיקה לעולם שבחוץ, ככה שהאזנה לשידור החי תמיד תעניק לכם חווייה שמתאימה למה שקורה עכשיו, בין אם זה מזג האוויר, או האווירה הכללית. רדיו און-ליין נגיש גם אם אתם לא באוטו, או רחוקים מהבית, נגן שידור חי תמיד ייתן לכם את שידורי הרדיו שלנו עם מוזיקה מעולה באיכות גבוהה ללא הפרעות. אז אתם מוזמנים להתחבר אלינו, להתנתק קצת מהרעש בסביבה עם אפליקציית רדיו הכי שווה וכייפית.

  ואם בא לכם עוד מוזיקה מעולה, אתם הכי מוזמנים לשוטט בין הפלייליסטים הערוכים שלנו שיעניקו לכם חוויית האזנה כמו לרדיו חי, כמו אלפי ערוצי רדיו בהתאמה אישית, שמתאימים בדיוק למצב רוח או לפעילות הספציפיים שלכם עכשיו. אנחנו מציעים לכם המלצות פלייליסטים שמתאימים ליום ולשעה, או שאתם יכולים לבחור פלייליסטים בעצמכם, כמו למשל מוזיקה ישראלית, מוזיקה לריכוז, מוזיקה מרימה, מוזיקה שחורה, מוזיקה לריצה פלייליסטים לבוקר, פלייליסטים לעבודה, ועוד.`;

  isLoadingRelated = false;
  constructor(
    public sharedService: SharedService,
    private networkService: NetworkService,
    public messageService: MessageService,
    private afs: AngularFirestore,
    private analyticsService: GoogleAnalyticsService
  ) {
    this.sharedService.showUpperSection = false;
  }

  ngOnInit() {
    this.networkService.createDynamicShortenLink(
      baseUrl + "/live-radio",
      data => {
        this.shortlink = data.shortLink;
      },
      this.sharedService.isPlatformBrowser
    );

    if (this.sharedService.isPlatformBrowser) {
      this.afs
        .collection("streamed_content")
        .doc("program")
        .valueChanges()
        .pipe()
        .subscribe(data => {
          this.liveRadioData = data;

          if (this.sharedService.currentPlayingContentID == "live-radio") {
            let playerData = {
              thumbnail: this.sharedService.getLiveRadioImage(data),
              songName: this.sharedService.getSongName(data),
              artistName: this.sharedService.getArtistName(data)
            };
            this.messageService.updatePlayerDetails(playerData);
          }
        });
    }

    var query = new ShowsQuery();
    this.isLoadingRelated = true;
    this.networkService.getRecommendedShows("IL").subscribe(
      resp => {
        this.shows = resp.items;
        this.isLoadingRelated = false;
      },
      err => {
        this.isLoadingRelated = false;
      }
    );
    this.networkService.getNextScheduledPrograms().subscribe(
      resp => {
        this.radioShows = resp.programs;
      },
      err => {}
    );
  }

  startPlayingRadio() {
    this.analyticsService.sendEvent(
      "live-radio",
      "play_radio_live",
      "play_click"
    );
    this.loadSongToPlayer(this.liveRadioData);
  }

  copyEmbedCode() {
    setTimeout(() => {
      var copyText = document.getElementById("embedText");
      copyText["select"]();
      document.execCommand("copy");
      this.sharedService.showAlert(
        "הקוד להטמעה הועתק ללוח",
        false,
        true,
        "",
        "אחלה",
        true
      );
    });
  }

  loadSongToPlayer(liveRadioData) {
     let radioUrl_mp3: string = "";
     let radioUrl_m4a: string = "";
     const mp3BaseURL = "https://99.mediacast.co.il/99fm?.mp3";
     const m4aBaseURL = "https://99.mediacast.co.il/99fm_aac?.m4a";

     if (typeof com_adswizz_synchro_decorateUrl === 'undefined') {
      radioUrl_mp3 = mp3BaseURL;
      radioUrl_m4a = m4aBaseURL;
     }
     else {
      radioUrl_mp3 = com_adswizz_synchro_decorateUrl(mp3BaseURL);
      radioUrl_m4a = com_adswizz_synchro_decorateUrl(m4aBaseURL);
     }

    if (this.sharedService.isIEBrowser) {
      liveRadioData.streamingLink = radioUrl_mp3;
    } else {
      liveRadioData.streamingLink = radioUrl_m4a;
    }
    liveRadioData.isLive = true;
    this.sharedService.loadSongToPlayer(liveRadioData);
  }


  sendAnalytics(type) {
    switch (type) {
      case "facebookmessanger":
        this.analyticsService.sendEvent(
          "playlists",
          "share_radio",
          "facebook_messanger_sharing"
        );
        break;
      case "facebook":
        this.analyticsService.sendEvent(
          "playlists",
          "share_radio",
          "facebook_sharing"
        );
        break;
      case "whatsapp":
        this.analyticsService.sendEvent(
          "playlists",
          "share_radio",
          "whatsApp_sharing"
        );
        break;
      case "email":
        this.analyticsService.sendEvent(
          "playlists",
          "share_radio",
          "email_sharing"
        );
        break;
      case "native":
        this.analyticsService.sendEvent(
          "playlists",
          "share_radio",
          "native_sharing"
        );
        break;
    }
  }

  ngOnDestroy(): void {
    this.sharedService.showUpperSection = true;
  }
}
