import { Component, OnInit, Input } from "@angular/core";
import { SharedService } from "../../services/shared.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"]
})
export class FooterComponent implements OnInit {
  @Input() footerItems: any;
  @Input() showUpperSection: boolean;
  @Input() footerPartners: [];
  @Input() aboutusText: string;
  @Input() playerIsVisible: boolean;

  constructor(public sharedService: SharedService) {}

  ngOnInit() {}
}
