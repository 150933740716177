import {
  Component,
  OnInit,
  ViewChild,
  HostListener,
  OnDestroy
} from "@angular/core";
import { SharedService } from "../../services/shared.service";
import { NetworkService } from "../../services/network.service";
import { MessageService } from "../../services/messageService";
import { ElementRef, Renderer2 } from "@angular/core";
import { ShowGalleryComponent } from "../../components/show-gallery/show-gallery.component";
import { ShowsQuery } from "../../services/showsQuery";
import { ProgramsQuery } from "../../services/programsQuery";
import { Router, ActivatedRoute } from "@angular/router";
import { GoogleAnalyticsService } from "../../services/googleAnalytics.service";
import { environment } from "../../../environments/environment";
import { MetaService } from "../../services/MetaService";
import { OnAttach, OnDetach } from "../../app.routes-events";

@Component({
  selector: "app-tal-and-aviad",
  templateUrl: "./tal-and-aviad.component.html",
  styleUrls: ["./tal-and-aviad.component.scss"]
})
export class TalAndAviadComponent implements OnInit, OnDestroy {
  selectedTab = 1;
  selectedTabAfterDelay = 1;
  showMonthPicker: boolean;
  textSearchFocus: boolean;
  showsDropdown: boolean;
  selectedYear: number;
  selectedMonth: string;
  noResultsText: string = "מממ… לא נמצאו תוצאות";
  currentYear = new Date().getFullYear();
  selectedShowsQuery = new ProgramsQuery(1, 11, [12]);
  fullShowsQuery = new ProgramsQuery(1, 11, [8]);
  termsText = "";
  aboutText = "";

  months1 = ["ינואר", "פברואר", "מרץ", "אפריל"];
  months2 = ["מאי", "יוני", "יולי", "אוגוסט"];
  months3 = ["ספטמבר", "אוקטובר", "נובמבר", "דצמבר"];
  searchQuery = "";
  pageTitle = "";
  pageDesc = "";
  @ViewChild("selectedShows", { static: false })
  selectedShows: ShowGalleryComponent;
  @ViewChild("fullShows", { static: false }) fullShows: ShowGalleryComponent;

  @ViewChild("textinput", { static: false }) textInput: ElementRef;

  constructor(
    public sharedService: SharedService,
    private networkService: NetworkService,
    private messageService: MessageService,
    public router: Router,
    private route: ActivatedRoute,
    private analyticsService: GoogleAnalyticsService,
    private metaService: MetaService
  ) {
    this.selectedYear = this.currentYear;

    this.route.params.subscribe(res => {
      let meta;
      if (res["activeTab"] != 1) {
        this.pageTitle = "תוכנית הבוקר עם טל ברמן ואביעד קיסוס – תוכניות מלאות";
        this.pageDesc = `
        מכורים כבדים לתוכנית הבוקר עם טל ואביעד? זה המקום בשבילכם! כאן תוכלו למצוא בכל יום את התוכנית המלאה של טל ואביעד, מוכנה להאזנה און ליין. כמו פודקאסט, אם פספסתם את תוכנית הבוקר, תוכלו להאזין לתוכניות מלאות מתי ואיפה שאתם רוצים, אפילו בחו"ל.
        בנוסף, ניתן להוריד את התוכניות המלאות, ללא פרסומות, להאזנה אופליין מהמכשיר גם ללא חיבור לרשת האינטרנט.`;
        meta = {
          title: this.pageTitle,
          description: this.pageDesc,
          imageUrl: environment.baseUrl + "/assets/images/tal-aviad-bkg1.jpg",
          url: environment.baseUrl + "/program/טל_ואביעד/tab/1"
        };
      } else {
        this.pageTitle = "תוכנית הבוקר עם טל ברמן ואביעד קיסוס – קטעים נבחרים";
        this.pageDesc = `
        חייבים לקבל את המנה היומית של טל ואביעד? מחפשים את הפינה המצחיקה של חגית גינצבורג משבוע שעבר, או את המתכון של לימור לניאדו תירוש, או את הטיפ המעולה לגידול ילדים של עינת נתן? אז זה המקום בשבילכם! הקטעים הנבחרים של תוכנית הבוקר עם טל ואביעד מדי בוקר עולים וזמינים להאזנה שלכם מתי ואיפה שאתם רוצים.
        בנוסף, אפשר כמובן לשתף את הקטעים וגם להוריד את הקטעים למכשיר להאזנה מתי שאתם רוצים באופליין גם ללא אינטרנט`;
        meta = {
          title: this.pageTitle,
          description: this.pageDesc,
          imageUrl:
            environment.baseUrl +
            "/assets/images/broadcasters/Tal_and_Aviad.jpg",
          url: environment.baseUrl + "/program/טל_ואביעד/tab/1"
        };
      }
      this.metaService.updateMetaInfo(
        meta.title,
        meta.description,
        meta.imageUrl,
        meta.url
      );
      this.selectTab(res["activeTab"], false);
    });
  }

  ngOnInit() {
    window.addEventListener("scroll", this.scroll, true); //third parameter
    this.networkService.getTermsAndAboutTextsForCategory(1).subscribe(
      resp => {
        if (resp.t_a_v_game_terms_and_conditions && resp.t_a_v_about_program) {
          this.termsText = resp.t_a_v_game_terms_and_conditions.description;
          this.aboutText = resp.t_a_v_about_program.description;
        }
      },
      err => {}
    );

    setTimeout(() => {
      this.route.queryParams.subscribe(params => {
        if (params["date"]) {
          this.selectedMonth = params["date"].split("_")[0];
          this.selectedYear = params["date"].split("_")[1];
          this.searchByDate();
        }
        if (params["text"]) {
          this.searchQuery = params["text"].split("_").join(" ");
          this.searchByString();
        }
      });
    }, 200);
  }

  scroll = (e): void => {
    if (e.currentTarget.pageYOffset > 580) {
      this.sharedService.showCollapsedHeader = true;
    } else {
      this.sharedService.showCollapsedHeader = false;
    }
  };
  ngAfterViewInit() {}

  ngOnDestroy(): void {
    this.sharedService.showHeader = true;
    this.sharedService.showUpperSection = true;
    this.sharedService.showCollapsedHeader = false;
    window.removeEventListener("scroll", this.scroll, true);
    this.showMonthPicker = false;
    this.textSearchFocus = false;
  }

  @ViewChild("monthPicker", { static: false }) monthPicker;
  @ViewChild("dateInput", { static: false }) dateInput;
  @HostListener("document:click", ["$event.target"])
  public onClick(targetElement) {
    const clickedInside =
      this.monthPicker.nativeElement.contains(targetElement) ||
      this.dateInput.nativeElement.contains(targetElement);
    if (this.showMonthPicker && !clickedInside) {
      this.showMonthPicker = false;
    }
  }

  toggleDrawer() {
    this.sharedService.showMenuTablet = !this.sharedService.showMenuTablet;
  }

  toggleMonthPicker() {
    if (!this.showMonthPicker) {
      setTimeout(() => {
        this.showMonthPicker = true;
        this.searchQuery = "";
      }, 100);
    } else {
      this.showMonthPicker = false;
      if (this.selectedMonth) {
        this.router.navigate(["/program/טל_ואביעד/tab/1/"], {
          queryParams: {
            date: this.selectedMonth + "_" + this.selectedYear
          }
        });
      }
      this.searchByDate();
    }
  }

  toggleTextInput() {
    if (!this.textSearchFocus) {
      this.textSearchFocus = true;
      setTimeout(() => {
        this.textInput.nativeElement.focus();
      }, 0); 
      this.showMonthPicker = false;
    } else {
      this.textSearchFocus = false;
      if (this.searchQuery) {
        this.router.navigate(["/program/טל_ואביעד/tab/1/"], {
          queryParams: {
            text: this.searchQuery.split(" ").join("_")
          }
        });
      }
      this.searchByString();
    }
  }

  cleanMonthPicker() {
    this.router.navigateByUrl("program/טל_ואביעד/tab/1");
    this.selectedMonth = "";
    this.searchByDate();
  }

  cleanTextInput() {
    this.router.navigateByUrl("program/טל_ואביעד/tab/1");
    this.searchQuery = "";
    this.searchByString();
  }

  focusElement(element) {
    setTimeout(() => {
      element.focus();
    });
  }

  yearUp() {
    if (this.selectedYear < this.currentYear) {
      this.selectedYear++;
    }
  }

  yearDown() {
    if (this.selectedYear > 2010) {
      this.selectedYear--;
    }
  }

  selectTab(tab, isUserInteraction = true) {
    if (tab == this.selectedTab) return;

    if (isUserInteraction) {
      switch (tab) {
        case 1:
          this.analyticsService.sendEvent(
            "tal_and_aviad",
            "selected_sections_tab",
            "קטעים_נבחרים"
          );
          break;
        case 2:
          this.analyticsService.sendEvent(
            "tal_and_aviad",
            "full_show_tab",
            "תוכניות_מלאות"
          );
          break;
        case 3:
          this.analyticsService.sendEvent(
            "tal_and_aviad",
            "about_show_tab",
            "על_התוכנית"
          );
          break;
        case 4:
          this.analyticsService.sendEvent(
            "tal_and_aviad",
            "terms_of_game_tab",
            "תקנון_משחק_התוכנית"
          );
          break;
        case 5:
          this.analyticsService.sendEvent(
            "tal_and_aviad",
            "facebook_link",
            "הבוקר_בפייסבוק"
          );
          break;
      }
    }

    if (tab == 5) return;

    if (isUserInteraction) {
      this.router.navigateByUrl("/program/טל_ואביעד/tab/" + tab);
    }
    this.selectedTab = tab;
    this.showsDropdown = false;
    if (this.sharedService.isPlatformBrowser) {
      window.scrollTo(0, 0);
    }
    setTimeout(() => {
      this.selectedTabAfterDelay = tab;
    }, 150);
  }

  searchByDate() {
    let date = undefined;
    if (this.selectedMonth) {
      let month = this.sharedService.getMonthString(this.selectedMonth);
      let year = this.selectedYear;
      date = new Date(month + "/01/" + year).toISOString();
    }
    this.analyticsService.sendEvent(
      "tal_and_aviad",
      "search_by_date_field_tal_aviad",
      "search_by_date",
      date
    );

    if (
      this.selectedYear != this.currentYear &&
      this.sharedService.user &&
      this.sharedService.user.payment_data.subscription == 4
    ) {
      this.noResultsText =
        "מממ… עליך להרשם למסלול Pro כדי לשמוע תוכניות מן העבר";
    } else {
      this.noResultsText = "לא נמצאו תוכניות לפי התאריך שחיפשת";
    }

    let selectedShowsQuery;
    if (!date) {
      selectedShowsQuery = new ProgramsQuery(1, 11, [12], date);
    } else {
      selectedShowsQuery = new ProgramsQuery(1, 11, [12, 2], date);
    }

    this.selectedShows.loadShows(selectedShowsQuery, true);

    if (!date) {
      selectedShowsQuery = new ProgramsQuery(1, 11, [8], date);
    } else {
      selectedShowsQuery = new ProgramsQuery(1, 11, [8, 1], date);
    }
    this.fullShows.loadShows(selectedShowsQuery, true);

    //move to selected shows on text search
    if (this.selectedTab != 2) {
      this.selectTab(1, false);
    }
  }

  searchByString() {
    // this.noResultsText = 'לא נמצאו תוכניות עבור<br>״'+ this.searchQuery + "״"
    this.noResultsText = "לא נמצאו תוכניות לפי הנושא שחיפשת";

    // if (!this.searchQuery) {
    //   this.searchQuery = undefined
    //   return
    // }
    this.analyticsService.sendEvent(
      "tal_and_aviad",
      "search_field_by_topic",
      "search_by_text",
      this.searchQuery
    );

    let selectedShowsQuery;
    if (!this.searchQuery) {
      selectedShowsQuery = new ProgramsQuery(
        1,
        11,
        [12],
        undefined,
        this.searchQuery
      );
    } else {
      selectedShowsQuery = new ProgramsQuery(
        1,
        11,
        [12, 2],
        undefined,
        this.searchQuery
      );
    }
    this.selectedShows.loadShows(selectedShowsQuery, true);

    if (!this.searchQuery) {
      selectedShowsQuery = new ProgramsQuery(
        1,
        11,
        [8],
        undefined,
        this.searchQuery
      );
    } else {
      selectedShowsQuery = new ProgramsQuery(
        1,
        11,
        [1, 8],
        undefined,
        this.searchQuery
      );
    }
    this.fullShows.loadShows(selectedShowsQuery, true);
    //move to selected shows on text search
    this.selectTab(1, false);
  }
}
