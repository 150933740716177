import { Component, OnInit, OnDestroy, Inject } from "@angular/core";
import { SharedService } from "../../services/shared.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { WINDOW } from "@ng-toolkit/universal";
import { NetworkService } from "../../services/network.service";
import { ActivatedRoute, Router } from "@angular/router";
declare var PayMe: any;
declare var $: any;
declare var bodymovin: any;

@Component({
  selector: "app-payment",
  templateUrl: "./payment.component.html",
  styleUrls: ["./payment.component.scss"]
})
export class PaymentComponent implements OnInit, OnDestroy {
  showNumberError = false;
  showCardErrors = false;
  showExpErrors = false;
  showNameErrors = false;
  showCVCErrors = false;
  showIDErrors = false;

  isValidForm = false;
  waitingToResp = false;
  isLoading = false;
  instance: any;
  cardNumber: string = "";
  expiry: string = "";
  cvv: string = "";
  ownerName: string = "";
  ownerId: string = "";
  validationResults = {};
  packageType: any;
  state: any;
  errorsFromField = {};
  packageObj: any;
  loadingAnimation: any;
  cardVendor = "";

  constructor(
    @Inject(WINDOW) private window: Window,
    public sharedService: SharedService,
    public fb: FormBuilder,
    private networkService: NetworkService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.packageType = this.route.snapshot.paramMap.get("packageType");
    this.state = this.route.snapshot.paramMap.get("state");
  }

  ngOnInit() {
    this.sharedService.showUpperSection = false;
    this.initPaymeForm("MPL15597-35400L2W-8FH1M7HD-XCC51KDA");
    this.networkService.getProPackages().subscribe(
      resp => {
        resp.items.forEach(pkg => {
          if (pkg.type == this.packageType) {
            this.packageObj = pkg;
          }
        });
      },
      err => {}
    );
  }

  ngOnDestroy(): void {
    this.sharedService.showUpperSection = true;
  }

  initPaymeForm(mpl) {
    // Cache DOM Nodes ---------------------------------------------------------------------------------------------------
    const form = document.getElementById("checkout-form-he");
    const cardProvider = $("#card-provider-he");
    const errorsMessagesContainer = document.getElementById("errors-he");
    const cardNumberContainer = $("#card-number-group-he");
    const cardExpContainer = $("#card-expiration-group-he");
    const cardCvvContainer = $("#card-cvv-group-he");
    const fullNameContainer = $("#full-name-group-he");
    const idInput = $("#id-input-he");
    let that = this;
    // Helpers -----------------------------------------------------------------------------------------------------------

    function checkForm(errorsFromField, validationResult) {
      switch (validationResult.field) {
        case "cardNumber":
          if (!validationResult.isValid && that.showCardErrors) {
            delete that.validationResults[validationResult.field];
            cardNumberContainer
              .find("#card-number-container-he")
              .addClass("invalid");
            cardNumberContainer.find(".error-string").addClass("show");
            cardNumberContainer
              .find(".error-string")
              .text(errorsFromField["cardNumber"]);
            cardNumberContainer.find(".wrong-value-icon").addClass("show");
          } else {
            that.validationResults[validationResult.field] = true;
            cardNumberContainer
              .find("#card-number-container-he")
              .removeClass("invalid");
            cardNumberContainer.find(".error-string").removeClass("show");
            cardNumberContainer.find(".wrong-value-icon").removeClass("show");
          }
          break;
        case "cardExpiration":
          if (!validationResult.isValid && that.showExpErrors) {
            delete that.validationResults[validationResult.field];
            cardExpContainer
              .find("#card-expiration-container-he")
              .addClass("invalid");
            cardExpContainer.find(".error-string").addClass("show");
            cardExpContainer
              .find(".error-string")
              .text(errorsFromField["cardExpiration"]);
            cardExpContainer.find(".wrong-value-icon").addClass("show");
          } else {
            that.validationResults[validationResult.field] = true;
            cardExpContainer
              .find("#card-expiration-container-he")
              .removeClass("invalid");
            cardExpContainer.find(".error-string").removeClass("show");
            cardExpContainer.find(".wrong-value-icon").removeClass("show");
          }
          break;
        case "cvc":
          if (!validationResult.isValid && that.showCVCErrors) {
            delete that.validationResults[validationResult.field];
            cardCvvContainer.find("#card-cvv-container-he").addClass("invalid");
            cardCvvContainer.find(".error-string").addClass("show");
            cardCvvContainer.find(".error-string").text(errorsFromField["cvc"]);
            cardCvvContainer.find(".wrong-value-icon").addClass("show");
          } else {
            that.validationResults[validationResult.field] = true;
            cardCvvContainer
              .find("#card-cvv-container-he")
              .removeClass("invalid");
            cardCvvContainer.find(".error-string").removeClass("show");
            cardCvvContainer.find(".wrong-value-icon").removeClass("show");
          }
          break;
        case "payerFirstName":
          if (!validationResult.isValid && that.showNameErrors) {
            delete that.validationResults[validationResult.field];
            $("#full-name-group-he .wrong-value-icon").addClass("show");
            $("#full-name-group-he .error-string").addClass("show");
            $("#full-name-group-he .error-string").text(
              validationResult.message
            );
            $("#full-name-group-he #full-name-input-he").addClass("invalid");
          } else {
            that.validationResults[validationResult.field] = true;
            $("#full-name-group-he .wrong-value-icon").removeClass("show");
            $("#full-name-group-he .error-string").removeClass("show");
            $("#full-name-group-he #full-name-input-he").removeClass("invalid");
          }
          break;
        case "payerSocialId":
          if (!validationResult.isValid && that.showIDErrors) {
            delete that.validationResults[validationResult.field];
            $("#id-group-he .wrong-value-icon").addClass("show");
            $("#id-group-he .error-string").addClass("show");
            $("#id-group-he .error-string").text(validationResult.message);
            $("#id-group-he #id-input-he").addClass("invalid");
          } else {
            that.validationResults[validationResult.field] = true;
            $("#id-group-he .wrong-value-icon").removeClass("show");
            $("#id-group-he .error-string").removeClass("show");
            $("#id-group-he #id-input-he").removeClass("invalid");
          }
          break;
        default:
          break;
      }
    }

    function toggleValidationMessages(validationResult) {
      delete that.errorsFromField[PayMe.fields.NONE];

      if (validationResult.isValid) {
        delete that.errorsFromField[validationResult.field];
      } else {
        that.errorsFromField[validationResult.field] = validationResult.message; // write errors to the object
      }
      checkForm(that.errorsFromField, validationResult);
    }

    function changeCardProviderIcon(cardVendor) {
      that.cardVendor = that.sharedService.vendorsToNumber[cardVendor];
      cardProvider.attr("src", that.sharedService.vendorsToClasses[cardVendor]);
      if (cardVendor == "unknown") {
        cardProvider.css("transform", "scale(0)");
      } else {
        cardProvider.css("transform", "scale(1)");
      }
    }

    function runNativeFieldValidator(value, field, messages) {
      let errors = {};
      if (field == "payerSocialId") {
        if (value.length == 0) {
          errors["required"] = true;
          errors["message"] = messages.required;
        } else {
          errors["invalid"] = that.checkIdnum(value) != 1;
          errors["required"] = false;
          errors["message"] = errors["invalid"] ? messages.invalid : "";
        }
      } else {
        const validator = PayMe.validators[field];
        errors = validator.test(value);
      }
      let message;
      if (errors && errors["required"]) {
        message = messages.required;
      }
      if (errors && errors["invalid"]) {
        message = messages.invalid;
      }

      return {
        isValid:
          (errors && !errors["required"] && !errors["invalid"]) || !errors,
        field: field,
        message: message
      };
    }

    function createNativeFieldValidatorHandler(fieldName, messagesObject) {
      return function(ev) {
        const validation = runNativeFieldValidator(
          ev.target.value,
          fieldName,
          messagesObject
        );
        toggleValidationMessages(validation);
      };
    }

    // Misc --------------------------------------------------------------------------------------------------------------

    const allFieldsReady = [];

    const DEFAULT_SETTINGS = {
      styles: {
        base: {
          "font-size": "16px",
          "font-weight": "700",
          "font-family": "Heebo",
          "::placeholder": { color: "#ffffff80" },
          "text-align": "right"
        },
        invalid: {
          color: "#ffffff"
        },
        valid: {
          color: "#ffffff"
        }
      }
    };

    // Main --------------------------------------------------------------------------------------------------------------
    function init() {
      // Getting hosted fields integration manager
      PayMe.create(mpl, { testMode: true, language: "he" }).then(instance => {
        const fields = instance.hostedFields();

        // Protected fields ------------------------------------------------------

        // Card Number
        const cardNumberSettings = Object.assign({}, DEFAULT_SETTINGS, {
          placeholder: "מספר כרטיס אשראי",
          messages: {
            invalid: "מספר כרטיס אשראי לא תקין",
            required: "שדה חובה"
          }
        });
        const cardNumber = fields.create(
          PayMe.fields.NUMBER,
          cardNumberSettings
        );
        allFieldsReady.push(cardNumber.mount("#card-number-container-he"));
        cardNumber.on("card-type-changed", ev =>
          changeCardProviderIcon(ev.cardType)
        );
        cardNumber.on("keyup", e => {
          that.showCardErrors = false;
          toggleValidationMessages(e);
        });
        cardNumber.on("blur", e => {
          that.showCardErrors = true;
          toggleValidationMessages(e);
        });

        // Expiry Date
        const expirationField = Object.assign({}, DEFAULT_SETTINGS, {
          messages: {
            invalid: "תוקף לא תקין",
            required: "שדה חובה"
          }
        });
        const expiration = fields.create(
          PayMe.fields.EXPIRATION,
          expirationField
        );
        allFieldsReady.push(expiration.mount("#card-expiration-container-he"));
        expiration.on("keyup", e => {
          that.showExpErrors = false;
          toggleValidationMessages(e);
        });
        expiration.on("blur", e => {
          that.showExpErrors = true;
          toggleValidationMessages(e);
        });
        expiration.on("validity-changed", toggleValidationMessages);

        // CVC/CVV
        const cvcField = Object.assign({}, DEFAULT_SETTINGS, {
          placeholder: "CVV",
          messages: {
            invalid: "CVV שגוי",
            required: "שדה חובה"
          }
        });
        const cvc = fields.create(PayMe.fields.CVC, cvcField);
        allFieldsReady.push(cvc.mount("#card-cvv-container-he"));
        cvc.on("blur", e => {
          that.showCVCErrors = true;
          toggleValidationMessages(e);
        });
        cvc.on("keyup", e => {
          that.showCVCErrors = false;
          toggleValidationMessages(e);
        });
        cvc.on("validity-changed", toggleValidationMessages);

        // AUX fields ------------------------------------------------------------

        // First Name
        const firstNameMessages = {
          invalid: "שדה שם חייב להכיל אותיות בלבד",
          required: "שדה חובה"
        };
        fullNameContainer.find("#full-name-input-he").on("keyup", e => {
          that.showNameErrors = false;
          createNativeFieldValidatorHandler(
            PayMe.fields.NAME_FIRST,
            firstNameMessages
          )(e);
        });
        fullNameContainer
          .find("#full-name-input-he")
          .on(
            "focus",
            createNativeFieldValidatorHandler(
              PayMe.fields.NAME_FIRST,
              firstNameMessages
            )
          );
        fullNameContainer.find("#full-name-input-he").on("blur", e => {
          that.showNameErrors = true;
          createNativeFieldValidatorHandler(
            PayMe.fields.NAME_FIRST,
            firstNameMessages
          )(e);
        });

        // ID Number
        const IDMessages = {
          invalid: "ת.ז. לא תקינה",
          required: "שדה חובה"
        };
        idInput.on("keyup", e => {
          that.showIDErrors = false;
          createNativeFieldValidatorHandler(
            PayMe.fields.SOCIAL_ID,
            IDMessages
          )(e);
        });
        idInput.on("focus", e => {
          createNativeFieldValidatorHandler(
            PayMe.fields.SOCIAL_ID,
            IDMessages
          )(e);
        });
        idInput.on("blur", e => {
          that.showIDErrors = true;
          createNativeFieldValidatorHandler(
            PayMe.fields.SOCIAL_ID,
            IDMessages
          )(e);
        });

        // Wait for fields initialization ----------------------------------------

        Promise.all(allFieldsReady).then();

        // Form submission handler -----------------------------------------------

        const formSubmit = ev => {
          if (!that.isFormActive()) {
            return;
          }
          that.waitingToResp = true;
          that.startLoadingAnimation();
          ev.preventDefault();
          const sale = {
            payerFirstName:
              $("#full-name-input-he")[0].value.split(" ")[0] == ""
                ? " "
                : $("#full-name-input-he")[0].value.split(" ")[0],
            payerLastName:
              $("#full-name-input-he")[0].value.split(" ").length > 1
                ? $("#full-name-input-he")[0].value.split(" ")[1] == ""
                  ? " "
                  : $("#full-name-input-he")[0].value.split(" ")[1]
                : " ",
            payerEmail: that.sharedService.user.personal_data.email,
            payerPhone: "0547777777", //TODO: remove hardcoded when enabled
            payerSocialId: $("#id-input-he")[0].value,
            total: {
              label: that.packageObj.name,
              amount: {
                currency: "ILS",
                value: that.packageObj.price.toFixed(2)
              }
            }
          };

          //   tokenizationStarted();
          toggleValidationMessages({ field: PayMe.fields.NONE, isValid: true });
          instance
            .tokenize(sale)
            .then(data => {
              that.networkService
                .sendPaymentDetails({
                  payment_token: data.token,
                  card_expiry_date: data.card.expiry,
                  // card_type: that.cardVendor,
                  card_mask: data.card.cardMask.slice(
                    data.card.cardMask.length - 8,
                    data.card.cardMask.length
                  )
                })
                .subscribe(resp => {
                  that.startLoadingAnimation();
                  that.isLoading = true;
                  that.networkService
                    .setSubscriptionPlan(that.packageType)
                    .subscribe(
                      resp => {
                        that.sharedService.user = resp;
                        that.waitingToResp = false;
                        that.sharedService.user = resp;
                        that.sharedService.showUpdatePackagePopup(
                          that.packageType
                        );
                        instance.teardown();
                      },
                      err => {
                        that.waitingToResp = false;
                        that.sharedService.showAlert(
                          "משהו השתבש והתשלום לא עבר. כדאי לעבור שוב על פרטי התשלום ולוודא שהכל נכון",
                          true,
                          false,
                          "משהו השתבש והתשלום לא עבר",
                          "אוקיי, מנסה שוב",
                          true
                        );
                      }
                    );
                });
            })
            .catch(err => {
              that.waitingToResp = false;
              that.sharedService.showAlert(
                err.payload.status_error_details,
                true,
                false,
                "אופסי, משהו השתבש...",
                "הבנתי",
                true
              );
            });
        };

        // Events binding --------------------------------------------------------

        form.addEventListener("submit", formSubmit);
      });
    }

    init();
  }

  isFormActive() {
    return (
      this.validationResults["cardNumber"] &&
      this.validationResults["cardExpiration"] &&
      this.validationResults["cvc"] &&
      this.validationResults["payerFirstName"] &&
      this.validationResults["payerSocialId"]
    );
  }

  startLoadingAnimation() {
    if (this.loadingAnimation) {
      this.loadingAnimation.destroy();
    }
    this.loadingAnimation = bodymovin.loadAnimation({
      container: document.getElementById("loader"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: "assets/animations/loader.json"
    });
  }

  checkIdnum(idnum: any): number {
    var R_ELEGAL_INPUT = -1;
    var R_NOT_VALID = -2;
    var R_VALID = 1;
    var IDnum = String(idnum);

    if (parseInt(IDnum) == 0 || IDnum == "null") {
      return R_NOT_VALID;
    }

    // Validate correct input
    if (IDnum.length > 9 || IDnum.length < 5) return R_ELEGAL_INPUT;
    if (isNaN(idnum)) return R_ELEGAL_INPUT;

    // The number is too short - add leading 0000
    if (IDnum.length < 9) {
      while (IDnum.length < 9) {
        IDnum = "0" + IDnum;
      }
    }

    // CHECK THE ID NUMBER
    var mone = 0,
      incNum;
    for (var i = 0; i < 9; i++) {
      incNum = Number(IDnum.charAt(i));
      incNum *= (i % 2) + 1;
      if (incNum > 9) incNum -= 9;
      mone += incNum;
    }
    if (mone % 10 == 0) return R_VALID;
    else return R_NOT_VALID;
  }
}
