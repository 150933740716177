import { Component, OnInit, ViewChild, HostListener } from "@angular/core";
import { SharedService } from "../../services/shared.service";
import { NetworkService } from "../../services/network.service";
import { MessageService } from "../../services/messageService";
import { ElementRef } from "@angular/core";
import { ShowGalleryComponent } from "../../components/show-gallery/show-gallery.component";
import { ProgramsQuery } from "../../services/programsQuery";
import { Router, ActivatedRoute } from "@angular/router";
import { GoogleAnalyticsService } from "../../services/googleAnalytics.service";
import { environment } from "../../../environments/environment";
import { MetaService } from "../../services/MetaService";

@Component({
  selector: "app-new-morning-show",
  templateUrl: "./new-morning-show.component.html",
  styleUrls: ["./new-morning-show.component.scss"],
})
export class NewMorningShowComponent implements OnInit {
  selectedTab = 1;
  selectedTabAfterDelay = 1;
  showMonthPicker: boolean;
  textSearchFocus: boolean;
  showsDropdown: boolean;
  selectedYear: number;
  selectedMonth: string;
  noResultsText: string = "מממ… לא נמצאו תוצאות";
  currentYear = new Date().getFullYear();
  selectedShowsQuery = new ProgramsQuery(1, 11, [31]);
  fullShowsQuery = new ProgramsQuery(1, 11, [32]);
  termsText = "";
  aboutText = "";

  months1 = ["ינואר", "פברואר", "מרץ", "אפריל"];
  months2 = ["מאי", "יוני", "יולי", "אוגוסט"];
  months3 = ["ספטמבר", "אוקטובר", "נובמבר", "דצמבר"];
  searchQuery = "";
  pageTitle = "";
  pageDesc = "";
  @ViewChild("selectedShows", { static: false })
  selectedShows: ShowGalleryComponent;
  @ViewChild("fullShows", { static: false }) fullShows: ShowGalleryComponent;
  @ViewChild("textinput", { static: false }) textInput: ElementRef;

  constructor(
    public sharedService: SharedService,
    private networkService: NetworkService,
    private messageService: MessageService,
    public router: Router,
    private route: ActivatedRoute,
    private analyticsService: GoogleAnalyticsService,
    private metaService: MetaService
  ) {
    this.selectedYear = this.currentYear;

    this.route.params.subscribe((res) => {
      let meta;
      if (res["activeTab"] != 1) {
        meta = {
          title: this.pageTitle,
          description: this.pageDesc,
          imageUrl: environment.baseUrl + "/assets/images/aviha-tom-tal.png",
          url: environment.baseUrl + "/program/בוקר_חדש/tab/1",
        };
      } else {
        meta = {
          title: this.pageTitle,
          description: this.pageDesc,
          imageUrl: environment.baseUrl + "/assets/images/aviha-tom-tal.png",
          url: environment.baseUrl + "/program/בוקר_חדש/tab/1",
        };
      }
      this.metaService.updateMetaInfo(
        meta.title,
        meta.description,
        meta.imageUrl,
        meta.url
      );
      this.selectTab(res["activeTab"], false);
    });
  }

  ngOnInit() {
    window.addEventListener("scroll", this.scroll, true); //third parameter
    this.networkService.getTermsAndAboutTextsForCategory(32).subscribe(
      (resp) => {
        if (
          resp.new_morning_game_terms_and_conditions &&
          resp.new_morning_about_program
        ) {
          this.termsText =
            resp.new_morning_game_terms_and_conditions.description;
          this.aboutText = resp.new_morning_about_program.description;
        }
      },
      (err) => {}
    );

    setTimeout(() => {
      this.route.queryParams.subscribe((params) => {
        if (params["date"]) {
          this.selectedMonth = params["date"].split("_")[0];
          this.selectedYear = params["date"].split("_")[1];
          this.searchByDate();
        }
        if (params["text"]) {
          this.searchQuery = params["text"].split("_").join(" ");
          this.searchByString();
        }
      });
    }, 200);
  }

  scroll = (e): void => {
    if (e.currentTarget.pageYOffset > 580) {
      this.sharedService.showCollapsedHeader = true;
    } else {
      this.sharedService.showCollapsedHeader = false;
    }
  };
  ngAfterViewInit() {}

  ngOnDestroy(): void {
    this.sharedService.showHeader = true;
    this.sharedService.showUpperSection = true;
    this.sharedService.showCollapsedHeader = false;
    window.removeEventListener("scroll", this.scroll, true);
    this.showMonthPicker = false;
    this.textSearchFocus = false;
  }

  @ViewChild("monthPicker", { static: false }) monthPicker;
  @ViewChild("dateInput", { static: false }) dateInput;
  @HostListener("document:click", ["$event.target"])
  public onClick(targetElement) {
    const clickedInside =
      this.monthPicker.nativeElement.contains(targetElement) ||
      this.dateInput.nativeElement.contains(targetElement);
    if (this.showMonthPicker && !clickedInside) {
      this.showMonthPicker = false;
    }
  }

  toggleDrawer() {
    this.sharedService.showMenuTablet = !this.sharedService.showMenuTablet;
  }

  toggleMonthPicker() {
    if (!this.showMonthPicker) {
      setTimeout(() => {
        this.showMonthPicker = true;
        this.searchQuery = "";
      }, 100);
    } else {
      this.showMonthPicker = false;
      if (this.selectedMonth) {
        this.router.navigate(["/program/בוקר_חדש/tab/1/"], {
          queryParams: {
            date: this.selectedMonth + "_" + this.selectedYear,
          },
        });
      }
      this.searchByDate();
    }
  }

  toggleTextInput() {
    if (!this.textSearchFocus) {
      this.textSearchFocus = true;
      setTimeout(() => {
        this.textInput.nativeElement.focus();
      }, 0); 
      this.showMonthPicker = false;
    } else {
      this.textSearchFocus = false;
      if (this.searchQuery) {
        this.router.navigate(["/program/בוקר_חדש/tab/1/"], {
          queryParams: {
            text: this.searchQuery.split(" ").join("_"),
          },
        });
      }
      this.searchByString();
    }
  }

  cleanMonthPicker() {
    this.router.navigateByUrl("program/בוקר_חדש/tab/1");
    this.selectedMonth = "";
    this.searchByDate();
  }

  cleanTextInput() {
    this.router.navigateByUrl("program/בוקר_חדש/tab/1");
    this.searchQuery = "";
    this.searchByString();
  }

  focusElement(element) {
    setTimeout(() => {
      element.focus();
    });
  }

  yearUp() {
    if (this.selectedYear < this.currentYear) {
      this.selectedYear++;
    }
  }

  yearDown() {
    if (this.selectedYear > 2010) {
      this.selectedYear--;
    }
  }

  selectTab(tab, isUserInteraction = true) {
    if (tab == this.selectedTab) return;

    if (isUserInteraction) {
      switch (tab) {
        case 1:
          this.analyticsService.sendEvent(
            "new_morning",
            "selected_sections_tab",
            "קטעים_נבחרים"
          );
          break;
        case 2:
          this.analyticsService.sendEvent(
            "new_morning",
            "full_show_tab",
            "תוכניות_מלאות"
          );
          break;
        case 3:
          this.analyticsService.sendEvent(
            "new_morning",
            "about_show_tab",
            "על_התוכנית"
          );
          break;
        case 4:
          this.analyticsService.sendEvent(
            "new_morning",
            "terms_of_game_tab",
            "תקנון_משחק_התוכנית"
          );
          break;
        case 5:
          this.analyticsService.sendEvent(
            "new_morning",
            "facebook_link",
            "הבוקר_בפייסבוק"
          );
          break;
      }
    }

    if (tab == 5) return;

    if (isUserInteraction) {
      this.router.navigateByUrl("/program/בוקר_חדש/tab/" + tab);
    }
    this.selectedTab = tab;
    this.showsDropdown = false;
    if (this.sharedService.isPlatformBrowser) {
      window.scrollTo(0, 0);
    }
    setTimeout(() => {
      this.selectedTabAfterDelay = tab;
    }, 150);
  }

  searchByDate() {
    let date = undefined;
    if (this.selectedMonth) {
      let month = this.sharedService.getMonthString(this.selectedMonth);
      let year = this.selectedYear;
      date = new Date(month + "/01/" + year).toISOString();
    }
    this.analyticsService.sendEvent(
      "new_morning",
      "search_by_date_field_new_morning",
      "search_by_date",
      date
    );

    if (
      this.selectedYear != this.currentYear &&
      this.sharedService.user &&
      this.sharedService.user.payment_data.subscription == 4
    ) {
      this.noResultsText =
        "מממ… עליך להרשם למסלול Pro כדי לשמוע תוכניות מן העבר";
    } else {
      this.noResultsText = "לא נמצאו תוכניות לפי התאריך שחיפשת";
    }

    let selectedShowsQuery = new ProgramsQuery(1, 11, [31], date);
    this.selectedShows.loadShows(selectedShowsQuery, true);

    selectedShowsQuery = new ProgramsQuery(1, 11, [32], date);
    this.fullShows.loadShows(selectedShowsQuery, true);

    //move to selected shows on text search
    if (this.selectedTab != 2) {
      this.selectTab(1, false);
    }
  }

  searchByString() {
    this.noResultsText = "לא נמצאו תוכניות לפי הנושא שחיפשת";

    this.analyticsService.sendEvent(
      "new_morning",
      "search_field_by_topic",
      "search_by_text",
      this.searchQuery
    );

    let selectedShowsQuery = new ProgramsQuery(
      1,
      11,
      [31],
      undefined,
      this.searchQuery
    );
    this.selectedShows.loadShows(selectedShowsQuery, true);
    
    selectedShowsQuery = new ProgramsQuery(
      1,
      11,
      [32],
      undefined,
      this.searchQuery
    );
    this.fullShows.loadShows(selectedShowsQuery, true);
    //move to selected shows on text search
    this.selectTab(1, false);
  }
}
