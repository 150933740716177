import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { SharedService } from "../../services/shared.service";
import { BoardingDialogComponent } from "../../dialogs/boarding-dialog/boarding-dialog.component";
import { ChangeDetectorRef } from "@angular/core";
import { GoogleAnalyticsService } from "../../services/googleAnalytics.service";

@Component({
  selector: "app-settings-tablet",
  templateUrl: "./settings-tablet.component.html",
  styleUrls: ["./settings-tablet.component.scss"]
})
export class SettingsTabletComponent implements OnInit {
  @Output() toggleEditModeTablet = new EventEmitter<string>();

  checked = true;
  disabled = false;

  constructor(
    public sharedService: SharedService,
    private cdRef: ChangeDetectorRef,
    public analyticsService: GoogleAnalyticsService
  ) {}

  ngOnInit() {}

  showOnBoarding() {
    this.sharedService.showOnboarding(BoardingDialogComponent, true);
  }

  changeToEdit() {
    this.analyticsService.sendEvent(
      "settings",
      "edit_profile_button",
      "עריכת פרופיל"
    );
    this.toggleEditModeTablet.emit();
  }
  logout() {
    this.sharedService.showLogoutConfirm();
  }
}
