import { HttpParams } from "@angular/common/http";

export class ProgramsQuery {
  page: number;
  itemsPerPage: number;
  category_id: number[];
  date_filter: string;
  name_filter: string;
  byDay: boolean;

  constructor(
    page: number = 1,
    itemsPerPage: number = 10,
    category_id: number[] = undefined,
    date_filter: string = undefined,
    name_filter: string = undefined,
    byDay: boolean = false
  ) {
    this.page = page;
    this.itemsPerPage = itemsPerPage;
    this.category_id = category_id;
    this.date_filter = date_filter;
    this.name_filter = name_filter;
    this.byDay = byDay;
  }

  public toString() {
    return (
      (this.page ? "page=" + this.page : "") +
      (this.itemsPerPage ? "&itemsPerPage=" + this.itemsPerPage : "") +
      (this.category_id ? "&category_id=" + this.category_id.toString() : "") +
      (this.date_filter ? "&date_filter=" + this.date_filter : "") +
      (this.name_filter ? "&name_filter=" + this.name_filter : "") +
      (this.byDay ? "&byDay=" + this.byDay : "")
    );
  }

  public toHttpParams(): HttpParams {
    return new HttpParams()
      .set("skip", ((this.page - 1) * this.itemsPerPage).toString())
      .set("limit", this.itemsPerPage.toString());
  }
}
