import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-radio-shows-schedule",
  templateUrl: "./radio-shows-schedule.component.html",
  styleUrls: ["./radio-shows-schedule.component.scss"],
})
export class RadioShowsScheduleComponent implements OnInit {
  schedulesByWeekDay = [
    [
      {
        days: "א'-ה'",
        hours: "07:00",
        title: "בוקר חדש",
        subtitle: "טל תם ואביה",
        image: "assets/images/newShowLogo.jpg",
      },
      {
        days: "א'-ה'",
        hours: "09:30",
        title: "eco music",
        subtitle: "מיטל בן יהודה",
        image: "assets/images/broadcasters/update2023/Meytal_Ben_Yehuda_23.jpg",
      },
      {
        days: "א'-ה'",
        hours: "12:00",
        title: "eco music",
        subtitle: "עידו כהן",
        image: "assets/images/broadcasters/update2023/Ido_Cohen_23.jpg",
      },
      {
        days: "א'-ה'",
        hours: "15:00",
        title: "עמרי רונן",
        subtitle: "",
        image: "assets/images/broadcasters/update2023/Omri_Ronen_23.jpg",
      },
      {
        days: "א'-ה'",
        hours: "17:00",
        title: "eco music",
        subtitle: "חיים גיל",
        image: "assets/images/broadcasters/update2023/Chaim_Gil_23.jpg",
      },
      {
        days: "א'-ה'",
        hours: "20:00",
        title: "eco music",
        subtitle: "עדי הדר",
        image: "assets/images/broadcasters/update2023/Adi_Hadar_23.jpg",
      },
    ],
    [
      {
        days: "א'-ג'",
        hours: "21:00",
        title: "משמרת לילה",
        subtitle: "בועז כהן",
        image: "assets/images/broadcasters/update2023/Boaz_Cohen_23.jpg",
      },
      {
        days: "א'-ג'",
        hours: "22:00",
        title: "eco music",
        subtitle: "",
        image: "assets/images/broadcasters/99logo-black.png",
      },
    ],
    [
      {
        days: "רביעי",
        hours: "21:00",
        title: "רביעי עברי",
        subtitle: "בועז כהן",
        image: "assets/images/broadcasters/update2023/Boaz_Cohen_23.jpg",
      },
      {
        days: "רביעי",
        hours: "23:00",
        title: "eco music",
        subtitle: "",
        image: "assets/images/broadcasters/99logo-black.png",
      },
    ],
    [
      {
        days: "חמישי",
        hours: "21:00",
        title: "השאלון",
        subtitle: "מור גלפנד",
        image: "assets/images/broadcasters/99logo-black.png",
      },
      {
        days: "חמישי",
        hours: "22:00",
        title: "איתי גלו",
        subtitle: "",
        image: "assets/images/broadcasters/itay-galo.png",
      },
    ],
    [
      {
        days: "שישי",
        hours: "08:00",
        title: "מוזיקה מעולה סופש",
        subtitle: "ספי ינקו",
        image: "assets/images/broadcasters/99logo-black.png",
      },
      {
        days: "שישי",
        hours: "10:00",
        title: "מוזיקה מעולה סופש",
        subtitle: "סטלי סולומונוב",
        image: "assets/images/broadcasters/update2023/Stely_Solomonov_23.jpeg",
      },
      {
        days: "שישי",
        hours: "13:00",
        title: "מוזיקה מעולה סופש",
        subtitle: "עידן זוויגי",
        image: "assets/images/broadcasters/update2023/Idan_Zwigi_23.jpg",
      },
      {
        days: "שישי",
        hours: "15:00",
        title: "קצה החול",
        subtitle: "בועז כהן",
        image: "assets/images/broadcasters/update2023/Boaz_Cohen_23.jpg",
      },
      {
        days: "שישי",
        hours: "17:00",
        title: "מוזיקה מעולה סופש",
        subtitle: "",
        image: "assets/images/broadcasters/99logo-black.png",
      },
    ],
    [
      {
        days: "שבת",
        hours: "09:00",
        title: "מוזיקה מעולה סופש",
        subtitle: "",
        image: "assets/images/broadcasters/99logo-black.png",
      },
    ],
  ];

  constructor() {}

  ngOnInit() {}
}
