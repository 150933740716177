import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NetworkService } from "../../services/network.service";
import { PlaylistQuery } from "../../services/playlistQuery";
import { SharedService } from "../../services/shared.service";
import { Router } from "@angular/router";
import { GoogleAnalyticsService } from "../../services/googleAnalytics.service";
declare var $: any;

@Component({
  selector: "app-playlist-genres",
  templateUrl: "./playlist-genres.component.html",
  styleUrls: ["./playlist-genres.component.scss"]
})
export class PlaylistGenresComponent implements OnInit {
  scrolling: boolean = false;
  loadingPlaylists = false;
  activeGenre: any;
  selectedIndex: any;

  @Output() genreSelected = new EventEmitter<string>();
  @Input() genres: any;
  @Input() galleryID;
  @Input() stringTitle;
  @Input() isLoading: boolean;
  isStartOfScroll = true;
  isEndOfScroll = false;
  constructor(
    public sharedService: SharedService,
    public router: Router,
    private networkService: NetworkService,
    private analyticsService: GoogleAnalyticsService
  ) {}

  ngOnInit() {}

  initSelectedGenre() {
    this.activeGenre = undefined;
  }

  selectGenre(genre, index) {
    if (genre == this.activeGenre) return;

    this.analyticsService.sendEvent(
      "music",
      "carrousel_genre",
      "genre_select",
      genre.original_id
    );
    this.activeGenre = genre;
    if (this.sharedService.isPlatformBrowser) {
      setTimeout(() => {
        $("html, body").animate(
          {
            scrollTop: $("app-playlist-genres").offset().top
          },
          500
        );
      });
    }
    if (genre.playlists.length) {
      this.selectedIndex = index;
      this.genreSelected.emit(genre);
      this.loadingPlaylists = false;
      return;
    }
    let query = new PlaylistQuery(1, 16, genre.original_id);
    this.loadingPlaylists = true;
    this.networkService.getPlaylists(query).subscribe(resp => {
      genre.playlists = resp.items;
      this.selectedIndex = index;
      this.genreSelected.emit(genre);
      this.loadingPlaylists = false;
    });
  }

  scroll(direction) {
    if (this.sharedService.isPlatformBrowser) {
      let selector = ".genres-container." + this.galleryID;
      if (!this.scrolling) {
        var width = $(selector).width() - 200;
        this.scrolling = true;
        if (direction == "left") {
          this.isStartOfScroll = false;
          $(selector).animate({ scrollLeft: "-=" + width }, 800, () => {
            this.scrolling = false;
            this.sharedService.checkIfEndOrStartOfScroll(
              $(selector),
              "left",
              res => {
                this.isEndOfScroll = res;
              }
            );
          });
        } else {
          this.isEndOfScroll = false;
          $(selector).animate({ scrollLeft: "+=" + width }, 800, () => {
            this.scrolling = false;
            this.sharedService.checkIfEndOrStartOfScroll(
              $(selector),
              "right",
              res => {
                this.isStartOfScroll = res;
              }
            );
          });
        }
      }
    }
  }
}
