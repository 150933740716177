import {
  Component,
  OnInit,
  ViewChild,
  HostListener,
  OnDestroy
} from "@angular/core";
import { SharedService } from "../../services/shared.service";
import { NetworkService } from "../../services/network.service";
import { MessageService } from "../../services/messageService";
import { ElementRef, Renderer2 } from "@angular/core";
import { ShowGalleryComponent } from "../../components/show-gallery/show-gallery.component";
import { ShowsQuery } from "../../services/showsQuery";
import { ProgramsQuery } from "../../services/programsQuery";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-nine-months",
  templateUrl: "./nine-months.component.html",
  styleUrls: ["./nine-months.component.scss"]
})
export class NineMonthsComponent implements OnInit {
  selectedTab = 1;
  selectedTabAfterDelay = 1;
  showMonthPicker: boolean;
  textSearchFocus: boolean;
  showsDropdown: boolean;
  selectedYear: number;
  selectedMonth: string;
  noResultsText: string = "מממ… לא נמצאו תוצאות";
  currentYear = new Date().getFullYear();
  fullShowsQuery = new ProgramsQuery(1, 11, [4]);
  termsText = "";
  aboutText = "";

  months1 = ["ינואר", "פברואר", "מרץ", "אפריל"];
  months2 = ["מאי", "יוני", "יולי", "אוגוסט"];
  months3 = ["ספטמבר", "אוקטובר", "נובמבר", "דצמבר"];
  searchQuery = "";
  @ViewChild("selectedShows", { static: false })
  selectedShows: ShowGalleryComponent;
  @ViewChild("fullShows", { static: false }) fullShows: ShowGalleryComponent;

  @ViewChild("textinput", { static: false }) textInput: ElementRef;

  constructor(
    public sharedService: SharedService,
    private networkService: NetworkService,
    private messageService: MessageService,
    public router: Router,
    private route: ActivatedRoute
  ) {
    sharedService.showHeader = false;
    // sharedService.transparentheader = true
    sharedService.showUpperSection = false;
    this.selectedYear = this.currentYear;
    this.route.params.subscribe(res => {
      this.selectTab(res["activeTab"]);
    });
  }

  ngOnInit() {
    window.addEventListener("scroll", this.scroll, true); //third parameter
    this.networkService.getTermsAndAboutTextsForCategory(4).subscribe(
      resp => {
        if (resp.t_a_v_game_terms_and_conditions && resp.t_a_v_about_program) {
          // this.termsText = resp.t_a_v_game_terms_and_conditions.description
          this.aboutText = resp.t_a_v_about_program.description;
        }
      },
      err => {}
    );
  }

  scroll = (e): void => {
    if (e.currentTarget.pageYOffset > 580) {
      this.sharedService.showCollapsedHeader = true;
    } else {
      this.sharedService.showCollapsedHeader = false;
    }
  };

  ngOnDestroy(): void {
    this.sharedService.showHeader = true;
    this.sharedService.showUpperSection = true;
    this.sharedService.showCollapsedHeader = false;

    window.removeEventListener("scroll", this.scroll, true);
  }

  @ViewChild("monthPicker", { static: false }) monthPicker;
  @ViewChild("dateInput", { static: false }) dateInput;
  @HostListener("document:click", ["$event.target"])
  public onClick(targetElement) {
    const clickedInside =
      this.monthPicker.nativeElement.contains(targetElement) ||
      this.dateInput.nativeElement.contains(targetElement);
    if (this.showMonthPicker && !clickedInside) {
      this.showMonthPicker = false;
    }
  }

  toggleDrawer() {
    this.sharedService.showMenuTablet = !this.sharedService.showMenuTablet;
  }

  toggleMonthPicker() {
    if (!this.showMonthPicker) {
      setTimeout(() => {
        this.showMonthPicker = true;
        this.searchQuery = "";
      }, 100);
    } else {
      this.showMonthPicker = false;
      this.searchByDate();
    }
  }

  toggleTextInput() {
    if (!this.textSearchFocus) {
      this.textSearchFocus = true;
      this.selectedMonth = "";
      this.textInput.nativeElement.focus();
      this.showMonthPicker = false;
    } else {
      this.textSearchFocus = false;
      this.searchByString();
    }
  }

  cleanMonthPicker() {
    this.selectedMonth = "";
    this.searchByDate();
  }

  cleanTextInput() {
    this.searchQuery = "";
    this.searchByString();
  }

  focusElement(element) {
    setTimeout(() => {
      element.focus();
    });
  }

  yearUp() {
    if (this.selectedYear < this.currentYear) {
      this.selectedYear++;
    }
  }

  yearDown() {
    if (this.selectedYear > 2010) {
      this.selectedYear--;
    }
  }

  selectTab(tab) {
    this.router.navigateByUrl("/program/תשעה_חודשים/tab/" + tab);
    this.selectedTab = tab;
    this.showsDropdown = false;
    if (this.sharedService.isPlatformBrowser) {
      window.scrollTo(0, 0);
    }
    setTimeout(() => {
      this.selectedTabAfterDelay = tab;
    }, 150);
  }

  searchByDate() {
    let date = undefined;
    if (this.selectedMonth) {
      let month = this.sharedService.getMonthString(this.selectedMonth);
      let year = this.selectedYear;
      date = new Date(month + "/01/" + year).toISOString();
    }

    if (
      this.selectedYear != this.currentYear &&
      this.sharedService.user &&
      this.sharedService.user.payment_data.subscription == 4
    ) {
      this.noResultsText =
        "מממ… עליך להרשם למסלול Pro כדי לשמוע תוכניות מן העבר";
    } else {
      this.noResultsText = "לא נמצאו תוכניות לפי התאריך שחיפשת";
    }

    let selectedShowsQuery;
    if (!date) {
      selectedShowsQuery = new ProgramsQuery(1, 11, [4], date);
    } else {
      selectedShowsQuery = new ProgramsQuery(1, 11, [4], date);
    }

    // this.selectedShows.loadShows(selectedShowsQuery, true)

    if (!date) {
      selectedShowsQuery = new ProgramsQuery(1, 11, [4], date);
    } else {
      selectedShowsQuery = new ProgramsQuery(1, 11, [4], date);
    }
    this.fullShows.loadShows(selectedShowsQuery, true);

    //move to selected shows on text search
    this.selectTab(1);
  }

  searchByString() {
    // this.noResultsText = 'לא נמצאו תוכניות עבור<br>״'+ this.searchQuery + "״"
    this.noResultsText = "לא נמצאו תוכניות לפי הנושא שחיפשת";

    if (!this.searchQuery.length) {
      this.searchQuery = undefined;
    }
    let selectedShowsQuery;
    if (!this.searchQuery) {
      selectedShowsQuery = new ProgramsQuery(
        1,
        11,
        [4],
        undefined,
        this.searchQuery
      );
    } else {
      selectedShowsQuery = new ProgramsQuery(
        1,
        11,
        [4],
        undefined,
        this.searchQuery
      );
    }
    // this.selectedShows.loadShows(selectedShowsQuery, true)

    if (!this.searchQuery) {
      selectedShowsQuery = new ProgramsQuery(
        1,
        11,
        [4],
        undefined,
        this.searchQuery
      );
    } else {
      selectedShowsQuery = new ProgramsQuery(
        1,
        11,
        [4],
        undefined,
        this.searchQuery
      );
    }
    this.fullShows.loadShows(selectedShowsQuery, true);
    //move to selected shows on text search
    this.selectTab(1);
  }
}
