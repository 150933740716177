import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { NetworkService } from "../../services/network.service";
import { GoogleAnalyticsService } from "../../services/googleAnalytics.service";
declare var bodymovin: any;

@Component({
  selector: "app-subscription-dialog",
  templateUrl: "./subscription-dialog.component.html",
  styleUrls: ["./subscription-dialog.component.scss"]
})
export class SubscriptionDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<any>,
    private networkService: NetworkService,
    private analyticsService: GoogleAnalyticsService
  ) {
    // this.familyUsers = data.familyUsers
  }
  loadingAnimation: any;
  showPopup = false;
  isLoading: boolean;
  acceptTerms = false;
  mouseEnter = false;

  ngOnInit() {
    setTimeout(() => {
      this.showPopup = true;
    }, 100);
  }

  onAccept() {
    if (this.isLoading) return;

    this.isLoading = true;
    this.startLoadingAnimation("loader1");
    this.analyticsService.sendEvent(
      "settings",
      this.acceptTerms ? "subscribed" : "unsubscribed",
      "subscription",
      "popup"
    );
    this.networkService.updateSubscriptionFlag(this.acceptTerms).subscribe(
      resp => {
        this.isLoading = false;
        this.dialogRef.close({ success: true, resp: resp });
      },
      err => {}
    );
  }

  startLoadingAnimation(loaderID) {
    if (this.loadingAnimation) {
      this.loadingAnimation.destroy();
    }
    this.loadingAnimation = bodymovin.loadAnimation({
      container: document.getElementById(loaderID),
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: "assets/animations/loader.json"
    });
  }
}
