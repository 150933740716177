import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { FormControl, Validators } from "@angular/forms";
import { NetworkService } from "../../services/network.service";
import { SharedService } from "../../services/shared.service";
import { Router } from "@angular/router";
declare var bodymovin: any;

@Component({
  selector: "app-set-package-dialog",
  templateUrl: "./set-package-dialog.component.html",
  styleUrls: ["./set-package-dialog.component.scss"]
})
export class SetPackageDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<any>,
    private networkService: NetworkService,
    private sharedService: SharedService,
    public router: Router
  ) {}
  loadingAnimation: any;
  showPopup = false;
  isLoading: boolean;

  ngOnInit() {
    setTimeout(() => {
      this.showPopup = true;
    }, 100);
  }

  onChange(packageType) {
    if (
      this.sharedService.user.payment_method_data &&
      this.sharedService.user.payment_method_data.payment_token &&
      this.sharedService.user.payment_data.subscription != 4
    ) {
      this.networkService.setSubscriptionPlan(packageType).subscribe(
        resp => {
          this.sharedService.user = resp;
          this.isLoading = false;
          this.sharedService.user = resp;
          this.sharedService.showUpdatePackagePopup(packageType);
        },
        err => {
          this.isLoading = false;
          this.sharedService.showAlert(
            err,
            true,
            false,
            "אופסי, משהו השתבש...",
            "הבנתי"
          );
        }
      );
    }
    this.dialogRef.close();
  }

  startLoadingAnimation() {
    if (this.loadingAnimation) {
      this.loadingAnimation.destroy();
    }
    this.loadingAnimation = bodymovin.loadAnimation({
      container: document.getElementById("loader"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: "assets/animations/loader.json"
    });
  }

  getIcon(type) {
    switch (type) {
      case 1:
        return "proicon.png";
      case 2:
        return "pro-family.png";
      case 3:
        return "pro-lite.png";
      default:
        return "proicon.png";
    }
  }

  getIconForPackage(type) {
    switch (type) {
      case 1:
        return "pro-icon.svg";
      case 2:
        return "family-pkg-icon.svg";
      case 3:
        return "lite-pkg-icon.svg";
    }
  }
}
