import { Component, OnInit, OnDestroy } from "@angular/core";
import { SharedService } from "../../services/shared.service";
import { environment } from "../../../environments/environment";

@Component({
  selector: "app-terms",
  templateUrl: "./terms.component.html",
  styleUrls: ["./terms.component.scss"]
})
export class TermsComponent implements OnInit, OnDestroy {
  constructor(private sharedService: SharedService) {}
  baseUrl = environment.baseUrl;

  terms =
    `ברוכים הבאים לאפליקציה של רדיו אקו 99FM.
  אנו מאחלים לכם חוויה מהנה, אך בטרם תיפנו לשימוש באפליקציה, נבקש להביא לתשומת ליבכם כללי יסוד שהם בעלי משמעות משפטית. לפיכך, נבקשכם לקרוא תנאי שימוש אלו במלואם ובעיון. עצם השימוש שלך או של מי מטעמך באפליקציה, יהווה אישור להסכמתך לכל תנאי השימוש המפורטים במסמך זה.
  1.	כללי
  1.1	מסמך זה מהווה הסכם בנוגע לשימוש, הפעילות והשירותים המוצעים לך באפליקציה של רדיו ללא הפסקה (ניהול 2008) בע"מ ("אקו"). השירותים הניתנים לך במסגרת האפליקציה מוצעים לשימושך בתנאי שתקבל ללא שינוי את כל התנאים הכלולים במסמך זה. עצם השימוש שלך או של מי מטעמך באפליקציה, יהווה אישור להסכמתך לכל תנאי השימוש המפורטים במסמך זה. אם אינך מסכים לתנאי השימוש באפליקציה, אינך רשאי לעשות שימוש בו, או בשירותיו.
  1.2	תנאי השימוש באפליקציה הם ההסכם המחייב היחיד והבלעדי בכל הקשור לשימוש באפליקציה ולפיכך בכל מקרה של סתירה או אי התאמה בין תנאי שימוש אלו לבין כל פרסום, מידע, תקנון או מסמך  אחר, מכל סוג ומין, יגברו הוראות תנאי השימוש המפורטים להלן (למעט לגבי שירותים נוספים כמפורט בסעיף 1.3 להלן).
  1.3	השימוש בחלק מהשירותים המוצעים באפליקציה (למשל, שירותים בתשלום, או קבלת הטבות שונות) עשוי להיות כפוף לתנאים נוספים. התנאים הנוספים מצטרפים לאמור במסמך תנאי שימוש זה, אולם במקרה של סתירה או אי התאמה בין תנאי השימוש לבין התנאים הנוספים, יגבר האמור בתנאים הנוספים. 
  1.4	אקו שומרת לעצמה את הזכות לשנות תנאי שימוש אלה מעת לעת, לפי שיקול דעתה הבלעדי וללא צורך במסירת הודעה כלשהי מראש. האחריות המלאה להכרת תנאי שימוש אלה, כפי שיעודכנו מעת לעת חלה, בכל עת, עליך בלבד.  
  1.5	השימוש באפליקציה ובכל המידע, התכנים והשירותים הנכללים בה נועד לשימוש אישי בלבד. אין לעשות שימוש באפליקציה לצרכים מסחריים או למטרות רווח.   
  1.6	אקו, עובדיה, מנהליה, נציגיה ו/או מי מטעמה אינם אחראים בכל חבות ו/או אחריות לשירותים אשר אינם מסופקים ישירות עבור אקו (לרבות ומבלי למצות רישום ו/או גבייה ו/או סליקה ו/או תשלום המבוצעים באמצעות צדדים שלישיים).
  
  2.	הורדת האפליקציה, רישום ושימוש בה
  2.1.	השימוש באפליקציה עשוי להיות כפוף להוראות חוזיות ולתנאי שימוש נוספים של צדדים שלישיים (לרבות כאלו המסדירות את השימוש בחנות המקוונת שממנה ניתן להוריד את האפליקציה, כגון Google Play ו-App Store). 
  2.2.	השימוש באפליקציה לא מצריך רישום, אולם רישום לאפליקציה משפר את חווית המשתמש ומעשיר אותה.
  2.3.	הרישום מתבצע באמצעות רישום ישיר (במסגרתו תתבקש למסור לנו פרטים אישיים מזהים, פרטי יצירת קשר ומגדר) או באמצעות חשבונך ברשתות חברתיות (דוגמת פייסבוק וגוגל+). 
  2.4.	השימוש ברשתות חברתיות לשם רישום לאפליקציה הוא חיצוני ונפרד לאפליקציה וכפוף גם למדיניות הפרטיות של רשתות חברתיות אלו. כמו כן רישום לאפליקציה באמצעות חשבונך ברשתות חברתיות מאפשר לאקו גישה למידע הבסיסי אודותייך בפרופיל הרשת החברתית שלך, כגון שמך, כתובת הדוא"ל שלך, תמונת הפרופיל שלך וכל מידע ותוכן אחר הזמין לעיון בחשבון הרשת החברתית שלך. גישתנו למידע ולתכנים כאמור מוגבלת למידע ולתכנים שמפעיל הרשת החברתית בה אתה משתמש מאפשר לנו גישה אליהם, וזאת בהתאם להגדרות הפרטיות שלך באותה רשת חברתית, אותן אתה יכול לשנות מעת לעת באמצעות ממשק הרשת החברתית.
  2.5.	אנו נהיה רשאים להסתמך על פרטים ומידע אשר יימסרו לנו על ידכם, בין השאר ובמיוחד בעת הרישום לאפליקציה, לכל דבר ועניין.
  2.6.	מובהר כי אנו לא אחראים על אמיתות הפרטים אשר יימסרו לנו במסגרת הליך הרישום ו/או התשלום (ככל ששירות מסוים כרוך בתשלום) ו/או המידע המופיע בחשבונך ברשתות חברתיות ולא נהיה אחראים לכל נזק ישיר ו/או עקיף, לרבות הוצאות ישירות ו/או עקיפות שיגרמו לגולש באפליקציה ו/או לכל צד שלישי עקב הסתמכותנו על הפרטים אשר יימסרו לנו על ידכם ו/או המידע המופיע ברשתות חברתיות כאמור ושימוש שלנו בפרטים ו/או במידע אלו.
  2.7.	הנכם אחראים לשמור על פרטי המייל שבחרתם לעשות בו שימוש לצורך השימוש באפליקציה (בין אם באמצעות אשרור המייל האישי ובין אם באמצעות רשת חברתית) בסודיות ולדווח לנו באופן מידי (באמצעות פנייה לכתובת music@eco99.fm) בכל מקרה בו תגלו שמישהו עשה בהם שימוש בלי הסכמתכם. אי דיווח כאמור משמעו כי הנכם עלולים להיות אחראים לכל שימוש אשר יעשה צד שלישי בפרטים שנמסרו על ידכם (כולל לתשלום עבור שירותים), אפילו אם נעשה בלי הסכמתך. כמו כן הנכם מתחייבים שלא לעשות שימוש בשם המשתמש או בסיסמה של אחר.
  2.8.	אנו רשאים גם בכל עת להפסיק, לשנות או להגביל את פעילות האפליקציה, את אפשרויות השימוש בה, את הגישה או השימוש במידע, בשירותים ובתכנים הכלולים בה, כולם או חלקם או כל היבט אחר הקשור באפליקציה וזאת בכל עת לפי שיקול דעתנו הבלעדי, וללא צורך בהודעה מוקדמת. 
  2.9.	מבלי לגרוע מהאמור לעיל, מעת לעת עשויה אקו לבצע שינויים, שיפורים ותיקונים באפליקציה, במידע, בשירותים ובתכנים הכלולים בה, וזאת לפי שיקול דעתנו הבלעדי וללא צורך בהודעה מוקדמת. שינויים, שיפורים, תיקונים וכיו"ב עלולים להיות כרוכים בתקלות, איבוד מידע, אי זמינות או הפסקתם של שירותים מסוימים וכיו"ב ולא תהיה לך טענה, תביעה ו/או דרישה כלפי אקו האמור.
  2.10.	אנו לא מציגים מצג כלשהו לגבי התאמה, איכות, אמינות, זמינות, תקינות, העדר טעויות ודייקנות האפליקציה, על כל חלקיה ומרכיביה, לכל מטרה שהיא, וכן לגבי היעדר וירוסים, רכיבים מזיקים אחרים בה, או העדרן של תקלות טכניות באפליקציה ו/או במערכות המחשוב ו/או התקשורת של אקו ו/או ספקיה. אקו לא תהא אחראית לכל נזק, ישיר או עקיף שייגרמו לך או לרכושך כתוצאה מכך. באחריותך לנקוט באמצעי אבטחה הולמים על מנת להגן על עצמך ועל הציוד שבבעלותך (נגד וירוסים, פריצה לציוד הקצה שברשותך וכו').
  2.11.	התכנים והשירותים באפליקציה ניתנים לשימוש "כפי שהם" (as is) ו"ככל שיהיו זמינים" (as available). לא ניתן להתאימם למטרה כלשהי או לצרכיו של כל משתמש ומשתמש.
  2.12.	הנכם מסכימים לכל פעולה שבה ננקוט כדי להגן על האפליקציה, השירותים ומערכות המחשוב של אקו, גם אם פעולות אלו עלולות, עקב קשיים טכנולוגיים, לחסום או לעכב גם שימוש לגיטימי באפליקציה ו/או בשירותים אלו.
  2.13.	אקו אינה מתחייבת כי התוכן אשר יהא זמין באפליקציה יהא זהה או דומה לתוכן המשודר בתחנת הרדיו 99FM או כי התוכן המשודר ברדיו יהא זמין להאזנה באפליקציה במועד בו שודר ברדיו או בסמוך לו או כי שימוש באפליקציה יהיה זמין מחוץ לגבולות מדינת ישראל. אף אם שימוש באפליקציה יהא זמין מחוץ לגבולות מדינת ישראל (באופן חלקי או מלא) שימוש זה כפוף, בנוסף לאמור בתנאי שימוש אלו, לתנאי השימוש של צדדים שלישיים (לרבות ארגוני זכויות יוצרים).
  2.14.	יובהר, כי על אף שהשימוש באפליקציה פתוח בעיקרון לכלל המשתמשים, אנו שומרים על זכותנו למנוע או להגביל או לחסום שימוש של משתמשים באפליקציה, במידע, או בשירותים הכלולים בה (בין היתר באמצעות מגבלות על גיל המשתמש, חובת תשלום, היקף שימוש, אופן השימוש, מועד השימוש, מספר החשבונות או שמות המשתמשים המותרים לכל אדם או תאגיד יחיד מניעת כניסה לאפליקציה לתקופה מוגבלת או לצמיתות) וכו' מכל סיבה שהיא ולפי שיקול דעתנו הבלעדי (לרבות, ומבלי למצות, במקרה של הפרת הוראת כל דין, הפרה של תנאי השימוש, הפרעה לפעילות התקינה של האפליקציה). זאת, מבלי לגרוע ו/או לפגוע בכל סעד אחר העומד לרשותה של אקו על פי כל דין.
  
  3.	שירותים נוספים בתשלום 
  3.1.	אקו עשויה להציע למשתמשי האפליקציה שירותים נוספים עשויים להיות כרוכים בתשלום על פי החלטתה של אקו. על שירותים אלו יחולו כל תנאי השימוש שבמסמך זה וכן תנאים נוספים הקשורים לשירותים בתשלום אשר יפורסמו בצד שירותים אלו. 
  3.2.	אקו תהיה רשאית לשנות בכל עת את השירותים שניתנים על ידה, או את העלות שהיא גובה עבורם, להוסיף ולהפסיק שירותים בהתאם לשיקול דעתה הבלעדי וזאת ללא כל הודעה מראש.
  3.3.	ככל שהרישום ו/או התשלום עבור שירותים שונים מתבצע באמצעות צדדים שלישיים, הנך מתחייב לפעול על פי תנאי הרישיונות של אותם גורמים ואקו לא תישא באחריות ליחסיך מולם. 
  
  4.	תכנים מסחריים
  4.1.	האפליקציה כוללת תכנים ומידע מסחרי, כגון פרסומות ומודעות, הנמסרים לפרסום מטעמם של מפרסמים שונים, המבקשים להציע למכירה שירותים או מוצרים. פרסום התכנים והמידע המסחרי באפליקציה אינו מהווה עידוד או המלצה מצד אקו לרכוש ו/או להשתמש בשירותים או המוצרים המוצעים בה.
  4.2.	אקו אינה אחראית לתכנים ולמידע המסחרי המפורסמים באפליקציה מטעם ו/או על ידי צדדים שלישיים ואלו מצויים אך ורק באחריותם. מבלי לגרוע מכלליות האמור, איננו מקבלים על עצמנו אחריות לבצע פיקוח, סינון, בדיקת נכונות, בדיקת יעילות או שמירה על המידע, השירותים והתכנים המפורסמים על ידי מפרסמים באפליקציה. לפיכך, הנך מתבקש לנהוג משנה זהירות בהסתמכות או בשימוש במושא החומר הפרסומי, וכל שימוש או הסתמכות על חומר פרסומי הוא על אחריותך בלבד.
  4.3.	מבלי לגרוע מהאמור לעיל, כל תוצאה שתיגרם משימוש במוצרים או בשירותים המפורסמים באפליקציה או מהסתמכות על תכנים ומידע מסחרי של צדדים שלישיים - חלה על המפרסמים בלבד.
  4.4.	כל עסקה שתיעשה בעקבות תכנים ומידע מסחרי המתפרסם באפליקציה על ידי צדדים שלישיים תתבצע ישירות בינך לבין המפרסם הנוגע בדבר. אקו אינה צד לעסקה כזו, והיא לא תישא באחריות לעסקה שכזו ו/או תוצאותיה. האמור בסעיף זה נוגע גם לכל עסקה, השתתפות במבצע, רכישה של מוצרים ו/או שירותים וכיו"ב אשר יהיו מוצעים לך במסגרת קישורים חיצוניים לאפליקציה כהגדרתם בסעיף 5 להלן.
  
  5.	קישורים באפליקציה ואל האפליקציה
  5.1.	האפליקציה עשויה להכיל בתוכה קישורים (hyperlinks) אל וממקורות מידע ותכנים אחרים המצויים ברשת האינטרנט  ואשר הינם חיצוניים לאפליקציה (לרבות, ומבלי למצות, קישורים לאתרי רכישת כרטיסים להופעות ולמופעים באמצעות היישום "אקו טיקטס").  
  5.2.	העובדה שהאפליקציה מכילה קישורים למקורות חיצוניים כאמור אינה מעידה על הסכמת אקו, לא במפורש ולא במשתמע, לתוכנם ואינה מהווה הבעת תמיכה או חסות מצידנו באותו מקור חיצוני או ערובה לאמינותם, תקינותם, שלמותם, לעדכניותם, לעמידתם בתנאי כל דין, לאופן בו ינהגו בתחום פרטיות הגולשים אליהם ולכל היבט אחר. הביקור בקישורים חיצוניים אלו הוא על אחריותך בלבד (וזאת אף אם ההפניה אליהם נעשתה באמצעות האפליקציה, לרבות ומבלי למצות, באמצעות "אקו טיקטס"). בהתאם, אקו אינה ולא תהיה אחראית לכל תוצאה אשר תגרם כתוצאה מגלישתך לאתרים אלו ו/או להסתמכותך על המידע המפורסם בהם. כדי לברר את התנאים והמחירים החלים על תכנים ושירותים של צדדים שלישיים, עליכם לפנות לספקים הרלוונטיים. 
  5.3.	בכל מקרה של שימוש בתוכנות ו/או בתכנים של צדדים שלישיים הנך מתחייב לפעול על פי תנאי הרישיונות של התוכנות ו/או התכנים האמורים. במקום בו השימוש בתוכנות ו/או בתכנים כאמור מותנה בתשלום, הנך מתחייב לשלם כנדרש. שימוש בלא תשלום מהווה הפרה של תנאי רישיון התוכנות ו/או התכנים, אשר עלול לגרום, בין השאר, להפרה של דיני זכויות יוצרים ואסור על פי דין. 
  5.4.	אין להציג תכנים מהאפליקציה באמצעות טכנולוגית מסגור (framing), או כל טכנולוגיה אחרת באמצעותה מוצגים למשתמש תכנים מהאפליקציה.
  5.5.	אקו מעמידה לרשות משתמשי האפליקציה כלים שונים שמטרתם מתן אפשרות לשיתוף וקישור תכנים מהאפליקציה באמצעות רשתות חברתיות ושירותים מקוונים אחרים של צדדים שלישיים. תשומת ליבך כי השימוש ברשתות חברתיות ושירותי צדדים שלישיים כאמור נעשה על אחריותך בלבד. שימוש כאמור אינו קשור לאפליקציה או לאקו והוא כפוף לתנאי השימוש בשירותים מקוונים אלו ומדיניות הפרטיות שלהם. באחריותך לקרוא ולהסכים לתנאי השימוש האמורים לפני שתעשה כל שיתוף ו/או קישור לתכנים כלשהם מהאפליקציה.
  
  6.	תנאים ומגבלות על השימוש באפליקציה
  6.1.	אנו מעודדים כמובן שימוש באפליקציה, על כל חלקיה. יחד עם זאת, שימוש באפליקציה חייב לעשות על פי הוראות כל דין ומבלי שתהיה בשימוש זה פגיעה באחרים, בנו או הפרה של תנאי השימוש. לעניין זה מובהר  כי כל שימוש באפליקציה שלא הותר באופן מפורש בתנאי השימוש – אסור.
  6.2.	מבלי לגרוע מהאמור לעיל, להלן יפורטו תנאים ומגבלות שהשימוש באפליקציה בכל דרך שהיא חייב להיעשות תוך שמירתם ובכפוף להם:
  6.3.	אין לעשות שימוש באפליקציה, או בכל חלק ממנה לכל מטרה לא חוקית ו/או לכל מטרה האסורה על פי תנאי השימוש.
  6.4.	השימוש באפליקציה נועד אך ורק לשימוש ביתי, פרטי שאינו מסחרי בלבד על ידך, המשתמש. כל שימוש אחר באפליקציה ו/או בתכניה אסורים בהחלט. בפרט, אינך רשאי: (1) לבצע אילו מתכני האפליקציה בביצוע פומבי ו/או (2) להעביר אילו מהתכנים הנכללים באפליקציה בהעברת המשך (re-transmission), לשדרם, לאפשר את ביצועם הפומבי ו/או להעמידם לרשות הציבור ו/או (3) להעמיד אילו מהתכנים המצויים באפליקציה, בצורה או אופן כלשהם, לרשות גורמים מסחריים ו/או ציבוריים.
  6.5.	אין להירשם לאפליקציה באמצעות פרטי הזדהות שאינם נכונים, מלאים ומדויקים. מבלי לגרוע מהאמור לעיל, אין להתחזות או לזייף פרטי משתמשים.
  6.6.	אינך רשאי להשתמש באפליקציה באופן העלול להזיק, לשתק, לגרום עומס יתר או לפגוע באפליקציה, ברשתות תקשורת ובציוד תקשורת כלשהו, או להפריע לשימוש של גורם אחר כלשהו ולהנאה שלו מהאפליקציה
  6.7.	אין להשתמש באפליקציה באופן כלשהו שיש בו כדי להוות פגיעה בפרטיות, פגיעה בשם הטוב, הפרת זכויות יוצרים, זכויות מבצעים, סימנים ושמות מסחריים, מדגמים, פטנטים וכו', הפרת זכויות אחרות כלשהן של צדדים שלישיים, עוולות אזרחיות או מסחריות, עבירות פליליות, הפרה של צווי ואיסורי פרסום, פרסום מידע על קטינים, חסויים או פסולי דין,  הטעיה או הונאה של משמשים.
  6.8.	אין לנסות לקבל גישה ללא הרשאה לאפליקציה, לשירותים, לתכנים, לחשבונות או פרטים של אחרים או למערכות מחשבים או רשתות המחוברות לאפליקציה ו/או קשורות אליה באמצעות "פריצה" (Hacking), "כריית סיסמאות" (Password Mining) או בכל אמצעי אחר. 
  6.9.	חל איסור להפעיל ו/או לנסות להפעיל כל אמצעי (תוכנה, חומרה או אחר) אשר מטרתו לעקוף (circumvention) את תנאי השימוש ו/או המגבלות השונות של האפליקציה.
  6.10.	במידה ונהיה סבורים, על פי שיקול דעתנו הבלעדי, כי הופר על ידך תנאי כלשהו מתנאי השימוש ו/או כי עבירת על הוראת דין כלשהו, תהא אקו רשאית למנוע ממך גישה לאפליקציה (כולה או חלקה, באופן זמני או קבוע) ו/או לבטל את הרישום שלך לאפליקציה (כולו או חלקו) ו/או לפעול בכל דרך אחרת, על פי שיקול דעתה הבלעדי. בכל אחד מהמקרים המנויים לעיל לא תהיה לך כל טענה, תביעה או דרישה כנגד אקו.
  6.11.	מבלי לגרוע מהאמור לעיל, הנך מתחייב לשפות את אקו, עובדיה, מנהליה ומי מטעמה בגין כל טענה, תביעה, נזק, הפסד, אבדן רווח, תשלום או הוצאה שיגרמו לה (לרבות שכר טרחת עו"ד והוצאות משפט) עקב הפרה שלך את תנאי השימוש.
  
  7.	פיקוח, העברת מידע ואבטחת מידע
  7.1.	אקו אינה מחויבות לפקח על האפליקציה על כל רכיביה ומרכיביה ו/או על קהל המשתמשים שלה והיא אינה עושה זאת. יחד עם זאת, אקו שומרת לעצמה את הזכות לעשות כן, על פי שיקול דעתה הבלעדי.
  7.2.	אף אם נפעיל פיקוח או אמצעי זהירות או שליטה כלשהם על המידע, השירותים והתכנים שבאפליקציה, ואף אם ייוודע לנו על האפשרות לפגיעה או לנזק שעלול להיגרם למשתמשים או לצדדים שלישיים כלשהם - כל אמצעי זהירות, פיקוח או שימוש כאמור, ואפילו ניסיון לפתור או לסייע בפתרון בעיות, או מניעת או צמצום או הפחתת נזקים, נעשים ללא מחויבות בדין ומתוך רצון טוב, ומבלי שהדבר יתפרש כקבלת אחריות כלשהי מצדנו מקום בו צוין מפורשות כי אין אנו אחראים.
  7.3.	אנו שומרים לעצמנו את הזכות לחשוף כל מידע אישי אודותיך או אודות השימוש שלך באפליקציה ללא קבלת רשות ממך, אם פעולה זו חיונית כדי: (1) לציית לדרישות משפטיות או למלא אחר צווים שניתנו במסגרת הליך משפטי או על-ידי רשות מוסמכת על פי כל דין; (2) לאכוף את תנאי השימוש; (3) על מנת למנוע נזק לגוף או לרכוש שלך או של כל צד שלישי שהוא; (4) בכל מקרה אחר בו אנו סבורים כי הדבר דרוש לשם שמירה על הוראות כל דין או לשם הגנה על זכויותיה של אקו. 
  7.4.	מאחר שימוש באפליקציה נעשה גם באמצעות רשת האינטרנט, יתכן שמידע שתפרסם או תעלה לאפליקציה (ככל שתהיה לך אפשרות לעשות כן) יגיע לידי צדדים שלישיים.
  
  8.	מדיניות פרטיות
  8.1.	השימוש באפליקציה כפוף גם להסכמתך לקבל ללא שינוי את מדיניות הפרטיות של אקו בקשר עם האפליקציה ותכניה והיא מהווה חלק בלתי נפרד מתנאי השימוש באפליקציה. 
  8.2.	את מדיניות הפרטיות שלנו ניתן לקרוא ב<a href="` +
    environment.baseUrl +
    `/privacy-policy">קישור</a> אם אינך מסכים למדיניות פרטיות זו, אינך רשאי לעשות שימוש באפליקציה. 
  
  9.	זכויות קניין רוחני והשימוש בהן
  9.1.	המידע והתכנים הכלולים באפליקציה ובשירותים המוצעים בה, לרבות טקסט, איורים, וידאו, גרפיקה, צליל, קטעי גרפיקה, קוד מחשב, יישומי תוכנה, תוכניות, גרפים ותמונות מוגנים בזכויות יוצרים ובזכויות קניין רוחני אחרות של אקו או של צדדים שלישיים, בהתאם לחוקי מדינת ישראל, אמנות בינלאומיות וחוקי זכויות יוצרים של מדינות אחרות.
  9.2.	מבלי לגרוע מהאמור בכל מקום אחר בתנאי שימוש אלה, התכנים הנכללים באפליקציה מיועדים לשימוש אישי בלבד ואין לעשות בהם כל שימוש, ובכלל כך, להעתיק את השירותים או תוכנם, לשכפל, לשנות, להפיץ, לשדר, להציג בפומבי, לבצע בפומבי, להעביר לציבור, לפרסם, לעבד, ליצור יצירות נגזרות, להסב, לתת רישיון או הרשאה, למכור או להשכיר ולאחסן את תוכנה של האפליקציה וכל תוכן אחר שהתקבל באמצעותו, באופן חלקי או מלא, זמני או קבוע, בלא הסכמה מפורשת של אקו מראש ובכתב. למען הבהירות נציין כי האמור לעיל חל גם על שמות וסימנים מסחריים המופיעים באפליקציה, פטנטים ומדגמים, סודות מסחריים וכיו"ב
  9.3.	יצירת עותקים של ההקלטות (downloading) הנכללות באפליקציה הינו אסור ומהווה הפרה של זכויות יוצרים.
  9.4.	האמור בתנאי השימוש נועד כדי להעניק הרשאה להשתמש באפליקציה בהתאם לתנאי השימוש בלבד ומותנה בהתחייבותך כי לא תבצע או תהיה מעורב בביצוע פעולות המפרות תנאי שימוש אלה, ובפרט בפעולות הגורמות להפרת זכויות קניין רוחני.  אין לעשות שום שימוש באפליקציה, במידע או בשירותים השייכים לנו, כהגדרתם לעיל, לרבות שימוש כלשהו במאגרי הנתונים שלנו, סימני המסחר ושמות המסחר וכיו"ב, לשום מטרה אחרת מלבד שימוש אישי שאינו מסחרי באפליקציה ולפי תנאי השימוש, ללא אישורנו בכתב ומראש.
  
  10.	אזהרה לגבי סיכונים; אחריות מוגבלת 
  10.1.	כל שימוש באפליקציה ובתכנים הנכללים בה הינו על אחריותך המלאה והבלעדית. בשל כך,  ולאור העובדה, כי ככלל אקו אינה מפקחת על האפליקציה או על קהל המשתמשים שלה, מצאנו לנכון להביא בפניכם במסגרת תנאי השימוש אזהרות מסוימות ביחס לסיכונים הכרוכים בשימוש במידע ובשירותים שבאפליקציה ולהתריע בפניך בדבר ההשלכות הנובעות מכך. 
  10.2.	המידע והשירותים באפליקציה עשויים לכלול מידע מקצועי בתחומים שונים. מידע כאמור אינו מהווה המלצה או חוות דעת מוסמכת ואינו תחליף לייעוץ מקצועי של איש מקצוע מוסמך. כל הסתמכות על מידע כאמור המופיע באפליקציה הינה באחריותכם הבלעדית ואנו לא נישא בשום אחריות לנזק כתוצאה מהשימוש במידע זה או מההסתמכות עליו. 
  10.3.	איננו מתחייבים שהאפליקציה תפעל בצורה רצופה, תהא זמינה כל העת, תפעל ללא הפרעות, הפסקות, טעויות, באגים או ליקויים אחרים, או שהשימוש באפליקציה יהיה בטוח וחסין מפני גישה בלתי מורשית לשרתי האפליקציה או מחשבי המשתמשים, או לתקלות או כשלים בפעילות באפליקציה, חומרה, בתוכנה, קווים ובמערכות התקשורת הקשורות באפליקציה. 
  10.4.	 איננו אחראים למעשים או מחדלים של צדדים שלישיים, משתמשים אחרים או כל גורם אחר שאין לנו שליטה מלאה עליו, או לתוצאות של מעשים או מחדלים כאמור, לרבות לפגיעה או להפרה של זכויות יוצרים, מבצעים או קניין רוחני כלשהו על ידי משתמשים באפליקציה      
  10.5.	איננו אחראים לקשר בין המשתמשים לבין משתמשים אחרים או צדדים שלישיים, או לתוצאותיו ואיננו צד להתקשרות כזו ולא נישא בשום אחריות לנזקים או פגיעות שתהיינה כתוצאה מהתקשרות כזו. 
  10.6.	איננו אחראים לכל נזק אשר ייגרם בעקבות נסיבות של כח עליון וכל נסיבה אחרת שאיננה בשליטתה של אקו.
  10.7.	מבלי לגרוע מהאמור לעיל בשום מקרה, ובמידה המקסימאלית המותרת בחוק, לא תהיה אקו אחראית לכל נזק ישיר או עקיף, כספי או אחר, שייגרם לך כתוצאה משימוש באפליקציה, מהסתמכות על המידע שמופיע בה או בקישורים חיצוניים לה, שאליהם תגיע באמצעות האפליקציה. 
  10.8.	כמו כן, אקו אינה אחראית ולא תחוב בכל חובה בקשר עם כל טענה, תביעה או דרישה שעיקרה הפרת זכויות של צדדים שלישיים  (לרבות משתמשי האפליקציה) על ידי מי מהמשתמשים באפליקציה.  
  10.9.	הפעלת או אי הפעלת אילו מסמכויותיה או זכויותיה של אקו כמפורט בתנאי השימוש לא תקים לך או לצד שלישי כל טענה, תביעה ו/או דרישה כלפי אקו, עובדיה, מנהליה או נציגיה, לא תתפרש כנטילת אחריות מצד אקו או כהטלת חובה עלינו להפעלת סמכויות אלו או כויתור על אילו מתנאי השימוש באפליקציה, ולא תסיר את האחריות המוטלת עליכם או על צד שלישי כלשהו על פי תנאי השימוש או הוראות הדין. 
  
  
  
  11.	פניות
  11.1.	אם הנך סבור כי תוכן כלשהו המופיע באפליקציה פוגע בך מטעם כלשהו, או שהתוכן מפר את תנאי השימוש או את זכויותיך, הנך מוזמן אלינו בכתובת music@eco99.fm ואנו נשתדל לטפל בפנייתך בהקדם ובהתחשב בשעות ובימי העבודה המקובלים. על מנת שנוכל לטפל בפנייה ביעילות, במסגרת הפנייה עליך לפרט מהי הפגיעה או ההפרה הנטענת, מהו החומר הפוגע או המפר (תוך צירוף של צילום מסך או של קישור לחומר זה), וכן את פרטייך האישיים המלאים (שם, כתובת, טלפון וכתובת דוא"ל) לשם יצירת קשר.  
  
  12.	שונות
  12.1.	החלוקה לסעיפים וכותרות הסעיפים הינה לשם נוחיות בלבד ולא תשמש לצורכי 
  פרשנות.
  12.2.	כל הקשור והנובע מהשימוש באפליקציה, במידע, בשירותים ובתכנים שבה, וכן כל הקשור ונובע מתנאי שימוש אלו יהיה כפוף לדין הישראלי בלבד, לרבות לצורך פרשנות. כל סכסוך או מחלוקת בקשר אליהם יהיו בסמכות בתי המשפט המוסמכים בישראל, באזור השיפוט של תל אביב יפו ויובאו בפניהם בלבד כמקום שיפוט ייחודי. 
  12.3.	האמור בתנאי השימוש לעיל מבטא באופן מלא וממצה את כל הנושאים והעניינים הנדונים בו או הקשורים לשימוש באפליקציה, וגובר על כל מצג, פרסום, נוהג, מכתבי כוונות או התחייבות, וכל מסמך אחר, בנושאים ובעניינים האמורים.
  12.4.	התנהגותנו או הסכמתנו לסטות, מתנאי כלשהו של תנאי השימוש, במקרה מסוים ובסדרת מקרים, לא תהווה תקדים ואין ללמוד ממנה גזירה שווה לכל מקרה בעתיד ולא תחשב כויתור על איזה מתנאי השימוש ו/או כויתור ו/או כהסכמה מצידנו לאיזו הפרה או אי קיום תנאי כלשהו,  אלא אם-כן נעשו במפורש ובכתב.
  12.5.	אם ייקבע שחלק כלשהו מתנאי השימוש אינו חוקי, אינו תקף או אינו בר אכיפה, הרי שאותו חלק יחשב כמוחלף בסעיפים תקפים אחרים הניתנים לאכיפה שתוכנם תואם במידת הקירוב הגדולה ביותר את כוונת הסעיפים המקוריים, ואילו יתר תנאי השימוש יישארו בתוקפם. 
  12.6.	אין בתנאי השימוש כדי להקנות למשתמשים או לצדדים שלישיים זכויות שלא הוקנו להם במפורש על פי תנאי השימוש או על פי הדין. 
  12.7.	אנו נהיה רשאים להעביר ו/או להסב בכל דרך (במישרין או בעקיפין, בתמורה או שלא בתמורה) את תנאי השימוש ו/או הזכויות ו/או החובות הכלולות ו/או הנובעות מהם, באופן מלא או חלקי, לכל אדם, תאגיד או כל יישות משפטית אחרת , ללא צורך בהודעה מוקדמת או בהסכמת המשתמש.
  12.8.	להסרת ספק נבהיר, כי תנאי השימוש חלים על כל אדם, זכר או נקבה, תאגיד או כל גוף אחר שהוא כשיר לזכויות ולחובות משפטיות, ללא יוצאים מן הכלל. השימוש בלשון זכר בתנאי שימוש אלה הינו לצרכי נוחות בלבד וכולל גם פניה בלשון נקבה, יחיד ורבים ולהיפך.
  

  תקנון משחק "ECO MUSIC", -  פעילות חלוקה בפייסבוק
 
1.1  "התחנה":  רדיו Eco99fm
1.2   "התוכנית" -  תוכנית "הבוקר" ו/או "גרין דיי" ו/או "אקו-לייף" ו/או "סטטוס" ו/או ”MY MUSIC” ו/או "אקודרייב" ו/או "אקולייזר" ו/או "עד חצות" ו/או "אקו-טריפ" המשודרת בתחנה, לפי העניין.
1.3   "נותנת החסות למשחק": החברה הנותנת חסות למשחק במסגרת התוכנית.
1.4   "המשחק": פעילות הנערכת על ידי נותנת החסות, כמפורט להלן, במסגרתה יוכלו מאזיני התוכנית להשתתף בפעילות במסגרת עמוד הפעילות באתר (כהגדרתו להלן) ולזכות בפרסים.
1.5  "משתתף" או "משתתפים": כל מאזין של התוכנית העומד בתנאי סעיפים 3 ו-4 להלן ואשר מבקש להשתתף במשחק באמצעות עמוד הפעילות באתר כמפורט להלן.
1.6  "האתר": אתר התחנה בכתובת www.eco99.fm
1.7  "פייסבוק": אתר האינטרנט בכתובת facebook.com
1.8  "עמוד הפעילות בפייסבוק ": עמוד הפייסבוק של התוכנית בכתובת https://www.facebook.com/pages/FM-%D7%90%D7%A7%D7%95-99/212600762093221?ref=tn_tnmn 1.9  "השדרן": מנחה או מנחת התוכנית
 
2.  כללי
2.1  תקנון זה (להלן: "התקנון") נועד להסדיר את הכללים על פיהם ינוהל המשחק   הנערך מטעם נותנת החסות למשחק מעת לעת במסגרת התוכנית (להלן: "תקופת המשחק").
התקנון מסדיר את תנאי ההשתתפות במשחק והינו חוזה לכל דבר ועניין, שיש בו כדי למצות את זכויות וחובות הצדדים בקשר להשתתפות במשחק. קבלתו של התקנון ללא שינוי הינה תנאי להשתתפות במשחק זה. התחנה ו/או נותנת החסות למשחק שומרות לעצמן את הזכות לשנות ו/או לעדכן את התקנון בכל עת, לפי שיקול דעתן הבלעדי והמוחלט, ללא צורך במתן הודעה מוקדמת, וכל שינוי יחייב מרגע פרסומו באתר. בכל מקרה של סתירה ו/או אי-התאמה בין הוראות תקנון זה לכל פרסום או מצג אחרים בדבר המשחק, בכתב או בעל-פה, מכל מין וסוג שהוא ומכל מועד שהוא, לרבות באתר ו/או בעיתונים ו/או במודעות, תגברנה הוראות תקנון זה לכל דבר ועניין והן אשר תקבענה. באחריותם הבלעדית של המשתתפים להתעדכן בפרטי התקנון ובכל  שינוי ו/או עדכון ו/או תיקון וכיו"ב שייעשו בתקנון.
2.2 התחנה ו/או נותנת החסות למשחק רשאית, מכל סיבה שהיא, לשנות את תנאי התקנון, לרבות תנאי ההשתתפות במשחק וכללי המשחק עצמו כמו גם להאריך ו/או לקצר ו/או לשנות משך תקופת המשחק ו/או לבטלה, על פי שיקול דעתה הבלעדי, בהודעה אשר תתפרסם באתר ו/או בעמוד הפעילות בפייסבוק. מוסכם, כי הודעה כאמור תשמש הודעה מספיקה והולמת והמשתתף מוותר על כל טענה בנוגע ו/או בנובע ו/או בקשור לאמור. נותנת החסות למשחק רשאית גם להאריך את משך תקופת המשחק, ולהחליט כי במהלך התקופה המוארכת לא יחולקו פרסים נוספים מלבד האמור להלן, הכל לפי שיקול דעתה הבלעדי של נותנת החסות למשחק. התחנה ו/או נותנת החסות למשחק לא תשאנה בכל אחריות ולא תחויבנה בכל חובה עקב כל שינוי כאמור לעיל.
2.3  המשתתפים מאשרים כי קראו והבינו וכי הם מסכימים לכל הוראות תקנון זה ופוטרים את התחנה ואת נותנת החסות למשחק, מכל טענה ו/או תביעה ו/או דרישה בקשר למשחק ו/או לתקנון ו/או לתקלות כלשהן בקשר למשחק ומוותרים על כל טענה הקשורה באלה, בלא יוצא מן הכלל.
 
3. מי רשאי להשתתף במשחק
3.1  בכפוף לאמור בסעיף 4 להלן, כל מאזין מעל גיל 16 בעל דף פייסבוק, רשאי להשתתף במשחק.
3.2  למען הסר ספק יובהר כי ההשתתפות מותרת ליחידים בלבד ושלא לצרכים מסחריים.
 
4.   סייגים להשתתפות במשחק
4.1   מבלי לגרוע ו/או לפגוע באמור לעיל, התחנה ו/או נותנת החסות למשחק תהיינה רשאיות להחליט, מראש או בדיעבד, על מניעת ו/או פסילת השתתפותו של משתתף במשחק ו/או שלילת זכאותו של משתתף לפרס בו זכה במסגרת המשחק וזאת מכל סיבה שהיא ובלי מתן כל הודעה או התראה למשתתף, בין השאר ומבלי למצות מהסיבות הבאות:
4.1.1  אי עמידה בתנאי השתתפות הנקובים בסעיף 3 לעיל (כולם או חלקם);
4.1.2  מסירת פרטים שאינם נכונים ו/או מדויקים ו/או פוגעניים ו/או מסולפים וכיו"ב;
4.1.3  התנהגות שאינה ראויה ו/או פסולה ו/או בלתי הולמת ו/או חסרת תום לב ו/או הפעלת אמצעים שאינם כשרים;
4.1.4   במקרה של חשד להפרת תנאי תקנון זה ו/או עקב שימוש באמצעים בלתי כשרים ו/או תחבולה ו/או כל פעולה שלא כדין ו/או תוך עשיית עבירה ו/או שלא על פי הוראות הדין;
4.1.5   אי מילוי אחר התנאים האמורים בתקנון זה;
4.1.6   מכל סיבה אחרת שהתחנה ו/או נותנת החסות למשחק תמצאנה לנכון על פי שיקול דעתן הבלעדי.
4.2  מובהר ומוסכם כי למשתתף לא תהיה כל טענה ו/או דרישה ו/או תביעה כנגד התחנה ו/או נותנת החסות למשחק בקשר עם מניעת ו/או פסילת השתתפותו במשחק ו/או זכייתו בפרס כאמור לעיל.
4.3  ההשתתפות במשחק אסורה על עובדי התחנה, עובדי נותנת החסות למשחק וכן עוזריהם, עובדיהם, מנהליהם, שותפיהם ובני משפחותיהם.
 
5.  המשחק ואופן ההשתתפות בו
 
א.  המשחק עצמו
5.1  במסגרת התוכנית יכריז השדרן על חידה נושאת פרסים המתפרסמת בעמוד הפעילות בפייסבוק. המאזינים יוזמנו להכנס לעמוד הפעילות בפייסבוק, לענות על החידה באמצעות תגובה על אותה החידה כפי שתפורסם בעמוד הפעילות בפייסבוק.
5.2  בנוסף, יתבקשו המאזינים לעשות לייק ו/או share לחידה הספציפית אשר הוקראה על ידי השדרן בשידור (הכל בהתאם לכתוב בעמוד הפעילות בפייסבוק בקשר עם הפעילות הספציפית).
5.3  כן יתבקשו המאזינים לשלוח את פרטי ההתקשרות שלהם (שם פרטי ושם משפחה, טלפון נייד ומען מדויק) באמצעות הודעה פרטית לעמוד הפעילות בפייסבוק.
 
ב.  בחירת הזוכים
5.4  המשתתף הראשון אשר: ענה על החידה בעמוד הפעילות בפייסבוק לאחר שפרסומה הוכרז על ידי השדרן; עשה לייק ו/אוshare  כאמור בסעיף 5.2 לעיל; ושלח את פרטיו כאמור בסעיף 5.3 לעיל יזכה בפרס.
5.5   למען הסר ספק החלטת מערכת ההפקה של התוכנית בדבר סוג המשחק ובחירת המנצחים הינן סופיות, מוחלטות, חלוטות, בלתי ניתנת לתביעה ו/או לערעור ומחייבות את המבקשים להשתתף במשחק כמו גם את  כל המשתתפים במשחק עצמו.
5.6  מבלי לגרוע מן האמור לעיל, יובהר כי משתתף אשר לא ניתן היה ליצור עימו קשר על בסיס הפרטים שמסר בהתאם לסעיף 5.3 לעיל, מכל סיבה שהיא, עשוי לאבד את זכאותו לפרס. במקרה כזה, מערכת ההפקה של התוכנית שומרת על זכותה להעביר את הפרס למשתתף הבא בתור אשר עונה על הקריטריונים המנויים בסעיף 5.4 לעיל.
5.7  הזכייה באילו מן הפרסים המפורטים בתקנון זה הינה אישית ואינה ניתנת להעברה.
5.8  הזכאות לפרסים במסגרת המשחק הינה בכפוף להוראות תקנון זה, ובכפוף לכך שהתחנה ו/או נותנת החסות למשחק תהינה רשאיות, אך לא חייבות, לוודא את זהות הזוכים בכל דרך חוקית שהיא, על מנת לוודא, בין השאר, כי הפרטים שנמסרו על ידם וזהותם הינם נכונים מדויקים ומלאים. כן תהינה רשאיות התחנה ו/או נותנת החסות למשחק, אך לא חייבות, לבחון את עמידתם של הזוכים בתנאי התקנון, הן ביחס לאפשרות להשתתף במשחק והן ביחס לאופן ההשתתפות עצמו ולקבוע האם המשתתף אכן זכאי לקבלת הפרס בו זכה. החלטת התחנה ו/או נותנת החסות למשחק ביחס לזכאותו של משתתף לקבלת פרס הינה סופית, מוחלטת ואינה ניתנת לערעור ולא תהיה למשתתף כל טענה ו/או דרישה ו/או תביעה לעניין זה כנגד התחנה ו/או נותנת החסות למשחק.
5.9  כתנאי למימוש הפרס תהיה התחנה ו/או נותנת החסות למשחק רשאית לדרוש מהמשתתף לחתום על אישורים, הצהרות ו/או ויתורים. באם לא יחתום המשתתף על המסמכים האמורים, תהינה התחנה ו/או נותנת החסות רשאיות, על פי שיקול דעתן הבלעדי, לשלול ממשתתף זכאותו לפרס בו זכה.
5.10 התחנה ו/או נותנת החסות למשחק שומרת לעצמה את הזכות להחליף אילו מן הפרסים במוצר או שירות שווה ערך או דומה, על פי שיקול דעתה בכל עת ומכל סיבה שהיא או להתנות את הענקת הפרס בתנאים, הכל בהתאם לעדכונים אשר יפורסמו במסגרת תקנון זה כפי שיעודכן מעת לעת ו/או באתר.
5.11 במקרה שזכייתו בפרס של משתתף תיפסל, מכל סיבה שהיא, רשאית נותנת החסות למשחק להעביר את הזכאות לפרס למשתתף אחר או לא להעניק את הפרס כלל, בהתאם לשיקול דעתה הבלעדי.
5.12 מובהר, כי התחנה ונותנת החסות למשחק לא תשאנה באחריות כלשהי ולא תחובנה בחובה כלשהי במקרה של אי יכולת אילו מהזוכים לנצל את הפרס בו זכו, או של נזק, מכל מין וסוג שהוא, שיגרמו למשתתף או לזוכה, במישרין או בעקיפין עקב השתתפותו במשחק ו/או עקב ניצול הפרס או אי ניצולו.
5.13 משתתפים אשר יזכו בפרסם כלשהו במסגרת המשחק נותנים בזאת הסכמה בלתי חוזרת ומודעים לכך שדבר זכייתם עשוי (לפי שיקול דעתה הבלעדי של התחנה ו/או נותנת החסות למשחק) להיות מסוקר ו/או מפורסם ו/או מצולם באמצעי התקשורת השונים לרבות בטלוויזיה, ברדיו, בעיתונות, באתר האינטרנט וכי בהשתתפותו במשחק מביע המשתתף את הסכמתו לפרסום במסגרת הסיקורים והפרסומים האמורים לרבות במסגרת פרסומים וקידום מכירות של התחנה ו/או נותנת החסות למשחק.
 
ג.  הפרסים
5.14 הפרס לזוכה במשחק ייבחר בהתאם לשיקול דעתה הבלעדי של התחנה ו/או נותנת החסות (ועשויים אף להשתנות משעת שידור אחת לשנייה ו/או מיום ליום משך תקופת המשחק), ויפורסמו על ידי השדרן במסגרת פעילות המשחק.
5.15 הפרסים יחולקו לזוכים במשחקים על ידי נותנת החסות במישרין או באמצעות התחנה במשרדי התחנה ברחוב דבורה הנביאה 6, רמת השרון (הכל לפי שיקול דעת נותנת החסות והתחנה) במועד של עד 60 ימי עסקים מיום בו הודיעה התחנה למשתתף כי זכה בפרס. מובהר כי הפרסים, טיבם, איכותם, מועד ו/או עצם אספקתם ו/או היכולת לממשם על ידי הזוכה (לרבות מפאת גילו), אינם באחריות התחנה ו/או נותנת החסות. מבלי לגרוע מהאמור לעיל, לא יהיה בעצם חלוקת הפרסים על ידי התחנה כדי לייצר ו/או להטיל חבות כלשהי של התחנה כלפי הזוכים.
 
6.  הוראות כלליות בנוגע למשחק
6.1  כל החלטה הנוגעת לקיומו של המשחק, לרבות אך לא רק, ההשתתפות במשחק, פרסום המשחק, תקופת המשחק והחלטות אחרות, תעשה על ידי נותנת החסות למשחק ו/או התחנה ועל פי שיקול דעתן הבלעדי.
6.2  ידוע למשתתף כי התחנה ו/או נותנת החסות למשחק תהינה רשאיות לשנות את המשחק, כלליו כפי שנקבעו בתקנון, תקופתו וכיו"ב ו/או להפסיקו זה על פי שיקול דעתן הבלעדי ללא צורך במתן הודעה מוקדמת, וכל שינוי יחייב מרגע פרסומו באתר ובשידור. יובהר כי התחנה ו/או נותנת החסות לא תשאנה בכל אחריות בקשר עם האמור לעיל, לרבות מקום בו יופסק ו/או לא יחל המשחק, מכל סיבה שהיא.
6.3  ידוע למשתתף כי התחנה ו/או נותנת החסות למשחק לא תהינה אחראית לכל מניעה להשתתפות במשחק ובכלל זה בכל מקרה של תקלה, עיכוב וכיו"ב בתקשורת ו/או במערכות התחנה ו/או באפשרות לשלוח ו/או לקבל מסרונים.
6.4  התחנה ונותנת החסות לא תהינה אחראיות לכל נזק ישיר ו/או עקיף, לרבות הוצאות ישירות ו/או עקיפות שיגרמו למשתתף ו/או לכל צד שלישי עקב משלוח המסרון ו/או ההשתתפות במשחק ו/או הסתמכות התחנה ו/או נותנת החסות למשחק על הפרטים אשר ימסרו להן על ידי המשתתף.
6.5  התחנה ו/או נותנת החסות למשחק לא תהינה אחראית לכל נזק ישיר ו/או עקיף אשר עשוי להגרם למשתתף עקב שינוי תנאי התקנון ו/או המשחק לרבות: ביטולו, קיצור תקופת המשחק או שינויה, שינוי בפרסים המחולקים לזוכים וכיו"ב ולרבות בגין הוצאות ישירות ו/או עקיפות שתגרמנה כתוצאה ו/או בקשר עם ההשתתפות במשחק זו לרבות נזקים מחמת כוח עליון, תאונות, מחלות, גניבות, אובדן של כל נכס, עגמת נפש וכו', ו/או לכל החלטה ו/או פעולה אשר יקבל ו/או יעשה משתתף על סמך השתתפותו במשחק זה.
6.6  מבלי לגרוע ו/או לפגוע באמור לעיל מובהר, כי ההשתתפות במשחק על כל שלביו הינה על אחריותו הבלעדית של המשתתף. התחנה ו/או נותנת החסות למשחק לא תהינה אחראית ולא תשאנה בכל אבדן, נזק ישיר ו/או עקיף (לרבות נזק בגוף), הפסד, לרבות הוצאות ישירות ו/או עקיפות, שיגרמו למי מהמשתתפים ו/או לצדדים שלישיים כלשהם בעקבות המשחק, לרבות אך לא רק, כתוצאה מהשתתפות במשחק ו/או בקשר עם המשחק ו/או בקשר עם מסירת פרטים לא נכונים ו/או לא ראויים ו/או לא מלאים ו/או לא מדויקים ו/או משובשים ו/או חסויים ו/או מנוגדים לכל דין בדרך אחרת לצורך ההשתתפות במשחק ו/או במסגרת המשחק ו/או עקב טעות, שיבוש, איחור, תקלה או כל עניין אחר הקשור ברשת הרט"ן ו/או האינטרנט ו/או רשת החשמל ו/או רשת המחשבים ו/או התקשורת.
6.7  מבלי לגרוע מהאמור לעיל, המשתתף מצהיר ומאשר כי ידוע לו שיתכן שתיפולנה טעויות בבחירת הזוכים במשחק ו/או ניהול המשחק ו/או תפעולו ו/או במערכות התחנה, וכי בכל מקרה של טעות כאמור, לא תהווה ההשתתפות ו/או אי ההשתתפות במשחק עילה לכל תביעה ו/או טענה ו/או דרישה וכיו"ב כנגד התחנה ו/או נותנת החסות למשחק בגין נזק מכל מין וסוג שהוא אשר יגרם, במישרין ו/או בעקיפין עקב טעות, איחור, כשל וכיו"ב בהשתתפות במשחק, לרבות אי קליטת רישומו של משתתף להשתתפות במשחק.
6.8  מבלי לגרוע מהאמור לעיל, התחנה ו/או נותנת החסות למשחק לא תהינה אחראיות בכל אופן שהוא לכל נזק ו/או אובדן ו/או פגיעה שיגרמו למשתתף ו/או לצד ג' כלשהו כתוצאה מהפרה ו/או אי קיום הוראות תקנון זה או כתוצאה מכל מעשה ו/או מחדל של המשתתף, המשתתף מתחייב לשפות את התחנה ואת נותנת החסות למשחק בגין כל דמי נזק ו/או הוצאה שתחויבנה בהן בקשר לאמור, לרבות בגין כל הוצאה בה תישאנה עקב תביעה שתוגש כנגדן בבית המשפט או כל פשרה שתתפשרנה בה, ובשל הצורך להתגונן כנגד תביעה כאמור.
 
7.  הצהרות נוספות של המשתתפים
מבלי לגרוע ו/או לפגוע באמור לעיל עם הפנייה להשתתפות במשחק ו/או בעצם השתתפות במשחק מצהיר, מסכים, מאשר ומתחייב המשתתף כי:
7.1  אין כל מניעה חוקית, חוזית או אחרת להשתתפותו במשחק.
7.2  כי לא יעשה כל שימוש ו/או ניצול לרעה של המשחק.
7.3  לתחנה ו/או לנותנת החסות למשחק זכות שימוש בלתי חוזרת, לפי שיקול דעתן הבלעדי, לעשות שימוש בפרטים הנמסרים על ידי המשתתף במסגרת השתתפות במשחק בהתאם להוראות הדין, לרבות חוק הגנת הפרטיות, התשמ"א-1981. מבלי לפגוע באמור לעיל ידוע למשתתף והוא מאשר כי פרטים אשר נמסרו על ידו לתחנה עשויים להיות מועברים על ידי התחנה לנותנת החסות למשחק לשם יח"צ, יצירת קשר עם הזוכים, חלוקת פרסים וכו'.
7.4  התחנה ו/או נותנת החסות למשחק לא תהינה אחראית על אמיתות הפרטים הנמסרים על ידי המשתתף לצורך השתתפות במשחק ו/או במסגרת המשחק עצמו והתחנה ונותנת החסות למשחק רשאיות להסתמך על פרטים אלו לכל דבר ועניין.
7.5  מבלי לגרוע באמור לעיל, למשתתף לא תהיה כל טענה ו/או דרישה ו/או תביעה כנגד התחנה ונותנת החסות למשחק בקשר עם השימוש בפרטים שנמסרו על ידו ו/או המופיעים במסרון לרבות שידורם ופרסומם.
7.6  מבלי לגרוע מן האמור לעיל, ידוע למשתתף כי השתתפותו ו/או אי השתתפותו במשחק כפופה לזמינות ותקינות רשת האינטרנט, רשתות מחשבים, האתר, רשת הטלפוניה, רשת הפלאפוניה, רשת החשמל, תוכנות ומערכות השונות (להלן יחדיו: "המערכות") אשר באמצעותן מתנהלת המשחק, הן אלה שברשות התחנה והן אלו שברשות צדדים שלישיים. התחנה ו/או נותנת החסות למשחק לא תחוב בכל חובה ולא תשא בכל אחריות, לא תשפה ולא תדרש לשפות את המשתתף ו/או כל גורם אחר בקשר עם ו/או כתוצאה עם כל טענה ו/או תביעה ו/או דרישה אשר עניינה נזק ו/או הוצאה וכיו"ב ישיר או עקיף אשר יגרם למשתתף ו/או לצדדים שלישיים עקב גישה לא מורשית ו/או קלקולים ו/או אי דיוקים ו/או שגיאות ו/או טעות, תקלה, איחור, השמטה, מחיקה, שיבוש, או כל כשל טכני הנובע ו/או הקשור במערכות, לרבות, אך לא רק, תקלות בקבלת מסרונים ו/או שיבוש פרטי המשתתף וכיו"ב.
 
8.  בעלות בחומרים
מבלי לגרוע ו/או לפגוע באמור לעיל, כל הזכויות, ובכלל זה זכויות הקניין הרוחני בתוכנית, בעמוד הפעילות בפייסבוק ובאתר על כל מרכיביהם הינן של התחנה אשר תהא רשאית לנהוג בהם, או בכל חלק מהם, מנהג בעלים, לעשות בה כל שימוש ללא כל הגבלה, תנאי או תשלום וזאת ללא קבלת אישור המשתתפים ו/או הסכמתם. המשתתפים מוותרים על כל טענה ו/או תביעה ו/או דרישה ו/או זכות אשר עניינה התוכנית (כולה או חלקה), לרבות עצם הבעלות בהם ו/או דרישה לקבלת תשלום, תגמול, שיפוי וכיו"ב בגין השימוש בהם.
 
9.  הוראות נוספות
9.1  החלוקה לסעיפים וכותרות הסעיפים הינה לשם נוחיות בלבד ולא תשמש לצורכי
 
פרשנות.
9.2  כל המועדים הרלבנטיים למשחק וכל נושא אחר הקשור ו/או הנובע מהמשחק יקבעו  אך ורק על ידי התחנה ו/או נותנת החסות למשחק ואלו אינם ניתנים להשגה או לערעור.
9.3  מבלי לגרוע מן האמור לעיל, הכרעה בגין כל מחלוקת ו/או נושא הקשורים ו/או הנובעים מהמשחק יהיו מסורים באופן ייחודי לתחנה ו/או לנותנת החסות למשחק והכרעתה תהא סופית ומחלטת ולמשתתף לא תהא כל טענה, תביעה או דרישה נגד נותנת החסות למשחק.
9.4   למען הסר ספק, המשחק הינו לצורכים שיווקיים גרידא ואין הוא נכלל בגדר אילו מן הפעילויות הנזכרות בסעיף 224 לחוק העונשין, התשל"ז-1977.
9.5   למען הסר ספק, הפעילות אינה ממומנת, מנוהלת או מבוצעת על ידי פייסבוק, וזו מהווה פלטפורמה לביצוע הפעילות ותו לא. ההשתתפות בפעילות כפופה גם לתנאי השימוש ומדיניות הפרטיות של פייסבוק.
9.6   השימוש בלשון זכר בתקנון זה הינו לצרכי נוחות בלבד וכולל גם פניה בלשון נקבה, יחיד ורבים.
9.7   לבית המשפט המוסמך בתל אביב-יפו תהיה הסמכות הבלעדית לדון בכל מחלוקת הנובעת ו/או הנוגעת בתקנון ו/או במשחק ודיני מדינת ישראל, בלבד, הם שיחולו.
9.8   עותקים מן התקנון מצויים במשרדי התחנה ברחוב דבורה הנביאה 6, רמת השרון, שם ניתן לעיין בהם בימים א'-ה' בין השעות 09:00-16:00, ובאתר האינטרנט www.eco99.fm (תחת מחיצת "תנאי שימוש". למען הסר ספק הנוסח המפורסם באתר הוא נוסחו הקובע של התקנון.
 
אנו מברכים אותך על השתתפותך במשחק ומאחלים לך הצלחה!
 
 
 
תקנון משחק "תוכנית הבוקר" של טל ואביעד
 
1. הגדרות
1.1 "התחנה": רדיו Eco99fm
1.2 "נותנת החסות למשחק": החברה הנותנת חסות למשחק במסגרת "תוכנית הבוקר" המוגשת על ידי השדרנים
1.3 "המשחק": פעילות הנערכת על ידי נותנת החסות, כמפורט להלן, במסגרתה יוכלו
מאזיני התוכנית "תוכנית הבוקר" (להלן: "התוכנית") להשתתף במשחק ולזכות בפרסים.
"משתתף": כל מאזין של התוכנית המבקש להשתתף במשחק באמצעות הרשמה לו
כמתואר בסעיף 5 בתקנון זה להלן וכן המאזינים אשר יבחרו להשתתף במשחק בפועל.
1.4 "האתר": אתר התחנה בכתובת www.eco99.fm
1.5 "השדרנים": מנחי "תוכנית הבוקר" בתחנה.
 
2. כללי
2.1 תקנון זה (להלן: "התקנון") נועד להסדיר את הכללים על פיהם ינוהל המשחק הנערך מטעם נותנת החסות למשחק במהלך התקופה אשר תוכרז על ידי השדרנים במסגרת "תוכנית הבוקר" (להלן: "תקופת המשחק").
2.2 התקנון מסדיר את תנאי ההשתתפות במשחק והינו חוזה לכל דבר ועניין, שיש בו כדי למצות את זכויות וחובות הצדדים בקשר להשתתפות במשחק. קבלתו של התקנון ללא שינוי הינה תנאי להשתתפות במשחק זה. התחנה ו/או נותנת החסות למשחק שומרות לעצמן את הזכות לשנות ו/או לעדכן את התקנון בכל עת, לפי שיקול דעתן הבלעדי והמוחלט, ללא צורך במתן הודעה מוקדמת, וכל שינוי יחייב מרגע פרסומו באתר. בכל מקרה של סתירה ו/או אי התאמה בין התקנון לבין כל פרסום אחר, בכתב או בעל-פה, מכל מין וסוג שהוא, תגברנה הוראות תקנון זה והן אשר תקבענה. באחריותם הבלעדית של המשתתפים להתעדכן בפרטי התקנון ובכל שינוי ו/או עדכון ו/או תיקון וכיו"ב שייעשו בתקנון.
2.3 התחנה ו/או נותנת החסות למשחק רשאית, מכל סיבה שהיא, לשנות את תנאי התקנון, לרבות תנאי ההשתתפות במשחק וכללי המשחק עצמו כמו גם להאריך ו/או לקצר ו/או לשנות משך תקופת המשחק ו/או לבטלה, על פי שיקול דעתה הבלעדי, בהודעה אשר תתפרסם כאמור בסעיף 31.8. מוסכם, כי הודעה כאמור תשמש הודעה מספיקה והולמת והמשתתף במשחק מוותר על כל טענה בנוגע ו/או בנובע ו/או בקשור לאמור. נותנת החסות למשחק רשאית גם להאריך את משך תקופת המשחק, ולהחליט כי במהלך התקופה המוארכת לא יחולקו פרסים נוספים מלבד האמור להלן, הכל לפי שיקול דעתה הבלעדי של נותנת החסות למשחק. התחנה ו/או נותנת החסות למשחק לא תשאנה בכל אחריות ולא תחויבנה בכל חובה עקב כל שינוי כאמור לעיל.
2.4 המשתתפים מאשרים כי קראו והבינו וכי הם מסכימים לכל הוראות תקנון זה ופוטרים את התחנה ואת נותנת החסות למשחק, מכל טענה ו/או תביעה ו/או דרישה בקשר למשחק ו/או לתקנון ו/או לתקלות כלשהן בקשר למשחק ומוותרים על כל טענה הקשורה באלה, בלא יוצא מן הכלל.
 
3. מי רשאי להשתתף במשחק
3.1 בכפוף לאמור בסעיף 4 להלן, כל מאזין מעל גיל 18 רשאי להשתתף במשחק.
3.2 למען הסר ספק יובהר כי ההשתתפות מותרת ליחידים בלבד ושלא לצרכים מסחריים.
 
4. סייגים להשתתפות במשחק
4.1 מבלי לגרוע ו/או לפגוע באמור לעיל, התחנה ו/או נותנת החסות למשחק תהיינה רשאיות להחליט, מראש או בדיעבד, על מניעת ו/או פסילת השתתפותו של משתתף במשחק ו/או שלילת זכאותו של משתתף לפרס בו זכה במסגרת המשחק וזאת מכל סיבה שהיא ובלי מתן כל הודעה או התראה למשתתף, בין השאר ומבלי למצות מהסיבות הבאות:
4.1.1 אי עמידה בתנאי השתתפות הנקובים בסעיף 3 לעיל (כולם או חלקם);
4.1.2 מסירת פרטים שאינם נכונים ו/או מדוייקים ו/או פוגעניים ו/או מסולפים וכיו"ב;
4.1.3 התנהגות שאינה ראויה ו/או פסולה ו/או בלתי הולמת ו/או חסרת תום לב ו/או הפעלת אמצעים שאינם כשרים;
4.1.4 במקרה של חשד להפרת תנאי תקנון זה ו/או עקב שימוש באמצעים בלתי כשרים ו/או תחבולה ו/או כל פעולה שלא כדין ו/או תוך עשיית עבירה ו/או שלא על פי הוראות הדין;
4.1.5 אי מילוי אחר התנאים האמורים בתקנון זה;
4.1.6 מכל סיבה אחרת שהתחנה ו/או נותנת החסות למשחק תמצאנה לנכון על פי שיקול דעתן הבלעדי.
4.2 מובהר ומוסכם כי למשתתף לא תהיה כל טענה ו/או דרישה ו/או תביעה כנגד התחנה ו/או נותנת החסות למשחק בקשר עם מניעת ו/או פסילת השתתפותו במשחק ו/או זכייתו בפרס כאמור לעיל.
4.3 ההשתתפות במשחק אסורה על עובדי התחנה, עובדי נותנת החסות למשחק וכן עוזריהם, עובדיהם, מנהליהם, שותפיהם ובני משפחותיהם.
 
5. המשחק ואופן ההשתתפות בו
א. הרשמה למשחק
5.1 על מנת להירשם למשחק המתקיים ביום נתון יש להתקשר באותו היום למספר הטלפון לעלייה לשידור אשר יפורסם על ידי השדרנים: 1-900-72-99-99 (השיחה כרוכה בעלות של 50 אג' לדקה בתוספת עלות שיחה סלולארית רגילה).בעת חיוג למספר הטלפון לעלייה לשידור, יש להמתין למענה אנושי. עצם ההמתנה כרוכה בתשלום (בעלות של 50 אג' לדקה בתוספת עלות שיחה סלולארית רגילה) ואין התחייבות לכך שתתבצע הרשמה למשחק ועלייה לשידור.
5.2 המשתתף יודע ומצהיר כי אין בעצם ההרשמה למשחק משום הבטחה ו/או יצירת מצג לעלייה לשידור, לנטילת חלק במשחק עצמו או לזכייה בפרס כלשהו.
 
ב. המשחק עצמו
6. לאחר ההרשמה למשחק כאמור לעיל תבחר מערכת ההפקה של התוכנית, על פי שיקול דעתה, מספר מאזינים אשר יעלו לשידור והשדרנים יבקשו מהם לספר סיפור בנושא מסוים או להשתתף בחידון - הכל לפי שיקול דעת התחנה ו/או נותנת החסות.
7. בחירת המאזינים אשר יעלו לשידור לשם השתתפות במשחק ביום נתון תעשה מבין כלל המאזינים אשר התקשרו באותו היום בלבד.
 
ג. בחירת הזוכים
8. פרס עלייה לשידור - בכל יום משך תקופת המשחק יזכה כל משתתף שעלה לשידור בפרס עלייה לשידור (להלן: "פרס העלייה לשידור"). מובהר כי יתכן ופרס העלייה לשידור ישתנה ממאזין למאזין.
9. בכל יום משך תקופת המשחק יחולקו בנוסף לפרס העלייה לשידור גם אחד משני סוגי הפרסים הבאים:
9.1 פרס למנצח שעתי - ישנם משחקים במסגרתם בכל שעת שידור של התוכנית נבחר המנצח השעתי (להלן: "המנצח השעתי") אשר יזכה בפרס. המנצח השעתי יהיה זה אשר סיפורו נבחר כסיפור הזוכה על ידי השדרנים באותה שעת שידור (אם מדובר במשחק במסגרתו יתבקשו המאזינים לספר סיפור בנושא מסויים) או זה אשר ענה על מירב התשובות הנכונות (אם מדובר במשחק מסוג חידון) – הכל בהתאם לסוג המשחק כאמור בסעיף 6 לעיל. או
9.2 פרס למנצח יומי – ישנם משחקים במסגרת בכל יום משך תקופת המשחק יבחר מבין המשתתפים אשר עלו לשידור באותו היום משתתף אשר יזכה בפרס נוסף (להלן: "המנצח היומי").
10. ישנם גם משחקים במסגרתם בנוסף לפרס עלייה לשידור ופרס למנצח השעתי (או פרס למנצח היומי, לפי העניין) תערך פעילות גמר במסגרתה עשויים המנצחים השעתיים או המנצחים היומיים (בתלות בסוג המשחק) להתבקש להגיע פיזית למיקום מסויים אשר יקבע על ידי התחנה על מנת להשתתף במשימת הגמר. הזוכה במשימת הגמר יזכה בפרס נוסף.
11. למען הסר ספק החלטת מערכת ההפקה של "תוכנית הבוקר" בדבר סוגי המשחקים, סוגי המנצחים, משימת הגמר וזהות המאזינים אשר יעלו לשידור כמו גם החלטת של השדרנים באשר למנצח השעתי או המנצח היומי או המנצח במשימת הגמר הינן סופיות, מוחלטות, חלוטות, בלתי ניתנת לתביעה ו/או לערעור ומחייבות את המבקשים להשתתף במשחק כמו גם את כל המשתתפים במשחק עצמו.
12. הזכייה באילו מן הפרסים המפורטים בתקנון זה הינה אישית ואינה ניתנת להעברה.
למעט אם יאושר אחרת במפורש ובכתב על ידי התחנה ו/או נותנת החסות על פי שיקול דעתן הבלעדי.
13. הזכאות לפרסים במסגרת המשחק הינה בכפוף להוראות תקנון זה, ובכפוף לכך שהתחנה ו/או נותנת החסות למשחק תהינה רשאיות, אך לא חייבות, לוודא את זהות הזוכים בכל דרך חוקית שהיא, על מנת לוודא, בין השאר, כי הפרטים שנמסרו על ידם וזהותם הינם נכונים מדויקים ומלאים. כן תהינה רשאיות התחנה ו/או נותנת החסות למשחק, אך לא חייבות, לבחון את עמידתם של הזוכים בתנאי התקנון, הן ביחס לאפשרות להשתתף במשחק והן ביחס לאופן ההשתתפות עצמו ולקבוע האם המשתתף אכן זכאי לקבלת הפרס בו זכה. החלטת התחנה ו/או נותנת החסות למשחק ביחס לזכאותו של משתתף לקבלת פרס הינה סופית, מוחלטת ואינה ניתנת לערעור ולא תהיה למשתתף כל טענה ו/או דרישה ו/או תביעה לעניין זה כנגד התחנה ו/או נותנת החסות למשחק.
14. כתנאי למימוש הפרס תהיה התחנה ו/או נותנת החסות למשחק רשאית לדרוש מהמשתתף לחתום על אישורים, הצהרות ו/או ויתורים. באם לא יחתום המשתתף על המסמכים האמורים, תהינה התחנה ו/או נותנת החסות רשאיות, על פי שיקול דעתן הבלעדי, לשלול ממשתתף זכאותו לפרס בו זכה.
15. נותנת החסות למשחק שומרת לעצמה את הזכות להחליף אילו מן הפרסים לעלייה לשידור ו/או למנצח היומי ו/או למנצח במשימת הגמר, במוצר או שירות שווה ערך או דומה, על פי שיקול דעתה בכל עת ומכל סיבה שהיא או להתנות את הענקת הפרס בתנאים, הכל בהתאם לעדכונים אשר יפורסמו במסגרת תקנון זה כפי שיעודכן מעת לעת ו/או באתר.
16. במקרה שזכייתו בפרס של משתתף תיפסל, מכל סיבה שהיא, רשאית נותנת החסות למשחק להעביר את הזכאות לפרס למשתתף אחר או לא להעניק את הפרס כלל, בהתאם לשיקול דעתה הבלעדי.
17. מובהר, כי התחנה ונותנת החסות למשחק לא תשאנה באחריות כלשהי ולא תחובנה בחובה כלשהי במקרה של אי יכולת להשתתף במשימת גמר ו/או במקרה של אי יכולת אילו מהזוכים לנצל את הפרס בו זכו, או של נזק, מכל מין וסוג שהוא, שיגרמו למשתתף או לזוכה, במישרין או בעקיפין עקב השתתפותו במשחק ו/או עקב ניצול הפרס או אי ניצולו.
18. משתתפים אשר יזכו בפרסם כלשהו במסגרת המשחק נותנים בזאת הסכמה בלתי חוזרת ומודעים לכך שדבר זכייתם עשוי (לפי שיקול דעתה הבלעדי של התחנה ו/או נותנת החסות למשחק) להיות מסוקר ו/או מפורסם ו/או מצולם באמצעי התקשורת השונים לרבות בטלוויזיה,ברדיו, בעיתונות, באתר האינטרנט וכי בהשתתפותו במשחק מביע המשתתף את הסכמתו לפרסום במסגרת הסיקורים והפרסומים האמורים לרבות במסגרת פרסומים וקידום מכירות של התחנה ו/או נותנת החסות למשחק.
 
ד. הפרסים
19. פרס העלייה לשידור והפרס למנצח היומי (או המנצח השעתי) או הפרס במסגרת משימת הגמר (ככל שתתקיים) ייבחרו בהתאם לשיקול דעתה הבלעדי של נותנת החסות (ועשויים אף להשתנות ממאזין למאזין ו/או מיום ליום משך תקופת המשחק), ויפורסמו על ידי השדרנים במסגרת פעילות המשחק.
20. הפרסים יחולקו לזוכים במשחקים על ידי נותנת החסות במישרין או באמצעות התחנה במשרדי התחנה ברחוב דבורה הנביאה 6, רמת השרון (הכל לפי שיקול דעת נותנת החסות והתחנה) במועד של עד 60 ימי עסקים מהיום בו הודיעה התחנה למשתתף כי זכה בפרס. מובהר כי הפרסים, טיבם, איכותם, מועד ו/או עצם אספקתם אינם באחריות התחנה ולא יהיה בעצם חלוקת הפרסים על ידי התחנה כדי לייצר ו/או להטיל חבות כלשהי של התחנה כלפי הזוכים.
 
ה. הוראות כלליות בנוגע למשחק
21. כל החלטה הנוגעת לקיומו של המשחק, לרבות אך לא רק, ההשתתפות במשחק, פרסום המשחק, תקופת המשחק והחלטות אחרות, תעשה על ידי נותנת החסות למשחק ו/או התחנה ועל פי שיקול דעתן הבלעדי.
22. ידוע למשתתף כי התחנה ו/או נותנת החסות למשחק תהינה רשאיות לשנות את המשחק, כלליו כפי שנקבעו בתקנון, תקופתו וכיו"ב ו/או להפסיקו זה על פי שיקול דעתן הבלעדי ללא צורך במתן הודעה מוקדמת, וכל שינוי יחייב מרגע פרסומו באתר ובשידור. יובהר כי התחנה ו/או נותנת החסות לא תשאנה בכל אחריות בקשר עם האמור לעיל, לרבות מקום בו יופסק ו/או לא יחל המשחק, מכל סיבה שהיא.
23. ידוע למשתתף כי בכל מקרה של תקלה, עיכוב וכיו"ב בתקשורת ו/או במערכות התחנה ו/או במקרה עשויה להימנע אפשרות להרשם למשחק ו/או להשתתף במשחק עצמו. התחנה ו/או נותנת החסות למשחק לא תהינה אחראית לכל מניעה כאמור.
24. התחנה ונותנת החסות לא תהינה אחראיות לכל לכל נזק ישיר ו/או עקיף, לרבות הוצאות ישירות ו/או עקיפות שיגרמו למשתתף ו/או לכל צד שלישי עקב ההרשמה להשתתפות במשחק ו/או השתתפות במחשק עצמו ו/או הסתמכות התחנה ו/או נותנת החסות למשחק על הפרטים אשר ימסרו להן על ידי המשתתף.
25. התחנה ו/או נותנת החסות למשחק לא תהינה אחראית לכל נזק ישיר ו/או עקיף אשר עשוי להגרם למשתתף עקב שינוי תנאי התקנון ו/או המשחק לרבות: ביטולו, קיצור תקופת המשחק, שינוי בפרסים המחולקים לזוכים וכיו"ב לרבות בגין קיצור תקופת המשחק או שינויה, ולרבות בגין הוצאות ישירות ו/או עקיפות שתגרמנה כתוצאה ו/או בקשר עם ההשתתפות במשחק זו לרבות נזקים מחמת כוח עליון, תאונות, מחלות, גניבות, אובדן של כל נכס, עגמת נפש וכו', ו/או לכל החלטה ו/או פעולה אשר יקבל ו/או יעשה משתתף על סמך השתתפותו במשחק זה.
26. מבלי לגרוע ו/או לפגוע באמור לעיל מובהר, כי ההשתתפות במשחק על כל שלביו (לרבות בשלב המוקדם של מסירת פרטים שונים לתחנה לצורך השתתפות במשחק), הינה על אחריותו הבלעדית של המשתתף. התחנה ו/או נותנת החסות למשחק לא תהינה אחראית ולא תשאנה בכל אבדן, נזק ישיר ו/או עקיף (לרבות נזק בגוף), הפסד, לרבות הוצאות ישירות ו/או עקיפות, שיגרמו למי מהמשתתפים ו/או לצדדים שלישיים כלשהם בעקבות המשחק, לרבות אך לא רק, כתוצאה מהשתתפות במשחק ו/או בקשר עם המשחק ו/או בקשר עם מסירת פרטים לצורך ההשתתפות במשחק ו/או במסגרת המשחק ו/או עקב טעות, שיבוש, איחור, תקלה או כל עניין אחר הקשור ברשת הרט"ן ו/או האינטרנט ו/או רשת החשמל ו/או רשת המחשבים ו/או התקשורת.
27. מבלי לגרוע מהאמור לעיל, המשתתף מצהיר ומאשר כי ידוע לו שיתכן שתיפולנה טעויות בבחירת המשתתפים למשחק ו/או ניהול המשחק ו/או תפעולו ו/או במערכות התחכנה, וכי בכל מקרה של טעות כאמור, לא תהווה ההשתתפות ו/או אי ההשתתפות במשחק עילה לכל תביעה ו/או טענה ו/או דרישה וכיו"ב כנגד התחנה ו/או נותנת החסות למשחק בגין נזק מכל מין וסוג שהוא אשר יגרם, במישרין ו/או בעקיפין עקב טעות, איחור, כשל וכיו"ב בהשתתפות במשחק, לרבות אי קליטת רישומו של משתתף להשתתפות במשחק.
28. מבלי לגרוע מהאמור לעיל, התחנה ו/או נותנת החסות למשחק לא תהינה אחראיות בכל אופן שהוא לכל נזק ו/או אובדן ו/או פגיעה שיגרמו למשתתף ו/או לצד ג' כלשהו כתוצאה מהפרה ו/או אי קיום הוראות תקנון זה או כתוצאה מכל מעשה ו/או מחדל של המשתתף, לרבות בגין שימוש בתכנים לא נכונים ו/או לא ראויים ו/או לא מלאים ו/או לא מדוייקים ו/או משובשים ו/או חסויים ו/או מנוגדים לכל דין בדרך אחרת. המשתתף מתחייב לשפות את התחנה ואת נותנת החסות למשחק בגין כל דמי נזק ו/או הוצאה שתחויבנה בהן בקשר לאמור, לרבות בגין כל הוצאה בה תישאנה עקב תביעה שתוגש כנגדן בבית המשפט או כל פשרה שתתפשרנה בה, ובשל הצורך להתגונן כנגד תביעה כאמור.
 
29. הצהרות נוספות של המשתתפים
מבלי לגרוע ו/או לפגוע באמור לעיל עם הפנייה להשתתפות במשחק ו/או בעצם השתתפות במשחק מצהיר, מסכים, מאשר ומתחייב המשתתף כי:
29.1 אין כל מניעה חוקית, חוזית או אחרת להשתתפותו במשחק.
29.2 כי לא יעשה כל שימוש ו/או ניצול לרעה של המשחק.
29.3 לתחנה ו/או לנותנת החסות למשחק זכות שימוש בלתי חוזרת, לפי שיקול דעתן הבלעדי, לעשות שימוש בפרטים הנמסרים על ידי המשתתף במסגרת השתתפות במשחק בהתאם להוראות הדין, לרבות חוק הגנת הפרטיות, התשמ"א-1981. מבלי לפגוע באמור לעיל ידוע למשתתף והוא מאשר כי פרטים אשר נמסרו על ידו לתחנה עשויים להיות מועברים על ידי התחנה לנותנת החסות למשחק לשם יצירת קשר עם הזוכים, חלוקת פרסים וכו'.
29.4 התחנה ו/או נותנת החסות למשחק לא תהינה אחראית על אמיתות הפרטים הנמסרים על ידי המשתתף לצורך השתתפות במשחק ו/או במסגרת המשחק עצמו והתחנה ונותנת החסות למשחק רשאיות להסתמך על פרטים אלו לכל דבר ועניין.
29.5 מבלי לגרוע באמור לעיל, למשתתף לא תהיה כל טענה ו/או דרישה ו/או תביעה כנגד התחנה ונותנת החסות למשחק בקשר עם השימוש בפרטים האמורים בסעיף 28.3 לרבות שידורם ופרסומם.
29.6 ידוע למשתתף כי השתתפותו ו/או אי השתתפותו במשחק כפופה לזמינות ותקינות רשת האינטרנט, רשתות מחשבים, האתר, רשת הטלפוניה, רשת החשמל, תוכנות ומערכות השונות (להלן יחדיו: "המערכות") אשר באמצעותן מתנהלת המשחק, הן אלה שברשות התחנה והן אלו שברשות צדדים שלישיים. התחנה ו/או נותנת החסות למשחק לא תחוב בכל חובה ולא תשא בכל אחריות, לא תשפה ולא תדרש לשפות את המשתתף ו/או כל גורם אחר בקשר עם ו/או כתוצאה עם כל טענה ו/או תביעה ו/או דרישה אשר עניינה נזק ו/או הוצאה וכיו"ב ישיר או עקיף אשר יגרם למשתתף ו/או לצדדים שלישיים עקב גישה לא מורשית ו/או קלקולים ו/או אי דיוקים ו/או שגיאות ו/או טעות, תקלה, איחור, השמטה, מחיקה, שיבוש, או כל כשל טכני הנובע ו/או הקשור במערכות, לרבות, אך לא רק, תקלות בהרשמה למשחק ו/או השתתפות במשחק; אי קליטת הרשמת המשתתף להשתתפות במשחק; שיבוש פרטי המשתתף וכיו"ב.
 
30. בעלות בחומרים
מבלי לגרוע ו/או לפגוע באמור לעיל, כל הזכויות, ובכלל זה זכויות הקניין הרוחני בכל הקשור ו/או הנובע במשחק הינן של נותנת החסות למשחק בלבד אשר תהא רשאית לנהוג בהם מנהג בעלים, לעשות בהם כל שימוש ללא כל הגבלה, תנאי או תשלום וזאת ללא קבלת אישור המשתתפים ו/או הסכמתם. המשתתפים מוותרים על כל טענה ו/או תביעה ו/או דרישה ו/או זכות אשר עניינה החומרים, לרבות עצם הבעלות בהם ו/או דרישה לקבלת תשלום, תגמול, שיפוי וכיו"ב בגין השימוש בהם.
 
31. הוראות נוספות
31.1 החלוקה לסעיפים וכותרות הסעיפים הינה לשם נוחיות בלבד ולא תשמש לצורכי פרשנות.
31.2 כל המועדים הרלבנטיים למשחק וכל נושא אחר הקשור ו/או הנובע מהמשחק יקבעו
אך ורק על ידי התחנה ו/או נותנת החסות למשחק ואלו אינם ניתנים להשגה או לערעור.
31.3 מבלי לגרוע מן האמור לעיל, הכרעה בגין כל מחלוקת ו/או נושא הקשורים ו/או הנובעים מהמשחק יהיו מסורים באופן ייחודי לתחנה ו/או לנותנת החסות למשחק והכרעתה תהא סופית ומחלטת ולמשתתף לא תהא כל טענה, תביעה או דרישה נגד נותנת החסות למשחק.
31.4 בכל מקרה של סתירה ו/או אי-התאמה בין הוראות תקנון זה לכל פרסום או מצג אחרים בדבר המשחק, בכתב או בעל-פה, מכל מין וסוג שהוא ומכל מועד שהוא, לרבות באתר ו/או בעיתונים ו/או במודעות, תגברנה הוראות תקנון זה לכל דבר ועניין והן אשר תקבענה.
31.5 למען הסר ספק, המשחק הינו לצורכים שיווקיים גרידא ואין הוא נכלל בגדר אילו מן הפעילויות הנזכרות בסעיף 224 לחוק העונשין, התשל"ז-1977.
31.6 השימוש בלשון זכר בתקנון זה הינו לצרכי נוחות בלבד וכולל גם פניה בלשון נקבה, יחיד ורבים.
31.7 לבית המשפט המוסמך בתל אביב-יפו תהיה הסמכות הבלעדית לדון בכל מחלוקת הנובעת ו/או הנוגעת בתקנון ו/או במשחק ודיני מדינת ישראל, בלבד, הם שיחולו.
31.8 עותקים מן התקנון מצויים במשרדי התחנה ברחוב דבורה הנביאה 6, רמת השרון, שם ניתן לעיין בהם בימים א'-ה' בין השעות 09:00-16:00, ובאתר האינטרנט www.eco99.fm (תחת מחיצת תוכנית הבוקר - תקנון). למען הסר ספק הנוסח המפורסם באתר הוא נוסחו הקובע של התקנון.
 
 
אנו מברכים אותך על השתתפותך במשחק ומאחלים לך הצלחה!
 
תקנון משחק "וויז 21 שאלות" של טל ואביעד
1. הגדרות
1.1 "התחנה": רדיו Eco99fm
1.2 "התוכנית": "תוכנית הבוקר"
1.3 "נותנת החסות למשחק": החברה הנותנת חסות למשחק במסגרת "תוכנית הבוקר" המוגשת על ידי השדרים – ווייז מובייל בע"מ.
1.4 "המשחק": פעילות הנערכת על ידי נותנת החסות, כמפורט להלן, במסגרתה יוכלו מאזיני התוכנית להשתתף במשחק ולזכות בפרסים. "משתתף": כל מאזין של התוכנית המבקש להשתתף במשחק באמצעות הרשמה לו
כמתואר בסעיף 5 בתקנון זה להלן וכן המאזינים אשר ייבחרו להשתתף במשחק בפועל כמתואר בסעיף 5 לתקנון.
1.5 "האתר": אתר התחנה בכתובת www.eco99.fm
1.6 "השדרים": מנחי "תוכנית הבוקר" בתחנה.
 
2. כללי
2.1 תקנון זה (להלן: "התקנון") נועד להסדיר את הכללים על פיהם ינוהל המשחק הנערך מטעם נותנת החסות למשחק בימי א' עד ה' הנכללים בתאריכים 23.06.2019-27.06.2019 בין השעות 09:00-07:00 (להלן: "תקופת המשחק").
2.2 התקנון מסדיר את תנאי ההשתתפות במשחק והינו חוזה לכל דבר ועניין, שיש בו כדי למצות את זכויות וחובות הצדדים בקשר להשתתפות במשחק. קבלתו של התקנון ללא שינוי הינה תנאי להשתתפות במשחק זה. התחנה ו/או נותנת החסות למשחק שומרות לעצמן את הזכות לשנות ו/או לעדכן את התקנון בכל עת (לרבות, ומבלי למצות, לשנות את תנאי ההשתתפות במשחק וכללי המשחק עצמו כמו גם להאריך ו/או לקצר ו/או לשנות משך תקופת המשחק), לפי שיקול דעתן הבלעדי והמוחלט, ללא צורך במתן הודעה מוקדמת, וכל שינוי יחייב מרגע פרסומו של תקנון מעודכן באתר. מוסכם, כי פרסום כאמור ישמש הודעה מספיקה והולמת ותחייב מרגע פרסומו. המשתתף במשחק מוותר על כל טענה בנוגע ו/או בנובע ו/או בקשור לאמור.
2.3 בכל מקרה של סתירה ו/או אי התאמה בין התקנון לבין כל פרסום אחר, בכתב או בעל-פה, מכל מין וסוג שהוא, תגברנה הוראות תקנון זה והן אשר תקבענה. באחריותם הבלעדית של המשתתפים להתעדכן בפרטי התקנון ובכל שינוי ו/או עדכון ו/או תיקון וכיו"ב שייעשו בתקנון והם מוותרים על כל טענה בנוגע, נובע או בקשר עם האמור.
2.4 נותנת החסות למשחק רשאית גם להאריך את משך תקופת המשחק, ולהחליט כי במהלך התקופה המוארכת לא יחולקו פרסים נוספים מלבד האמור להלן, הכל לפי שיקול דעתה הבלעדי של נותנת החסות למשחק. התחנה ו/או נותנת החסות למשחק לא תשאנה בכל אחריות ולא תחויבנה בכל חובה עקב כל שינוי כאמור לעיל.
2.5 המשתתפים מאשרים כי קראו והבינו וכי הם מסכימים לכל הוראות תקנון זה ופוטרים את התחנה ואת נותנת החסות למשחק, מכל טענה ו/או תביעה ו/או דרישה בקשר למשחק ו/או לתקנון ו/או לתקלות כלשהן בקשר למשחק ומוותרים על כל טענה הקשורה באלה, בלא יוצא מן הכלל.
 
3. מי רשאי להשתתף במשחק
3.1 בכפוף לאמור בסעיף 4 להלן, כל מאזין של התוכנית מעל גיל 18 רשאי להשתתף במשחק.
3.2 למען הסר ספק יובהר כי ההשתתפות מותרת ליחידים בלבד ושלא לצרכים מסחריים.
 
4. סייגים להשתתפות במשחק
4.1 מבלי לגרוע ו/או לפגוע באמור לעיל, התחנה ו/או נותנת החסות למשחק תהיינה רשאיות להחליט, מראש או בדיעבד, על מניעת ו/או פסילת השתתפותו של משתתף במשחק ו/או שלילת זכאותו של משתתף לפרס בו זכה במסגרת המשחק וזאת מכל סיבה שהיא ובלי מתן כל הודעה או התראה למשתתף, בין השאר ומבלי למצות מהסיבות הבאות:
4.1.1 אי עמידה בתנאי השתתפות הנקובים בסעיף 3 לעיל (כולם או חלקם);
4.1.2 מסירת פרטים שאינם נכונים ו/או מדוייקים ו/או פוגעניים ו/או מסולפים וכיו"ב;
4.1.3 התנהגות שאינה ראויה ו/או פסולה ו/או בלתי הולמת ו/או חסרת תום לב ו/או הפעלת אמצעים שאינם כשרים;
4.1.4 במקרה של חשד להפרת תנאי תקנון זה ו/או עקב שימוש באמצעים בלתי כשרים ו/או תחבולה ו/או כל פעולה שלא כדין ו/או תוך עשיית עבירה ו/או שלא על פי הוראות הדין;
4.1.5 אי מילוי אחר התנאים האמורים בתקנון זה;
4.1.6 מכל סיבה אחרת שהתחנה ו/או נותנת החסות למשחק תמצאנה לנכון על פי שיקול דעתן הבלעדי.
4.2 מובהר ומוסכם כי למשתתף לא תהיה כל טענה ו/או דרישה ו/או תביעה כנגד התחנה ו/או נותנת החסות למשחק בקשר עם מניעת ו/או פסילת השתתפותו במשחק ו/או זכייתו בפרס כאמור לעיל.
4.3 ההשתתפות במשחק אסורה על עובדי התחנה, עובדי נותנת החסות למשחק וכן עוזריהם, עובדיהם, מנהליהם, שותפיהם ובני משפחותיהם.
 
5. המשחק ואופן ההשתתפות בו
א. הרשמה למשחק
5.1 על מנת להירשם למשחק (ימים א'-ה' בין התאריכים 23.06.2019-27.06.2019), יש לאתר באפליקציית-יישומון וויז (waze) את האייקון המיועד של התוכנית על מפת המדינה (בדמותם של השדרנים טל ברמן ואביעד קיסוס) ולשלוח את התשובה על מיקומם בהודעת SMS למספר הטלפון 072-525-99-99. הראשון שיענה שישלח תשובה נכונה בSMS יעלה לשידור וישתתף במשחק. ניתן לשלוח הודעה בין השעות 09:00-07:00 בבוקר. מובהר כי שליחת הודעה למספר הטלפון למסירת התשובה ביום נתון הינה עבור השתתפות במשחק באותו היום בלבד. המבקש להשתתף במשחק ביום נוסף ו/או אחר צריך לאתר את האייקון באפליקציה ולשלוח הודעה באותו היום למספר הטלפון פעם נוספת.
5.2 מוסכם כי אין בעצם שליחת הודעה למספר הטלפון שבסעיף 5.1 משום יצירת מצג ו/או הבטחה מצד התחנה ו/או נותנת החסות לכך שהמתקשר יירשם למשחק בפועל.
5.3 המשתתף יודע ומצהיר כי אין בעצם ההרשמה למשחק משום הבטחה ו/או יצירת מצג' לעלייה לשידור, לנטילת חלק במשחק עצמו או לזכייה בפרס כלשהו.
5.4 מבין כל המאזינים אשר יירשמו למשחק ויעמדו בתנאי ההשתתפות בו כמפורט לעיל, תבחר הפקת התוכנית, על פי שיקול דעתה הבלעדי, מאזין אחד בכל יום אשר יעלה לשידור. המאזין הנבחר יתבקש למסור להפקת התוכנית פרטים שונים ליצירת קשר עמו.
 
ב. המשחק עצמו
5.5 במהלך התוכנית שתשודר בימים א'-ה', בתאריכים 23.06.2019-27.06.2019, בשעות 09:00-07:00, יעלה המאזין ששלח את התשובה הנכונה על מיקום האייקון באפליקציית וויז (waze) ראשון להשתתפות בפעילות.
5.6 שדרני התוכנית יבחרו ביום נתון דמות מוכרת לכל אשר את שמה לא יציינו ועל המשתתף לנחש מי היא אותה דמות במסגרת זמן של שתי דקות.
5.7 בפני המשתתף שנבחר יעמדו כאמור שתי דקות ובנוסף 21 שאלות לשאול את שדרני התוכנית בעזרתן ינסה לנחש אתה שמה המלא של אותה דמות.
5.8 במידה והשדרנים אינם יודעים לתת תשובה על השאלה אותה שאל המשתתף, לא תיחשב לו שאלה זו והוא יישאר עם אותה כמות שאלות עוד בטרם שאל את השאלה.
5.9 במידה והמשתתף מעוניין לקבל רמז, הינו רשאי להגיד את המילה 'לתדלק' ושדרני התוכנית יתנו לו רמז שייתכן ויועיל לו לגלות את זהות הדמות. במידה ואמר 'לתדלק', ירדו לו שתי שאלות מסך השאלות שנותרו לו לשאול על מנת לזהות את הדמות.
5.10 המנצח הוא האדם הצליח לזהות את הדמות הנבחרת במסגרת שתי הדקות ובעזרת 21 השאלות שהיו לרשותו.
5.11 במידה ולא יזהה את הדמות במסגרת שתי הדקות, יפסיד את ההזדמנות שלו להשתתף בפעילות ולזכות בפרס.
 
ג. הזוכים
6. המאזין שניחש את שמה של הדמות הנבחרת במסגרת הזמן של שתי הדקות.
7. למען הסר ספק החלטת מערכת ההפקה של התוכנית בדבר סוגי השאלות במשחק, התשובות, המאזינים אשר יעלו לשידור והמנצחים הינן סופיות, מוחלטות, חלוטות, בלתי ניתנת לתביעה ו/או לערעור ומחייבות את המבקשים להשתתף במשחק כמו גם את כל המשתתפים במשחק עצמו.
8. הזכייה בפרסים המפורטים בתקנון זה הינה אישית ואינה ניתנת להעברה או להסבה.
9. לזוכים אין אפשרות להמיר, להחליף, לפדות ו/או לשנות בכל דרך שהיא את הפרס, לא בכסף ולא בשווה כסף ולא בכל פרס או מוצר אחר, ולא יוחזר בגין הפרס כל סכום שהוא היה ולא ינוצלו, מכל סיבה שהיא, במלואו או בחלקו.
10. הזכאות לפרסים במסגרת המשחק הינה בכפוף להוראות תקנון זה, ובכפוף לכך שהתחנה ו/או נותנת החסות למשחק תהינה רשאיות, אך לא חייבות, לוודא את זהות הזוכים בכל דרך חוקית שהיא, על מנת לוודא, בין השאר, כי הפרטים שנמסרו על ידם וזהותם הינם נכונים מדויקים ומלאים. כן תהינה רשאיות התחנה ו/או נותנת החסות למשחק, אך לא חייבות, לבחון את עמידתם של הזוכים בתנאי התקנון, הן ביחס לאפשרות להשתתף במשחק והן ביחס לאופן ההשתתפות עצמו ולקבוע האם המשתתף אכן זכאי לקבלת הפרס בו זכה. החלטת התחנה ו/או נותנת החסות למשחק ביחס לזכאותו של משתתף לקבלת פרס הינה סופית, מוחלטת ואינה ניתנת לערעור ולא תהיה למשתתף כל טענה ו/או דרישה ו/או תביעה לעניין זה כנגד התחנה ו/או נותנת החסות למשחק.
11. כתנאי למימוש הפרס תהיה התחנה ו/או נותנת החסות למשחק רשאית לדרוש מהמשתתף לחתום על אישורים, הצהרות ו/או ויתורים. באם לא יחתום המשתתף על המסמכים האמורים, תהינה התחנה ו/או נותנת החסות רשאיות, על פי שיקול דעתן הבלעדי, לשלול ממשתתף זכאותו לפרס בו זכה.
12. הפרסים אשר יחולקו לזוכים נתונים לשיקול דעתה הבלעדי של נותנת החסות. נותנת החסות למשחק שומרת לעצמה את הזכות להחליף את הפרס, במוצר או שירות שווה ערך או דומה, על פי שיקול דעתה בכל עת ומכל סיבה שהיא או להתנות את הענקת הפרס בתנאים, הכל בהתאם לעדכונים אשר יפורסמו במסגרת תקנון זה כפי שיעודכן מעת לעת ו/או באתר.
13. מובהר, כי התחנה ונותנת החסות למשחק לא תשאנה באחריות כלשהי ולא תחובנה בחובה כלשהי במקרה של הזוכים לנצל את הפרס בו זכו, או של נזק, מכל מין וסוג שהוא, שיגרמו למשתתף או לזוג הזוכה, במישרין או בעקיפין עקב השתתפותם במשחק ו/או עקב ניצול הפרס או אי ניצולו.
 
 
ד. הפרס
14. במסגרת תקופת המשחק, משתתף אשר הצליח לזהות את הדמות במסגרת הזמן יקבל 300 ₪ שוברים לדלק.
15. במידה וביום נתון לא זכה המשתתף בפרס, יצטבר הפרס ליום למחרת בנוסף לפרס שהוקצה לאותו יום וכך הלאה עד היום האחרון לפעילו. סה"כ ניתן לזכות בין 300 ₪ ל1,500 ₪ בהתאם להצטברות.
16. במידה ונותרו שוברים בהם לא זכה שום משתתף, רשאית הפקת התוכנית לבחור מה לעשות עם השוברים שנותרו.
17. הפרס יחולק לזוכים על ידי נותנת החסות, באמצעות התחנה, במשרדי התחנה ברחוב דבורה הנביאה 6, רמת השרון, במועד של עד 60 ימי עסקים מהיום בו הודיעה התחנה למשתתף (באמצעות פרטי ההתקשרות אשר מסר לתחנה בעת ההרשמה למשחק) כי זכה בפרס/ים הנ"ל. מובהר כי הפרסים, טיבם, איכותם, מועד ו/או עצם אספקתם אינם באחריות התחנה ולא יהיה בעצם חלוקת הפרסים על ידי התחנה כדי לייצר ו/או להטיל חבות כלשהי של התחנה כלפי הזוכים.
18. זוכה בפרס אשר לא יגיע לאסוף את הפרס/ים במועד אשר יתואם עימו יפסיד את זכאותו לפרס/ים. כתנאי לקבלת הפרסים, בכל מקרה בו הפרס מצריך, בהתאם להוראות כל דין, תשלום מס כלשהו, על הזוכה לשלם את מלוא חוב מס ההכנסה הנובע מקבלת הפרס (אם וכפי שחל), בהתאם להוראות פקודת מס הכנסה [נוסח חדש], התשכ"א-1961, ולתקנות ולצווים אשר חוקקו מכוחה, וכן להמציא לנותנת החסות למשחק אישור מאת פקיד השומה הרלוונטי, להנחת דעת נותנת החסות למשחק לפיו התחנה ו/או נותנת החסות במשחק לא חייבות בניכוי מס במקור בגין הפרס. מבלי לגרוע מכלליות האמור בסעיף זה לעיל, התחנה ו/או נותנת החסות למשחק תהיינה רשאיות להעביר לרשויות המס, בהתאם להוראות כל דין, את פרטי הזוכה ו/או כל מידע אחר כנדרש על פי דין.
19. למען הסר ספק, מובהר כי היה ופרס כלשהו, כולו או חלקו, לא יחולק או לא ימומש, עקב כך שלא ניתן היה ליצור קשר עם הזוכה בו ו/או כיוון שלא נדרש במועד, עקב כך שהזוכה לא עמד באחד מהתנאים לזכייה ו/או בהוראות תקנון זה, עקב כך שזכייתו בוטלה ו/או עקב כך שהזוכה ויתר על זכייתו, במעשה או במחדל, תהא התחנה ו/או נותנת החסות רשאית, על פי שיקול דעתן הבלעדי להחליט מה ייעשה עם הפרס (לרבות, ומבלי למצות, לערוך פעילות נוספת לצורך חלוקת הפרס האמור, כולו או חלקו ו/או להחליט כי לא יחולק כלל). זוכה אשר שלא דרש פרס במועד, לא ניתן היה ליצור עמו קשר, לא עמד בתנאים לזכייה ו/או בהוראות תקנון, שזכייתו בוטלה ו/או שויתר על פרס, ו/או כל משתתף, יהיו מנועים מלהעלות כל טענה ו/או תביעה ו/או דרישה כלפי התחנה ו/או נותנת החסות למשחק בגין המשחק.
 
ה. הוראות כלליות בנוגע למשחק
20. כל החלטה הנוגעת לקיומו של המשחק, לרבות אך לא רק, ההשתתפות במשחק, פרסום המשחק, תקופת המשחק והחלטות אחרות, תעשה על ידי נותנת החסות למשחק ו/או התחנה ועל פי שיקול דעתן הבלעדי.
21. ידוע למשתתף כי התחנה ו/או נותנת החסות למשחק תהינה רשאיות לשנות את המשחק, כלליו כפי שנקבעו בתקנון, תקופתו וכיו"ב ו/או להפסיקו זה על פי שיקול דעתן הבלעדי ללא צורך במתן הודעה מוקדמת, וכל שינוי יחייב מרגע פרסומו באתר ובשידור. יובהר כי התחנה ו/או נותנת החסות לא תשאנה בכל אחריות בקשר עם האמור לעיל, לרבות מקום בו יופסק ו/או לא יחל המשחק, מכל סיבה שהיא.
22. ידוע למשתתף כי בכל מקרה של תקלה, עיכוב וכיו"ב בתקשורת ו/או במערכות התחנה ו/או במקרה עשויה להימנע אפשרות להרשם למשחק ו/או להשתתף במשחק עצמו. התחנה ו/או נותנת החסות למשחק לא תהינה אחראית לכל מניעה כאמור.
23. התחנה ונותנת החסות לא תהינה אחראיות לכל נזק ישיר ו/או עקיף, לרבות הוצאות ישירות ו/או עקיפות שיגרמו למשתתף ו/או לכל צד שלישי עקב ההרשמה להשתתפות במשחק ו/או השתתפות במחשק עצמו ו/או הסתמכות התחנה ו/או נותנת החסות למשחק על הפרטים אשר ימסרו להן על ידי המשתתף.
24. התחנה ו/או נותנת החסות למשחק לא תהינה אחראית לכל נזק ישיר ו/או עקיף אשר עשוי להגרם למשתתף עקב שינוי תנאי התקנון ו/או המשחק לרבות: ביטולו, קיצור תקופת המשחק, שינוי בפרסים המחולקים לזוכים וכיו"ב לרבות בגין קיצור תקופת המשחק או שינויה, ולרבות בגין הוצאות ישירות ו/או עקיפות שתגרמנה כתוצאה ו/או בקשר עם ההשתתפות במשחק זו לרבות נזקים מחמת כוח עליון, תאונות, מחלות, גניבות, אובדן של כל נכס, עגמת נפש וכו', ו/או לכל החלטה ו/או פעולה אשר יקבל ו/או יעשה משתתף על סמך השתתפותו במשחק זה.
25. מבלי לגרוע ו/או לפגוע באמור לעיל מובהר, כי ההשתתפות במשחק על כל שלביו (לרבות בשלב המוקדם של מסירת פרטים נכונים, מדוייקים ומלאים שונים לתחנה לצורך השתתפות במשחק), הינה על אחריותו הבלעדית של המשתתף. התחנה ו/או נותנת החסות למשחק לא תהינה אחראית ולא תשאנה בכל אבדן, נזק ישיר ו/או עקיף (לרבות נזק בגוף), הפסד, לרבות הוצאות ישירות ו/או עקיפות, שיגרמו למי מהמשתתפים ו/או לצדדים שלישיים כלשהם בעקבות המשחק, לרבות אך לא רק, כתוצאה מהשתתפות במשחק ו/או בקשר עם המשחק ו/או בקשר עם מסירת פרטים לצורך ההשתתפות במשחק ו/או במסגרת המשחק ו/או עקב טעות, שיבוש, איחור, תקלה או כל עניין אחר הקשור ברשת הרט"ן ו/או האינטרנט ו/או רשת החשמל ו/או רשת המחשבים ו/או התקשורת.
26. מבלי לגרוע מהאמור לעיל, המשתתף מצהיר ומאשר כי ידוע לו שיתכן שתיפולנה טעויות בבחירת המשתתפים למשחק ו/או ניהול המשחק ו/או תפעולו ו/או במערכות התחנה, וכי בכל מקרה של טעות כאמור, לא תהווה ההשתתפות ו/או אי ההשתתפות במשחק עילה לכל תביעה ו/או טענה ו/או דרישה וכיו"ב כנגד התחנה ו/או נותנת החסות למשחק בגין נזק מכל מין וסוג שהוא אשר יגרם, במישרין ו/או בעקיפין עקב טעות, איחור, כשל וכיו"ב בהשתתפות במשחק, לרבות אי קליטת רישומו של משתתף להשתתפות במשחק.
27. מבלי לגרוע מהאמור לעיל, התחנה ו/או נותנת החסות למשחק לא תהינה אחראיות בכל אופן שהוא לכל נזק ו/או אובדן ו/או פגיעה שיגרמו למשתתף ו/או לצד ג' כלשהו כתוצאה מהפרה ו/או אי קיום הוראות תקנון זה או כתוצאה מכל מעשה ו/או מחדל של המשתתף, לרבות בגין שימוש בתכנים לא נכונים ו/או לא ראויים ו/או לא מלאים ו/או לא מדוייקים ו/או משובשים ו/או חסויים ו/או מנוגדים לכל דין בדרך אחרת. המשתתף מתחייב לשפות את התחנה ואת נותנת החסות למשחק בגין כל דמי נזק ו/או הוצאה שתחויבנה בהן בקשר לאמור, לרבות בגין כל הוצאה בה תישאנה עקב תביעה שתוגש כנגדן בבית המשפט או כל פשרה שתתפשרנה בה, ובשל הצורך להתגונן כנגד תביעה כאמור.
 
28. הצהרות נוספות של המשתתפים
מבלי לגרוע ו/או לפגוע באמור לעיל עם הפנייה להשתתפות במשחק ו/או בעצם השתתפות במשחק מצהיר, מסכים, מאשר ומתחייב המשתתף כי:
28.1 אין כל מניעה חוקית, חוזית או אחרת להשתתפותו במשחק.
28.2 כי לא יעשה כל שימוש ו/או ניצול לרעה של המשחק.
28.3 לתחנה ו/או לנותנת החסות למשחק זכות שימוש בלתי חוזרת, לפי שיקול דעתן הבלעדי, לעשות שימוש בפרטים הנמסרים על ידי המשתתף במסגרת השתתפות במשחק בהתאם להוראות הדין, לרבות חוק הגנת הפרטיות, התשמ"א-1981. מבלי לפגוע באמור לעיל ידוע למשתתף והוא מאשר כי פרטים אשר נמסרו על ידו לתחנה עשויים להיות מועברים על ידי התחנה לנותנת החסות למשחק לשם יצירת קשר עם הזוכים, חלוקת פרסים וכו'.
28.4 התחנה ו/או נותנת החסות למשחק לא תהינה אחראיות על אמיתות הפרטים הנמסרים על ידי המשתתף לצורך השתתפות במשחק ו/או במסגרת המשחק עצמו והתחנה ונותנת החסות למשחק רשאיות להסתמך על פרטים אלו לכל דבר ועניין.
28.5 מבלי לגרוע באמור לעיל, למשתתף לא תהיה כל טענה ו/או דרישה ו/או תביעה כנגד התחנה ונותנת החסות למשחק בקשר עם השימוש בפרטים אשר יימסרו על ידי המשתתפים לרבות שידורם ופרסומם.
28.6 ידוע למשתתף כי השתתפותו ו/או אי השתתפותו במשחק כפופה לזמינות ותקינות רשת האינטרנט, רשתות מחשבים, האתר, רשת הטלפוניה, רשת החשמל, תוכנות ומערכות השונות (להלן יחדיו: "המערכות") אשר באמצעותן מתנהלת המשחק, הן אלה שברשות התחנה והן אלו שברשות צדדים שלישיים. התחנה ו/או נותנת החסות למשחק לא תחובנה בכל חובה ולא תשאנה בכל אחריות, לא ישפו ולא יידרשו לשפות את המשתתף ו/או כל גורם אחר בקשר עם ו/או כתוצאה עם כל טענה ו/או תביעה ו/או דרישה אשר עניינה נזק ו/או הוצאה וכיו"ב ישיר או עקיף אשר יגרם למשתתף ו/או לצדדים שלישיים עקב גישה לא מורשית ו/או קלקולים ו/או אי דיוקים ו/או שגיאות ו/או טעות, תקלה, איחור, השמטה, מחיקה, שיבוש, או כל כשל טכני הנובע ו/או הקשור במערכות, לרבות, אך לא רק, תקלות בהרשמה למשחק ו/או השתתפות במשחק; אי קליטת הרשמת המשתתף להשתתפות במשחק; שיבוש פרטי המשתתף וכיו"ב.
 
29. בעלות בחומרים
מבלי לגרוע ו/או לפגוע באמור לעיל, כל הזכויות, ובכלל זה זכויות הקניין הרוחני בכל הקשור ו/או הנובע בתוכנית, במשחק (על כל מרכיביו), באתר, באפליקציית המוסיקה של התחנה וכיו"ב או בתוצריהם (להלן: "החומרים") הינן ותשארנה של התחנה בלבד אשר תהא רשאית לנהוג בהם מנהג בעלים, לעשות בהם כל שימוש ללא כל הגבלה, תנאי או תשלום וזאת ללא קבלת אישור המשתתפים ו/או הסכמתם.
 
30. הוראות נוספות
27.1. החלוקה לסעיפים וכותרות הסעיפים הינה לשם נוחות בלבד ולא תשמש לצורכי פרשנות.
27.2. כל המועדים הרלבנטיים למשחק וכל נושא אחר הקשור ו/או הנובע מהמשחק יקבעו
אך ורק על ידי התחנה ו/או נותנת החסות למשחק ואלו אינם ניתנים להשגה או לערעור.
27.3. מבלי לגרוע מן האמור לעיל, הכרעה בגין כל מחלוקת ו/או נושא הקשורים ו/או הנובעים מהמשחק יהיו מסורים באופן ייחודי לתחנה ו/או לנותנת החסות למשחק והכרעתה תהא סופית ומחלטת ולמשתתף לא תהא כל טענה, תביעה או דרישה נגד נותנת החסות למשחק.
27.4. בכל מקרה של סתירה ו/או אי-התאמה בין הוראות תקנון זה לכל פרסום או מצג אחרים בדבר המשחק, בכתב או בעל-פה, מכל מין וסוג שהוא ומכל מועד שהוא, לרבות באתר ו/או בעיתונים ו/או במודעות, תגברנה הוראות תקנון זה לכל דבר ועניין והן אשר תקבענה.
27.5. למען הסר ספק, המשחק הינו לצורכים שיווקיים גרידא ואין הוא נכלל בגדר אילו מן הפעילויות הנזכרות בסעיף 224 לחוק העונשין, התשל"ז-1977.
27.6. השימוש בלשון זכר בתקנון זה הינו לצרכי נוחות בלבד וכולל גם פניה בלשון נקבה, יחיד ורבים.
27.7. לבית המשפט המוסמך בתל אביב-יפו תהיה הסמכות הבלעדית לדון בכל מחלוקת הנובעת ו/או הנוגעת בתקנון ו/או במשחק ודיני מדינת ישראל, בלבד, הם שיחולו.
27.8. עותקים מן התקנון מצויים במשרדי התחנה ברחוב דבורה הנביאה 6, רמת השרון, שם ניתן לעיין בהם בימים א'-ה' בין השעות 16:00-09:00, ובאתר (תחת מחיצת תוכנית הבוקר – תקנון משחק התוכנית). למען הסר ספק הנוסח המפורסם באתר הוא נוסחו הקובע של התקנון.
 
אנו מברכים אותך על השתתפותך במשחק ומאחלים לך הצלחה!
 
 
תקנון משחק "וויז קארפול - שירים" של טל ואביעד
 
 
1. הגדרות
1.1 "התחנה": רדיו Eco99fm
1.2 "התוכנית": "תוכנית הבוקר"
1.3 "נותנת החסות למשחק": החברה הנותנת חסות למשחק במסגרת "תוכנית הבוקר" המוגשת על ידי השדרים – ווייז מובייל בע"מ.
1.4 "המשחק": פעילות הנערכת על ידי נותנת החסות, כמפורט להלן, במסגרתה יוכלו
מאזיני התוכנית להשתתף במשחק ולזכות בפרסים. "משתתף": כל מאזין של התוכנית המבקש להשתתף במשחק באמצעות הרשמה לו כמתואר בסעיף 5 בתקנון זה להלן וכן המאזינים אשר ייבחרו להשתתף במשחק
בפועל כמתואר בסעיף 5 לתקנון.
1.5 "האתר": אתר התחנה בכתובת www.eco99.fm
1.6 "השדרים": מנחי "תוכנית הבוקר" בתחנה.
 
2. כללי
2.1 תקנון זה (להלן: "התקנון") נועד להסדיר את הכללים על פיהם ינוהל המשחק הנערך מטעם נותנת החסות למשחק בימי א' עד ה' הנכללים בתאריכים 01.09-05.09.2019 בין השעות 09:00-07:00 (להלן: "תקופת המשחק").
2.2 התקנון מסדיר את תנאי ההשתתפות במשחק והינו חוזה לכל דבר ועניין, שיש בו כדי למצות את זכויות וחובות הצדדים בקשר להשתתפות במשחק. קבלתו של התקנון ללא שינוי הינה תנאי להשתתפות במשחק זה. התחנה ו/או נותנת החסות למשחק שומרות לעצמן את הזכות לשנות ו/או לעדכן את התקנון בכל עת (לרבות, ומבלי למצות, לשנות את תנאי ההשתתפות במשחק וכללי המשחק עצמו כמו גם להאריך ו/או לקצר ו/או לשנות משך תקופת המשחק), לפי שיקול דעתן הבלעדי והמוחלט, ללא צורך במתן הודעה מוקדמת, וכל שינוי יחייב מרגע פרסומו של תקנון מעודכן באתר. מוסכם, כי פרסום כאמור ישמש הודעה מספיקה והולמת ותחייב מרגע פרסומו. המשתתף במשחק מוותר על כל טענה בנוגע ו/או בנובע ו/או בקשור לאמור.
2.3 בכל מקרה של סתירה ו/או אי התאמה בין התקנון לבין כל פרסום אחר, בכתב או בעל-פה, מכל מין וסוג שהוא, תגברנה הוראות תקנון זה והן אשר תקבענה. באחריותם הבלעדית של המשתתפים להתעדכן בפרטי התקנון ובכל שינוי ו/או עדכון ו/או תיקון וכיו"ב שייעשו בתקנון והם מוותרים על כל טענה בנוגע, נובע או בקשר עם האמור.
2.4 נותנת החסות למשחק רשאית גם להאריך את משך תקופת המשחק, ולהחליט כי במהלך התקופה המוארכת לא יחולקו פרסים נוספים מלבד האמור להלן, הכל לפי שיקול דעתה הבלעדי של נותנת החסות למשחק. התחנה ו/או נותנת החסות למשחק לא תשאנה בכל אחריות ולא תחויבנה בכל חובה עקב כל שינוי כאמור לעיל.
2.5 המשתתפים מאשרים כי קראו והבינו וכי הם מסכימים לכל הוראות תקנון זה ופוטרים את התחנה ואת נותנת החסות למשחק, מכל טענה ו/או תביעה ו/או דרישה בקשר למשחק ו/או לתקנון ו/או לתקלות כלשהן בקשר למשחק ומוותרים על כל טענה הקשורה באלה, בלא יוצא מן הכלל.
 
3. מי רשאי להשתתף במשחק
3.1 בכפוף לאמור בסעיף 4 להלן, כל מאזין של התוכנית מעל גיל 18 רשאי להשתתף במשחק.
3.2 למען הסר ספק יובהר כי ההשתתפות מותרת ליחידים בלבד ושלא לצרכים מסחריים.
 
4. סייגים להשתתפות במשחק
4.1 מבלי לגרוע ו/או לפגוע באמור לעיל, התחנה ו/או נותנת החסות למשחק תהיינה רשאיות להחליט, מראש או בדיעבד, על מניעת ו/או פסילת השתתפותו של משתתף במשחק ו/או שלילת זכאותו של משתתף לפרס בו זכה במסגרת המשחק וזאת מכל סיבה שהיא ובלי מתן כל הודעה או התראה למשתתף, בין השאר ומבלי למצות מהסיבות הבאות:
4.1.1 אי עמידה בתנאי השתתפות הנקובים בסעיף 3 לעיל (כולם או חלקם);
4.1.2 מסירת פרטים שאינם נכונים ו/או מדוייקים ו/או פוגעניים ו/או מסולפים וכיו"ב;
4.1.3 התנהגות שאינה ראויה ו/או פסולה ו/או בלתי הולמת ו/או חסרת תום לב ו/או הפעלת אמצעים שאינם כשרים;
4.1.4 במקרה של חשד להפרת תנאי תקנון זה ו/או עקב שימוש באמצעים בלתי כשרים ו/או תחבולה ו/או כל פעולה שלא כדין ו/או תוך עשיית עבירה ו/או שלא על פי הוראות הדין;
4.1.5 אי מילוי אחר התנאים האמורים בתקנון זה;
4.1.6 מכל סיבה אחרת שהתחנה ו/או נותנת החסות למשחק תמצאנה לנכון על פי שיקול דעתן הבלעדי.
4.2 מובהר ומוסכם כי למשתתף לא תהיה כל טענה ו/או דרישה ו/או תביעה כנגד התחנה ו/או נותנת החסות למשחק בקשר עם מניעת ו/או פסילת השתתפותו במשחק ו/או זכייתו בפרס כאמור לעיל.
4.3 ההשתתפות במשחק אסורה על עובדי התחנה, עובדי נותנת החסות למשחק וכן עוזריהם, עובדיהם, מנהליהם, שותפיהם ובני משפחותיהם.
 
5. המשחק ואופן ההשתתפות בו
א. הרשמה למשחק
5.1 על מנת להירשם למשחק (ימים א'-ה' בין התאריכים 01.09-05.09.2019), יש להתקשר בכל יום בין התאריכים הנ"ל למספר הטלפון לעלייה לשידור אשר יפורסם על ידי השדרים: 1-900-72-99-99 (השיחה כרוכה בעלות של 50 אג' לדקה בתוספת עלות שיחה סלולארית רגילה). ניתן להתקשר בין השעות 09:00-07:00 בבוקר. מובהר כי התקשרות למספר הטלפון לעלייה לשידור ביום נתון הינה עבור השתתפות במשחק באותו היום בלבד. המבקש להשתתף במשחק ביום נוסף ו/או אחר צריך להתקשר באותו היום למספר הטלפון פעם נוספת.
5.2 מוסכם כי אין בעצם ההתקשרות למספר הטלפון שבסעיף 5.1 משום יצירת מצג ו/או הבטחה מצד התחנה ו/או נותנת החסות לכך שהמתקשר יירשם למשחק בפועל.
5.3 המשתתף יודע ומצהיר כי אין בעצם ההרשמה למשחק משום הבטחה ו/או יצירת מצג לעלייה לשידור, לנטילת חלק במשחק עצמו או לזכייה בפרס כלשהו.
5.4 מבין כל המאזינים אשר יירשמו למשחק ויעמדו בתנאי ההשתתפות בו כמפורט לעיל, תבחר הפקת התוכנית, על פי שיקול דעתה הבלעדי, ארבעה מאזינים בכל יום אשר יעלו לשידור (שני מאזינים בכל שעה). מאזינים אלו יתבקשו למסור להפקת התוכנית פרטים שונים ליצירת קשר עמם.
 
ב. המשחק עצמו
5.5 במהלך התוכנית שתשודר בימים א'-ה', בתאריכים 01.09-05.09.2019, בשעות 09:00-07:00, יעלו בכל שעה לשידור שני מאזינים (בעדיפות למאזינים אשר לוקחים חלק בנסיעה שיתופית (קארפול) באותה עת), שנרשמו להשתתפות במשחק ונבחרו על ידי הפקת התוכנית.
5.6 בכל שעה יתחרו שני המשתתפים בזיהוי ארבעה שירים (באמצעות האזנה לקטעים קצרים מתוך כל שיר) שיושמעו להם יחד. כל הקשור לקטעי השירים אשר יושמעו לשמתתפים (זהות השירים, סדר השמעתם וכיו"ב) נתון לשיקול דעתם הבלעדי של השדרים.
5.7 משתתף שזיהה נכונה את השיר ראשון יזכה בנקודה. במידה ויש התלבטות מי ענה ראשון, רשאים השדרים להכריע או לפסול כליל את זיהוי השיר. במידה ומשתתף ענה תשובה לא נכונה, התשובה נפסלת ואף אחד לא מקבל נקודה.
5.8 לאחר השמעת השיר השני, יוכל משתתף לקבל נקודה נוספת אם זיהה את השיר הבא נכונה וגם זיהה את הקשר בין השיר הבא לשיר/ים שלפניו (ובסה"כ שתי נקודות על השאלה השנייה) וכן הלאה. כך יכול כל משתתף לצבור סך כולל מקסימלי של עד שבע נקודות (2+2+2+1) או לא לצבור ניקוד כלל.
5.9 המנצח הוא האדם שצבר את כמות הנקודות הגבוהה ביותר בתום השמעת ארבעת השירים והוא זוכה בפרס השעתי.
5.10 במקרה והתוצאה זהה לשני המשתתפים, השדרים ישאלו שאלה שוברת שוויון והזוכה בפרס השעתי יהיה מי שענה על השאלה נכונה ראשון בתום הקראת 3 התשובות האפשריות על ידי השדרנים.
 
ג. הזוכים
6. המאזין שקיבל הכי הרבה נקודות באותה שעה יזכה בפרס השעתי.
7. למען הסר ספק החלטת מערכת ההפקה של התוכנית בדבר סוגי השאלות במשחק, התשובות, המאזינים אשר יעלו לשידור והמנצחים הינן סופיות, מוחלטות, חלוטות, בלתי ניתנת לתביעה ו/או לערעור ומחייבות את המבקשים להשתתף במשחק כמו גם את כל המשתתפים במשחק עצמו.
8. הזכייה בפרסים המפורטים בתקנון זה הינה אישית ואינה ניתנת להעברה או להסבה.
9. לזוכים אין אפשרות להמיר, להחליף, לפדות ו/או לשנות בכל דרך שהיא את הפרס, לא בכסף ולא בשווה כסף ולא בכל פרס או מוצר אחר, ולא יוחזר בגין הפרס כל סכום שהוא היה ולא ינוצלו, מכל סיבה שהיא, במלואו או בחלקו.
10. הזכאות לפרסים במסגרת המשחק הינה בכפוף להוראות תקנון זה, ובכפוף לכך שהתחנה ו/או נותנת החסות למשחק תהינה רשאיות, אך לא חייבות, לוודא את זהות הזוכים בכל דרך חוקית שהיא, על מנת לוודא, בין השאר, כי הפרטים שנמסרו על ידם וזהותם הינם נכונים מדויקים ומלאים. כן תהינה רשאיות התחנה ו/או נותנת החסות למשחק, אך לא חייבות, לבחון את עמידתם של הזוכים בתנאי התקנון, הן ביחס לאפשרות להשתתף במשחק והן ביחס לאופן ההשתתפות עצמו ולקבוע האם המשתתף אכן זכאי לקבלת הפרס בו זכה. החלטת התחנה ו/או נותנת החסות למשחק ביחס לזכאותו של משתתף לקבלת פרס הינה סופית, מוחלטת ואינה ניתנת לערעור ולא תהיה למשתתף כל טענה ו/או דרישה ו/או תביעה לעניין זה כנגד התחנה ו/או נותנת החסות למשחק.
11. כתנאי למימוש הפרס תהיה התחנה ו/או נותנת החסות למשחק רשאית לדרוש מהמשתתף לחתום על אישורים, הצהרות ו/או ויתורים. באם לא יחתום המשתתף על המסמכים האמורים, תהינה התחנה ו/או נותנת החסות רשאיות, על פי שיקול דעתן הבלעדי, לשלול ממשתתף זכאותו לפרס בו זכה.
12. הפרסים אשר יחולקו לזוכים נתונים לשיקול דעתה הבלעדי של נותנת החסות. נותנת החסות למשחק שומרת לעצמה את הזכות להחליף את הפרס, במוצר או שירות שווה ערך או דומה, על פי שיקול דעתה בכל עת ומכל סיבה שהיא או להתנות את הענקת הפרס בתנאים, הכל בהתאם לעדכונים אשר יפורסמו במסגרת תקנון זה כפי שיעודכן מעת לעת ו/או באתר.
13. מובהר, כי התחנה ונותנת החסות למשחק לא תשאנה באחריות כלשהי ולא תחובנה בחובה כלשהי במקרה של הזוכים לנצל את הפרס בו זכו, או של נזק, מכל מין וסוג שהוא, שיגרמו למשתתף או לזוג הזוכה, במישרין או בעקיפין עקב השתתפותם במשחק ו/או עקב ניצול הפרס או אי ניצולו.
 
ד. הפרס
14. פרס העלייה לשידור – במסגרת תקופת המשחק, בכל שעת שידור של התוכנית בין 09:00-07:00, כל משתתף אשר עלה לשידור, יזכה בפרס העלייה לשידור שהינו 300 ₪ לדלק.
15. פרס לזוכה השעתי – במסגרת תקופת המשחק, בכל שעת שידור של התוכנית בין 07:00 – 09:00, משתתף שצבר את מירב הנקודות באותה שעה יקבל 600 ₪ נוספים לדלק. סה"כ יכול לצבור זוכה בשעה 900 ₪.
16. פרסי העלייה לשידור, הפרסים לזוכים השעתיים והפרסים לזוכי השאלה הקשה יחולקו לזוכים על ידי נותנת החסות, באמצעות התחנה, במשרדי התחנה ברחוב דבורה הנביאה 6, רמת השרון, במועד של עד 60 ימי עסקים מהיום בו הודיעה התחנה למשתתף (באמצעות פרטי ההתקשרות אשר מסר לתחנה בעת ההרשמה למשחק) כי זכה בפרס/ים הנ"ל. מובהר כי הפרסים, טיבם, איכותם, מועד ו/או עצם אספקתם אינם באחריות התחנה ולא יהיה בעצם חלוקת הפרסים על ידי התחנה כדי לייצר ו/או להטיל חבות כלשהי של התחנה כלפי הזוכים.
17. זוכה בפרס אשר לא יגיע לאסוף את הפרס/ים במועד אשר יתואם עימו יפסיד את זכאותו לפרס/ים. כתנאי לקבלת הפרסים, בכל מקרה בו הפרס מצריך, בהתאם להוראות כל דין, תשלום מס כלשהו, על הזוכה לשלם את מלוא חוב מס ההכנסה הנובע מקבלת הפרס (אם וכפי שחל), בהתאם להוראות פקודת מס הכנסה [נוסח חדש], התשכ"א-1961, ולתקנות ולצווים אשר חוקקו מכוחה, וכן להמציא לנותנת החסות למשחק אישור מאת פקיד השומה הרלוונטי, להנחת דעת נותנת החסות למשחק לפיו התחנה ו/או נותנת החסות במשחק לא חייבות בניכוי מס במקור בגין הפרס. מבלי לגרוע מכלליות האמור בסעיף זה לעיל, התחנה ו/או נותנת החסות למשחק תהיינה רשאיות להעביר לרשויות המס, בהתאם להוראות כל דין, את פרטי הזוכה ו/או כל מידע אחר כנדרש על פי דין.
18. למען הסר ספק, מובהר כי היה ופרס כלשהו, כולו או חלקו, לא יחולק או לא ימומש, עקב כך שלא ניתן היה ליצור קשר עם הזוכה בו ו/או כיוון שלא נדרש במועד, עקב כך שהזוכה לא עמד באחד מהתנאים לזכייה ו/או בהוראות תקנון זה, עקב כך שזכייתו בוטלה ו/או עקב כך שהזוכה ויתר על זכייתו, במעשה או במחדל, תהא התחנה ו/או נותנת החסות רשאית, על פי שיקול דעתן הבלעדי להחליט מה ייעשה עם הפרס (לרבות, ומבלי למצות, לערוך פעילות נוספת לצורך חלוקת הפרס האמור, כולו או חלקו ו/או להחליט כי לא יחולק כלל). זוכה אשר שלא דרש פרס במועד, לא ניתן היה ליצור עמו קשר, לא עמד בתנאים לזכייה ו/או בהוראות תקנון, שזכייתו בוטלה ו/או שויתר על פרס, ו/או כל משתתף, יהיו מנועים מלהעלות כל טענה ו/או תביעה ו/או דרישה כלפי התחנה ו/או נותנת החסות למשחק בגין המשחק.
 
ה. הוראות כלליות בנוגע למשחק
19. כל החלטה הנוגעת לקיומו של המשחק, לרבות אך לא רק, ההשתתפות במשחק, פרסום המשחק, תקופת המשחק והחלטות אחרות, תעשה על ידי נותנת החסות למשחק ו/או התחנה ועל פי שיקול דעתן הבלעדי.
20. ידוע למשתתף כי התחנה ו/או נותנת החסות למשחק תהינה רשאיות לשנות את המשחק, כלליו כפי שנקבעו בתקנון, תקופתו וכיו"ב ו/או להפסיקו זה על פי שיקול דעתן הבלעדי ללא צורך במתן הודעה מוקדמת, וכל שינוי יחייב מרגע פרסומו באתר ובשידור. יובהר כי התחנה ו/או נותנת החסות לא תשאנה בכל אחריות בקשר עם האמור לעיל, לרבות מקום בו יופסק ו/או לא יחל המשחק, מכל סיבה שהיא.
21. ידוע למשתתף כי בכל מקרה של תקלה, עיכוב וכיו"ב בתקשורת ו/או במערכות התחנה ו/או במקרה עשויה להימנע אפשרות להרשם למשחק ו/או להשתתף במשחק עצמו. התחנה ו/או נותנת החסות למשחק לא תהינה אחראית לכל מניעה כאמור.
22. התחנה ונותנת החסות לא תהינה אחראיות לכל נזק ישיר ו/או עקיף, לרבות הוצאות ישירות ו/או עקיפות שיגרמו למשתתף ו/או לכל צד שלישי עקב ההרשמה להשתתפות במשחק ו/או השתתפות במחשק עצמו ו/או הסתמכות התחנה ו/או נותנת החסות למשחק על הפרטים אשר ימסרו להן על ידי המשתתף.
23. התחנה ו/או נותנת החסות למשחק לא תהינה אחראית לכל נזק ישיר ו/או עקיף אשר עשוי להגרם למשתתף עקב שינוי תנאי התקנון ו/או המשחק לרבות: ביטולו, קיצור תקופת המשחק, שינוי בפרסים המחולקים לזוכים וכיו"ב לרבות בגין קיצור תקופת המשחק או שינויה, ולרבות בגין הוצאות ישירות ו/או עקיפות שתגרמנה כתוצאה ו/או בקשר עם ההשתתפות במשחק זו לרבות נזקים מחמת כוח עליון, תאונות, מחלות, גניבות, אובדן של כל נכס, עגמת נפש וכו', ו/או לכל החלטה ו/או פעולה אשר יקבל ו/או יעשה משתתף על סמך השתתפותו במשחק זה.
24. מבלי לגרוע ו/או לפגוע באמור לעיל מובהר, כי ההשתתפות במשחק על כל שלביו (לרבות בשלב המוקדם של מסירת פרטים נכונים, מדוייקים ומלאים שונים לתחנה לצורך השתתפות במשחק), הינה על אחריותו הבלעדית של המשתתף. התחנה ו/או נותנת החסות למשחק לא תהינה אחראית ולא תשאנה בכל אבדן, נזק ישיר ו/או עקיף (לרבות נזק בגוף), הפסד, לרבות הוצאות ישירות ו/או עקיפות, שיגרמו למי מהמשתתפים ו/או לצדדים שלישיים כלשהם בעקבות המשחק, לרבות אך לא רק, כתוצאה מהשתתפות במשחק ו/או בקשר עם המשחק ו/או בקשר עם מסירת פרטים לצורך ההשתתפות במשחק ו/או במסגרת המשחק ו/או עקב טעות, שיבוש, איחור, תקלה או כל עניין אחר הקשור ברשת הרט"ן ו/או האינטרנט ו/או רשת החשמל ו/או רשת המחשבים ו/או התקשורת.
25. מבלי לגרוע מהאמור לעיל, המשתתף מצהיר ומאשר כי ידוע לו שיתכן שתיפולנה טעויות בבחירת המשתתפים למשחק ו/או ניהול המשחק ו/או תפעולו ו/או במערכות התחנה, וכי בכל מקרה של טעות כאמור, לא תהווה ההשתתפות ו/או אי ההשתתפות במשחק עילה לכל תביעה ו/או טענה ו/או דרישה וכיו"ב כנגד התחנה ו/או נותנת החסות למשחק בגין נזק מכל מין וסוג שהוא אשר יגרם, במישרין ו/או בעקיפין עקב טעות, איחור, כשל וכיו"ב בהשתתפות במשחק, לרבות אי קליטת רישומו של משתתף להשתתפות במשחק.
26. מבלי לגרוע מהאמור לעיל, התחנה ו/או נותנת החסות למשחק לא תהינה אחראיות בכל אופן שהוא לכל נזק ו/או אובדן ו/או פגיעה שיגרמו למשתתף ו/או לצד ג' כלשהו כתוצאה מהפרה ו/או אי קיום הוראות תקנון זה או כתוצאה מכל מעשה ו/או מחדל של המשתתף, לרבות בגין שימוש בתכנים לא נכונים ו/או לא ראויים ו/או לא מלאים ו/או לא מדוייקים ו/או משובשים ו/או חסויים ו/או מנוגדים לכל דין בדרך אחרת. המשתתף מתחייב לשפות את התחנה ואת נותנת החסות למשחק בגין כל דמי נזק ו/או הוצאה שתחויבנה בהן בקשר לאמור, לרבות בגין כל הוצאה בה תישאנה עקב תביעה שתוגש כנגדן בבית המשפט או כל פשרה שתתפשרנה בה, ובשל הצורך להתגונן כנגד תביעה כאמור.
 
27. הצהרות נוספות של המשתתפים
מבלי לגרוע ו/או לפגוע באמור לעיל עם הפנייה להשתתפות במשחק ו/או בעצם השתתפות במשחק מצהיר, מסכים, מאשר ומתחייב המשתתף כי:
27.1 אין כל מניעה חוקית, חוזית או אחרת להשתתפותו במשחק.
27.2 כי לא יעשה כל שימוש ו/או ניצול לרעה של המשחק.
27.3 לתחנה ו/או לנותנת החסות למשחק זכות שימוש בלתי חוזרת, לפי שיקול דעתן הבלעדי, לעשות שימוש בפרטים הנמסרים על ידי המשתתף במסגרת השתתפות במשחק בהתאם להוראות הדין, לרבות חוק הגנת הפרטיות, התשמ"א-1981. מבלי לפגוע באמור לעיל ידוע למשתתף והוא מאשר כי פרטים אשר נמסרו על ידו לתחנה עשויים להיות מועברים על ידי התחנה לנותנת החסות למשחק לשם יצירת קשר עם הזוכים, חלוקת פרסים וכו'.
27.4 התחנה ו/או נותנת החסות למשחק לא תהינה אחראיות על אמיתות הפרטים הנמסרים על ידי המשתתף לצורך השתתפות במשחק ו/או במסגרת המשחק עצמו והתחנה ונותנת החסות למשחק רשאיות להסתמך על פרטים אלו לכל דבר ועניין.
27.5 מבלי לגרוע באמור לעיל, למשתתף לא תהיה כל טענה ו/או דרישה ו/או תביעה כנגד התחנה ונותנת החסות למשחק בקשר עם השימוש בפרטים אשר יימסרו על ידי המשתתפים לרבות שידורם ופרסומם.
27.6 ידוע למשתתף כי השתתפותו ו/או אי השתתפותו במשחק כפופה לזמינות ותקינות רשת האינטרנט, רשתות מחשבים, האתר, רשת הטלפוניה, רשת החשמל, תוכנות ומערכות השונות (להלן יחדיו: "המערכות") אשר באמצעותן מתנהלת המשחק, הן אלה שברשות התחנה והן אלו שברשות צדדים שלישיים. התחנה ו/או נותנת החסות למשחק לא תחובנה בכל חובה ולא תשאנה בכל אחריות, לא ישפו ולא יידרשו לשפות את המשתתף ו/או כל גורם אחר בקשר עם ו/או כתוצאה עם כל טענה ו/או תביעה ו/או דרישה אשר עניינה נזק ו/או הוצאה וכיו"ב ישיר או עקיף אשר יגרם למשתתף ו/או לצדדים שלישיים עקב גישה לא מורשית ו/או קלקולים ו/או אי דיוקים ו/או שגיאות ו/או טעות, תקלה, איחור, השמטה, מחיקה, שיבוש, או כל כשל טכני הנובע ו/או הקשור במערכות, לרבות, אך לא רק, תקלות בהרשמה למשחק ו/או השתתפות במשחק; אי קליטת הרשמת המשתתף להשתתפות במשחק; שיבוש פרטי המשתתף וכיו"ב.
 
28. בעלות בחומרים
מבלי לגרוע ו/או לפגוע באמור לעיל, כל הזכויות, ובכלל זה זכויות הקניין הרוחני בכל הקשור ו/או הנובע בתוכנית, במשחק (על כל מרכיביו), באתר, באפליקציית המוסיקה של התחנה וכיו"ב או בתוצריהם (להלן: "החומרים") הינן ותשארנה של התחנה בלבד אשר תהא רשאית לנהוג בהם מנהג בעלים, לעשות בהם כל שימוש ללא כל הגבלה, תנאי או תשלום וזאת ללא קבלת אישור המשתתפים ו/או הסכמתם.
 
29. הוראות נוספות
29.1 החלוקה לסעיפים וכותרות הסעיפים הינה לשם נוחות בלבד ולא תשמש לצורכי פרשנות.
29.2 כל המועדים הרלבנטיים למשחק וכל נושא אחר הקשור ו/או הנובע מהמשחק יקבעו
אך ורק על ידי התחנה ו/או נותנת החסות למשחק ואלו אינם ניתנים להשגה או לערעור.
29.3 מבלי לגרוע מן האמור לעיל, הכרעה בגין כל מחלוקת ו/או נושא הקשורים ו/או הנובעים מהמשחק יהיו מסורים באופן ייחודי לתחנה ו/או לנותנת החסות למשחק והכרעתה תהא סופית ומחלטת ולמשתתף לא תהא כל טענה, תביעה או דרישה נגד נותנת החסות למשחק.
29.4 בכל מקרה של סתירה ו/או אי-התאמה בין הוראות תקנון זה לכל פרסום או מצג אחרים בדבר המשחק, בכתב או בעל-פה, מכל מין וסוג שהוא ומכל מועד שהוא, לרבות באתר ו/או בעיתונים ו/או במודעות, תגברנה הוראות תקנון זה לכל דבר ועניין והן אשר תקבענה.
29.5 למען הסר ספק, המשחק הינו לצורכים שיווקיים גרידא ואין הוא נכלל בגדר אילו מן הפעילויות הנזכרות בסעיף 224 לחוק העונשין, התשל"ז-1977.
29.6 השימוש בלשון זכר בתקנון זה הינו לצרכי נוחות בלבד וכולל גם פניה בלשון נקבה, יחיד ורבים.
29.7 לבית המשפט המוסמך בתל אביב-יפו תהיה הסמכות הבלעדית לדון בכל מחלוקת הנובעת ו/או הנוגעת בתקנון ו/או במשחק ודיני מדינת ישראל, בלבד, הם שיחולו.
29.8 עותקים מן התקנון מצויים במשרדי התחנה ברחוב דבורה הנביאה 6, רמת השרון, שם ניתן לעיין בהם בימים א'-ה' בין השעות 16:00-09:00, ובאתר (תחת מחיצת תוכנית הבוקר – תקנון משחק התוכנית). למען הסר ספק הנוסח המפורסם באתר הוא נוסחו הקובע של התקנון.
 
אנו מברכים אותך על השתתפותך במשחק ומאחלים לך הצלחה!


  עדכון אחרון: 1.9.19
`;

  ngOnInit() {
    setTimeout(() => {
      this.sharedService.showHeaderHam = false;
    });
    this.sharedService.showUpperSection = false;
  }

  ngOnDestroy(): void {
    setTimeout(() => {
      this.sharedService.showHeaderHam = true;
    });
    this.sharedService.showUpperSection = true;
  }
}
