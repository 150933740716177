import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Rx";
import { _throw } from "rxjs/observable/throw";
import { Router } from "@angular/router";
import "rxjs/add/operator/catch";
import { EmptyObservable } from "rxjs/observable/EmptyObservable";
/**
 * Intercepts each error recived from the server.
 * It checks if the error is 401 or 403.
 * That mean that the token is wrong then it makes logout.
 */
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).catch(error => {
      if (error instanceof HttpErrorResponse && error.status == 404) {
        return new EmptyObservable();
      } else return _throw(error);
    });
  }
}
