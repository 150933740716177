import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { NetworkService } from "../../services/network.service";
declare var bodymovin: any;

@Component({
  selector: "app-welcome-friend-dialog",
  templateUrl: "./welcome-friend-dialog.component.html",
  styleUrls: ["./welcome-friend-dialog.component.scss"]
})
export class WelcomeFriendDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<any>,
    private networkService: NetworkService
  ) {
    // this.familyUsers = data.familyUsers
  }
  loadingAnimation: any;
  showPopup = false;
  invitationSent = false;
  emailFocusedOut: boolean;
  isEmailValid: boolean;
  email: string = "";
  isLoading: boolean;
  familyUsers = [];

  ngOnInit() {
    setTimeout(() => {
      this.showPopup = true;
    }, 100);
  }

  onAccept() {
    if (this.isLoading) return;

    this.isLoading = true;
    this.startLoadingAnimation("loader1");
    this.networkService.verifyInvitation(this.data.invitationId).subscribe(
      resp => {
        this.isLoading = false;
        this.dialogRef.close({ success: true, resp: resp });
      },
      err => {
        this.isLoading = false;
        if (err.error.error_custom_code == 11) {
          this.dialogRef.close({ success: false, resp: "הלינק כבר לא בתוקף" });
        } else {
          if (err.error.error_custom_code == 13) {
            this.dialogRef.close({
              success: false,
              resp: "הלינק כבר לא בתוקף"
            });
          } else {
            this.dialogRef.close({
              success: false,
              resp: "היתה בעיה, אנא נסה שנית"
            });
          }
        }
      }
    );
  }

  onCancel() {
    if (this.isLoading) return;

    this.isLoading = true;
    this.startLoadingAnimation("loader2");
    this.networkService.declineInvitation().subscribe(
      resp => {
        this.isLoading = false;
        this.dialogRef.close({ success: true, resp: resp, isDecline: true });
      },
      err => {
        this.isLoading = false;
        this.dialogRef.close({
          success: false,
          resp: "היתה בעיה, אנא נסה שנית"
        });
      }
    );
  }

  checkEmail() {
    // this.emailFocusedOut = false
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.isEmailValid = re.test(this.email);
  }

  startLoadingAnimation(loaderID) {
    if (this.loadingAnimation) {
      this.loadingAnimation.destroy();
    }
    this.loadingAnimation = bodymovin.loadAnimation({
      container: document.getElementById(loaderID),
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: "assets/animations/loader.json"
    });
  }
}
