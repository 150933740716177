import { Component } from '@angular/core';

@Component({
  selector: 'app-annual-parade',
  templateUrl: './annual-parade.component.html',
  styleUrls: ['./annual-parade.component.scss']
})
export class AnnualParadeComponent {

  constructor() { }
}
