import { HttpParams } from "@angular/common/http";

export class ShowsQuery {
  page: number = 1;
  itemsPerPage: number = 40;
  start: string;
  end: string;
  artist: string;
  artist_id: string;
  location: string;
  location_id: string;
  geoRestriction: string;
  text: string;
  show_id: string;

  constructor() {}

  public toString() {
    return (
      (this.page ? "page=" + this.page : "") +
      (this.itemsPerPage ? "&itemsPerPage=" + this.itemsPerPage : "") +
      (this.start ? "&start=" + this.start : "") +
      (this.end ? "&end=" + this.end : "") +
      (this.artist ? "&artist=" + this.artist : "") +
      (this.location ? "&location=" + this.location : "") +
      (this.geoRestriction ? "&geoRestriction=" + this.geoRestriction : "") +
      (this.text ? "&text=" + this.text : "") +
      (this.show_id ? "&show_id=" + this.show_id : "") +
      (this.artist_id ? "&artist_id=" + this.artist_id : "") +
      (this.location_id ? "&venue_id=" + this.location_id : "")
    );
  }

  public toHttpParams(): HttpParams {
    return new HttpParams()
      .set("skip", ((this.page - 1) * this.itemsPerPage).toString())
      .set("limit", this.itemsPerPage.toString());
  }
}
