import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NetworkService } from "../../services/network.service";
import { PlaylistQuery } from "../../services/playlistQuery";
import { SharedService } from "../../services/shared.service";
import { Router } from "@angular/router";
import { GoogleAnalyticsService } from "../../services/googleAnalytics.service";
declare var $: any;

@Component({
  selector: "app-playlist-specials",
  templateUrl: "./playlist-specials.component.html",
  styleUrls: ["./playlist-specials.component.scss"]
})
export class PlaylistSpecialsComponent implements OnInit {
  scrolling: boolean = false;
  loadingPlaylists = false;

  activeSpecial: any;
  selectedIndex: any;

  @Output() specialSelected = new EventEmitter<string>();
  @Input() specials: any;
  @Input() galleryID;
  @Input() stringTitle;
  @Input() isLoading: boolean;
  isStartOfScroll = true;
  isEndOfScroll = false;
  constructor(
    public sharedService: SharedService,
    public router: Router,
    private networkService: NetworkService,
    private analyticsService: GoogleAnalyticsService
  ) {}

  ngOnInit() {}

  selectSpecial(special, index) {
    if (special == this.activeSpecial) return;

    this.analyticsService.sendEvent(
      "music",
      "carrousel_special",
      "special_select",
      special.original_id
    );

    this.activeSpecial = special;
    if (this.sharedService.isPlatformBrowser) {
      setTimeout(() => {
        $("html, body").animate(
          {
            scrollTop: $("app-playlist-specials").offset().top
          },
          1000
        );
      });
    }
    if (special.playlists.length) {
      this.selectedIndex = index;
      this.specialSelected.emit(special);
      this.loadingPlaylists = false;
      return;
    }

    let query = new PlaylistQuery(1, 16, special.original_id);
    this.loadingPlaylists = true;
    this.networkService.getPlaylists(query).subscribe(resp => {
      special.playlists = resp.items;
      this.selectedIndex = index;
      this.specialSelected.emit(special);
      this.loadingPlaylists = false;
    });
  }

  initSelectedSpecial() {
    this.activeSpecial = undefined;
  }

  scroll(direction) {
    if (this.sharedService.isPlatformBrowser) {
      let selector = ".specials-container." + this.galleryID;
      if (!this.scrolling) {
        var width = $(selector).width() - 200;
        this.scrolling = true;
        if (direction == "left") {
          this.isStartOfScroll = false;
          $(selector).animate({ scrollLeft: "-=" + width }, 800, () => {
            this.scrolling = false;
            this.sharedService.checkIfEndOrStartOfScroll(
              $(selector),
              "left",
              res => {
                this.isEndOfScroll = res;
              }
            );
          });
        } else {
          this.isEndOfScroll = false;
          $(selector).animate({ scrollLeft: "+=" + width }, 800, () => {
            this.scrolling = false;
            this.sharedService.checkIfEndOrStartOfScroll(
              $(selector),
              "right",
              res => {
                this.isStartOfScroll = res;
              }
            );
          });
        }
      }
    }
  }
}
