import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { NetworkService } from "../../services/network.service";
import { GenericService } from "../../services/generic.service";

@Component({
  selector: "app-upgrade-to-pro",
  templateUrl: "./upgrade-to-pro.component.html",
  styleUrls: ["./upgrade-to-pro.component.scss"]
})
export class UpgradeToProComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<any>,
    private networkService: NetworkService,
    private genericService: GenericService
  ) {
    // this.familyUsers = data.familyUsers
  }
  showPopup = false;

  ngOnInit() {
    setTimeout(() => {
      this.showPopup = true;
    }, 100);
  }

  onAccept() {
    let osType = this.genericService.getMobileOperatingSystem();
    if (osType == "Android") {
      window.open("https://eco99fm.page.link/app");
      this.dialogRef.close({ success: false });
      return;
    }

    if (osType == "iOS") {
      window.open("https://eco99fm.page.link/app");
      this.dialogRef.close({ success: false });
      return;
    }

    this.dialogRef.close({ success: true });
  }

  onCancel() {
    this.dialogRef.close();
  }
}
