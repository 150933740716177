import { Component, OnInit, ViewChild } from "@angular/core";
import { SharedService } from "../../services/shared.service";
import { ProgramsQuery } from "../../services/programsQuery";
import { ShowGalleryComponent } from "../../components/show-gallery/show-gallery.component";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-radio",
  templateUrl: "./radio.component.html",
  styleUrls: ["./radio.component.scss"],
})
export class RadioComponent implements OnInit {
  tabs = [];
  selectedTab = 0;
  date = new Date("5/1/19").toISOString();
  selectedShowsQuery = new ProgramsQuery(1, 11, [30]);
  showMonthPicker: boolean;
  selectedMonth: string;
  selectedYear: number;
  currentYear = new Date().getFullYear();
  noResultsText: string = "מממ… לא נמצאו תוצאות";
  months1 = ["ינואר", "פברואר", "מרץ", "אפריל"];
  months2 = ["מאי", "יוני", "יולי", "אוגוסט"];
  months3 = ["ספטמבר", "אוקטובר", "נובמבר", "דצמבר"];

  @ViewChild("selectedShows", { static: false })
  selectedShows: ShowGalleryComponent;

  constructor(
    public sharedService: SharedService,
    private route: ActivatedRoute,
    public router: Router
  ) {
    this.route.params.subscribe((res) => {
      if (res["activeTab"] == "תוכניות_eco99fm" && this.selectedTab == 0)
        return;
      if (this.sharedService.isPlatformBrowser) {
        window.scrollTo(0, 0);
      }
      this.selectTab(
        this.getTabByName(res["activeTab"]),
        res["activeTab"].split(" ").join("_")
      );
    });
  }

  ngOnInit() {
    this.selectedYear = this.currentYear;
    this.sharedService.showUpperSection = true;
  }

  getTabByName(name) {
    switch (name) {
      case "תוכניות_eco99fm":
        return 0;
      case "שידורי_רדיו":
        return 1;
      case "עורכים":
        return 2;
      case "אלבומים_מומלצים":
        return 3;
      case "רדיו_ירוק":
        return 4;
      case "המגרש_הביתי":
        return 5;
      default:
        return 0;
        break;
    }
  }

  selectTab(i, name) {
    this.router.navigateByUrl("/radio/" + name.split(" ").join("_"));
    this.selectedTab = i;
    this.showMonthPicker = false;
  }

  toggleMonthPicker() {
    if (!this.showMonthPicker) {
      setTimeout(() => {
        this.showMonthPicker = true;
      }, 100);
    } else {
      this.showMonthPicker = false;
      this.searchByDate();
    }
  }

  yearUp() {
    if (this.selectedYear < this.currentYear) {
      this.selectedYear++;
    }
  }

  yearDown() {
    if (this.selectedYear > 2010) {
      this.selectedYear--;
    }
  }

  searchByDate() {
    let date = undefined;
    if (this.selectedMonth) {
      let month = this.sharedService.getMonthString(this.selectedMonth);
      let year = this.selectedYear;
      date = new Date(month + "/01/" + year).toISOString();
    }

    this.noResultsText = "לא נמצאו תוכניות לפי התאריך שחיפשת";

    let selectedShowsQuery;
    selectedShowsQuery = new ProgramsQuery(1, 11, [30], date);
    this.selectedShows.loadShows(selectedShowsQuery, true);
  }

  cleanMonthPicker() {
    this.selectedMonth = "";
    this.searchByDate();
  }
}
