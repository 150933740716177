import { Component, OnInit, Input } from "@angular/core";
import { SharedService } from "../../services/shared.service";
import { MessageService } from "../../services/messageService";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { GoogleAnalyticsService } from "../../services/googleAnalytics.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit {
  @Input() transparent: boolean;
  path: string;
  searchOnFocus: any;
  constructor(
    public sharedService: SharedService,
    private messageService: MessageService,
    private router: Router,
    private location: Location,
    private analyticsService: GoogleAnalyticsService
  ) {}

  ngOnInit() {}

  checkIfPro(e) {
    if (this.sharedService.user && this.sharedService.isFreeUser()) {
      this.sharedService.showCreateUserPopup();
      e.preventDefault();
    }
  }

  toggleDrawer() {
    this.analyticsService.sendEvent("settings", "side_menu", "hamburger");
    this.sharedService.showMenuTablet = !this.sharedService.showMenuTablet;
  }

  goBack() {
    this.path = this.router.url;
    if (this.path == "/settings") {
      this.messageService.moveBack();
    } else {
      this.location.back();
    }
  }
}
