import { Component, OnInit, OnDestroy, Inject } from "@angular/core";
import { SharedService } from "../../services/shared.service";
import { NetworkService } from "../../services/network.service";
import { ShowsQuery } from "../../services/showsQuery";
import { Router, ActivatedRoute } from "@angular/router";
import { environment } from "../../../environments/environment";
import { MetaService } from "../../services/MetaService";
import { GoogleAnalyticsService } from "../../services/googleAnalytics.service";
import { DOCUMENT } from "@angular/common";
let baseUrl = environment.baseUrl;

@Component({
  selector: "app-show",
  templateUrl: "./show.component.html",
  styleUrls: ["./show.component.scss"]
})
export class ShowComponent implements OnInit, OnDestroy {
  shows: any;
  show: any;
  venue: any;
  isLoadingRelated = false;
  shortlink = "";

  constructor(
    public sharedService: SharedService,
    private networkService: NetworkService,
    public router: Router,
    public route: ActivatedRoute,
    private analyticsService: GoogleAnalyticsService,
    private metaService: MetaService
  ) {
    this.sharedService.showUpperSection = false;
    this.sharedService.showHeader = false;

    this.route.params.subscribe(res => {
      this.route.queryParams.subscribe(params => {
        if (params["referral"] == "") {
          this.analyticsService.sendEvent(
            "tickets",
            "show_share_click_event",
            "referral",
            res["showID"]
          );
        }
      });
      if (this.sharedService.tempShow) {
        this.show = this.sharedService.tempShow;
        this.getShowData();
      } else {
        this.networkService.searchShowByID(res["showID"]).subscribe(resp => {
          if (!resp.item) {
            this.router.navigateByUrl("/tickets/הופעות_בארץ");
            return;
          }
          this.show = resp.item;
          this.getShowData();
        });
      }
    });
  }

  ngOnInit() {}

  getShowData() {
    this.networkService.createDynamicShortenLink(
      baseUrl +
        "/tickets/show/" +
        (this.show.event_name_he
          ? this.show.event_name_he
          : this.show.event_name_en
        )
          .split(" ")
          .join("_") +
        "/" +
        this.show.show_id,
      data => {
        this.shortlink = data.shortLink;
      },
      this.sharedService.isPlatformBrowser
    );
    this.metaService.updateMetaInfo(
      this.show.event_name_he
        ? this.show.event_name_he
        : this.show.event_name_en,
      this.sharedService.extractContent(this.show.description)
        ? this.sharedService.extractContent(this.show.description)
        : (this.show.venue_name_he
            ? this.show.venue_name_he
            : this.show.venue_name_en) +
            ", " +
            (this.show.city_name_he
              ? this.show.city_name_he
              : this.show.city_name_en),
      this.show.show_image,
      baseUrl +
        "/tickets/show/" +
        (this.show.event_name_he
          ? this.show.event_name_he
          : this.show.event_name_en
        )
          .split(" ")
          .join("_") +
        "/" +
        this.show.show_id
    );
    this.isLoadingRelated = true;
    this.shows = [];
    var query = new ShowsQuery();
    query.artist_id = this.show.artists[0] ? this.show.artists[0].id : "";

    this.networkService.searchShows(query).subscribe(
      resp => {
        let indexToRemove = -1;
        resp.items.forEach((item, index) => {
          if (item.show_id == this.show.show_id) {
            indexToRemove = index;
          }
        });
        resp.items.splice(indexToRemove, 1);
        this.shows = resp.items;
        this.isLoadingRelated = false;
      },
      err => {}
    );
  }

  sendAnalytics(type) {
    switch (type) {
      case "facebookmessanger":
        this.analyticsService.sendEvent(
          "tickets",
          "share_show",
          "facebook_messanger_share",
          this.show.show_id
        );
        break;
      case "facebook":
        this.analyticsService.sendEvent(
          "tickets",
          "share_show",
          "facebook_share",
          this.show.show_id
        );
        break;
      case "whatsapp":
        this.analyticsService.sendEvent(
          "tickets",
          "share_show",
          "whatsapp_share",
          this.show.show_id
        );
        break;
      case "email":
        this.analyticsService.sendEvent(
          "tickets",
          "share_show",
          "email_share",
          this.show.show_id
        );
        break;
      case "native":
        this.analyticsService.sendEvent(
          "tickets",
          "share_show",
          "native_share",
          this.show.show_id
        );
        break;
    }
  }

  ngOnDestroy(): void {
    this.sharedService.showUpperSection = true;
    this.sharedService.showHeader = true;
  }

  showClicked(show) {
    window.scrollTo(0, 0);
    this.show = show;
    this.sharedService.tempShow = this.show;
    this.getShowData();
  }

  buyTicketClicked() {
    this.analyticsService.sendEvent(
      "tickets",
      "tickets",
      "רכישת_כרטיסים",
      this.show.event_name_he
    );
  }

  getBgUrl(image_url) {
    if (image_url) {
      return image_url;
    } else {
      return "/assets/images/show-placeholder.png";
    }
  }
}
