import { Component, OnInit, Inject } from "@angular/core";
import { NetworkService } from "../../services/network.service";
import { Router, ActivatedRoute } from "@angular/router";
import { SharedService } from "../../services/shared.service";
import { PlaylistQuery } from "../../services/playlistQuery";
import { LOCAL_STORAGE } from "@ng-toolkit/universal";
import { AuthService } from "../../services/auth.service";
import { PromotionQuery } from "../../services/promotionQuery";
import { MetaService } from "../../services/MetaService";
import { environment } from "../../../environments/environment.prod";
declare var bodymovin: any;

@Component({
  selector: "app-playlists-gallery",
  templateUrl: "./playlists-gallery.component.html",
  styleUrls: ["./playlists-gallery.component.scss"]
})
export class PlaylistsGalleryComponent implements OnInit {
  loadingContent = true;
  isLoadingMorePlaylists = false;
  showLoadMoreButton = false;
  playlists = [];
  playlistsQuery: PlaylistQuery;
  loadingAnimation: any;
  loadMoreWithNoResults: boolean;
  type: any;
  index: any;
  secondaryIndex: any;
  grid: boolean;
  title = "";
  description = "";
  breadcrumbTitle = "";
  breadcrumbParentTitle = "";
  parentLink = "";
  promotionQuery = new PromotionQuery(1, 40);

  constructor(
    private networkService: NetworkService,
    public router: Router,
    public route: ActivatedRoute,
    private sharedService: SharedService,
    @Inject(LOCAL_STORAGE) private localStorage: any,
    private authService: AuthService,
    private metaService: MetaService
  ) {
    this.sharedService.showUpperSection = false;
  }

  ngOnInit() {

    this.route.params.subscribe(res => {
      // console.log('route :' ,this.route.params);
      this.type = res["type"];
      this.index = res["index"];
      this.secondaryIndex = res["secondaryIndex"];
      this.playlistsQuery = new PlaylistQuery();
      this.playlistsQuery.itemsPerPage = 40;
      this.loadingContent = true;
      this.playlists = [];
      this.title = "";
      this.description = "";
      this.loadPlaylistsByType();

      // console.log("type : " , this.type ," ,index :", this.index," ,secondaryIndex : ", this.secondaryIndex);
      // debugger
      //TO DO: Change route 'relatedPlaylists' value like 'gender' 
      //BehaviorSubject: .value type, index // 267 - 195

    });
  }

  updateMetaTags(title, description?) {
    let meta = {
      title: (title ? title : this.index + " ") + " | eco99fm",
      description: description
        ? this.sharedService.extractContent(description)
        : title,
      imageUrl: environment.baseUrl + "/assets/images/eco99seo.jpeg"
    };
    this.metaService.updateMetaInfo(
      meta.title,
      meta.description,
      meta.imageUrl
    );
  }

  loadPlaylistsByType() {
    switch (this.type) {
      case "mood":
        this.networkService
          .getSubmoodByID(this.secondaryIndex, this.playlistsQuery)
          .subscribe(resp => {
            this.breadcrumbTitle = resp.mood.title;
            this.breadcrumbParentTitle = "שלי";
            this.parentLink = "/";
            this.handlePlaylistsResponse(resp);
            this.title = resp.mood.description;
            this.updateMetaTags(this.title);
          });
        break;
      case "recently":
        this.title = "מוזיקה ששמעת לאחרונה";
        this.breadcrumbTitle = "שמעת לאחרונה";
        this.breadcrumbParentTitle = "שלי";
        this.parentLink = "/";
        this.updateMetaTags(this.title);
        if (this.authService.getToken()) {
          this.networkService
            .getListenedContent(this.playlistsQuery, "one_track_playlist")
            .subscribe(resp => {
              this.handlePlaylistsResponse(resp);
            });
        } else {
          if (this.localStorage.getItem("listened-content")) {
            this.playlists = JSON.parse(
              this.localStorage.getItem("listened-content")
            );
          }
          this.loadingContent = false;
        }

        break;
      case "likes":
        this.title = "מוזיקה שאהבת";
        this.breadcrumbTitle = "מוזיקה שאהבת";
        this.breadcrumbParentTitle = "שלי";
        this.parentLink = "/";
        this.updateMetaTags(this.title);
        if (this.authService.getToken()) {
          this.networkService
            .getLikedContent(this.playlistsQuery, "one_track_playlist")
            .subscribe(resp => {
              this.handlePlaylistsResponse(resp);
            });
        }

        break;
      case "hits":
        this.title = "להיטים";
        this.breadcrumbTitle = "להיטים מהארץ והעולם";
        this.breadcrumbParentTitle = "מוזיקה";
        this.parentLink = "/music";
        this.updateMetaTags(this.title);
        this.promotionQuery.promotion_type_id = 2;
        this.sharedService.getPromotionData(
          this.promotionQuery,
          data => {
            this.playlists.push(data);
            this.loadingContent = false;
          },
          (items, totalPages) => {
            if (totalPages > this.promotionQuery.page) {
              this.showLoadMoreButton = true;
            } else {
              this.showLoadMoreButton = false;
            }
            this.isLoadingMorePlaylists = false;
          }
        );
        break;
      case "bestDecade":
        this.breadcrumbParentTitle = "מוזיקה";
        this.parentLink = "/music";
  
        /**
         *  TO DO: add description - wating for server
         *  this.description = '';
         */ 

        this.promotionQuery.promotion_type_id = 6;
        this.sharedService.getPromotionData(
          this.promotionQuery,
          data => {
            this.playlists.push(data);
            this.loadingContent = false;
          },
          (items, totalPages, title) => {
            if (totalPages > this.promotionQuery.page) {
              this.showLoadMoreButton = true;
            } else {
              this.showLoadMoreButton = false;
            }
            this.title = title;
            this.breadcrumbTitle = title;
            this.updateMetaTags(this.title);

            this.isLoadingMorePlaylists = false;
          }
        );
        break;
      case "genre":
        this.networkService
          .getCategoryByID(this.secondaryIndex)
          .subscribe(resp => {
            this.title =
              (resp.name_he ? resp.name_he : resp.name_en) +
              (resp.description ? " - " + resp.description : "");
            this.description = resp.seo_text ? resp.seo_text : resp.description;
            this.breadcrumbTitle = resp.name_he ? resp.name_he : resp.name_en;
            this.breadcrumbParentTitle = "מוזיקה";
            this.parentLink = "/music";
            this.updateMetaTags(this.title, this.description);
          });
          console.log(this.secondaryIndex);
          

        this.playlistsQuery.category = this.secondaryIndex;
        this.networkService
          .getPlaylists(this.playlistsQuery)
          .subscribe(resp => {
            this.handlePlaylistsResponse(resp);
          });
        break;
      case "specials":
        this.networkService
          .getCategoryByID(this.secondaryIndex)
          .subscribe(resp => {
            this.title =
              (resp.name_he ? resp.name_he : resp.name_en) +
              (resp.description ? " - " + resp.description : "");
            this.description = resp.seo_text ? resp.seo_text : resp.description;
            this.breadcrumbTitle = resp.name_he ? resp.name_he : resp.name_en;
            this.breadcrumbParentTitle = "מוזיקה";
            this.parentLink = "/music";
            this.updateMetaTags(this.title, this.description);
          });

        this.playlistsQuery.category = this.secondaryIndex;

        this.networkService
          .getPlaylists(this.playlistsQuery)
          .subscribe(resp => {
            this.handlePlaylistsResponse(resp);
          });
        break;
        case "DJ_91":
          this.title = "DJ איתי גלו - עם הסט השבועי";
          this.breadcrumbTitle = "איתי גלו";
          this.breadcrumbParentTitle = "מוזיקה";
          this.parentLink = "/music";
          this.updateMetaTags(this.title);
          this.promotionQuery.promotion_type_id = 8;
          this.sharedService.getPromotionData(
            this.promotionQuery,
            data => {
              this.playlists.push(data);
              this.loadingContent = false;
            },
            (items, totalPages) => {
              if (totalPages > this.promotionQuery.page) {
                this.showLoadMoreButton = true;
              } else {
                this.showLoadMoreButton = false;
              }
              this.isLoadingMorePlaylists = false;
            }
          );
        break;
        case "studio99":
          this.title = "סטודיו 99";
          this.breadcrumbTitle = "סטודיו 99";
          this.breadcrumbParentTitle = "מוזיקה";
          this.parentLink = "/music";
          this.updateMetaTags(this.title);
          this.promotionQuery.promotion_type_id = 7;
          this.sharedService.getPromotionData(
            this.promotionQuery,
            data => {
              this.playlists.push(data);
              this.loadingContent = false;
            },
            (items, totalPages) => {
              if (totalPages > this.promotionQuery.page) {
                this.showLoadMoreButton = true;
              } else {
                this.showLoadMoreButton = false;
              }
              this.isLoadingMorePlaylists = false;
            }
          );
          break;
      case "boazcohen":
        this.title = "מוזיקה בעריכת בועז כהן";
        this.breadcrumbTitle = "בועז כהן"
        this.breadcrumbParentTitle = "מוזיקה";
        this.parentLink = "/music";
        this.updateMetaTags(this.title);
        this.promotionQuery.promotion_type_id = 5;
        this.sharedService.getPromotionData(
          this.promotionQuery,
          data => {
            this.playlists.push(data);
            this.loadingContent = false;
          },
          (items, totalPages) => {
            if (totalPages > this.promotionQuery.page) {
              this.showLoadMoreButton = true;
            } else {
              this.showLoadMoreButton = false;
            }
            this.isLoadingMorePlaylists = false;
          }
        );
        break;
      case "relatedPlaylists":
        this.networkService
        .getCategoryByID(this.index)
        .subscribe(resp => {
          this.title =
            (resp.name_he ? resp.name_he : resp.name_en) +
            (resp.description ? " - " + resp.description : "");
          this.description = resp.seo_text ? resp.seo_text : resp.description;
          this.breadcrumbTitle = resp.name_he ? resp.name_he : resp.name_en;
          this.breadcrumbParentTitle = "מוזיקה";
          this.parentLink = "/music";
          this.updateMetaTags(this.title, this.description);
        });

        this.playlistsQuery.category = this.index;
        this.networkService
          .getPlaylists(this.playlistsQuery)
          .subscribe(resp => {
            this.handlePlaylistsResponse(resp);
          });
        break;
    }
  }

  handlePlaylistsResponse(resp) {
    let counter = 0;
    if (resp.totalPages > this.promotionQuery.page) {
      this.showLoadMoreButton = true;
    } else {
      this.showLoadMoreButton = false;
    }
    this.isLoadingMorePlaylists = false;
    let interval = setInterval(() => {
      if (!resp.items || resp.items.length == counter) {
        this.loadingContent = false;
        clearInterval(interval);
      } else {
        let playlistToAdd = resp.items[counter];
        setTimeout(() => {
          playlistToAdd.isLoaded = true;
        }, 10);
        this.playlists.push(playlistToAdd);
        counter++;
      }
    }, 50);
  }

  loadMorePlaylists() {
    this.isLoadingMorePlaylists = true;
    this.startLoadingAnimation();
    this.playlistsQuery.page++;
    this.promotionQuery.page++;
    this.loadPlaylistsByType();
  }

  startLoadingAnimation() {
    if (this.loadingAnimation) {
      this.loadingAnimation.destroy();
    }
    setTimeout(() => {
      this.loadingAnimation = bodymovin.loadAnimation({
        container: document.getElementById("more-playlists-loader"),
        renderer: "svg",
        loop: true,
        autoplay: true,
        path: "assets/animations/loader.json"
      });
    });
  }

  ngOnDestroy(): void {
    this.sharedService.showUpperSection = true;
  }
}
