import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomepageComponent } from "./pages/homepage/homepage.component";
import { LoginComponent } from "./pages/login/login.component";
import { SettingsComponent } from "./pages/settings/settings.component";
import { TermsComponent } from "./pages/terms/terms.component";
import { AboutComponent } from "./pages/about/about.component";
import { ContactComponent } from "./pages/contact/contact.component";
import { TalAndAviadComponent } from "./pages/tal-and-aviad/tal-and-aviad.component";
import { ProPackagesComponent } from "./pages/pro-packages/pro-packages.component";
import { TicketsComponent } from "./pages/tickets/tickets.component";
import { ShowComponent } from "./pages/show/show.component";
import { SettingsInnerPageComponent } from "./pages/settings-inner-page/settings-inner-page.component";
import { InvitationComponent } from "./pages/invitation/invitation.component";
import { PaymentComponent } from "./pages/payment/payment.component";
import { LiveRadioComponent } from "./pages/live-radio/live-radio.component";
import { PrivacyPolicyComponent } from "./pages/privacy-policy/privacy-policy.component";
import { EmbeddedPlayerComponent } from "./pages/embedded-player/embedded-player.component";
import { RadioComponent } from "./pages/radio/radio.component";
import { PlaylistComponent } from "./pages/playlist/playlist.component";
import { ProgramComponent } from "./pages/program/program.component";
import { MusicComponent } from "./pages/music/music.component";
import { PlaylistsGalleryComponent } from "./pages/playlists-gallery/playlists-gallery.component";
import { AccessibilityComponent } from "./pages/accessibility/accessibility.component";
import { HamigrashHabeitiComponent } from "./pages/hamigrash-habeiti/hamigrash-habeiti.component";
import { EcoLifeComponent } from "./pages/eco-life/eco-life.component";
import { NineMonthsComponent } from "./pages/nine-months/nine-months.component";
import { MusicAppComponent } from "./pages/music-app/music-app.component";
import { environment } from "../environments/environment";
import { RedirectComponent } from "./pages/redirect/redirect.component";
import { LegalDocumentComponent } from "./pages/legal-document/legal-document.component";
import { AnnualParadeComponent } from "./pages/annual-parade/annual-parade.component";
import { NewMorningShowComponent } from "./pages/new-morning-show/new-morning-show.component";
import { AuthGuard } from "./auth.guard";
import { ROUTES } from "./data/routes";

let baseUrl = environment.baseUrl;
const routes: Routes = [
  { path: "invitation", component: InvitationComponent },
  { path: "redirect", component: RedirectComponent },
  {
    path: "",
    pathMatch: "full",
    component: HomepageComponent,
    data: {
      title: "רדיו, מוזיקה, אקו 99 | music, radio, eco99fm",
      keywords: "רדיו,מוזיקה,אקו 99,music,radio,eco99fm",
      description:
        "רדיו אקו eco99fm - האזן עכשיו לתחנת הרדיו המובילה בישראל בשידור חי וללא תשלום, האזן לארכיון תוכניות הבוקר של טל ואביעד, או הקשב לפלייליסטים במגוון ז'אנרים של מוזיקה.",
      imageUrl: environment.baseUrl + "/assets/images/eco99seo.jpeg",
      url: baseUrl,
      reuseRoute: true,
    },
  },
  {
    path: "music",
    component: MusicComponent,
    data: {
      title: "מוזיקה, שירים, להיטים, מוזיקה חדשה, ז'אנרים  eco99fm",
      description:
        "מוזיקה, שירים,  להיטים, מגוון רחב של מוזיקה ,מוזיקה ישראלית, מוזיקה לועזית,  מוזיקה מזרחית, שירים בעברית, שירים ישראלים, מוזיקה מרגיעה, שירים לועזים, מוזיקה אונליין,  eco99fm",
      imageUrl: environment.baseUrl + "/assets/images/eco99seo.jpeg",
      url: baseUrl + "/music",
      keywords: "מוזיקה,שירים",
      reuseRoute: true,
    },
  },
  {
    path: "login",
    component: LoginComponent,
    data: {
      title: "התחברות, eco99music",
      description:
        "אלפי פלייליסטים שערכנו בשבילכם לכל מצב להשמעה מיידית, בחינם! תהנו",
      imageUrl: environment.baseUrl + "/assets/images/eco99seo.jpeg",
      url: baseUrl + ROUTES.LOGIN,
    },
  },
  {
    path: "settings",
    component: SettingsComponent,
    data: {
      title: "פרופיל, eco99music",
      description:
        "אלפי פלייליסטים שערכנו בשבילכם לכל מצב להשמעה מיידית, בחינם! תהנו",
      imageUrl: environment.baseUrl + "/assets/images/eco99seo.jpeg",
      url: baseUrl + "/settings",
    },
    canActivate: [AuthGuard],
  },
  { 
    path: "settings-inner-page/:type",
    component: SettingsInnerPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "terms",
    component: TermsComponent,
    data: {
      title: "תנאי שימוש, eco99music",
      description:
        "אלפי פלייליסטים שערכנו בשבילכם לכל מצב להשמעה מיידית, בחינם! תהנו",
      imageUrl: environment.baseUrl + "/assets/images/eco99seo.jpeg",
      url: baseUrl + "/settings",
    },
  },
  {
    path: "privacy-policy",
    component: PrivacyPolicyComponent,
    data: {
      title: "מדיניות פרטיות, eco99music",
      description:
        "אלפי פלייליסטים שערכנו בשבילכם לכל מצב להשמעה מיידית, בחינם! תהנו",
      imageUrl: environment.baseUrl + "/assets/images/eco99seo.jpeg",
      url: baseUrl + "/privacy-policy",
    },
  },
  {
    path: "legal-document",
    component: LegalDocumentComponent,
    data: {
      title: "הסדר פשרה",
      description: "הסדר פשרה",
      imageUrl: environment.baseUrl + "/assets/images/eco99seo.jpeg",
      url: baseUrl + "/legal-document",
    },
  },
  {
    path: "about",
    component: AboutComponent,
    data: {
      title: "עלינו, eco99music",
      description:
        "אלפי פלייליסטים שערכנו בשבילכם לכל מצב להשמעה מיידית, בחינם! תהנו",
      imageUrl: environment.baseUrl + "/assets/images/eco99seo.jpeg",
      url: baseUrl + "/about",
    },
  },
  {
    path: "music-app",
    component: MusicAppComponent,
    data: {
      title: "אקו 99FM  אפליקציית מוזיקה חינם - Digital eco99",
      description:
        "אפליקציית שירים חינמית המאפשרת לכם להאזין לפלייליסטים מעולים, בכל רגע, מכל מקום, ובחינם. השירות הינו פלטפורמה מבוססת סטרימינג, שתעניק לכם, המשתמשים, חוויה מוזיקלית מלאה ואיכותית במחשב, בסמארטפון ובטאבלט, לרבות אפליקציות המיועדות ל- iPhone ולאנדרואיד.",
      imageUrl: environment.baseUrl + "/assets/images/eco99seo.jpeg",
      url: baseUrl + "/music-app",
    },
  },
  {
    path: "contact",
    component: ContactComponent,
    data: {
      title: "צור קשר, eco99music",
      description:
        "אלפי פלייליסטים שערכנו בשבילכם לכל מצב להשמעה מיידית, בחינם! תהנו",
      imageUrl: environment.baseUrl + "/assets/images/eco99seo.jpeg",
      url: baseUrl + "/contact",
    },
  },
  {
    path: "program",
    children: [
      { path: "טל_ואביעד/:id", component: ProgramComponent },
      {
        path: "טל_ואביעד/tab/:activeTab",
        component: TalAndAviadComponent,
        data: {
          reuseRoute: false,
        },
      },
      { path: "בוקר_חדש/:id", component: ProgramComponent },
      {
        path: "בוקר_חדש/tab/:activeTab",
        component: NewMorningShowComponent,
        data: {
          reuseRoute: false,
        },
      },
      { path: "המגרש_הביתי/:id", component: HamigrashHabeitiComponent },
      // { path: "אקו_לייף/:id", component: ProgramComponent },
      // {
      //   path: "אקו_לייף/tab/:activeTab",
      //   component: EcoLifeComponent,
      //   data: {
      //     reuseRoute: true
      //   }
      // },
      { path: "תשעה_חודשים/:id", component: ProgramComponent },
      {
        path: "תשעה_חודשים/tab/:activeTab",
        component: NineMonthsComponent,
        data: {
          reuseRoute: true,
        },
      },
    ],
  },
  // {path: 'payment/:packageType/:state', component: PaymentComponent, canActivate: [CanActivateLoggedUser]},
  // {
  //   path: "tickets",
  //   children: [
  //     {
  //       path: ":activeTab",
  //       component: TicketsComponent,
  //       data: {
  //         reuseRoute: true
  //       }
  //     },
  //     {
  //       path: "artist/:artistInput/:artistID",
  //       component: TicketsComponent,
  //       data: {
  //         reuseRoute: true
  //       }
  //     },
  //     {
  //       path: "venue/:venueInput",
  //       component: TicketsComponent,
  //       data: {
  //         reuseRoute: true
  //       }
  //     }, // fore cities
  //     {
  //       path: "venue/:venueInput/:venueID",
  //       component: TicketsComponent,
  //       data: {
  //         reuseRoute: true
  //       }
  //     },
  //     {
  //       path: "venue_artist/:artistInput/:artistID/:venueInput",
  //       component: TicketsComponent,
  //       data: {
  //         reuseRoute: true
  //       }
  //     }, // fore cities
  //     {
  //       path: "venue_artist/:artistInput/:artistID/:venueInput/:venueID",
  //       component: TicketsComponent,
  //       data: {
  //         reuseRoute: true
  //       }
  //     },
  //     { path: "show/:showName/:showID", component: ShowComponent }
  //   ]
  // },

  // {path: 'pro-packages', component: ProPackagesComponent},
  {
    path: "live-radio",
    component: LiveRadioComponent,
    data: {
      title: "רדיו, שידור חי , רדיו אונליין, רדיו בשידור חי,  eco99fm",
      description:
        "רדיו, שידור חי , רדיו אונליין, רדיו בשידור חי, תחנת רדיו, ערוצי רדיו, רדיו ישראלי, מוזיקה אונליין, תחנת הרדיו המובילה בישראל בשידור חי וללא תשלום, eco99fm",
      imageUrl: environment.baseUrl + "/assets/images/eco99seo.jpeg",
      url: baseUrl + "/live-radio",
      keywords: "שידור חי,רדיו אונליין,רדיו בשידור חי",
    },
  },
  {
    path: "radio/:activeTab",
    component: RadioComponent,
    data: {
      title: "תחנת רדיו, תוכניות רדיו, תחנת רדיו eco99fm",
      description:
        "רדיו, ערוצי רדיו, תחנת רדיו ישראלית, פודקאסטים, בלוג מוזיקה, רדיו ישראלי, פודקאסט מוזיקה, המלצות תקליטים, אלבומים מומלצים, לוח שידורים, תחנת רדיו, eco99fm",
      imageUrl: environment.baseUrl + "/assets/images/radiobg-min.jpg",
      url: baseUrl + "/radio/1",
      keywords: "רדיו,ערוצי רדיו,פודקאסטים,בלוג מוזיקה,תחנת רדיו",
    },
  },
  { path: "playlist/:playlistId", component: PlaylistComponent },
  { path: "playlists/:type", component: PlaylistsGalleryComponent },
  { path: "playlists/:type/:index", component: PlaylistsGalleryComponent },
  {
    path: "playlists/:type/:index/:secondaryIndex",
    component: PlaylistsGalleryComponent,
  },

  {
    path: "accessibility",
    component: AccessibilityComponent,
    data: {
      title: "הצהרת נגישות | eco99music",
      description:
        "אלפי פלייליסטים שערכנו בשבילכם לכל מצב להשמעה מיידית, בחינם! תהנו",
      imageUrl: environment.baseUrl + "/assets/images/eco99seo.jpeg",
      url: baseUrl + "/accessibility",
    },
  },

  {
    path: "embedded-player/:datatype/:id",
    component: EmbeddedPlayerComponent,
    data: {
      title: "מוזיקה מעולה כל היום | eco99music",
      description:
        "אלפי פלייליסטים שערכנו בשבילכם לכל מצב להשמעה מיידית, בחינם! תהנו",
      imageUrl: environment.baseUrl + "/assets/images/eco99seo.jpeg",
      url: baseUrl,
    },
  },
  { path: "**", redirectTo: "", pathMatch: "full" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
      initialNavigation: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
