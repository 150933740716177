import { Component, OnInit, Input } from "@angular/core";
import { SharedService } from "../../services/shared.service";
declare var bodymovin: any;

@Component({
  selector: "app-loader",
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.scss"]
})
export class LoaderComponent implements OnInit {
  @Input() elementID: string;
  animation: any;
  show = false;
  constructor(private sharedService: SharedService) {}

  ngOnInit() {}

  ngAfterContentInit(): void {
    if (this.sharedService.isPlatformBrowser) {
      setTimeout(() => {
        this.animation = bodymovin.loadAnimation({
          container: document.getElementById(this.elementID),
          renderer: "svg",
          loop: true,
          autoplay: true,
          path: "assets/animations/loader.json"
        });
        setTimeout(() => {
          this.show = true;
        }, 100);
      });
    }
  }
}
