import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NetworkService } from "../../services/network.service";
import { SharedService } from "../../services/shared.service";
import { GoogleAnalyticsService } from "../../services/googleAnalytics.service";

declare var $: any;

@Component({
  selector: "app-playlist-categories",
  templateUrl: "./playlist-categories.component.html",
  styleUrls: ["./playlist-categories.component.scss"]
})
export class PlaylistCategoriesComponent implements OnInit {
  activeCategory: any;
  @Output() categorySelected = new EventEmitter<any>();
  @Input() categories: any;
  @Input() galleryID;

  isStartOfScroll = true;
  isEndOfScroll = false;
  scrolling: boolean = false;

  constructor(
    private networkService: NetworkService,
    public sharedService: SharedService,
    private analyticsService: GoogleAnalyticsService
  ) {}

  ngOnInit() {}

  selectCategory(category, index) {
    this.activeCategory = category;
    this.categorySelected.emit({ category: category, index: index });
    if (this.galleryID == "moods") {
      this.analyticsService.sendEvent(
        "my_music",
        "carrousel_moods",
        "mood_select",
        category.original_id
      );
    } else {
      if (this.galleryID == "sub-moods") {
        this.analyticsService.sendEvent(
          "my_music",
          "carrousel_sub_moods",
          "mood_select",
          category.original_id
        );
      }
    }
  }

  scroll(direction) {
    let selector =  this.galleryID == 'moods'? ".moods-container" : ".submoods-container";
    if (!this.scrolling) {
      var width = $(selector).width();
      this.scrolling = true;
      if (direction == "left") {
        this.isStartOfScroll = false;
        $(selector).animate({ scrollLeft: "-=" + width }, 800, () => {
          this.scrolling = false;
          this.sharedService.checkIfEndOrStartOfScroll(
            $(selector),
            "left",
            res => {
              this.isEndOfScroll = res;
            }
          );
        });
      } else {
        this.isEndOfScroll = false;
        $(selector).animate({ scrollLeft: "+=" + width }, 800, () => {
          this.scrolling = false;
          this.sharedService.checkIfEndOrStartOfScroll(
            $(selector),
            "right",
            res => {
              this.isStartOfScroll = res;
            }
          );
        });
      }
    }
  }
}
