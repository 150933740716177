import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-radio-show",
  templateUrl: "./radio-show.component.html",
  styleUrls: ["./radio-show.component.scss"]
})
export class RadioShowComponent implements OnInit {
  constructor() {}

  mainShows = [];

  ngOnInit() {}
}
