import { Component, OnInit, Input } from "@angular/core";
import { SharedService } from "../../services/shared.service";
import { Router } from "@angular/router";
import { GoogleAnalyticsService } from "../../services/googleAnalytics.service";
declare var $: any;

@Component({
  selector: "app-show-square",
  templateUrl: "./show-square.component.html",
  styleUrls: ["./show-square.component.scss"]
})
export class ShowSquareComponent implements OnInit {
  @Input() show: any;
  @Input() moreShows: any;
  @Input() activeTab: any;

  constructor(
    private sharedService: SharedService,
    public router: Router,
    private analyticsService: GoogleAnalyticsService
  ) {}

  ngOnInit() {
    if (this.sharedService.isPlatformBrowser) {
      // setTimeout(() => {
      //   $(".jarallax").jarallax({
      //     speed: 0.9
      //   });
      // });
    }
  }

  buyTicketClicked() {
    this.analyticsService.sendEvent(
      "tickets",
      "tickets",
      "רכישת_כרטיסים",
      this.show.event_name_he
    );
  }

  getBgUrl(show) {
    if (show.show_image) {
      return show.show_image;
    } else {
      return "/assets/images/talandaviad-placeholder.svg";
    }
  }

  goToShowPage(show) {
    if (this.activeTab == 0) {
      this.analyticsService.sendEvent(
        "tickets",
        "tab_israel_tickets_show",
        "show_click",
        show.show_id
      );
    }
    if (this.activeTab == 1) {
      this.analyticsService.sendEvent(
        "tickets",
        "tab_abroad_tickets_show",
        "show_click",
        show.show_id
      );
    }
    if (this.activeTab == 2) {
      this.analyticsService.sendEvent(
        "tickets",
        "tab_today_tickets_show",
        "show_click",
        show.show_id
      );
    }
    if (this.activeTab == 3) {
      this.analyticsService.sendEvent(
        "show",
        "related_tickets_show",
        "show_click",
        show.show_id
      );
    }
    if (this.activeTab == 4) {
      this.analyticsService.sendEvent(
        "live-radio",
        "popular_show",
        "show_click",
        show.show_id
      );
    }

    this.sharedService.tempShow = show;
    this.router.navigateByUrl(
      "/tickets/show/" +
        this.sharedService.escapeRegExp(
          show.event_name_he
            ? show.event_name_he.split(" ").join("_")
            : show.event_name_en.split(" ").join("_")
        ) +
        "/" +
        show.show_id
    );
  }
}
