import { Component, OnInit, Input } from "@angular/core";
import * as _ from "lodash";
import { NetworkService } from "src/app/services/network.service";

type RadioShowsRequestType = {
  day_range: string;
  hour_range: string;
  program_name: string;
  broadcaster_name: string;
  image: string;
  program_page_link: string;
}

@Component({
  selector: "app-radio-shows",
  templateUrl: "./radio-shows.component.html",
  styleUrls: ["./radio-shows.component.scss"],
})
export class RadioShowsComponent implements OnInit {
  @Input() shows: RadioShowsRequestType[] = [];
  constructor(private networkService: NetworkService) {} 

  ngOnInit(): void {
    this.networkService.getAllRadioBroadcasts().subscribe((resp) => {
      this.shows = resp.items.sort((showA, showB) => showA.order_number - showB.order_number);
      this.shows.forEach(show => {
        show.image = show.image === '' ? 'assets/images/broadcasters/99logo-black.jpeg' : show.image;
      });
    });
  }
  
}
