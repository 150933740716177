import { Component, OnInit, Inject } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MetaService } from "../../services/MetaService";
import { SharedService } from "../../services/shared.service";
import { DOCUMENT } from "@angular/common";

@Component({
  selector: "app-redirect",
  templateUrl: "./redirect.component.html",
  styleUrls: ["./redirect.component.css"]
})
export class RedirectComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private metaService: MetaService,
    private sharedService: SharedService,
    @Inject(DOCUMENT) private dom
  ) {
    route.queryParams.subscribe(params => {
      let redirectUrl = params.url;
      console.log(redirectUrl);
      metaService.updateOGUrl(redirectUrl);
      if (sharedService.isPlatformBrowser) {
        var link = document.createElement("a");
        link.href = redirectUrl;
        link.click();
        window.location.replace(redirectUrl);
      }
    });
  }

  ngOnInit() {}
}
