import { Component, OnInit, NgZone, OnDestroy } from "@angular/core";
import { NetworkService } from "../../services/network.service";
import { SharedService } from "../../services/shared.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { Inject } from "@angular/core";
import { WINDOW } from "@ng-toolkit/universal";

@Component({
  selector: "app-boarding-dialog",
  templateUrl: "./boarding-dialog.component.html",
  styleUrls: ["./boarding-dialog.component.scss"]
})
export class BoardingDialogComponent implements OnInit, OnDestroy {
  constructor(
    @Inject(WINDOW) private window: Window,
    private networkService: NetworkService,
    private sharedService: SharedService,
    private ngZone: NgZone,
    private dialogRef: MatDialogRef<BoardingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    networkService.getOnBoardingQuestions().subscribe(
      resp => {
        this.ngZone.run(() => {
          this.questions = resp.questions;
          this.skipQuestions(); //for now

          this.version = resp.version;
          this.answersIdsDic = sharedService.getCookie("onboarding-answers")
            ? sharedService.getCookie("onboarding-answers")
            : {};
          if (
            this.version ==
            parseInt(sharedService.getCookie("onboarding-version"))
          ) {
            if (sharedService.getCookie("onboarding-currentindex")) {
              // this.currentPage = parseInt(sharedService.getCookie('onboarding-currentindex'))
              // if (this.currentPage==0 && this.data.hidePrePostScreens){
              //   this.currentPage = 1
              // }
            }
          } else {
            sharedService.setCookie("onboarding-version", String(this.version));
            //  if (this.data.hidePrePostScreens){
            //   this.currentPage = 1
            // }else {
            //   this.currentPage = 0
            // }
          }
        });
      },
      err => {}
    );
  }
  currentPage = -1;
  version = 0;
  userNickname = "";
  selectedAvatar: string;
  holdingForNextPage = false;
  questions = [];
  answers = [];
  answersIdsDic = {};
  skipedQuestions: boolean = false;

  ngOnInit() {
    this.window.onbeforeunload = function(e) {
      e = e || this["window"].event;
      // For IE<8 and Firefox prior to version 4
      if (e) {
        e.returnValue = "are you sure?";
      }
      // For Chrome, Safari, IE8+ and Opera 12+
      return "are you sure?";
    };
  }

  ngOnDestroy(): void {
    this.window.onbeforeunload = null;
  }

  selectAnswer(question, answer, index) {
    if (!this.holdingForNextPage) {
      if (question.type == 1) {
        //multi select
        answer.selected = !answer.selected;
        if (answer.selected) {
          if (!this.answersIdsDic[question._id]) {
            this.answersIdsDic[question._id] = [];
          }
          this.answersIdsDic[question._id].push(answer._id);
        } else {
          this.answersIdsDic[question._id].splice(
            this.answersIdsDic[question._id].indexOf(answer._id),
            1
          );
        }
      } else {
        answer.selected = true;
        this.holdingForNextPage = true;
        if (!this.answersIdsDic[question._id]) {
          this.answersIdsDic[question._id] = [];
        }
        this.answersIdsDic[question._id].push(answer._id);
        setTimeout(() => {
          this.nextPage();
          this.holdingForNextPage = false;
        }, 500);
      }
    }
  }

  noSelections(question) {
    let selected = false;
    question.answers.forEach(answer => {
      if (answer.selected) {
        selected = true;
        return;
      }
    });
    return !selected;
  }

  nextPage() {
    if (this.data.hidePrePostScreens) {
      if (this.currentPage == this.questions.length) {
        let questionWithAnswers = [];
        Object.keys(this.answersIdsDic).forEach(questionID => {
          let answersArray = this.answersIdsDic[questionID];
          questionWithAnswers.push({
            question_id: questionID,
            answers_ids: answersArray
          });
        });
        this.dialogRef.close({
          userNickname: this.userNickname,
          selectedAvatar: this.selectedAvatar,
          questions: questionWithAnswers
        });
      } else {
        this.currentPage++;
      }
    } else {
      this.currentPage++;
      this.sharedService.setCookie(
        "onboarding-currentindex",
        String(this.currentPage)
      );
      this.sharedService.setCookie(
        "onboarding-answers",
        String(this.answersIdsDic)
      );
    }
  }

  skipQuestions() {
    this.skipedQuestions = true;
    this.currentPage = this.questions.length + 1;
  }
}
