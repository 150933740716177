import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { SharedService } from "../../services/shared.service";
import { BoardingDialogComponent } from "../../dialogs/boarding-dialog/boarding-dialog.component";
import { GoogleAnalyticsService } from "../../services/googleAnalytics.service";
import { NetworkService } from "../../services/network.service";
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DeleteUserModalComponent } from "../delete-user-modal/delete-user-modal.component";
import { RESOURCES } from "src/app/data/resources";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"]
})
export class ProfileComponent implements OnInit {
  @Output() toggleEditMode = new EventEmitter();
  resources = RESOURCES;
  constructor(
    public sharedService: SharedService,
    private analyticsService: GoogleAnalyticsService,
    private networkService: NetworkService,
    private deleteUserDialog: MatDialog,
  ) {}

  ngOnInit() {}

  showOnBoarding() {
    this.analyticsService.sendEvent(
      "settings",
      "preferences_questionnaire",
      "שאלון העדפות"
    );
    this.sharedService.showOnboarding(BoardingDialogComponent, true);
  }

  changeToEdit() {
    this.analyticsService.sendEvent(
      "settings",
      "edit_profile_button",
      "ערוך פרופיל"
    );
    this.toggleEditMode.emit();
  }

  onDeleteUserClicked() {
    const dialogRef = this.deleteUserDialog.open(DeleteUserModalComponent, {
      data: {
        headline: this.resources.DELETE_DIALOG_ARE_YOU_SURE,
        approveText: this.resources.DELETE_CONFIRMATION,
        cancelText: this.resources.CANCEL,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'approved') {
        this.handleDeleteApprove();
      } else {
        this.deleteUserDialog.closeAll();
      }
    });
  }

  handleDeleteApprove() {
    this.sharedService.deleteUserConfirmed();
  }

  updateEmailActive() {
    if (!this.sharedService.user.meta_data.email_active) {
      this.analyticsService.sendEvent(
        "settings",
        "unsubscribed",
        "subscription",
        "profile"
      );
      this.networkService
        .updateSubscriptionFlag(this.sharedService.user.meta_data.email_active)
        .subscribe(
          resp => {},
          err => {}
        );
    } else {
      this.sharedService.showSubscriptionPopup();
    }
  }

  logout() {
    this.sharedService.showLogoutConfirm();
  }
}
