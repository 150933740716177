import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { SharedService } from "../../services/shared.service";
import { BoardingDialogComponent } from "../../dialogs/boarding-dialog/boarding-dialog.component";

@Component({
  selector: "app-profile-tablet",
  templateUrl: "./profile-tablet.component.html",
  styleUrls: ["./profile-tablet.component.scss"]
})
export class ProfileTabletComponent implements OnInit {
  @Output() toggleEditModeTablet = new EventEmitter<string>();

  constructor(public sharedService: SharedService) {}

  ngOnInit() {}

  showOnBoarding() {
    this.sharedService.showOnboarding(BoardingDialogComponent, true);
  }

  changeToEdit() {
    this.toggleEditModeTablet.emit();
  }
}
