import { Component, OnInit, Inject } from "@angular/core";
import { WINDOW } from "@ng-toolkit/universal";
import { ActivatedRoute } from "@angular/router";
import { SharedService } from "../../services/shared.service";

@Component({
  selector: "app-invitation",
  templateUrl: "./invitation.component.html",
  styleUrls: ["./invitation.component.scss"]
})
export class InvitationComponent implements OnInit {
  constructor(
    @Inject(WINDOW) private window: Window,
    private route: ActivatedRoute,
    private sharedService: SharedService
  ) {
    this.route.queryParams.subscribe(params => {
      if (params["invite"]) {
        this.window.localStorage.setItem("invitationID", params["invite"]);
        this.window.localStorage.setItem("inviterName", params["name"]);
      }
      this.sharedService.tookQueryParams = true;
    });
  }

  ngOnInit() {}
}
