import { Injectable, NgZone } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ROUTES } from './data/routes';
import { AuthService } from './services/auth.service';

/**
 *  Check if there is a token of the user, and if exist then it completes the route.
 * If there is not a token , then it will send to login
 *
 */

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router,
    private ngZone: NgZone) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    
    return this.authService.isTokenValid().pipe(
      map(isValid => {
        if (!isValid) {
          this.router.navigate([ROUTES.LOGIN]);
          return false;
        }
        return true;
      })
    );
  }
}