import { Injectable, Injector } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { switchMap } from "rxjs/operators";
import { AuthService } from "./auth.service";

/**
 * Intercepts each request to the server.
 * It adds an active token to the header and send the request
 */

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.indexOf("oauthCallback") > -1) {
      return next.handle(request);
    }

    return this.auth.getUserIdToken().pipe(
      switchMap(token => {
        if (token.length) {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`
            }
          });
        } else {
          let requestUrl = request.url;
          if (requestUrl.indexOf("public/") == -1) {
            let index = requestUrl.indexOf("v1/") + 3;
            requestUrl =
              request.url.slice(0, index) +
              "public/" +
              request.url.slice(index);
            request = request.clone({
              url: requestUrl
            });
          }
        }

        return next.handle(request);
      })
    );
  }
}
