import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "birthday"
})
export class BirthdayPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) {
      return "-";
    }
    var date = new Date(value).toISOString().split("T")[0];
    return (
      date.split("-")[0] +
      " / " +
      date.split("-")[1] +
      " / " +
      date.split("-")[2]
    );
  }
}
