// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: true,
//   baseUrl: "https://eco99.moveodevelop.com",
//   envHeader: "R!%hWDz4awKc*VdD",
//   domainUriPrefix: "https://reco99fm.page.link",
//   androidPackageName: "com.cyberserve.android.reco99fm.test",
//   androidMinVersion: "88",
//   iosBundleId: "com.moveo.Eco99-iOS",
//   iosAppStoreId: "538406522",
//   firebaseConfig: {
//     apiKey: "AIzaSyDYGSXPhlSaBJri4OHM3fL84njI6MvxKMI",
//     authDomain: "ecopro-dev.firebaseapp.com",
//     databaseURL: "https://ecopro-dev.firebaseio.com",
//     projectId: "ecopro-dev",
//     storageBucket: "ecopro-dev.appspot.com",
//     messagingSenderId: "142165373926",
//     appId: "1:142165373926:web:449b195c3eb5a639"
//   },
//     googleUnitPath: "/6499/example/interstitial" 

// };

// //for local prod
export const environment = {
  production: true,
  baseUrl: "https://eco99fm.maariv.co.il",
  envHeader: "9C7W_cn_j%+34gwg",
  domainUriPrefix: "https://eco99fm.page.link",
  androidPackageName: "com.cyberserve.android.reco99fm",
  androidMinVersion: "88",
  iosAppStoreId: "538406522",
  iosBundleId: "com.cyberserve.Eco99FM",
  firebaseConfig: {
    apiKey: "AIzaSyCN9DRdNHtF4rixiNqhz8CfzUgIKtAx6jo",
    authDomain: "eco-99-production.firebaseapp.com",
    databaseURL: "https://eco-99-production.firebaseio.com",
    projectId: "eco-99-production",
    storageBucket: "eco-99-production.appspot.com",
    messagingSenderId: "1001363808786",
    appId: "1:1001363808786:web:001748e3d7c55fe50daecb"
  },
  googleUnitPath: "/43010785/99fm/mobile-web/special_only/ros_new_mw_splash_320x480" 
};

//com.cyberserve.Eco99FM
