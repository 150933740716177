import { Component, OnInit, OnDestroy } from "@angular/core";
import { SharedService } from "../../services/shared.service";

@Component({
  selector: "app-about",
  templateUrl: "./about.component.html",
  styleUrls: ["./about.component.scss"]
})
export class AboutComponent implements OnInit, OnDestroy {
  constructor(private sharedService: SharedService) {}
  aboutText = `<b>eco99fm</b>
  <b>גם ברדיו, גם באפליקציה, גם באתר</b>

  <b>האזנה לשידור החי:</b> לא יכולים בלי eco99fm? יכולים להאזין לנו דרך האתר והאפליקציה לשידור החי של תחנת הרדיו הטובה ביותר בהיסטוריה של היקום (לדעתנו).

  <b>בוקר חדש - טל ברמן, תם אהרון ואביה פרחי</b> - קטעים נבחרים ותוכניות המללות של תוכנית הבוקר הפופולרית בישראל

  <b>תוכניות הרדיו של אקו 99</b> - תוכנית המוזיקה השבועית - סטודיו 99 עם נוי אלוש ואביה פרחי, תוכניות הקלאסיקות של בועז כהן, הסטים השבועיים של DJ איתי גלו - התקליטן מס' 1 בארץ, ועוד ועוד...

  <b>פלייליסטים לכל מצב:</b> באפליקציית eco99fm יש לנו מוזיקה שמתאימה בדיוק למה שאתם עושים עכשיו. הפלייליסטים שלנו משתנים בהתאם ליום ולשעה בה אתם משתמשים באפליקציה ונותנים לכם מקסימום הנאה במינימום מאמץ. לא מצאתם פלייליסט מומלץ? בחרו בעצמכם מתוך עשרות הפעילויות ומצבי הרוח את הפלייליסט שמתאים לכם בדיוק.

  <b>המוזיקה שלכם בכל מסך ובכל מקום:</b> אייפון, המחשב בעבודה, הדסקטופ בבית וגם ברכב - עם <b>אפליקציות קארפליי ואנדרואיד אוטו</b> שמנגנות את שידורי ותכני eco99fm בכל מקום בארץ ובעולם.
  eco99fm זמינה לכם איפה שרק תרצו - כדי שתמיד לא תתקעו בלי המוזיקה שאתם אוהבים.

  <b>שמעתם? אהבתם?</b>: עכשיו, הכי קל לחזור לשמוע את הפלייליסטים שאהבתם או שמתאימים לשמעתם. חפשו את ההיסטוריית האהובים שלכם ב"שמעתם לאחרונה" ומוזיקה שאהבתם.
  `;

  ngOnInit() {
    this.sharedService.showUpperSection = false;
    setTimeout(() => {
      this.sharedService.showHeaderHam = false;
    });
  }

  ngOnDestroy(): void {
    this.sharedService.showUpperSection = true;
    setTimeout(() => {
      this.sharedService.showHeaderHam = true;
    });
  }
}
