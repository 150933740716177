import { Component, OnInit } from "@angular/core";
import { SharedService } from "../../services/shared.service";
import { Router, ActivatedRoute } from "@angular/router";
import { MessageService } from "../../services/messageService";
import { NetworkService } from "../../services/network.service";
import { PlaylistQuery } from "../../services/playlistQuery";
import { MetaService } from "../../services/MetaService";
import { MatSnackBar } from "@angular/material";
declare var bodymovin: any;
import { environment } from "../../../environments/environment";
import { GoogleAnalyticsService } from "../../services/googleAnalytics.service";
let baseUrl = environment.baseUrl;
declare var bodymovin: any;

@Component({
  selector: "app-playlist",
  templateUrl: "./playlist.component.html",
  styleUrls: ["./playlist.component.scss"]
})
export class PlaylistComponent implements OnInit {
  likeImageSrc = "like-icon.svg";
  embedLink = "";
  playlist: any = {};
  currentSecond = 0;
  currentSong: any;
  subscription: any;
  relatedPlaylists = [];
  moreOptionsActive = false;
  isLoadingRelated = false;
  shortlink = "";
  schema: any;

  constructor(
    private messageService: MessageService,
    public sharedService: SharedService,
    public router: Router,
    public route: ActivatedRoute,
    private networkService: NetworkService,
    private metaService: MetaService,
    private _snackBar: MatSnackBar,
    private analyticsService: GoogleAnalyticsService
  ) {
    sharedService.showUpperSection = false;
  }

  ngOnDestroy(): void {
    this.sharedService.showUpperSection = true;
  }

  ngOnInit() {
    this.route.params.subscribe(res => {
      this.route.queryParams.subscribe(params => {
        if (params["referral"] == "") {
          this.analyticsService.sendEvent(
            "playlists",
            "playlist_share_click_event",
            "referral",
            res["playlistId"]
          );
        }
      });

      if (this.sharedService.tempPlaylist) {
        this.initPlaylist(this.sharedService.tempPlaylist);
      } else {
        this.networkService
          .getPlaylistByID(res["playlistId"])
          .subscribe(resp => {
            if (resp.item && Object.keys(resp.item).length) {
              this.initPlaylist(resp.item);
            } else {
              this.router.navigateByUrl("/music");
            }
          });
      }
    });

    this.subscription = this.messageService
      .listenToPlayerActions()
      .subscribe(e => {
        if (e.currentTime.indexOf(":") != -1) {
          this.currentSecond =
            parseInt(String(e.currentTime.split(":")[0] * 60 * 60)) +
            parseInt(String(e.currentTime.split(":")[1] * 60)) +
            parseInt(String(e.currentTime.split(":")[2]));
        }
        this.currentSong = e.currentSong;
      });
  }

  initPlaylist(playlist) {
    this.playlist = playlist;
    this.networkService.createDynamicShortenLink(
      baseUrl + "/playlist/" + this.playlist.original_id,
      data => {
        this.shortlink = data.shortLink;
        this.updateMetaTags(playlist);
      },
      this.sharedService.isPlatformBrowser
    );
    if (this.playlist.is_liked) {
      this.likeImageSrc = "liked-icon.svg";
    } else {
      this.likeImageSrc = "like-icon.svg";
    }
    let query = new PlaylistQuery();
    query.category = this.playlist.category;
    query.itemsPerPage = 20;
    this.isLoadingRelated = true;

    this.networkService
      .getSimilarPlaylists(
        this.playlist.original_id
          ? this.playlist.original_id
          : this.playlist.playlist_id,
        query
      )
      .subscribe(
        resp => {
          this.relatedPlaylists = resp.items;
          this.isLoadingRelated = false;
          let i = 0;
          let interval = setInterval(() => {
            if (!this.relatedPlaylists || i == this.relatedPlaylists.length) {
              clearInterval(interval);
            } else {
              if (this.relatedPlaylists[i]) {
                this.relatedPlaylists[i].isLoaded = true;
              }
              i++;
            }
          }, 50);
        },
        err => {}
      );
  }

  updateMetaTags(playlist) {
    let meta = {
      title: playlist.page_html_title
        ? playlist.page_html_title
        : (playlist.name_he ? playlist.name_he : playlist.name_en) +
          " " +
          "| eco99fm",
      description: playlist.description
        ? playlist.description
        : playlist.short_description,
      imageUrl: playlist.image_mobile,
      url: baseUrl + "/playlist/" + playlist.original_id,
      keywords: playlist.keywords
    };
    this.metaService.updateMetaInfo(
      meta.title,
      meta.description,
      meta.imageUrl,
      meta.url,
      meta.keywords
    );

    this.schema = {
      "@context": ["http://schema.googleapis.com/", { "@language": "he" }],
      "@type": "MusicPlaylist",
      "@id": baseUrl + "/playlist/" + playlist.original_id,
      url: baseUrl + "/playlist/" + playlist.original_id,
      name: playlist.name_he ? playlist.name_he : playlist.name_en,
      image: [
        {
          "@type": "ImageObject",
          contentUrl: playlist.image
        },
        {
          "@type": "ImageObject",
          contentUrl: playlist.image_mobile
        }
      ],
      numTracks: playlist.song_list ? playlist.song_list.length : 1,
      potentialAction: {
        "@type": "ListenAction",
        target: [
          {
            "@type": "EntryPoint",
            urlTemplate: this.shortlink,
            actionPlatform: [
              "http://schema.org/DesktopWebPlatform",
              "http://schema.org/IOSPlatform",
              "http://schema.org/AndroidPlatform"
            ]
          }
        ],
        expectsAcceptanceOf: {
          "@type": "Offer",
          category: "free"
        }
      },
      creator: {
        "@type": "Organization",
        name: "eco99music"
      },
      keywords: playlist.keywords ? playlist.keywords.split(" ") : "",
      description: playlist.description
    };
  }

  playPause() {
    if (
      this.sharedService.isPlaying &&
      this.sharedService.currentPlayingContentID ==
        (this.playlist.original_id
          ? this.playlist.original_id
          : this.playlist.playlist_id)
    ) {
      this.messageService.updatePlayerDetails({ pause: true });
    } else {
      this.playlist.playlistList = Object.assign([], this.relatedPlaylists);
      this.playlist.playlistList.unshift(this.playlist);
      this.playlist.playlistIndex = 0;
      this.sharedService.loadPlaylistToPlayer(this.playlist);
    }
  }
  copyEmbedCode() {
    this.embedLink =
      '<iframe src="' +
      baseUrl +
      "/embedded-player/playlist/" +
      this.playlist.original_id +
      '" scrolling="no" width="593" height="386" frameborder="0"></iframe>';
    setTimeout(() => {
      var copyText = document.getElementById("embedText");
      copyText["select"]();
      document.execCommand("copy");
      this.sharedService.showAlert(
        "הקוד להטמעה הועתק ללוח",
        false,
        true,
        "",
        "אחלה",
        true
      );
    });
  }

  handleClickEvent() {
    this.playlist = this.sharedService.tempPlaylist;
    if (this.sharedService.isPlatformBrowser) {
      window.scrollTo(0, 0);
    }
  }

  downloadPlaylist() {
    if (this.sharedService.isFreeUser()) {
      this.sharedService.showCreateUserPopup();
    }
  }

  toHHMMSS(full_seconds) {
    var sec_num = parseInt(full_seconds, 10);
    var hours: any = Math.floor(sec_num / 3600);
    var minutes: any = Math.floor((sec_num - hours * 3600) / 60);
    var seconds: any = sec_num - hours * 3600 - minutes * 60;
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return hours + ":" + minutes + ":" + seconds;
  }

  like() {
    if (!this.sharedService.user) {
      this.sharedService.showCreateUserPopup();
      return;
    }
    this.sharedService.updateLikedContent(this.playlist.original_id);
    if (!this.playlist.is_liked) {
      this.analyticsService.sendEvent(
        "playlists",
        "add_to_favorites",
        "הוספה_למוזיקה_שאהבת",
        this.playlist.original_id
      );
      this.playlist.is_liked = true;
      let like = bodymovin.loadAnimation({
        container: document.getElementById("like-animation"),
        loop: false,
        autoplay: true,
        path: "assets/animations/Likeddesktop.json"
      });
      setTimeout(() => {
        this.likeImageSrc = "liked-icon.svg";
      }, 700);
      like.addEventListener("complete", () => {
        like.destroy();
      });
    } else {
      this.analyticsService.sendEvent(
        "playlists",
        "remove_from_favorites",
        "הוספה_למוזיקה_שאהבת",
        this.playlist.original_id
      );
      this.playlist.is_liked = false;
      this.likeImageSrc = "like-icon.svg";
    }
  }

  sendAnalytics(type) {
    switch (type) {
      case "facebookmessanger":
        this.analyticsService.sendEvent(
          "playlists",
          "share_playlist",
          "facebook_messanger_sharing",
          this.playlist.original_id
        );
        break;
      case "facebook":
        this.analyticsService.sendEvent(
          "playlists",
          "share_playlist",
          "facebook_sharing",
          this.playlist.original_id
        );
        break;
      case "whatsapp":
        this.analyticsService.sendEvent(
          "playlists",
          "share_playlist",
          "whatsApp_sharing",
          this.playlist.original_id
        );
        break;
      case "email":
        this.analyticsService.sendEvent(
          "playlists",
          "share_playlist",
          "email_sharing",
          this.playlist.original_id
        );
        break;
      case "native":
        this.analyticsService.sendEvent(
          "playlists",
          "share_playlist",
          "native_sharing",
          this.playlist.original_id
        );
        break;
    }
  }

  openSnackBar(message: string, data: any) {
    this.analyticsService.sendEvent(
      "playlists",
      "player_media_playlist",
      "song_click",
      data.song_name.split(" ").join("%20")
    );
    this._snackBar.open(message, null, {
      duration: 2000
    });
  }
}
